import { isMysqlError } from 'src/lib/util';
import { SocketErrorResponse } from 'src/types/pantheon/socket.types';
import { v4 } from 'uuid';

export const makeSocketID = (): string => v4();

export const parseResponse = (message: unknown): string => {
  if (typeof message === 'string') return message;
  if (isMysqlError(message)) {
    if (message.sqlMessage) return message.sqlMessage;
    return `Database error - ${message.code}`;
  }

  return JSON.stringify(message);
};

export const isSocktErrorResponse = (obj: unknown): obj is SocketErrorResponse => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    (obj as SocketErrorResponse).code !== undefined &&
    (obj as SocketErrorResponse).queryID !== undefined &&
    (obj as SocketErrorResponse).message !== undefined &&
    (obj as SocketErrorResponse).code !== 200
  );
};
