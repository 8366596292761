import { TableContainer, Typography, Table, TableHead, TableRow, TableCell, TableBody, createStyles, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useSocketEmit } from 'src/modules/websocket/hooks';
import { APIRequest, APIGetResponse } from 'src/types/pantheon/pantheon.types';
import { StyledDivider } from '../styled/StyledDivider';
import { BlockObjectTree, ComparisonData, SnapshotObject } from 'src/types/shared/notice.sql.types';
import { green, red, yellow } from '@material-ui/core/colors';
import { StyledTooltip } from '../styled';
import { makeDateRange } from 'src/lib/util';

const useStyles = makeStyles(() =>
  createStyles({
    cell: {
      padding: '2px 12px',
    },

    addedBlock: {
      backgroundColor: green[200],
    },
    cancelledBlock: {
      backgroundColor: red[200],
    },
    changedBlock: {
      backgroundColor: yellow[200],
    },
  }),
);

// headers for outage table

export const OutageTable = ({
  fields = ['Date', 'Plant', 'Reason', 'Impact', 'GIC Notice'],
  ...params
}: {
  title?: string;
  fields?: string[];
  asset?: number;
  entity?: number;
  blockObjects?: SnapshotObject;
  comparison?: ComparisonData;
}): JSX.Element => {
  const { blockObjects, comparison, asset, entity, title } = params;
  const classes = useStyles();

  const [emit] = useSocketEmit('pantheon.api');
  const [blocks, setBlocks] = React.useState<BlockObjectTree[] | undefined>(blockObjects ? Object.values(blockObjects).sort((a: BlockObjectTree, b: BlockObjectTree) => moment(a.startDate).diff(moment(b.startDate))) : undefined);

  // data fetch
  React.useEffect(() => {
    if (!blockObjects)
      emit<APIRequest, APIGetResponse<{ block: BlockObjectTree }>>(
        {
          method: 'get',
          table: 'api_block_objects',
          schema: 'gic_disclosure',
        },
        data => {
          if (!data?.message) return; // no response
          setBlocks(data.message.filter(row => !((asset && row.block.assetID !== asset) || (entity && row.block.operatorID !== entity))).map(row => row.block));
        },
      );
  }, []);

  const BlockTable = React.useCallback(() => {
    // keys for comparison objects if set (if not comparing, blank array to simplify later code)
    const changedKeys = comparison ? Object.keys(comparison.changed) : [];
    const cancelledKeys = comparison ? Object.keys(comparison.cancelled) : [];
    const addedKeys = comparison ? Object.keys(comparison.added) : [];

    return (
      <>
        <TableContainer style={{ paddingBottom: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant={'h5'} style={{ textAlign: 'left' }}>
              {title || `GIC Outage Disclosure Calendar - ${comparison ? `Comparison` : `Current`}`}
            </Typography>
            {/* <Button startIcon={<ImportExportIcon />} variant={'contained'} color={'primary'} onClick={() => console.log('blockCSV')}>
              Export table as CSV
            </Button> */}
          </div>
          <StyledDivider />
          <Table size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                {fields.map((header: string, key: number) => (
                  <TableCell key={key} align='left' style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {blocks && blocks.length > 0 ? (
                blocks.map((row: BlockObjectTree, key: number) => {
                  let extraClass = '';

                  //TODO extract out into component main scope
                  // wrapper to add tooltip with changes to blocks that have been changed
                  const RowWrapper = ({ children }: { children: JSX.Element }) =>
                    comparison && changedKeys.includes(row.blockID.toString()) ? (
                      <StyledTooltip
                        title={
                          <>
                            <Typography color='inherit'>Outage changes</Typography>
                            <ul>
                              {Object.keys(comparison.changed[row.blockID.toString()].from).map((key: string) => (
                                <li key={key}>
                                  Field ({key}) change from (<b>{comparison.changed[row.blockID.toString()].from[key as keyof Partial<BlockObjectTree>]}</b>) to (
                                  <b>{comparison.changed[row.blockID.toString()].to[key as keyof Partial<BlockObjectTree>]}</b>)
                                </li>
                              ))}
                            </ul>
                          </>
                        }
                        aria-label={`Outage Block #${row.blockID.toString()}`}
                      >
                        {children}
                      </StyledTooltip>
                    ) : (
                      <>{children}</>
                    );

                  // do some styling based on comparison settings
                  if (addedKeys.includes(row.blockID.toString())) extraClass = classes.addedBlock;
                  if (cancelledKeys.includes(row.blockID.toString())) extraClass = classes.cancelledBlock;
                  if (changedKeys.includes(row.blockID.toString())) extraClass = classes.changedBlock;

                  // JSX
                  return (
                    <RowWrapper key={key}>
                      <TableRow className={extraClass}>
                        <TableCell align='left' className={classes.cell}>
                          {makeDateRange(row.startDate, row.endDate)}
                        </TableCell>
                        <TableCell align='left' className={classes.cell}>
                          {row.asset}
                        </TableCell>
                        <TableCell align='left' className={classes.cell}>
                          {row.notes}
                        </TableCell>
                        <TableCell align='left' className={classes.cell}>
                          {row.reductionLow == row.reductionHigh ? `${row.reductionLow} ${row.startDate !== row.endDate ? 'TJ/d' : 'TJ'}` : `${row.reductionLow} - ${row.reductionHigh} ${row.startDate !== row.endDate ? 'TJ/d' : 'TJ'}`}
                        </TableCell>
                        <TableCell align='center' className={classes.cell}>
                          <a href={`https://industrynotifications.gasindustry.co.nz/home/show/${row.gicID}`} target={'_blank'} rel={'noreferrer'}>
                            {row.gicID}
                          </a>
                        </TableCell>
                      </TableRow>
                    </RowWrapper>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={fields.length}>No outages to display</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }, [blocks]);
  return <BlockTable />;
};
