import moment from 'moment';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import * as fieldSettings from 'src/components/Charts/components/config/makeChartField';
import { NexusChart } from 'src/components/Charts/NexusChart';
import { ChartControlWrapper } from 'src/lab/ChartControlWrapper';
import { ChartAccumulatorRadio } from '../../../components/Charts/components/controls/ChartAccumulatorRadio';
import { ChartRangePicker } from '../../../components/Charts/components/controls/ChartRangePicker';
import { ChartStackingRadio } from '../../../components/Charts/components/controls/ChartStackingRadio';
import { ChartTypeRadio } from '../../../components/Charts/components/controls/ChartTypeRadio';
import { GasFlowPage } from './GasFlowPage';

// types for possible parameters
export type FieldCategory = 'production' | 'reserves';
export type FieldFocus = 'field' | 'company' | 'setting';

// guards for parameter checking
const isFieldFocus = (obj: unknown): obj is FieldFocus => typeof obj === 'string' && ['field', 'company', 'setting'].includes(obj);
const isFieldType = (obj: unknown): obj is FieldCategory => typeof obj === 'string' && ['production', 'reserves'].includes(obj);

function FieldView({ match, location }: RouteComponentProps): JSX.Element {
  // split out new url details
  const [category, focus] = location.pathname
    .substring(match.path.length)
    .split('/')
    .filter(item => item.length > 0);

  // check they are valid
  if (!isFieldFocus(focus) || !isFieldType(category)) throw new Error(`Field view focus or category is invalid. Category: (${category}) Focus: (${focus})`);

  // make chart id
  const chartId = 'field-charts'; // `chart-${category}-${focus}`;
  const initialSettings = fieldSettings[`${category}OptionsMap`][focus];

  return (
    <GasFlowPage
      controlsComponent={
        <>
          <ChartControlWrapper title={'Chart Type'} left>
            <ChartTypeRadio id={chartId} initialValue={'column'} />
          </ChartControlWrapper>
          <ChartControlWrapper title={'Chart Stacking'} left>
            <ChartStackingRadio id={chartId} initialValue={'normal'} />
          </ChartControlWrapper>
          <ChartControlWrapper title={'Series Aggregation'} left>
            <ChartAccumulatorRadio id={chartId} initialValue={'normal'} focus={focus} category={category} />
          </ChartControlWrapper>
          <ChartControlWrapper title={'Chart Range'}>
            <ChartRangePicker id={chartId} format={'year'} initialValue={[moment().year(+initialSettings.range[0]), moment().year(+initialSettings.range[1])]} />
          </ChartControlWrapper>
        </>
      }
    >
      <NexusChart id={chartId} settings={[initialSettings]} />
    </GasFlowPage>
  );
}

export { FieldView };
