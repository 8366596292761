import React from 'react';

import { StyledContainer } from 'src/components/styled';
import { PathDefI, SchematicWrapper } from 'src/components/Wrapper/SchematicWrapper';
import { GasSchematic } from 'src/image';
import 'src/style/interactive.css';

const gasDefs: PathDefI[] = [
  {
    class: 'pohokura',
    redirect: 'pohokura',
    parent: 'gas/assets',
  },
];

function Overview(): JSX.Element {
  return (
    <StyledContainer columns={'1fr'} rows={'1fr'}>
      <SchematicWrapper defs={gasDefs}>
        <GasSchematic id={'gas-svg'} style={{ borderRadius: 4 }} />
      </SchematicWrapper>
    </StyledContainer>
  );
}

export { Overview };
