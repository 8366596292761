import { Button, makeStyles } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  linkItem: {
    display: 'flex',
    color: '#fff',
    flexDirection: 'row',
    padding: 0,
    margin: 0,
    textAlign: 'center',
    alignSelf: 'center',
    width: '100%',
    borderRadius: '12px 0px 12px 0px',
    '&:hover': {
      cursor: 'pointer',
      color: '#2fabe1',
      backgroundColor: deepPurple[900],
    },
  },

  icon: {
    display: 'flex',
    height: 30,
  },
  iconLabel: {
    alignSelf: 'center',
  },
});

export interface NavBarItemProps {
  icon: string;
  label: string;
  link: string;
}

export const NavBarItem = (props: NavBarItemProps): JSX.Element => {
  const classes = useStyles();
  const { icon, label, link } = props;

  return (
    <Button component={Link} className={classes.linkItem} to={link}>
      <img src={icon} className={classes.icon} alt='icon' />
      <div className={classes.iconLabel}>{label}</div>
    </Button>
  );
};
