import { TableContainer, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import React, { useState } from 'react';
import { useSP } from 'src/modules/websocket/hooks';
import { MakeTableFlow, MakeTableResponse } from 'src/types/module/tableModule.types';
import { StyledDivider } from '../styled/StyledDivider';

export const FlowTable = (params: { meters: number[] }): JSX.Element => {
  const emit = useSP();

  const [flowTableHeader, setHeader] = useState<string[]>([]);
  const [flowTableContent, setContent] = useState<(string | number)[][]>([[], [], [], [], [], [], []]);

  React.useEffect(
    () =>
      emit<MakeTableResponse[][], MakeTableFlow>({ method: 'execute', procedure: { sp: 'makeTableFlow', database: 'datacore', params } }, response => {
        const tableData = response?.message?.[0]?.[0];
        if (tableData) {
          const { headerRow, contentRows } = tableData;
          if (headerRow && contentRows) {
            setHeader(headerRow);
            setContent(contentRows.map(row => row.map((cell, i) => (typeof cell === 'number' ? (i > 3 ? cell * 100 : cell).toFixed(2) : cell))));
          }
        }
      }),
    [],
  );

  return (
    <TableContainer style={{ paddingBottom: 10, height: 'min-content' }}>
      <Typography variant={'h5'}>Field Statistics</Typography>
      <StyledDivider />
      <Table size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            {flowTableHeader.map((cell: string, key: number) => (
              <TableCell key={key} align='right' style={{ padding: '4px 16px', fontWeight: 'bold', paddingLeft: '5px' }}>
                {cell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {flowTableContent.map((row: (string | number)[], key: number) => (
            <TableRow key={key}>
              {row.map((cell: string | number, key: number) => (
                <TableCell key={key} align={key >= 2 ? 'right' : 'left'} style={{ padding: '2px 12px', color: (key >= 4 && (cell < 0 ? '#cb6e6c' : '#8fb48e')) || '' }}>
                  {key >= 4 ? cell + '%' : cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
