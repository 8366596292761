import React, { useCallback } from 'react';
import HighchartsReact from 'highcharts-react-official';
import sizeMe, { SizeMeProps } from 'react-sizeme';

import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
highchartsMore(Highcharts);

interface PropsI {
  label: string;
  backgroundColor: string;
  style?: React.CSSProperties;
  gaugeHook: 'elec' | 'gas' | 'lpg' | 'oils';
}

const BasicGauge = ({ style, ...otherprops }: PropsI & SizeMeProps): JSX.Element => {
  const { label, size, backgroundColor } = otherprops;

  const [value] = [1];

  const gaugeOptions: Highcharts.Options = {
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    } as Highcharts.NavigationOptions,
    chart: {
      type: 'gauge',
      backgroundColor: 'transparent',
      height: size.height,
      width: size.width,
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      plotBorderWidth: 0,
      margin: [0, 0, 0, 0],
    } as Highcharts.ChartOptions,
    title: {
      text: undefined,
    },
    pane: {
      startAngle: -22.5,
      endAngle: 202.5,
      size: '100%',
      innerSize: '30%',
      center: ['40%', '50%'],
      // size: 30,
      background: [
        {
          backgroundColor: Highcharts.defaultOptions.legend?.backgroundColor || '#EEE',
          innerRadius: '0%',
          outerRadius: '105%',
          shape: 'arc',
        },
      ],
    },
    // the value axis
    yAxis: {
      min: -0.25,
      max: 3.25,
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      minorTicks: true,
      minorTickInterval: 0.5,
      plotBands: [
        {
          from: 0,
          to: 1,
          color: '#55BF3B', // green
        },
        {
          from: 1,
          to: 2,
          color: '#DDDF0D', // yellow
        },
        {
          from: 2,
          to: 3,
          color: '#DF5353', // red
        },
      ],
    },
    series: [
      {
        name: label,
        data: [value],
        tooltip: {
          valueSuffix: ' level',
        },
        dataLabels: {
          enabled: false,
        },
      } as Highcharts.SeriesGaugeOptions,
    ],
  };
  const Gauge = useCallback(() => <HighchartsReact containerProps={{ style: { padding: 0, margin: 0 } }} highcharts={Highcharts} constructorType={'chart'} options={gaugeOptions} />, [value]);
  return <Gauge />;
};

const Gauge = sizeMe({ monitorHeight: true })(BasicGauge);

export { Gauge };
