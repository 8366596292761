import { UserPass, HashSet } from '../types';
import crypto from 'crypto';

// detructures a forms input tags
export const inputTagDestructure = (formId = ''): Record<string, boolean | string> => {
  const formEl = document.getElementsByTagName('form')[0] || document.getElementById(formId);

  let formIn: Record<string, boolean | string> = {};

  const el: HTMLCollectionOf<HTMLInputElement> = formEl.getElementsByTagName('input');

  for (let i = 0; i < el.length; i++) {
    const values: Record<string, boolean | string> = {
      checkbox: el[i].checked,
      text: el[i].value,
      password: el[i].value,
    };
    // TODO eh?
    formIn = { ...formIn, [el[i].id]: values[el[i].type] };
  }

  return formIn;
};

// generate user & hash from credentials
export function generateCredentials({ username, password }: UserPass): HashSet {
  const passwordDigestSha256 = crypto.createHash('sha256').update(password).digest('hex').toUpperCase();

  return {
    user: username.toLowerCase(),
    hash: passwordDigestSha256,
  };
}
