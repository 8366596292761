import { ClassNameMap } from '@material-ui/styles';
import { NodeTreeDefinition, TreeNode, TreeNodeConfig, TreeNodeStyleMap } from './makeTree';

export const makeTreeConfig = (config: TreeNodeConfig[], styles: ClassNameMap<'primaryNode' | 'secondaryNode' | 'tertiaryNode' | 'quarternaryNode'>): NodeTreeDefinition => {
  const nodeStyles: TreeNodeStyleMap = {
    quarternaryNode: {
      color: '#b62fe3',
      bgColor: '#fcefe3',
      depth: 3,
      labelStyle: styles.quarternaryNode,
    },
    primaryNode: {
      color: '#1a73e8',
      bgColor: '#e8f0fe',
      depth: 0,
      labelStyle: styles.primaryNode,
    },
    secondaryNode: {
      color: '#a250f5',
      bgColor: '#f3e8fd',
      depth: 1,
      labelStyle: styles.secondaryNode,
    },
    tertiaryNode: {
      color: '#e3742f',
      bgColor: '#fcefe3',
      depth: 2,
      labelStyle: styles.tertiaryNode,
    },
  };

  const parseConfig = (config: TreeNodeConfig[]): { treeDefinition: TreeNode[]; nodeCount: number } => {
    let nodeCount = 0;

    const makeBranch = (config: TreeNodeConfig[]): TreeNode[] => {
      return config.reduce<TreeNode[]>((prev, curr) => {
        const { nodeStyle, childNodeStyle, childNodes, depth, childDepth, ...rest } = curr;

        const thisNode: TreeNode = {
          ...rest,
          ID: nodeCount++,
          nodeStyle: nodeStyle && { ...nodeStyles[nodeStyle], depth },
          childNodeStyle: childNodeStyle && { ...nodeStyles[childNodeStyle], depth: childDepth },
          childNodes: childNodes && makeBranch(childNodes),
        };

        return [...prev, thisNode];
      }, []);
    };
    return {
      treeDefinition: makeBranch(config),
      nodeCount,
    };
  };

  const output = parseConfig(config);
  return output;

  // const treeDefinition: TreeNode[] = [
  //   {
  //     ID: expandedArray.push(i++),
  //     text: 'Upstream',
  //     info: 2,
  //     nodeStyle: primaryNodeStyle,
  //     childNodeStyle: secondaryNodeStyle,
  //     childNodes: [
  //       {
  //         ID: expandedArray.push(i++),
  //         text: 'Reserves',
  //         info: 3,
  //         childNodeStyle: tertiaryNodeStyle,
  //         childNodes: [
  //           {
  //             ID: expandedArray.push(i++),
  //             text: 'By Field',
  //             navigate: 'upstream/reserves/field',
  //           },
  //           {
  //             ID: expandedArray.push(i++),
  //             text: 'By Company',
  //             navigate: 'upstream/reserves/company',
  //           },
  //           {
  //             ID: expandedArray.push(i++),
  //             text: 'By Offshore/offshore',
  //             navigate: 'upstream/reserves/setting',
  //           },
  //         ],
  //       },
  //       {
  //         ID: expandedArray.push(i++),
  //         text: 'Production',
  //         info: 3,
  //         childNodeStyle: tertiaryNodeStyle,
  //         childNodes: [
  //           {
  //             ID: expandedArray.push(i++),
  //             text: 'By Field',
  //             navigate: 'upstream/production/field',
  //           },
  //           {
  //             ID: expandedArray.push(i++),
  //             text: 'By Company',
  //             navigate: 'upstream/production/company',
  //           },
  //           {
  //             ID: expandedArray.push(i++),
  //             text: 'By Setting',
  //             navigate: 'upstream/production/setting',
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     ID: expandedArray.push(i++),
  //     text: 'Midstream',
  //     info: 8,
  //     nodeStyle: primaryNodeStyle,
  //     childNodeStyle: secondaryNodeStyle,
  //     childNodes: [
  //       {
  //         ID: expandedArray.push(i++),
  //         text: 'Storage',
  //         info: 2,
  //         nodeStyle: { ...tertiaryNodeStyle, depth: 1 },
  //         navigate: 'midstream/storage',
  //       },
  //       {
  //         ID: expandedArray.push(i++),
  //         text: 'Welded Points',
  //         info: 2,
  //         childNodeStyle: tertiaryNodeStyle,
  //         childNodes: [
  //           { ID: expandedArray.push(i++), text: 'Vector', navigate: 'midstream/points/vector' },
  //           { ID: expandedArray.push(i++), text: 'Maui', navigate: 'midstream/points/maui' },
  //           { ID: expandedArray.push(i++), text: 'Consolidated', navigate: 'midstream/points' },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     ID: expandedArray.push(i++),
  //     text: 'Downstream',
  //     info: 8,
  //     nodeStyle: primaryNodeStyle,
  //     childNodeStyle: { ...tertiaryNodeStyle, depth: 1 },
  //     childNodes: [
  //       {
  //         ID: expandedArray.push(i++),
  //         text: 'By sector',
  //         navigate: 'downstream/sector',
  //       },

  //       {
  //         ID: expandedArray.push(i++),
  //         text: 'By sector and company',
  //         navigate: 'downstream/sector/company',
  //       },
  //       {
  //         ID: expandedArray.push(i++),
  //         text: 'By sector and asset',
  //         navigate: 'downstream/sector/asset',
  //       },
  //       {
  //         ID: expandedArray.push(i++),
  //         text: 'Methanex',
  //         navigate: 'downstream/methanex',
  //       },
  //     ],
  //   },
  // ];

  // return { treeDefinition, expandArray: expandedArray };
};
