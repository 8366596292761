import { Button, ButtonProps, darken, lighten, Paper, Typography, WithTheme } from '@material-ui/core';
import { LinkButton } from 'src/components/LinkButton';
import { withStyles, withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import React from 'react';
import { WithStyles } from '@material-ui/styles';
import { EnergyStreamRowProps } from './styling.types';

// const myBlueDarker =
// const myBlue = darken(myBlueDarker, 0.4);

export const EnergyStreamContainer = withTheme(styled(Paper)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-template-rows: repeat(3, 1fr);
  width: -webkit-fill-available;
  padding: 0px;
  overflow: hidden;
  gap: 0px;
  flex-direction: column;
`);

export const SubMenuContainer = withTheme(styled('div')`
  overflow-x: hidden;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: left;
  /* color: ${props => props.theme.palette.text.primary}; */
  display: flex;
  flex-grow: 1;
  width: max-content;
`);

export const SubMenuBox = withTheme(styled('div')`
  overflow-x: hidden;
  width: max-content;
  height: 100%;
`);

export const Container = withTheme(styled('div')`
  display: flex;
  width: -webkit-fill-available;
  padding: 0px;
  gap: 16px;
  flex-direction: column;
`);

export const EnergyStreamInteractive = withTheme(styled(Paper)`
  display: flex;
  padding: 2px;
  gap: 0px;
  flex-direction: column;
`);

export const EnergyStreamRow = withTheme(styled.div<EnergyStreamRowProps>`
  display: flex;
  background-color: ${props => (props.colour === 'white' ? darken(props.theme.palette.background.paper, 0.0) : darken(props.theme.palette.background.paper, 0.2))};
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 25%;
  gap: ${props => props.theme.spacing(2)}px;
  padding: ${props => props.theme.spacing(2)}px;
`);

export const EnergyStreamLabel = styled(Typography).attrs(props => ({
  variant: 'h6',
  component: 'div',
  ...props,
}))`
  width: 5rem;
  display: flex;
  writing-mode: vertical-lr;
  justify-content: center;
  justify-self: center;
  font-stretch: expanded;
  font-weight: bolder;
`;

export const EnergyStreamButton = withStyles<WithStyles<'label' & 'root', true>, Record<string, string>, ButtonProps>(theme => ({
  root: {
    width: 'min-content',
    display: 'flex',
    justifyContent: 'center',
    justifySelf: 'center',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.7),
      color: '#000',
    },
    boxShadow: theme.shadows[1],
  },
  label: {
    ...theme.typography.h6,
    fontStretch: 'expanded',
    fontWeight: 'bolder',
    writingMode: 'vertical-lr',
  },
}))(props => <Button variant={'outlined'} {...props} />);

export const EnergyStreamItem = withTheme(styled(LinkButton).attrs(props => ({
  variant: 'contained',
  ...props,
}))`
  text-transform: none;
  display: flex;
  width: 7.5rem;
  background-color: ${({ theme }) => theme.palette.streamButton.selected};
  color: ${({ theme }) => theme.palette.getContrastText(theme.palette.streamButton.selected)};
  &:hover {
    background-color: ${({ theme }) => darken(theme.palette.myOrange, 0.2)};
    color: ${({ theme }) => theme.palette.getContrastText(darken(theme.palette.myOrange, 0.2))};
  }
`);

export const EnergyStreamOption = withTheme(styled(Button).attrs(props => ({
  variant: 'contained',
  fullWidth: true,
  ...props,
}))<WithTheme & ButtonProps & { selected?: boolean }>`
  text-transform: none;
  display: flex;
  background-color: ${({ selected, theme }) => (selected ? theme.palette.myBlueDarker : theme.palette.primary.main)};
  color: ${({ selected, theme }) => theme.palette.getContrastText(selected ? theme.palette.myBlueDarker : theme.palette.primary.main)};
  &:hover {
    background-color: ${({ selected, theme }) => (selected ? darken(theme.palette.myBlueDarker, 0.2) : darken(theme.palette.primary.main, 0.2))};
  }
`);

export const StyledGasSectionButton = withTheme(styled(Button).attrs(props => ({
  variant: 'contained',
  fullWidth: true,
  ...props,
}))<WithTheme & ButtonProps & { selected?: boolean }>`
  display: flex;
  background-color: ${({ selected, theme }) => (selected ? theme.palette.sectionButton.selected : theme.palette.primary.main)};
  font-weight: bold;
  color: ${({ selected, theme }) => theme.palette.getContrastText(selected ? theme.palette.sectionButton.selected : theme.palette.primary.main)};
  &:hover {
    background-color: ${({ selected, theme }) => (selected ? darken(theme.palette.sectionButton.selected, 0.2) : darken(theme.palette.primary.main, 0.2))};
  }
`);
