import { MakeSeriesTypes, MakeSeriesDiscriminators, MakeSeriesChartOptions, MakeSeriesRequest, MakeChartEditorVariables, MakeSeriesOptions, MakeSeriesExtraOptions } from 'src/types/module/chartModule.types';

// functions to split union types and extended interfaces of chart make/options. mainly to avoid having unused vars everywhere

// returns object with
// makeInterface:  MakeSeries discriminator
// request: raw MakeSeries that can be sent to server
export const stripMakeDiscriminator = (makeRequest: MakeSeriesTypes): { makeInterface: MakeSeriesDiscriminators; request: MakeSeriesRequest } => {
  const { _makeInterface, ...request } = makeRequest;
  return { makeInterface: _makeInterface, request };
};

// returns object with
// internalOptions: extra series options
// request: MakeSeries interface object
export const stripMakeOptions = (makeOptions: MakeSeriesChartOptions<MakeSeriesTypes>): { internalOptions: MakeSeriesExtraOptions; request: MakeSeriesTypes } => {
  const { options, seriesOptions, ...request } = makeOptions;
  return {
    internalOptions: { options, seriesOptions },
    request,
  };
};

// returns object with
// internalOptions: extra series options
// request: MakeSeries interface object
export const stripSeriesMakeOptions = (makeOptions: MakeSeriesOptions<MakeSeriesTypes>): { internalOptions: Pick<MakeSeriesExtraOptions, 'seriesOptions'>; request: MakeSeriesTypes } => {
  const { seriesOptions, ...request } = makeOptions;
  return {
    internalOptions: { seriesOptions },
    request,
  };
};

// returns object with
// other: some MakeSeries properties
// request: of properties that can be edited in the object editor
export const stripEditorVariables = (makeOptions: MakeSeriesTypes): { other: Omit<MakeSeriesTypes, keyof MakeChartEditorVariables>; request: MakeChartEditorVariables } => {
  const { _makeInterface, range, ...request } = makeOptions;
  return { other: { _makeInterface, range }, request };
};
