import * as React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';
import { Unauthorised } from './';

interface AuthorisedRouteProps extends RouteProps {
  axxGroup?: number;
  axx?: number;
}

export const AuthorisedRoute = (props: AuthorisedRouteProps): JSX.Element => {
  const { isAuthenticated, user } = useAppSelector(state => state.auth);

  const { axxGroup, axx, ...routeProps } = props;

  // send to login (with referred) when not auth
  if (!isAuthenticated || !user) {
    return <Redirect to={{ pathname: '/login', state: { referrer: window.location.pathname } }} />;
  }

  const effectiveUserAxx = user.axx || -1;
  const effectiveGroupAxx = user.axxGroup || -1;

  const axxGroupValid = axxGroup ? axxGroup === effectiveGroupAxx : true;
  const axxLevelValid = axx ? axx <= effectiveUserAxx : true;

  // access level 100 overrides any group settings
  const unauthorised = (!axxGroupValid || !axxLevelValid) && effectiveUserAxx === 100;

  if (unauthorised) return <Unauthorised />;

  // render route
  return <Route {...routeProps} />;
};
