import { TableContainer, PaperProps, Paper, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, createStyles, Theme } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { AccordionChild } from 'src/components/Accordion';
import { useSnackSend } from 'src/hooks/useSnackSend';
import { useAPI } from 'src/modules/websocket/hooks';
import { TableField } from 'src/types/pantheon/pantheon.types';
import { AccordionTitle } from './AccordionTitle';
import { EditableRow } from './EditableRow';
import { SectionToolbar } from './SectionToolbar';
import { SourceDefinition, DataRowType } from './types';
import { useTableStyles } from './useTableStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      color: theme.palette.common.white,
    },
    accordionDetail: {
      padding: theme.spacing(0, 2, 2, 2),
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacing(2)}px`,
    },
    accordionSummary: { margin: 0 },
    actionButtons: {
      textAlign: 'end',
      maxWidth: 20,
    },
  }),
);
export const DataSection = (props: { i: number; name: string; source: SourceDefinition; opener: (url: string) => () => void }): JSX.Element => {
  const { i, name, source, opener } = props;
  const { table, fields, fk } = source;
  const columns = ['ID', 'date', ...fields];

  const { sendSnack } = useSnackSend();
  const classes = { ...useStyles(), ...useTableStyles() };
  const [data, setData] = React.useState<{ fieldTypes: TableField[]; rows: DataRowType[] }>({ fieldTypes: [], rows: [] });
  const emit = useAPI();

  React.useEffect(() => {
    if (data.fieldTypes.length === 0 || data.rows.length === 0) return;
  }, [data]);

  // fetch for data
  React.useEffect(() => {
    emit<DataRowType>(
      {
        method: 'get',
        schema: 'nzed',
        table,
        fields: columns,
        fk,
        last: {
          orderFields: ['date'],
          order: 'DESC',
          count: 10,
        },
        info: {
          fieldInfo: true,
        },
      },
      response => {
        if (!response?.message || !response.tableFields || response.code !== 200) return;
        setData({ fieldTypes: response.tableFields, rows: response.message });
      },
    );
  }, []);

  const addRow = () => {
    if (!data.rows.find(row => !row.ID)) setData(state => ({ ...state, rows: [{}, ...state.rows] }));
    else sendSnack('You must finalize your current entry before adding another', 'warning');
  };

  return (
    <AccordionChild heading={<AccordionTitle>{name}</AccordionTitle>} id={i.toString()} className={classes.accordion} summaryProps={{ className: classes.accordionSummary }} detailProps={{ className: classes.accordionDetail }}>
      <SectionToolbar name={name} source={source} openUrl={opener} addRow={addRow} />

      <TableContainer
        id={`confo-table-${name}`}
        component={({ children, ...props }: PropsWithChildren<PaperProps>) => (
          <Paper elevation={2} {...props}>
            {children}
          </Paper>
        )}
      >
        <Table style={{ tableLayout: 'fixed' }}>
          <TableHead className={classes.tableHeader}>
            <TableRow className={classes.headerRow}>
              <TableCell className={classes.headerCell} style={{ width: 'min-content' }}>
                <span className={classes.commonHeader}>Record ID</span>
              </TableCell>
              <TableCell className={classes.headerCell}>
                <span className={classes.commonHeader}>Date</span>
              </TableCell>
              {fields.map((field: string) => (
                <TableCell key={`${field}-title`} className={classes.commonCell}>
                  <span className={classes.commonHeader}>{field}</span>
                </TableCell>
              ))}

              <TableCell className={clsx(classes.headerCell, classes.actionButtons)}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rows.map((row: DataRowType, i: number) => (
              <EditableRow key={i} index={i} data={row} fields={data.fieldTypes} setter={setData} newRow={!row.ID && i === 0} source={source} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AccordionChild>
  );
};
