import moment from 'moment';
import { mergeDeep } from 'src/lib/util';
import { MakeSeasonalTimeSeriesSP, MakeCoreTimeSeriesSP, SeriesRollupType, MakeSeriesChartOptions, MakeSeriesOptionsMap, CoreTimeSeries } from 'src/types/module/chartModule.types';
import { lineChart } from '..';
import { createMakeMap, createOptionsArrayMap } from '../../lib/util';
import { monthCategories } from '../common';
import { gasFieldOptionsMap } from '../makeTimeSeries';

const seasonalBase: Pick<MakeSeriesChartOptions<MakeSeasonalTimeSeriesSP>, '_makeInterface' | 'range'> = {
  _makeInterface: CoreTimeSeries.Seasonal,
  range: [(moment().year() - 4).toString(), moment().year().toString()],
};

export const withSeasonal = (series: MakeSeriesChartOptions<MakeCoreTimeSeriesSP>, rollup: SeriesRollupType = 'month'): MakeSeriesChartOptions<MakeSeasonalTimeSeriesSP> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { xAxisColumn, seriesNames, seriesColumns, tableStatement, _makeInterface, ...rest } = series;
  return {
    dataColumn: seriesColumns[0],
    dateColumn: xAxisColumn,
    ...rest,
    ...seasonalBase,
    rollup,

    options: mergeDeep<Highcharts.Options>({}, lineChart, {
      title: {
        text: 'Yearly comparison',
      },
      xAxis: {
        type: 'category',

        categories: monthCategories,
      },
    }),
  };
};

export const seasonalChartOptionsMap: MakeSeriesOptionsMap<MakeSeasonalTimeSeriesSP> = {
  pohokura: withSeasonal(gasFieldOptionsMap.pohokura, 'month'),
};

export const seasonalChartMakeMap = createMakeMap(seasonalChartOptionsMap);
export const seasonalChartOptionsArrayMap = createOptionsArrayMap(seasonalChartOptionsMap);
