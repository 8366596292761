import { toProperNoun } from '@skipnz/juno';
import Highcharts from 'highcharts';
import { SeriesOptionsType } from 'highcharts';
import moment from 'moment';
import { mergeDeep } from 'src/lib/util';
import { SPEmitter } from 'src/modules/websocket/hooks/useSP';
import { MakeSeriesTypes, MakeSeriesDiscriminators, MakeChartResponse, AddChartProps, MakeSeriesChartOptions, SeriesRange } from 'src/types/module/chartModule.types';
import { StoredProcedureResponse } from 'src/types/pantheon/pantheon.types';
import { monthCategories } from '../config/common';
import { isNetbackRequest, isTimeSeriesRequest } from './isNetbackRequest';

export const makeSeriesName = (request: MakeSeriesTypes): string => {
  if (isNetbackRequest(request)) return `${request.name} (${request.referenceNode}) ${toProperNoun(request.fuelType.toString())}, ${request.heatRate} HR`;
  if (isTimeSeriesRequest(request)) return `time series name`;
  return `could  not determine series type`;
};

export const makeAddChartProps = (settings: MakeSeriesChartOptions<MakeSeriesTypes>[], optionsOverride?: Highcharts.Options, rangeOverride?: SeriesRange): Omit<AddChartProps, 'chart' | 'container'> => {
  console.log(settings);
  return settings.reduce<Omit<AddChartProps, 'chart' | 'container'>>(
    (prev, curr) => {
      if (!curr) return prev;
      const { options, range, ...rest } = curr;
      // merges previous series/current series specific chart options, and override options set in props
      const newOptions = mergeDeep<Highcharts.Options>({}, prev.options, options || {}, optionsOverride || {});

      // if range override set, use that for each series, otherwise use series
      return { options: newOptions, request: [...prev.request, { ...rest, range: rangeOverride || range }], range: rangeOverride || range };
    },
    { options: {}, request: [], range: ['', ''] },
  );
};

export const isLineSeries = (obj: SeriesOptionsType): obj is Highcharts.SeriesLineOptions => obj.type === 'line';
export const isColumnSeries = (obj: SeriesOptionsType): obj is Highcharts.SeriesColumnOptions => obj.type === 'column';
export const isAreaSeries = (obj: SeriesOptionsType): obj is Highcharts.SeriesAreaOptions => obj.type === 'area';
export const isPieSeries = (obj: SeriesOptionsType): obj is Highcharts.SeriesPieOptions => obj.type === 'pie';
export const isLCASeries = (obj: SeriesOptionsType): obj is Highcharts.SeriesLineOptions | Highcharts.SeriesColumnOptions | Highcharts.SeriesAreaOptions => isLineSeries(obj) || isColumnSeries(obj) || isAreaSeries(obj);

export const makeSeriesFetch = (sp: MakeSeriesDiscriminators, emit: SPEmitter) => async (params: Omit<MakeSeriesTypes, '_makeInterface'>): Promise<MakeChartResponse> => {
  const response = await new Promise<StoredProcedureResponse<MakeChartResponse[][]> | undefined>(resolve =>
    emit<MakeChartResponse[][], Omit<MakeSeriesTypes, '_makeInterface'>>(
      {
        method: 'execute',
        procedure: {
          database: 'datacore',
          sp,
          params,
        },
      },
      response => resolve(response),
    ),
  );
  return new Promise<MakeChartResponse>(resolve => {
    if (response && response.code !== 200) return { series: [], error: response?.message };
    const data = response?.message?.[0]?.[0];
    if (!data) return { series: [], error: 'No data returned' };
    if (!data.series) return { series: [], error: 'No series returned' };
    const { dataSort, seriesSort, ...callbackData } = data;

    // todo more series are needing this so need to re-factor it out
    // data post-query processing

    // sort series order
    if (seriesSort) {
      // check seriesSort info and set whether to use max or min function
      const minMax = seriesSort[0] === 'latest' ? Math.max : Math.min;

      // find value of latest / oldest point (target point)
      const targetPoint = callbackData.series.reduce<number>((prev, curr) => {
        if (isColumnSeries(curr) && curr.data) {
          return minMax(
            curr.data.reduce<number>((jPrev, jCurr) => {
              return Array.isArray(jCurr) && typeof jCurr[0] === 'number' ? minMax(jCurr[0], jPrev) : jPrev;
            }, 0),
            prev,
          );
        }
        return prev;
      }, 0);

      // sort the series
      callbackData.series.sort((a, b) => {
        // short circuit ...
        //todo only works with columns... add other series types?
        if (!isColumnSeries(a) || !a.data || !isColumnSeries(b) || !b.data) return 0;

        // get point which matches the target timestamp
        const aPoint = a.data.findIndex(item => Array.isArray(item) && item[0] === targetPoint);
        const bPoint = b.data.findIndex(item => Array.isArray(item) && item[0] === targetPoint);

        // get value for target timestamp
        const aTuple = aPoint >= 0 && Array.isArray(a.data[aPoint]) ? a.data[aPoint] : [-1, -1];
        const bTuple = bPoint >= 0 && Array.isArray(b.data[bPoint]) ? b.data[bPoint] : [-1, -1];

        // calculate sort index (as ascending)
        const sortIndex = (aTuple && bTuple && Array.isArray(aTuple) && Array.isArray(bTuple) && aTuple[1] - bTuple[1]) || 0;

        // if order is descending, invert the sort index
        return seriesSort[1] === 'asc' ? sortIndex : sortIndex * -1;
      });
    }

    // sort point order in each series (ensures axis are in ascending x-value order)
    if (dataSort) {
      switch (dataSort) {
        case 'alpha':
          callbackData.series.map(series => {
            return !isLCASeries(series) ? series : series.data?.sort((a, b) => (Array.isArray(a) && typeof a[0] === 'string' && Array.isArray(b) && typeof b[0] === 'string' ? (a[0] < b[0] ? -1 : +1) : 0));
          });
          break;
        case 'month':
          callbackData.series.map(series =>
            !isLCASeries(series) ? series : series.data?.sort((a, b) => (Array.isArray(a) && typeof a[0] === 'string' && Array.isArray(b) && typeof b[0] === 'string' ? monthCategories.indexOf(a[0]) - monthCategories.indexOf(b[0]) : 0)),
          );
          break;
        case 'date':
          callbackData.series.map(series =>
            !isLCASeries(series) ? series : series.data?.sort((a, b) => (Array.isArray(a) && typeof a[0] === 'string' && Array.isArray(b) && typeof b[0] === 'string' ? moment(a[0], 'YYYY-MM-DD').diff(moment(b[0], 'YYYY-MM-DD')) : 0)),
          );
          break;
        case 'unix_ms':
          callbackData.series.map(series => (!isColumnSeries(series) ? series : series.data?.sort((a, b) => (Array.isArray(a) && typeof a[0] === 'number' && Array.isArray(b) && typeof b[0] === 'number' ? a[0] - b[0] : 0))));
          break;
      }
    }

    // check response is defined
    resolve(callbackData);
  });
};
