import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SeriesRollupType } from 'src/types/module/chartModule.types';
import { useChart } from 'src/components/Charts/components/Context';

export function ChartRollupRadio({ id, initialValue }: { id: string; initialValue?: SeriesRollupType }): JSX.Element {
  const [value, setValue] = React.useState<SeriesRollupType>(initialValue || 'none');
  const [chart, , fetchNewData] = useChart(id);

  const options: [SeriesRollupType, string][] = [
    ['none', 'None'],
    ['hour', 'Hourly'],
    ['day', 'Daily'],
    ['month', 'Monthly'],
    // ['quarter', 'Quarterly'],
    ['year', 'Yearly'],
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = (event.target as HTMLInputElement).value as SeriesRollupType;

    const chartInstance = chart();

    // get chart instance
    if (!chartInstance) throw new Error(`Could not retreive chart instance with id ${id}`);

    chartInstance.nexus.currentConfig.request = chartInstance.nexus.currentConfig.request.map(request => {
      if (type === 'none') request.rollup = undefined;
      else request.rollup = type;
      return request;
    });

    fetchNewData(chartInstance.nexus.currentConfig);

    setValue(type);
  };

  const optionControls = React.useMemo(() => options.map(option => <FormControlLabel key={option[0]} value={option[0]} control={<Radio />} label={option[1]} />), [value]);

  return (
    <RadioGroup aria-label='chartType' name='chartType' value={value} onChange={handleChange}>
      {optionControls}
    </RadioGroup>
  );
}
