import { createStyles, Divider, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { amber } from '@material-ui/core/colors';
import { useHistory } from 'react-router';
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
      height: 'inherit',
    },
    divider: {
      marginTop: 3,
      marginBottom: 7,
      height: '1px',
    },
    hoverRows: {
      '& .MuiTableRow-hover': {
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: amber[50],
        },
      },
    },
  }),
);

export type TableContentCell = string | number;

export type TableContentRow = TableContentCell[];

export interface GenericTableProps {
  headerRow?: string[];
  contentRows: TableContentRow[];
  rowLinks?: [number, string][];
  title?: string;
  noHR?: boolean;
  columnWidth?: string;
}

export function NexusTable(props: GenericTableProps): JSX.Element {
  const { headerRow, contentRows, title, noHR, columnWidth, rowLinks } = props;
  const history = useHistory();
  const classes = useStyles();
  const extraStyle = columnWidth ? { width: columnWidth } : {};

  return (
    <TableContainer className={clsx({ [classes.hoverRows]: Boolean(rowLinks) })} style={{ paddingBottom: 10, display: 'flex', flexDirection: 'column' }}>
      {title && <Typography variant={'h5'}>{title}</Typography>}
      {title && !noHR && <Divider className={classes.divider} />}
      <Table size='small' aria-label='a dense table' padding='none'>
        {headerRow && (
          <TableHead>
            <TableRow style={{ backgroundColor: '#00adef' }}>
              {headerRow.map((cell: string, key: number) => (
                <TableCell key={key} align='left' style={{ fontWeight: 'bold', paddingLeft: '5px', ...extraStyle }}>
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {contentRows.map((row: TableContentRow, key: number) => {
            const rowLink = rowLinks?.find(rowLink => rowLink[0] === key)?.[1];
            const isHover = rowLinks && Boolean(rowLink);
            return (
              <TableRow onClick={rowLink ? () => history.push(`/${rowLink}`) : undefined} hover={isHover} key={key}>
                {row.map((cell: TableContentCell, key: number) => (
                  <TableCell key={key} align='left' style={{ ...extraStyle, color: (key >= 4 && (cell < 0 ? '#cb6e6c' : '#8fb48e')) || '' }}>
                    {key >= 4 ? cell + '%' : cell}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
