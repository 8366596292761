import React from 'react';
import { SeriesSelectionList } from '.';
import { generationNetbackOptionsMap } from 'src/components/Charts/components/config/makeNetback';
import { makeSeriesName } from '../lib/makeSeries';
import { useChart } from '../Context';
import { v4 } from 'uuid';

export type SeriesItem = { name: string; id: string };

function ChartManager(props: { id: string; closeModal: () => void }): JSX.Element {
  const { closeModal, id } = props;
  const [chart, chartSeries] = useChart(id);
  if (!chart) return <div>Could not find chart instance in context</div>;

  const currentSeries: SeriesItem[] = chart().series.map(series => ({ name: series.name, id: series.nexus.id }));
  const availableSeries: SeriesItem[] = Object.values(generationNetbackOptionsMap).map(series => ({ name: makeSeriesName(series), id: v4() }));

  const hasId = (source: SeriesItem[], series: SeriesItem) => Boolean(source.find(item => item.id === series.id));

  const onApply = (selected: SeriesItem[], unselected: SeriesItem[]) => {
    closeModal();
    const seriesToAdd = selected.filter(series => !hasId(currentSeries, series));
    const seriesToRemove = currentSeries.filter(series => hasId(unselected, series));

    seriesToRemove.map(series => chartSeries.remove(series.id));
    seriesToAdd.map(series => {
      const seriesOptions = Object.values(generationNetbackOptionsMap).find(sourceSeries => makeSeriesName(sourceSeries) === series.name);
      seriesOptions && chartSeries.add(seriesOptions);
    });
  };

  return <SeriesSelectionList chartId={id} currentSeries={currentSeries} availableSeries={availableSeries} onApply={onApply} onDiscard={closeModal} />;
}
export { ChartManager as SeriesManager };
