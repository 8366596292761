/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useChart } from 'src/components/Charts/components/Context';
import { grey } from '@material-ui/core/colors';
import { useModal } from 'src/components/Modal';
import { TransferList, NexusTransferList } from './NexusTransferList';
import { Button } from '@material-ui/core';
import { generateOatisDaily } from 'src/components/Charts/components/config/makeTimeSeries';
import { oatisPointToMeters } from './lib';
import { useAppSelector } from 'src/redux/hooks';

export function ChartFilterSelect({ id, title, initialValue = [], items }: { id: string; title: string; initialValue?: TransferList; items: TransferList }): JSX.Element {
  const [openModal, closeModal] = useModal();
  const [chart, chartSeries] = useChart(id);
  const meterStore = useAppSelector(state => state.metadata.oatisGasMeter);

  const [selectedFilter, setSelected] = React.useState<TransferList>(initialValue);
  const unselectedFilter = React.useMemo<TransferList>(() => items.filter(item => !selectedFilter.map(selected => selected.id).includes(item.id)), [selectedFilter]);

  const handleApply = (selected: TransferList) => {
    const chartRange = chart().nexus.currentConfig.range;
    const removedSeriesIndex = selectedFilter.reduce<number[]>((prev, curr, index) => (selected.find(item => curr.id === item.id) ? prev : [...prev, index]), []);
    const addedSeries = selected.reduce<TransferList>((prev, curr) => (selectedFilter.find(item => curr.id === item.id) ? prev : [...prev, curr]), []);

    removedSeriesIndex.map(index => {
      chartSeries.remove(index);
    });

    removedSeriesIndex.length > 0 && chart().series.map(series => (series.nexus.id = series.index));

    const newRequests = addedSeries.map(series => generateOatisDaily(series.name, [series.name], [oatisPointToMeters(series.id, meterStore)], chartRange));
    newRequests.map(request => {
      chartSeries.add(request);
    });

    newRequests.length === 0 && chart().redraw();
    setSelected(selected);
  };

  const openFilterModal = () => {
    openModal({
      children: <NexusTransferList closeModal={closeModal} chartId={'7'} selected={selectedFilter} unselected={unselectedFilter} onApply={handleApply} />,
      boxProps: { style: { backgroundColor: grey[200], padding: 0 } },
      title,
      // container: directRef.current.container.current,
    });
  };
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const type = (event.target as HTMLInputElement).value as SeriesChartType;
  //   const chartInstance = chart();
  //   // get chart instance
  //   if (!chartInstance) throw new Error(`Could not retreive chart instance with id ${id}`);
  //   // change chart series type
  //   const allSeries = chartInstance.series;
  //   allSeries.map(series => series.update({ type }, false));
  //   chartInstance.redraw();
  //   setValue(type);
  // };

  // const optionControls = React.useMemo(() => options.map(option => <FormControlLabel key={option[0]} value={option[0]} control={<Radio />} label={option[1]} />), [value]);

  return (
    <Button variant={'contained'} color={'primary'} onClick={openFilterModal}>
      Add/remove welded points
    </Button>
  );
}
