/* eslint-disable @typescript-eslint/ban-types */
import { IconButton, createStyles, makeStyles, SvgIconTypeMap, Theme } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import { StyledTooltip } from './styled/StyledTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '& svg': {
        margin: theme.spacing(1.5),
      },
      width: '2.4rem',
      height: '2.4rem',
      borderRadius: '2px 2px 0px 0px',
      '&:hover': {
        transition: '0.5s ease',
        color: 'rgb(154, 195, 191)',
        backgroundColor: '#dadada',
      },
    },
  }),
);
interface StyledIconButtonProps {
  tooltip: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  autoMargin?: 'left' | 'right';
}

const UberIconButton = (props: StyledIconButtonProps): JSX.Element => {
  const { onClick, tooltip, Icon, autoMargin } = props;
  const classes = useStyles();

  const marginProperty = autoMargin
    ? {
        [autoMargin === 'left' ? 'marginLeft' : 'marginRight']: 'auto',
      }
    : {};

  return (
    <StyledTooltip title={tooltip}>
      <IconButton className={classes.button} style={marginProperty} onClick={onClick}>
        <Icon />
      </IconButton>
    </StyledTooltip>
  );
};

export { UberIconButton };
