import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { WritableDraft } from '@reduxjs/toolkit/node_modules/immer/dist/internal';
import { DecodedJWT } from 'src/modules/auth/types';

// Define a type for the slice state
export interface AuthStore {
  isAuthenticated: boolean;
  user?: DecodedJWT;
}

// Define the initial state using that type
const initialState: AuthStore = { isAuthenticated: false };

export const authSlice = createSlice({
  name: 'authSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    auth: (state, data: PayloadAction<DecodedJWT>) => {
      state.isAuthenticated = true;
      state.user = data.payload;
    },
    deauth: state => {
      state.isAuthenticated = false;
      state.user = undefined;
    },
  },
});

export const { auth, deauth } = authSlice.actions;
