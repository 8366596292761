import React from 'react';
import { Typography, Divider, TextField, FormControl, Button, List } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { NoticeInterface, OutageInterface, CancelItem, OutageBlockInterface, CancelTarget } from '../common/notice.types';
import { OutagePane } from './OutagePane';
import { PaperItem } from 'src/components/styled/Grid';
import { useSocket } from 'src/modules/websocket/hooks/useSocket';
import { SocketResponse } from 'src/types/pantheon/socket.types';
import { OutageObject, BlockObject, NoticeObject } from '../common/notice.sql.types';
import { GenericListItem } from './GenericListItem';
import { NoticeCancel } from './NoticeCancel';
import { makeBlankNotice, makeBlankOutage } from './util';
import { noticeEntryStyles } from './styles';
import { APIRequest } from 'src/types/pantheon/pantheon.types';

const NoticeForm = ({ noticeRef, clear, noticeID, cancelList }: { noticeRef: React.MutableRefObject<NoticeInterface>; cancelList: React.MutableRefObject<CancelItem[]>; clear: boolean; noticeID?: number }): JSX.Element => {
  const classes = noticeEntryStyles();

  const [noticeState, setNoticeState] = React.useState<NoticeInterface>({ ...noticeRef.current });

  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined);
  console.log({
    method: 'get',
    schema: 'gic_disclosure',
    table: 'api_notice_objects',
    fk: ['noticeID', noticeID],
  });
  const [noticeData] = useSocket<SocketResponse<{ noticeID: number; notice: NoticeObject }[]>, APIRequest>(
    'pantheon.api',
    noticeID
      ? {
          method: 'get',
          schema: 'gic_disclosure',
          table: 'api_notice_objects',
          fk: ['noticeID', noticeID],
        }
      : undefined,
  );

  React.useEffect(() => {
    if (noticeData?.message && Array.isArray(noticeData.message)) {
      noticeData;
      if (noticeData.message[0].notice) {
        const notice = noticeData.message[0].notice;
        noticeRef.current = {
          ...notice,
          noticeDate: moment(notice.noticeDate, 'YYYY-MM-DD'),
          outages: notice.outages.map((outage: OutageObject) => ({
            ...outage,
            blocks: outage.blocks.map((block: BlockObject) => ({
              ...block,
              startDate: moment(block.startDate, 'YYYY-MM-DD'),
              endDate: moment(block.endDate, 'YYYY-MM-DD'),
            })),
          })),
        };
        setNoticeState(noticeRef.current);
      } else alert('Could not find notice ' + noticeID);
    }
  }, [noticeData]);

  React.useEffect(() => void (noticeRef.current = { ...noticeState }), [noticeState]);

  React.useEffect(() => {
    setSelectedIndex(undefined);
    setNoticeState(makeBlankNotice());
  }, [clear]);

  const handleChange = (property: keyof typeof noticeState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNoticeState(state => ({ ...state, [property]: value }));
  };

  const handleDateChange = (noticeDate: MaterialUiPickersDate): void => void (noticeDate && setNoticeState(state => ({ ...state, noticeDate })));

  const addOutage = () => setNoticeState(state => ({ ...state, outages: [...state.outages, makeBlankOutage('New Outage')] }));

  const removeOutage = (index: number, reason?: number) => () => {
    if (reason) {
      // cancel outage
      cancelList.current.push({
        target: 'outage',
        id: noticeState.outages[index].id,
        reason,
      });
      // cancel all child outage blocks too
      cancelList.current.push(...noticeState.outages[index].blocks.map((block: OutageBlockInterface) => ({ target: 'outage_block' as CancelTarget, id: block.id, reason })));
    }
    if (selectedIndex === index) setSelectedIndex(undefined);
    setNoticeState(state => ({ ...state, outages: state.outages.filter((item: OutageInterface, outageIndex: number) => outageIndex !== index) }));
  };

  const clickOutage = (index: number) => () => setSelectedIndex(index);

  const setOutageDescription = React.useCallback((id: number, description: string) => {
    setNoticeState(state => ({ ...state, outages: state.outages.map((outage: OutageInterface, oIndex: number) => (oIndex === id ? { ...outage, description } : outage)) }));
  }, []);

  return (
    <>
      <PaperItem flex colSpan={1} rowSpan={2} className={classes.noticeRoot}>
        <Typography variant={'h5'}>GIC Notice Entry</Typography>
        <Divider className={classes.divider} />
        <TextField id='notice-id' type={'number'} required label='GIC Notice Number' value={noticeState.gicNoticeID || ''} onChange={handleChange('gicNoticeID')} variant='outlined' className={classes.formControl} />
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'en'}>
          <FormControl variant='outlined' className={classes.formControl}>
            <KeyboardDatePicker
              format='DD-MM-YYYY'
              variant='inline'
              inputVariant='outlined'
              id='notice-date'
              label='GIC Notice Date'
              value={noticeState.noticeDate}
              onChange={handleDateChange}
              autoOk
              KeyboardButtonProps={{
                'aria-label': 'change start date',
              }}
            />
          </FormControl>
        </MuiPickersUtilsProvider>

        <Button startIcon={<NoteAddIcon />} variant={'contained'} color={'primary'} onClick={addOutage}>
          Add outage
        </Button>

        <div className={classes.list}>
          <List>
            {noticeState.outages.map((outage: OutageInterface, index: number) => (
              <GenericListItem name={'outage'} key={`outage-${index}`} text={outage.description} index={index} click={clickOutage} remove={removeOutage} withReason={Boolean(noticeID)} />
            ))}
          </List>
        </div>
        {noticeID && <NoticeCancel noticeRef={noticeRef} />}
      </PaperItem>
      {selectedIndex !== undefined && <OutagePane setter={setOutageDescription} id={selectedIndex} noticeRef={noticeRef} noticeID={noticeID} cancelList={cancelList} />}
    </>
  );
};

export { NoticeForm };
