import { mergeDeep } from 'src/lib/util';
import { MakeFieldTimeSeries, MakeSeriesChartOptions } from 'src/types/module/chartModule.types';
import { columnChart } from '..';
import { createSeriesMaps } from '../common';
import { fieldDefault } from './common';

const productionBySetting: MakeSeriesChartOptions<MakeFieldTimeSeries> = {
  ...fieldDefault,
  metric: 1,
  dataType: 'production',
  groupingColumn: 'fieldType',
  options: mergeDeep<Highcharts.Options>({}, columnChart, fieldDefault.options as Highcharts.Options, {
    title: {
      text: 'Field production, by setting',
    },
  }),
};

const productionByCompany: MakeSeriesChartOptions<MakeFieldTimeSeries> = {
  ...fieldDefault,
  metric: 1,
  dataType: 'production',
  groupingColumn: 'ownerName',
  options: mergeDeep<Highcharts.Options>({}, columnChart, fieldDefault.options as Highcharts.Options, {
    title: {
      text: 'Field production, by company',
    },
  }),
};

const productionByField: MakeSeriesChartOptions<MakeFieldTimeSeries> = {
  ...fieldDefault,
  metric: 1,
  dataType: 'production',
  groupingColumn: 'field',
  options: mergeDeep<Highcharts.Options>({}, columnChart, fieldDefault.options as Highcharts.Options, {
    title: {
      text: 'Field production, by field',
    },
  }),
};

const kupe: MakeSeriesChartOptions<MakeFieldTimeSeries> = { ...productionByField, fieldID: [1] };
const pohokura: MakeSeriesChartOptions<MakeFieldTimeSeries> = { ...productionByField, fieldID: [14] };
const mm: MakeSeriesChartOptions<MakeFieldTimeSeries> = { ...productionByField, fieldID: [8, 10] };
const maui: MakeSeriesChartOptions<MakeFieldTimeSeries> = { ...productionByField, fieldID: [9] };
const greymouth: MakeSeriesChartOptions<MakeFieldTimeSeries> = { ...productionByField, fieldID: [6, 22] };

// individual field production (matched to oatis data basis)
export const [gasFieldOptionsMap, gasFieldMakeMap, gasFieldArrayMap] = createSeriesMaps({
  kupe,
  pohokura,
  mm,
  maui,
  greymouth,
});

// production sliced by setting, company, or field
export const [productionOptionsMap, productionMakeMap, productionOptionsArrayMap] = createSeriesMaps({
  setting: productionBySetting,
  company: productionByCompany,
  field: productionByField,
});
