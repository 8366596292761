import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@material-ui/core';
import Highcharts from 'highcharts';
import { grey } from '@material-ui/core/colors';
import { useModal } from '../Modal';
import { SeriesManager } from './components/SeriesManager';
import { useChartContext } from './components/Context';
import { ChartProps, HighchartChartRef, MakeSeriesTypes } from 'src/types/module/chartModule.types';
import { v4 } from 'uuid';
import { makeAddChartProps } from './components/lib/makeSeries';

const NexusChart = ({ options, range, settings, id }: ChartProps<MakeSeriesTypes>): JSX.Element => {
  const theme = useTheme();
  const [openModal, closeModal] = useModal();

  // strip timerange and highcharts options from parameter, rest is sent to server
  const addChartProps = makeAddChartProps(settings, options, range);

  // chart context
  const [addChart, , removeChart] = useChartContext();
  const chartId = React.useRef<string>(id || v4());
  //! this ref can be undefined!!
  const directRef = React.useRef<HighchartChartRef>((null as unknown) as HighchartChartRef);

  React.useEffect(() => {
    // once we have the ref to the chart, add it via the chart context interface
    if (directRef.current.chart) {
      const { chart, container } = directRef.current;

      addChart({ ...addChartProps, chart, container }, chartId.current);
    }

    // todo cleanup may not be neccessary? generally fails anyway as the chart itself re-renders when props change
    return () => {
      removeChart(chartId.current);
    };
  }, [settings]);

  //TODO turn into HOC that can be called and pass the closeModal as parameter
  const chartLoaded: Highcharts.ChartCallbackFunction = chart => {
    // load chart

    // menu item defs - take copy for mutating
    const menuItems = [...(chart.options.exporting?.buttons?.contextButton?.menuItems || [])];
    const menuItemDefinitions = chart.options.exporting?.menuItemDefinitions || {};

    menuItems.push('separator');

    // add chart manager options
    menuItems.push('manageSeries');
    menuItemDefinitions.manageSeries = {
      text: 'Chart Series Manager',
      onclick: function () {
        openModal({
          children: <SeriesManager closeModal={closeModal} id={chartId.current} />,
          boxProps: { style: { backgroundColor: grey[200], padding: 0 } },
          title: 'Chart Series Manager',
          container: directRef.current.container.current,
        });
      },
    } as Highcharts.ExportingMenuObject;

    // menu items to remove
    const excludedItems = ['downloadPNG', 'downloadPDF', 'downloadSVG', 'downloadXLS', 'viewData'];

    // update chart options
    chart.update({
      exporting: {
        showTable: false,
        buttons: {
          contextButton: {
            ...chart.options?.exporting?.buttons?.contextButton,
            menuItems: menuItems.filter(item => !excludedItems.includes(item)),
          },
        },
        menuItemDefinitions,
      },
    });
  };

  // returns memoed chart object and dispatches for changing settings
  return (
    <HighchartsReact
      ref={directRef}
      callback={chartLoaded}
      containerProps={{ style: { width: '100%', height: '100%', borderRadius: theme.shape.borderRadius } }}
      highcharts={Highcharts}
      constructorType={'chart'}
      options={addChartProps.options}
    />
  );
};

export { NexusChart };
