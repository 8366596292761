import React from 'react';

import { makeStyles, Theme, createStyles, lighten } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { SkipBox, SkipPaper } from 'src/style/components/styledFlex';
import { IconButton, ListItemSecondaryAction, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { ActionButtonGroup } from 'src/components/ActionButtonGroup';
import { intersection, not } from 'src/components/Charts/components/lib/util';
import AutoSizer from 'react-virtualized-auto-sizer';
import { areEqual, VariableSizeList } from 'react-window';
import memoizeOne from 'memoize-one';
import { ObjectEditor } from '../ObjectEditor';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
    },
    paper: {
      width: 200,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
      height: 'max-content',
      '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.8),
      },
    },

    editBackdropRoot: {},
  }),
);

export type TransferListItem = { id: number | string; name: string };
export type TransferList = TransferListItem[];

export function NexusTransferList({
  chartId,
  selected = [],
  unselected = [],
  closeModal,
  onApply,
}: {
  chartId: string;
  selected?: TransferList;
  unselected?: TransferList;
  closeModal?: () => void;
  onApply: (selected: TransferList) => void;
}): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const initialSelection = React.useRef<{ selected: TransferList; unselected: TransferList }>({ selected, unselected });
  const [checked, setChecked] = React.useState<TransferList>([]);
  const [left, setLeft] = React.useState<TransferList>(selected);
  const [right, setRight] = React.useState<TransferList>(unselected);
  const [editModalOpen, setEditModal] = React.useState<TransferListItem>();

  const leftChecked = intersection<TransferListItem>(checked, left);
  const rightChecked = intersection<TransferListItem>(checked, right);

  const handleToggle = (value: TransferListItem) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not<TransferListItem>(left, leftChecked));
    setChecked(not<TransferListItem>(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not<TransferListItem>(right, rightChecked));
    setChecked(not<TransferListItem>(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const RowRenderer = React.memo(({ data, index, style }: { data: { items: TransferList; handleToggle: (value: TransferListItem) => () => void; edit?: boolean }; index: number; style: React.CSSProperties }) => {
    const { items, handleToggle, edit } = data;
    const value = items[index];

    return (
      <ListItem key={value.id} role='listitem' button onClick={handleToggle(value)} style={style}>
        <ListItemIcon>
          <Checkbox checked={checked.indexOf(value) !== -1} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': `transfer-list-item-${value.name}-label` }} />
        </ListItemIcon>
        <ListItemText id={`transfer-list-item-${value.name}-label`} style={{ paddingRight: 16 }} primary={value.name} />
        {edit && (
          <ListItemSecondaryAction>
            <IconButton edge='end' aria-label='comments' onClick={() => console.log('edit item')}>
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }, areEqual);

  const makeList = (name: string, items: TransferList, edit?: boolean) => {
    const createItemData = memoizeOne((items: TransferList, handleToggle: (value: TransferListItem) => () => void, edit?: boolean) => ({
      items,
      handleToggle,
      edit,
    }));

    const itemData = createItemData(items, handleToggle, edit);

    return (
      <>
        <Typography
          variant={'h6'}
          style={{
            padding: theme.spacing(1, 2.5),
          }}
        >
          {name}
        </Typography>
        <AutoSizer>
          {({ width }) => (
            <VariableSizeList style={{ overflowY: 'auto', maxHeight: '70vh' }} height={600} itemCount={items.length} estimatedItemSize={40} itemSize={() => 40} width={width} itemData={itemData}>
              {RowRenderer}
            </VariableSizeList>
          )}
        </AutoSizer>
      </>
    );
  };

  const handleApply = () => {
    onApply(left);
    closeModal && closeModal();
  };

  const onReset = () => {
    setChecked([]);
    setLeft([...initialSelection.current.selected]);
    setRight([...initialSelection.current.unselected]);
  };

  const toggleSeriesEdit = (series?: TransferListItem) => () => setEditModal(series);

  return (
    <SkipBox id={'transfer-list'} isGrid rows={'1fr min-content'} cols={'1fr auto 1fr'} p={3} className={classes.root}>
      {/* <ObjectEditor chartId={chartId} setOpenState={toggleSeriesEdit} open={editModalOpen} objectSchema={objectSchema.makeChartNetbackSeries} objectMap={objectSource.generationNetbackMakeMap} edit /> */}

      <SkipPaper flex ga={'1 / 1'} h={'70vh'} d={'column'}>
        {makeList('Selected', left)}
      </SkipPaper>
      <SkipBox h={'100%'} flex ga={'1 / 2'}>
        <SkipBox flex p={2} g={1} style={{ flexDirection: 'column', flexShrink: 1, alignSelf: 'center' }}>
          <Button variant='outlined' size='small' color={'primary'} className={classes.button} onClick={handleAllRight} disabled={left.length === 0} aria-label='move all right'>
            ≫
          </Button>
          <Button variant='outlined' size='small' color={'primary'} className={classes.button} onClick={handleCheckedRight} disabled={leftChecked.length === 0} aria-label='move selected right'>
            &gt;
          </Button>
          <Button variant='outlined' size='small' color={'primary'} className={classes.button} onClick={handleCheckedLeft} disabled={rightChecked.length === 0} aria-label='move selected left'>
            &lt;
          </Button>
          <Button variant='outlined' size='small' color={'primary'} className={classes.button} onClick={handleAllLeft} disabled={right.length === 0} aria-label='move all left'>
            ≪
          </Button>
        </SkipBox>
      </SkipBox>
      <SkipPaper h={'70vh'} flex ga={'1 / 3'} d={'column'}>
        {makeList('Available', right)}
      </SkipPaper>
      <ActionButtonGroup ga={'2 / span 3'} onApply={handleApply} onDiscard={() => closeModal && closeModal()} onReset={onReset} />
    </SkipBox>
  );
}
