import { Dispatch, useEffect, useState, useRef } from 'react';
import { useSocketInstance } from 'src/modules/websocket/context';
import { SocketMessage, SocketRequest, SocketResponse } from 'src/types/pantheon/socket.types';
import { makeSocketID } from '../util';
import { useSnackSend } from 'src/hooks/useSnackSend';

export const useSocket = <TResponse extends SocketResponse<unknown>, TRequest extends SocketRequest>(channel: string, request?: TRequest): [TResponse | null, Dispatch<TRequest>] => {
  const { socket } = useSocketInstance();
  const { sendSnack } = useSnackSend();

  const [query, setQuery] = useState<TRequest | null>(request || null);
  const [data, setData] = useState<TResponse | null>(null);

  const sockID = useRef<string>(makeSocketID());
  const complete = useRef<boolean>(false);
  const sent = useRef<boolean>(false);

  const emit = (message: TRequest) => {
    // remove listener for previous message
    socket.off(sockID.current);

    // new ID and reset
    sockID.current = makeSocketID();
    complete.current = false;
    sent.current = false;

    // emit
    setQuery(message);
  };

  // error response handler
  useEffect(() => void (data && data.code !== 200 && sendSnack((data.message as SocketMessage).message || 'Unknown response', 'error')), [data]);

  useEffect(() => {
    if (query && !complete.current && !sent.current) {
      socket.emit(channel, { queryID: sockID.current, ...query });
      sent.current = true;
      // setImmediate(() =>
      socket.once(sockID.current, (response: TResponse) => {
        complete.current = true;
        setData(response);
      });
      // );
    }

    return () => void socket.off(sockID.current);
  }, [query, complete, sent, sockID]);

  return [data, emit];
};
