import { Button, Collapse, FormControl, InputLabel, Select, MenuItem, makeStyles, createStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useSocket } from 'src/modules/websocket/hooks/useSocket';
import { RPCRequest, RPCResponse } from 'src/types/pantheon/rpc.types';
import { NoticeInterface, NoticeSubmit, CancelItem, OutageInterface, OutageBlockInterface } from '../common/notice.types';
import { useSnackbar } from 'notistack';
import { isMysqlError } from './util';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: '-webkit-fill-available',
      textAlign: 'left',
    },
    container: { marginTop: 'auto' },
  }),
);

const NoticeCancel = ({ noticeRef }: { noticeRef: React.MutableRefObject<NoticeInterface> }): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [response, request] = useSocket<RPCResponse<string>, RPCRequest<NoticeSubmit>>('pantheon.rpc');

  const [cancelOpen, setCancelOpen] = React.useState(false);
  const [reason, setCancelReason] = React.useState<number>(0);

  const toggleCancel = () => setCancelOpen(state => !state);

  const cancelNotice = () => {
    if (reason === 0) return alert('You must choose a cancellation reason');
    const cancelList: CancelItem[] = [
      { target: 'notice', id: noticeRef.current.id, reason },
      ...noticeRef.current.outages
        .map((outage: OutageInterface) => {
          return [
            {
              target: 'outage',
              id: outage.id,
              reason,
            } as CancelItem,
            ...outage.blocks.map((block: OutageBlockInterface) => {
              return { target: 'outage_block', id: block.id, reason } as CancelItem;
            }),
          ];
        })
        .flat(),
    ];

    request({ method: 'patch', procedure: 'submitGIC', parameters: { noticeObject: noticeRef.current, cancelList } });
  };

  React.useEffect(() => {
    // parse server response
    const parseResponse = (message: unknown) => {
      if (typeof message === 'string') return message;
      if (isMysqlError(message)) {
        if (message.sqlMessage) return message.sqlMessage;
        return `Database error - ${message.code}`;
      }

      return JSON.stringify(message);
    };

    if (response) {
      if (response.code === 200) history.push('../view');
      const message = parseResponse(response.message);
      enqueueSnackbar(message, {
        variant: response.code === 200 ? 'success' : 'error',
        persist: true,
        action: (key: string) => (
          <Button aria-label='confirm' variant={'outlined'} onClick={() => closeSnackbar(key)} color={'primary'}>
            DISMISS
          </Button>
        ),
      });
    }
  }, [response]);
  return (
    <div className={classes.container}>
      <Button fullWidth variant={'contained'} color={'primary'} onClick={toggleCancel}>
        cancel notice
      </Button>
      <Collapse in={cancelOpen} className={classes.formControl}>
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='notice-cancel-reason-label'>Notice Cancellation Reason</InputLabel>
          <Select labelId='notice-cancel-reason-label' id='notice-cancel-reason' value={reason} onChange={e => setCancelReason(e.target.value as number)} label='Notice Cancellation Reason'>
            {reason === 0 && (
              <MenuItem value={0} selected={true}>
                <em>Choose outage asset</em>
              </MenuItem>
            )}
            <MenuItem value={1}>Cancelled</MenuItem>
            <MenuItem value={2}>Rescheduled</MenuItem>
            <MenuItem value={3}>User error</MenuItem>
            <MenuItem value={5}>Completed</MenuItem>
            <MenuItem value={4}>Other</MenuItem>
          </Select>
        </FormControl>
        <Button fullWidth variant={'contained'} color={'secondary'} onClick={cancelNotice}>
          confirm
        </Button>
      </Collapse>
    </div>
  );
};

export { NoticeCancel };
