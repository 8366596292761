import React from 'react';
import { Typography, Divider, TextField, FormControl, Button, InputLabel, MenuItem, Select, List } from '@material-ui/core';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { NoticeInterface, CancelItem, OutageBlockInterface, OutageInterface } from '../common/notice.types';
import { OutageBlock } from './OutageBlock';
import { PaperItem } from 'src/components/styled/Grid';
import { APIRequest, APIGetResponse } from 'src/types/pantheon.types';
import { useSocketEmit } from 'src/modules/websocket/hooks/useSocketEmit';
import { GenericListItem } from './GenericListItem';
import { makeBlankOutageBlock } from './util';
import { noticeEntryStyles } from './styles';

type Asset = {
  ID: number;
  name: string;
  entityID: number;
};

type Entity = {
  ID: number;
  name: string;
};

interface PageData {
  assets: JSX.Element[];
  entities: JSX.Element[];
}

const OutagePane = function OutagePane({
  id,
  noticeRef,
  cancelList,
  noticeID,
  setter,
}: {
  id: number;
  noticeRef: React.MutableRefObject<NoticeInterface>;
  cancelList: React.MutableRefObject<CancelItem[]>;
  noticeID?: number;
  setter: (id: number, desc: string) => void;
}): JSX.Element {
  const classes = noticeEntryStyles();

  const [outageState, setState] = React.useState<OutageInterface>({ ...noticeRef.current.outages[id] });
  const [pageData, setPageData] = React.useState<PageData>({ assets: [], entities: [] });

  const [emit] = useSocketEmit('pantheon.api');

  React.useEffect(() => {
    emit<APIRequest, APIGetResponse<Asset>>(
      {
        method: 'get',
        table: 'asset',
        schema: 'gic_disclosure',
        fields: ['ID', 'name', 'entityID'],
      },
      data => {
        const assets = data?.message;
        if (!assets) return; // null response

        setPageData(state => ({
          ...state,
          assets: assets.map((asset: Asset) => (
            <MenuItem key={asset.ID} value={asset.ID}>
              {asset.name}
            </MenuItem>
          )),
        }));
      },
    );
    emit<APIRequest, APIGetResponse<Entity>>(
      {
        method: 'get',
        table: 'entity',
        schema: 'gic_disclosure',
        fields: ['ID', 'name'],
      },
      data => {
        const entities = data?.message;
        if (!entities) return; // null response
        setPageData(state => ({
          ...state,
          entities: entities.map((entity: Entity) => (
            <MenuItem key={entity.ID} value={entity.ID}>
              {entity.name}
            </MenuItem>
          )),
        }));
      },
    );
  }, []);

  React.useEffect(() => {
    setSelectedIndex(undefined);
    setState({ ...noticeRef.current.outages[id] });
  }, [id]);

  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>();

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const description = event.target.value;
    setter(id, description);
    setState(state => ({ ...state, description }));
  };

  const handleSelectChange = (property: keyof Pick<OutageInterface, 'assetID' | 'operatorID' | 'ownerID'>) => (event: React.ChangeEvent<{ value: unknown }>) => setState(state => ({ ...state, [property]: event.target.value as string }));

  const addOutageBlock = () => setState(state => ({ ...state, blocks: [...state.blocks, makeBlankOutageBlock()] }));

  const removeOutageBlock = (index: number, reason?: number) => () => {
    if (reason) {
      cancelList.current.push({
        target: 'outage_block',
        id: noticeRef.current.outages[id].blocks[index].id,
        reason: reason,
      } as CancelItem);
    }
    setState(state => ({ ...state, blocks: state.blocks.filter((item: OutageBlockInterface, blockIndex: number) => index !== blockIndex) }));
    if (selectedIndex === index) setSelectedIndex(undefined);
  };

  const openOutageBlock = (index: number) => () => setSelectedIndex(index);

  React.useEffect(() => void (noticeRef.current.outages[id] = { ...outageState }), [outageState]);

  const setBlockNotes = React.useCallback((id: number, notes: string) => {
    setState(state => ({ ...state, blocks: state.blocks.map((block: OutageBlockInterface, bIndex: number) => (bIndex === id ? { ...block, notes } : block)) }));
  }, []);

  return (
    <>
      <PaperItem flex colSpan={1} rowSpan={2} className={classes.noticeRoot}>
        <Typography variant={'h5'}>Outage ID#{outageState.id}</Typography>
        <Divider className={classes.divider} />
        <TextField id='notice-id' label='Outage Description' value={outageState.description} onChange={handleDescriptionChange} variant='outlined' className={classes.formControl} />
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='outage-asset-label'>Outage Asset</InputLabel>
          <Select labelId='outage-asset-label' id='outage-asset' value={outageState.assetID} onChange={handleSelectChange('assetID')} label='Outage Asset'>
            <MenuItem value={0}>
              <em>Choose outage asset</em>
            </MenuItem>
            {pageData.assets}
          </Select>
        </FormControl>
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='operator-label'>Asset Operator</InputLabel>
          <Select labelId='operator-label' id='operator' value={outageState.operatorID} onChange={handleSelectChange('operatorID')} label='Asset Operator'>
            <MenuItem value={0}>
              <em>Choose asset operator</em>
            </MenuItem>
            {pageData.entities}
          </Select>
        </FormControl>
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='owner-label'>Asset Owner</InputLabel>
          <Select labelId='owner-label' id='owner' value={outageState.ownerID} onChange={handleSelectChange('ownerID')} label='Asset Owner'>
            <MenuItem value={0}>
              <em>Choose asset owner</em>
            </MenuItem>
            {pageData.entities}
          </Select>
        </FormControl>
        <Button startIcon={<NoteAddIcon />} variant={'contained'} color={'primary'} onClick={addOutageBlock}>
          Add outage block
        </Button>
        <div className={classes.list}>
          <List>
            {outageState.blocks.map((block: OutageBlockInterface, index: number) => (
              <GenericListItem
                key={`outage-block-${index}`}
                index={index}
                name={'outage-block'}
                text={block.notes && block.notes.length > 0 ? block.notes : block.startDate.format('DD/MM/YY')}
                click={openOutageBlock}
                remove={removeOutageBlock}
                withReason={Boolean(noticeID)}
              />
            ))}
          </List>
        </div>
      </PaperItem>
      {selectedIndex !== undefined && <OutageBlock setter={setBlockNotes} id={selectedIndex} outageID={id} noticeRef={noticeRef} />}
    </>
  );
};

export { OutagePane };
