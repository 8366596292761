import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Properties } from 'csstype';
import { useRouteMatch, useHistory } from 'react-router';

interface Styles extends Properties {
  '--tree-view-color'?: string;
  '--tree-view-bg-color'?: string;
}

export type StyledTreeItemProps = TreeItemProps & {
  depth: number;
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelStyle?: string;
  labelInfo?: string;
  labelText: string;
  navigate?: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      maxWidth: undefined,
      width: '-webkit-fill-available',
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content,  &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: 'var(--tree-view-color)',
      },
      '&:hover > $content $label': {
        backgroundColor: 'transparent',
      },
      '& .Mui-selected': {
        '& .MuiTreeItem-content': {
          '& .MuiTreeItem-label': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]}) !important`,
          },
          backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]}) !important`,
        },
      },
    },
    content: {
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingLeft: (props: Pick<StyledTreeItemProps, 'depth'>) => theme.spacing(props.depth),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
      paddingRight: theme.spacing(2),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
    iconContainer: {},
  }),
);

export function StyledTreeItem(props: StyledTreeItemProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { navigate, depth, labelText, labelIcon: LabelIcon, labelInfo, labelStyle, color, bgColor, ...other } = props;
  const classes = useTreeItemStyles({ depth });
  const { url } = useRouteMatch();
  const history = useHistory();

  const navigatePage = (target: string) => () => history.push(`${url}/${target}`);
  const clickOverride = navigate ? { onLabelClick: navigatePage(navigate) } : {};

  return (
    <TreeItem
      {...clickOverride}
      label={
        <div className={classes.labelRoot}>
          {/* <LabelIcon color='inherit' className={classes.labelIcon} /> */}
          <Typography variant='body2' className={classes.labelText}>
            {labelText}
          </Typography>
          {/* <Typography variant='caption' color='inherit'>
            {labelInfo}
          </Typography> */}
        </div>
      }
      icon={<></>}
      style={
        {
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        } as Styles
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: clsx(classes.label, labelStyle),
        iconContainer: classes.iconContainer,
      }}
      {...other}
    />
  );
}

export { TreeView };
