import React from 'react';
import { SnackbarProvider } from 'notistack';
import { WebSocketProvider } from './modules/websocket/context';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { theme } from './style/theme';
import moment from 'moment';
import Content from './Content';
// import { AuthContextProvider } from './modules/auth/context';
import { BrowserRouter as Router } from 'react-router-dom';

import { store } from './redux/store';
import { Provider } from 'react-redux';

import 'src/style/highchartGlobals';
import { ModalProvider } from './components/Modal';
import { ChartProvider } from 'src/components/Charts/components/Context';

moment.locale('en-NZ');

export default function App(): JSX.Element {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={10}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Router>
            <WebSocketProvider>
              <ChartProvider>
                <ModalProvider>
                  <Content />
                </ModalProvider>
              </ChartProvider>
            </WebSocketProvider>
          </Router>
        </SnackbarProvider>
      </MuiThemeProvider>
    </Provider>
  );
}
