import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from 'src/components/Copyright';
import { authenticateUser } from 'src/modules/auth/authenticate';
import { Paper } from '@material-ui/core';
import { useSnackSend } from 'src/hooks/useSnackSend';
import { useSocketInstance } from '../websocket/context';
import { RouteProps, useHistory } from 'react-router';
import { useAppSelector } from 'src/redux/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    width: 'fit-content',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const LogIn = (props: RouteProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const { sendSnack } = useSnackSend();
  const { socket } = useSocketInstance();
  const { isAuthenticated } = useAppSelector(state => state.auth);

  const disabled = true;

  React.useEffect(() => {
    if (isAuthenticated) history.push((props.location?.state as { referrer: string } | undefined)?.referrer || '/');
  }, [isAuthenticated]);

  const processLogin = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    authenticateUser(response => {
      if (response.code !== 200) {
        localStorage.clear();
        sendSnack(response.message || 'No reason was given for login error', 'error');
      } else {
        setTimeout(() => {
          localStorage.setItem('isAuthenticated', 'true');

          if (socket.connected) {
            socket.emit('pantheon.user');
          } else {
            socket.disconnect();
            setTimeout(() => socket.connect(), 100);
          }
          // setTimeout(() => {
          //   history.push((props.location?.state as { referrer: string } | undefined)?.referrer || '\\');
          // }, 1000);
        }, 1000);
      }
    });
  };

  return (
    <main className={classes.root}>
      <>
        <Paper elevation={3} className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon style={{ color: '#fff' }} />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField variant='outlined' margin='normal' required fullWidth id='username' label='Username / Email' name='username' autoComplete='username' autoFocus />
            <TextField variant='outlined' margin='normal' required fullWidth name='password' label='Password' type='password' id='password' autoComplete='current-password' />
            {!disabled && <FormControlLabel control={<Checkbox id='remember' value='remember' color='primary' />} label='Remember me' />}
            <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit} onClick={processLogin}>
              Sign In
            </Button>
            <Grid container style={{ justifyContent: 'center' }}>
              {!disabled && (
                <Grid item xs>
                  <Link href='#' variant='body2' color='primary'>
                    Forgot password?
                  </Link>
                </Grid>
              )}
              <Grid item>
                <Link href='mailto:sysop@aotearoa.energy' variant='body2' color='primary'>
                  {'Email SysOp'}
                </Link>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Box mt={8}>
          <Copyright />
        </Box>
      </>
    </main>
  );
};
