import moment from 'moment';
import React from 'react';
import { agsNetOptionsMap } from 'src/components/Charts/components/config/makeAGSNet';
import { ChartRollupRadio } from 'src/components/Charts/components/controls/ChartRollupRadio';
import { NexusChart } from 'src/components/Charts/NexusChart';
import { ChartControlWrapper } from 'src/lab/ChartControlWrapper';
import { ChartRangePicker } from '../../../components/Charts/components/controls/ChartRangePicker';
import { ChartTypeRadio } from '../../../components/Charts/components/controls/ChartTypeRadio';
import { GasFlowPage } from './GasFlowPage';

function Storage(): JSX.Element {
  const settings = agsNetOptionsMap.agsNetFlow;
  return (
    <GasFlowPage
      controlsComponent={
        <>
          <ChartControlWrapper title={'Chart Type'} left>
            <ChartTypeRadio id={'ags'} initialValue={'column'} />
          </ChartControlWrapper>
          <ChartControlWrapper title={'Chart Range'} column>
            <ChartRangePicker id={'ags'} format={'date'} initialValue={[moment(settings.range[0]), moment(settings.range[1])]} />
          </ChartControlWrapper>
          <ChartControlWrapper title={'Data Rollup'} column>
            <ChartRollupRadio id={'ags'} initialValue={'day'} />
          </ChartControlWrapper>
        </>
      }
    >
      <NexusChart id={'ags'} settings={[settings]} />
    </GasFlowPage>
  );
}

export { Storage };
