import { ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';

export interface GenericListItemProps {
  text?: string;
  index: number;
  click: (index: number) => () => void;
  remove: (index: number, reason?: number) => () => void;
  withReason: boolean;
  name: string;
}

const GenericListItem = (props: GenericListItemProps): JSX.Element => {
  const { text, index, click, remove, withReason, name } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [reason, setReason] = React.useState(0);

  const handleCancelClose = (index: number, option: number | null) => () => {
    setAnchorEl(null);
    if (option) {
      setReason(option);
      remove(index, option || undefined)();
    }
  };

  const removeWithReason = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <React.Fragment>
      <ListItem button onClick={click(index)}>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={text} />
        <ListItemSecondaryAction>
          <IconButton edge='end' aria-label='delete' onClick={withReason ? removeWithReason : remove(index)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Menu id={`${name}-cancel-menu-${index}`} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCancelClose(index, null)}>
        {reason === 0 && (
          <MenuItem value={0}>
            <em>Choose outage asset</em>
          </MenuItem>
        )}

        <MenuItem onClick={handleCancelClose(index, 1)} selected={reason === 1}>
          Cancelled
        </MenuItem>
        <MenuItem onClick={handleCancelClose(index, 2)} selected={reason === 2}>
          Rescheduled
        </MenuItem>
        <MenuItem onClick={handleCancelClose(index, 3)} selected={reason === 3}>
          User Error
        </MenuItem>
        <MenuItem onClick={handleCancelClose(index, 5)} selected={reason === 4}>
          Completed
        </MenuItem>
        <MenuItem onClick={handleCancelClose(index, 4)} selected={reason === 4}>
          Other
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export { GenericListItem };
