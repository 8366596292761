import React from 'react';

export type DashboardProduct = 'oils' | 'lpg' | 'gas' | 'elec';
export interface DashboardRowProps {
  label: string;
  labelColour: string;
  gaugeHook: DashboardProduct;
  link: string;
  row: number;
}

export interface InstrumentMetadata {
  prefix: string;
  suffix: string;
  longPrefix: string;
  unit: string;
  description: string;
}

export interface PanelData {
  hydrated: boolean;
  code: string;
  meta?: InstrumentMetadata;
  lastValue?: number;
  lastTime?: string;
  change?: number;
  panelStyle: React.CSSProperties;
  highlight?: 'green' | 'red';
}

export interface InstrumentPacket {
  code: string;
  meta?: InstrumentMetadata;
  lastValue?: number;
  lastTime?: string;
  change?: number;
}

export type PanelSubRow = PanelData[];

// maker function for panels
export const makeInstrument = (code: string, panelStyle?: React.CSSProperties): PanelData => ({ code, hydrated: false, panelStyle: panelStyle || {} });

export const nullPanel = makeInstrument('NULL');

export type ProductRow = {
  upstream: PanelSubRow[];
  midstream: PanelSubRow[];
  downstream: PanelSubRow[];
  [key: string]: PanelSubRow[];
};
