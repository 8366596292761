import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SeriesStackingType } from 'src/types/module/chartModule.types';
import { useChart } from 'src/components/Charts/components/Context';
import { mergeDeep } from 'src/lib/util';
import memoizeOne from 'memoize-one';
import { areEqual } from 'react-window';

export function ChartStackingRadio({ id, initialValue }: { id: string; initialValue: SeriesStackingType }): JSX.Element {
  const [value, setValue] = React.useState<SeriesStackingType>(initialValue);
  const [chart] = useChart(id);

  const options: [SeriesStackingType, string][] = [
    ['none', 'None'],
    ['normal', 'Stacked'],
    ['percent', '100% Stacked'],
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stacking = (event.target as HTMLInputElement).value as SeriesStackingType;
    const chartInstance = chart();
    // get chart instance
    if (!chartInstance) throw new Error(`Could not retreive chart instance with id ${id}`);

    // update chart with new stacking options (global)
    //todo flag on series to indicate which series should be updated?
    const optionsUpdate: Highcharts.Options = { plotOptions: { series: { stacking: stacking === 'none' ? undefined : stacking } } };

    chartInstance.update(optionsUpdate);
    mergeDeep(chartInstance.nexus.currentConfig.options, optionsUpdate);
    setValue(stacking);
  };

  // const currentConfig = memoizeOne(() => chart().nexus.currentConfig, areEqual);

  const optionControls = React.useMemo(() => options.map(option => <FormControlLabel key={option[0]} value={option[0]} control={<Radio />} label={option[1]} />), [value]);

  return (
    <RadioGroup aria-label='chartType' name='chartType' value={value} onChange={handleChange}>
      {optionControls}
    </RadioGroup>
  );
}
