import { Theme, useTheme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Dashboard, DashRow, DashPanel, DashboardProduct } from 'src/components/Dashboard';
import { DashboardProvider } from 'src/components/Dashboard/Context/DashboardContext';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelBox: {
      display: 'grid',
      backgroundColor: 'inherit',
      border: theme.palette.dashboard.border,
      borderLeft: 0,
      borderRight: 0,
      gridTemplateColumns: 'repeat(3,33%)',
      gridTemplateRows: 'repeat(2, 50%)',
    },
    rightBox: {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  }),
);

const EnergyDashboardRow = ({ label, product, colour, link, row }: { label: string; product: DashboardProduct; colour: string; link: string; row: number }) => {
  const data = [
    [
      ['', '', ''],
      ['', '', ''],
    ],
    [
      ['', '', ''],
      ['', '', ''],
    ],
    [
      ['', '', ''],
      ['', '', ''],
    ],
  ];
  const classes = useStyles();
  return (
    <DashRow label={label} labelColour={colour} gaugeHook={product} link={link} row={row}>
      {data.map((section, i) => {
        return (
          <div className={clsx(classes.panelBox, { [classes.rightBox]: i === data.length - 1 })} key={`oils-panel-box-${i}`} style={{ backgroundColor: colour, gridArea: `${row} / ${i + 3} / auto / auto` }}>
            {section.map((block, j) => {
              return block.map((panel, k) => {
                return <DashPanel key={`${panel}-${i}-${j}-${k}`} product={product} location={[i, j, k]} />;
              });
            })}
          </div>
        );
      })}
    </DashRow>
  );
};
export const EnergyDashboard = (): JSX.Element => {
  const theme = useTheme();

  return (
    <DashboardProvider>
      <Dashboard>
        <EnergyDashboardRow label={'OILS'} colour={theme.palette.dashboard.oils} link={'/oil'} product={'oils'} row={1} />
        <EnergyDashboardRow label={'GAS'} colour={theme.palette.dashboard.gas} link={'/gas'} product={'gas'} row={2} />
        <EnergyDashboardRow label={'LPG'} colour={theme.palette.dashboard.lpg} link={'/lpg'} product={'lpg'} row={3} />
        <EnergyDashboardRow label={'ELECTRICITY'} colour={theme.palette.dashboard.electricity} link={'/electricity'} product={'elec'} row={4} />
      </Dashboard>
    </DashboardProvider>
  );
};
