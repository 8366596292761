import { generateCredentials, inputTagDestructure } from './util';
import { UserPass } from '../types';
import { AuthResponse } from './types';
import { store } from 'src/redux/store';
import { deauth } from 'src/redux/reducer/auth';
import { newRequest } from 'src/lib/util';

export const hasResponseCode = (obj: unknown): obj is AuthResponse => (obj as AuthResponse).code !== undefined && typeof (obj as AuthResponse).code === 'number';

export const authenticateUser = (callback: (response: AuthResponse) => void): void => {
  const credentials = generateCredentials(inputTagDestructure() as UserPass);
  newRequest('/pantheon/auth', {
    method: 'POST',
    body: credentials,
  })
    .then((response: Response) => response.json())
    .then((resJSON: AuthResponse) => {
      callback(resJSON);
    });
};

export const revokeAuthentication = (callback?: (response: AuthResponse) => void): void => {
  newRequest('/pantheon/deauth', {
    method: 'POST',
  })
    .then((response: Response) => response.json())
    .then((resJSON: AuthResponse) => {
      localStorage.clear();
      store.dispatch(deauth());
      callback && callback(resJSON);
    });
};
