import { Button, ButtonProps, lighten, Theme } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

const buttonColor = 'rgb(242, 127, 0)';

const buttonStyle = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: buttonColor,
      color: theme.palette.getContrastText(buttonColor),
      '&:hover': {
        backgroundColor: lighten(buttonColor, 0.15),
      },
    },
  });

const StyledButton = (props: WithStyles<typeof buttonStyle> & ButtonProps) => (
  <Button {...props} variant={'contained'} color={'primary'} fullWidth style={{ backgroundColor: buttonColor }}>
    {props.children}
  </Button>
);

const DownloadButton = ({ to, children }: Pick<LinkProps, 'to' | 'children'>): JSX.Element => (
  <Link component={StyledButton} to={to} download>
    {children}
  </Link>
);

const Styled = withStyles(buttonStyle)(DownloadButton);

export { Styled as DownloadCSVButton };
