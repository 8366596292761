import { makeStyles } from '@material-ui/core';
import React from 'react';
import { NavBarItem } from './NavBarItem';
import { gasLightBlue, oilLightBlue, lpgLightBlue } from '../../image';

const useStyles = makeStyles({
  headerBarNav: {
    height: 30,
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-evenly',
  },
});

export const NavLinkBar = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.headerBarNav}>
      <NavBarItem icon={oilLightBlue} label={'OILS'} link={'/oil/overview'} />
      <NavBarItem icon={gasLightBlue} label={'GAS'} link={'/gas/overview'} />
      <NavBarItem icon={lpgLightBlue} label={'LPG'} link={'/lpg/overview'} />
      <NavBarItem icon={gasLightBlue} label={'ELECTRICITY'} link={'/electricity/overview'} />
      <NavBarItem icon={gasLightBlue} label={'CALENDAR'} link={'/calendar'} />
      <NavBarItem icon={lpgLightBlue} label={'NEWS'} link={'/news'} />
      <NavBarItem icon={gasLightBlue} label={'DASHBOARDS'} link={'/dashboards'} />
    </div>
  );
};
