import { makeStyles, createStyles, Button, CircularProgress, ButtonProps, Theme, lighten } from '@material-ui/core';
import { deepPurple, green, red } from '@material-ui/core/colors';
import React, { useEffect } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: deepPurple[500],
      color: theme.palette.getContrastText(deepPurple[500]),
      '&:hover': {
        backgroundColor: lighten(deepPurple[500], 0.15),
      },
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: lighten(green[500], 0.15),
      },
    },
    buttonFailure: {
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: lighten(red[500], 0.15),
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

export type StatefulButtonClickFunction = (setSuccess: (message: string) => void, setFailure: (message: string) => void) => void;

export interface StatefulButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick: StatefulButtonClickFunction;
  timeout?: number;
  label: string;
}

export const StatefulButton = (props: StatefulButtonProps): JSX.Element => {
  const { onClick, timeout, label, ...buttonProps } = props;

  const classes = useStyles();

  const [buttonState, setButtonState] = React.useState<'success' | 'failure' | 'loading' | null>(null);
  const [message, setMessage] = React.useState(label);
  const timer = React.useRef<NodeJS.Timeout>();

  const buttonClassname = clsx(classes.button, {
    [classes.buttonSuccess]: buttonState === 'success',
    [classes.buttonFailure]: buttonState === 'failure',
  });

  const handleButtonClick = (): void => {
    const setSuccess = (message: string) => {
      timer.current && clearTimeout(timer.current);
      setMessage(message);
      setButtonState('success');
    };

    const setFailure = (message: string) => {
      timer.current && clearTimeout(timer.current);
      setMessage(message);
      setButtonState('failure');
    };

    if (buttonState !== 'loading') {
      setButtonState('loading');
      onClick(setSuccess, setFailure);
    }
  };

  useEffect(() => {
    if (buttonState === 'loading') {
      timer.current = setTimeout(() => {
        setButtonState('failure');
        setMessage('Timed out!');
      }, timeout || 30000); // 30 second default
    }
    return (): void => void (timer.current && clearTimeout(timer.current));
  }, [buttonState, setButtonState]);

  return (
    <Button color={'primary'} className={buttonClassname} disabled={buttonState === 'loading'} onClick={handleButtonClick} variant={'contained'} fullWidth {...buttonProps}>
      {message}
      {buttonState === 'loading' && <CircularProgress size={24} className={classes.buttonProgress} />}
    </Button>
  );
};
