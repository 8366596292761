import React from 'react';
import { APIRequest, APIGetResponse } from 'src/types/pantheon/pantheon.types';
import { useSocketEmit } from 'src/modules/websocket/hooks/useSocketEmit';
import { BlockObjectTree } from '../common/notice.sql.types';

import { TableContainer, Typography, Divider, Table, TableHead, TableRow, TableCell, TableBody, createStyles, makeStyles, Theme, Tooltip, withStyles, Button } from '@material-ui/core';
import moment from 'moment';
import { ComparisonData, SnapshotObject } from 'src/types/shared/notice.sql.types';
import { green, yellow, red } from '@material-ui/core/colors';
import clsx from 'clsx';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: '0.5rem',
      height: '-webkit-fill-available',
    },
    divider: {
      marginTop: 3,
      marginBottom: 7,
      height: '1px',
    },
    rowHeader: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      width: 'min-content',
    },
    cell: {
      padding: theme.spacing(1),
      width: 'min-content',
      whiteSpace: 'nowrap',
    },
    evenRow: {
      backgroundColor: theme.palette.background.paper,
    },
    oddRow: {
      backgroundColor: theme.palette.grey[200],
    },
    addedBlock: {
      backgroundColor: green[200],
    },
    cancelledBlock: {
      backgroundColor: red[200],
    },
    changedBlock: {
      backgroundColor: yellow[200],
    },
  }),
);

// date helper functions
const makeShortDate = (sqlDate: string): string => moment(sqlDate, 'YYYY-MM-DD').format('D-MMM');

const makeOutageVolume = (row: BlockObjectTree): React.ReactNode => {
  const { reductionLow, reductionHigh, startDate, endDate } = row;
  const suffix = startDate !== endDate ? 'TJ/d' : 'TJ';

  const reductionRange = `${reductionLow} ${suffix}`;
  const reductionConstant = `${reductionHigh} ${suffix}`;

  if (reductionLow === 0 && reductionHigh === 0) return 'TBA';

  return reductionLow === reductionHigh ? reductionRange : reductionConstant;
};

const makeDateRange = (start: string, end: string): string => {
  const startMoment = moment(start, 'YYYY-MM-DD');
  const endMoment = moment(end, 'YYYY-MM-DD');

  // if not current year
  if (startMoment.year() !== moment().year()) {
    // if different months
    if (startMoment.month() !== endMoment.month()) return `${makeShortDate(start)} - ${endMoment.format('D-MMM, YYYY')}`;

    // more than 1 day
    if (startMoment.date() !== endMoment.date()) return `${startMoment.date()} - ${endMoment.format('D-MMM, YYYY')}`;

    // same month, single day, different year
    return `${startMoment.format('D-MMM, YYYY')}`;
  }

  // this year, but different months
  if (startMoment.month() !== endMoment.month()) return `${makeShortDate(start)} - ${makeShortDate(end)}`;

  // this year, same month, more than 1 day
  if (startMoment.date() !== endMoment.date()) return `${startMoment.date()} - ${makeShortDate(end)}`;

  // this year, single day
  return makeShortDate(start);
};

// headers for outage table
const headers = ['Date', 'Plant', 'Reason', 'Est. Reduction', 'GIC Notice'];

const saveToFile = (content: BlobPart, filename: string, contentType: string) => {
  const a = document.createElement('a');
  const file = new Blob([content], { type: contentType });

  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();

  URL.revokeObjectURL(a.href);
};

const OutageTable = ({ blockObjects, comparison }: { blockObjects?: SnapshotObject; comparison?: ComparisonData }): JSX.Element => {
  // state & socket comms
  const [emit] = useSocketEmit('pantheon.api');
  const [blocks, setBlocks] = React.useState<BlockObjectTree[] | undefined>(blockObjects ? Object.values(blockObjects).sort((a: BlockObjectTree, b: BlockObjectTree) => moment(a.startDate).diff(moment(b.startDate))) : undefined);

  const classes = useStyles();

  // data fetch
  React.useEffect(() => {
    if (!blockObjects)
      emit<APIRequest, APIGetResponse<{ block: BlockObjectTree }>>(
        {
          method: 'get',
          table: 'api_block_objects',
          schema: 'gic_disclosure',
        },
        data => {
          if (!data?.message) return; // no response
          setBlocks(data.message.map(row => row.block));
        },
      );
  }, []);

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  const blockCSV = () => {
    const csv: string[][] = [headers];

    blocks &&
      blocks.length > 0 &&
      blocks.map((row: BlockObjectTree) => {
        csv.push([
          makeDateRange(row.startDate, row.endDate),
          row.asset,
          row.notes || '',
          row.reductionLow == row.reductionHigh ? `${row.reductionLow} ${row.startDate !== row.endDate ? 'TJ/d' : 'TJ'}` : `${row.reductionLow} - ${row.reductionHigh} ${row.startDate !== row.endDate ? 'TJ/d' : 'TJ'}`,
          row.gicID.toString(),
        ]);
      });

    saveToFile(csv.map((row: string[]) => row.map((cell: string) => `"${cell.replace(`"`, `'`)}"`).join(',')).join('\n'), 'test.csv', 'text/csv');
  };

  const BlockTable = React.useCallback(() => {
    // keys for comparison objects if set (if not comparing, blank array to simplify later code)
    const changedKeys = comparison ? Object.keys(comparison.changed) : [];
    const cancelledKeys = comparison ? Object.keys(comparison.cancelled) : [];
    const addedKeys = comparison ? Object.keys(comparison.added) : [];

    return (
      <>
        <TableContainer className={classes.container}>
          <Typography variant={'h5'} style={{ textAlign: 'left' }}>
            GIC Outage Disclosure Calendar - {comparison ? `Comparison` : `Current`}
          </Typography>
          <Divider className={classes.divider} />
          <Table size='small' aria-label='a dense table' padding='none' stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map((header: string, key: number) => (
                  <TableCell key={header} className={classes.rowHeader} align={key === headers.length - 1 ? 'center' : 'left'} style={{ fontWeight: 'bold' }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {blocks && blocks.length > 0 ? (
                blocks.map((row: BlockObjectTree, key: number) => {
                  let extraClass = '';

                  //TODO extract out into component main scope
                  // wrapper to add tooltip with changes to blocks that have been changed
                  const RowWrapper = ({ children }: { children: JSX.Element }) =>
                    comparison && changedKeys.includes(row.blockID.toString()) ? (
                      <HtmlTooltip
                        title={
                          <>
                            <Typography color='inherit'>Outage changes</Typography>
                            <ul>
                              {Object.keys(comparison.changed[row.blockID.toString()].from).map((key: string) => (
                                <li key={key}>
                                  Field ({key}) change from (<b>{comparison.changed[row.blockID.toString()].from[key as keyof Partial<BlockObjectTree>]}</b>) to (
                                  <b>{comparison.changed[row.blockID.toString()].to[key as keyof Partial<BlockObjectTree>]}</b>)
                                </li>
                              ))}
                            </ul>
                          </>
                        }
                        aria-label={`Outage Block #${row.blockID.toString()}`}
                      >
                        {children}
                      </HtmlTooltip>
                    ) : (
                      <>{children}</>
                    );

                  // do some styling based on comparison settings
                  if (addedKeys.includes(row.blockID.toString())) extraClass = classes.addedBlock;
                  if (cancelledKeys.includes(row.blockID.toString())) extraClass = classes.cancelledBlock;
                  if (changedKeys.includes(row.blockID.toString())) extraClass = classes.changedBlock;

                  // JSX
                  return (
                    <RowWrapper key={key}>
                      <TableRow className={clsx(key % 2 === 0 ? classes.evenRow : classes.oddRow, extraClass)}>
                        <TableCell align='left' className={classes.cell}>
                          {makeDateRange(row.startDate, row.endDate)}
                        </TableCell>
                        <TableCell align='left' className={classes.cell}>
                          {row.asset}
                        </TableCell>
                        <TableCell align='left' className={classes.cell}>
                          {row.notes}
                        </TableCell>
                        <TableCell align='left' className={classes.cell}>
                          {makeOutageVolume(row)}
                        </TableCell>
                        <TableCell align='center' className={classes.cell}>
                          <a href={`https://industrynotifications.gasindustry.co.nz/home/show/${row.gicID}`} target={'_blank'} rel={'noreferrer'}>
                            {row.gicID}
                          </a>
                        </TableCell>
                      </TableRow>
                    </RowWrapper>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={headers.length}>No outages to display</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Button startIcon={<ImportExportIcon />} variant={'contained'} color={'primary'} onClick={blockCSV}>
          Export table as CSV
        </Button>
      </>
    );
  }, [blocks]);

  return <BlockTable />;
};

export { OutageTable };
