import { v4 } from 'uuid';

export enum CoreAction {
  INSTRUMENTS,
  CONNECT,
  DISCONNECT,
}

export enum InstrumentAction {
  SUBSCRIBE,
  PUBLISH,
  UNSUBSCRIBE,
}

export enum ClientAction {
  INFO,
}

interface MessageInterface {
  id: string;
  timestamp: number;
}

export class LastMonMessage implements MessageInterface {
  id: string = v4();
  timestamp: number = new Date().valueOf();
}

export class ClientMessage extends LastMonMessage {
  constructor(public action: string, public message: string) {
    super();
  }
}

export class InstrumentMessage extends LastMonMessage {
  constructor(public code: string, public last: number) {
    super();
  }
}

export class CoreMessage extends LastMonMessage {
  constructor(public action: CoreAction, public payload?: Record<string, unknown>) {
    super();
  }
}
