import { Button } from '@material-ui/core';
import React from 'react';
import { SkipBox } from 'src/style/components/styledFlex';
import { SkipBoxProps } from 'src/style/components/styling.types';

export const ActionButtonGroup = ({ onDiscard, onApply, onReset, ...boxProps }: { onDiscard: () => void; onApply: () => void; onReset?: () => void } & SkipBoxProps): JSX.Element => (
  <SkipBox flex g={2} w={'100%'} style={{ paddingTop: 32, marginLeft: 'auto', placeContent: 'flex-end', alignItems: 'flex-end' }} {...boxProps}>
    <Button variant={'outlined'} color={'primary'} onClick={onApply}>
      Apply changes
    </Button>
    {onReset && (
      <Button variant={'outlined'} color={'primary'} onClick={onReset}>
        Reset
      </Button>
    )}
    <Button variant={'outlined'} color={'secondary'} onClick={onDiscard}>
      Discard changes
    </Button>
  </SkipBox>
);
