import { DatePickerView } from '@material-ui/pickers';
import moment, { unitOfTime } from 'moment';
import { MakeSeriesChartOptions, MakeNetbackTimeSeriesSP, MakeSeriesTypes, MakeRangeType, MakeSeriesOptionsMap, SeriesRange, SeriesRollupType } from 'src/types/module/chartModule.types';
import { createMakeMap, createOptionsArrayMap } from '../../lib/util';

// format options for makeRange
export const rangeFormat = { date: 'YYYY-MM-DD', default: 'YYYY-MM-DD HH:mm:ss', year: 'YYYY' };
export const rangeFormatPicker: Record<RangeFormat, string> = { date: 'DD-MM-yyyy', default: 'yyyy-MM-DD HH:mm:ss', year: 'yyyy' };
export const rangeFormatViews: Record<RangeFormat, DatePickerView[]> = { date: ['date'], default: ['date'], year: ['year'] };

export type RangeFormat = keyof typeof rangeFormat;

export const defaultRange: Pick<MakeSeriesChartOptions<MakeNetbackTimeSeriesSP>, 'range'> = {
  range: [moment().subtract(1, 'month').startOf('day').format(rangeFormat.default), moment().endOf('day').format(rangeFormat.default)],
};
export const defaultRangeHourly: Pick<MakeSeriesChartOptions<MakeNetbackTimeSeriesSP>, 'range'> = {
  range: [moment().subtract(1, 'week').startOf('day').format(rangeFormat.default), moment().endOf('day').format(rangeFormat.default)],
};

// range maker for makeChart module. merge return object with makeChart object
export const makeRange = (type: MakeRangeType, count: number, unit: unitOfTime.DurationConstructor, show: RangeFormat = 'default'): Pick<MakeSeriesChartOptions<MakeSeriesTypes>, 'range'> => {
  switch (type) {
    case 'calendar':
      return { range: [moment().subtract(1, unit).startOf(unit).format(rangeFormat[show]), moment().subtract(1, unit).endOf(unit).format(rangeFormat[show])] };
    case 'rolling':
      return { range: [moment().endOf('day').subtract(count, unit).format(rangeFormat[show]), moment().endOf('day').format(rangeFormat[show])] };
    case 'current':
      return { range: [moment().startOf(unit).format(rangeFormat[show]), moment().endOf(unit).format(rangeFormat[show])] };
    case 'toDate':
      return { range: [moment().startOf(unit).format(rangeFormat[show]), moment().format(rangeFormat[show])] };
    case 'previous':
      return { range: [moment().subtract(count, unit).format(rangeFormat[show]), moment().format(rangeFormat[show])] };
  }
};

// wrapper for makeRange which returns the tuple instead of a object with a range property
export const makeRangeProperty = (type: MakeRangeType, count: number, unit: unitOfTime.DurationConstructor, show: RangeFormat = 'default'): SeriesRange => makeRange(type, count, unit, show).range;

// maker for daily category lists
export const makeDailyCategory = (): string[] => {
  const categories: string[] = [];
  const start = moment('01-01-2016', 'DD-MM-YYYY');

  let done = false;

  do {
    categories.push(start.format('D-MMM'));
    start.add(1, 'day');
    if (start.year() === 2017) done = true;
  } while (!done);

  return categories;
};

export const makeMonthlyCategory = (): string[] => {
  const categories: string[] = [];
  const start = moment('01-01-2016', 'DD-MM-YYYY');

  let done = false;

  do {
    categories.push(start.format('MMM'));
    start.add(1, 'month');
    if (start.year() === 2017) done = true;
  } while (!done);

  return categories;
};

export const makeSeasonalCategories = (rollUpPeriod: SeriesRollupType): string[] => {
  switch (rollUpPeriod) {
    case 'day':
      return makeDailyCategory();
    case 'month':
      return makeMonthlyCategory();
    default:
      return [];
  }
};

export const monthCategories = makeSeasonalCategories('month');

export const createSeriesMaps = (seriesMap: MakeSeriesOptionsMap<MakeSeriesTypes>): [MakeSeriesOptionsMap<MakeSeriesTypes>, MakeSeriesTypes[], MakeSeriesChartOptions<MakeSeriesTypes>[]] => [
  seriesMap,
  createMakeMap(seriesMap),
  createOptionsArrayMap(seriesMap),
];
