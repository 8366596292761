import React from 'react';

import { makeStyles } from '@material-ui/core';
import { theme } from 'src/style/theme';
import { StyledContainer } from 'src/components/styled';
import styled from 'styled-components';
import { EnergyDashboard } from './EnergyDashboard';

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(2),
    width: '-webkit-fill-available',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
  },
  contentBox: {
    display: 'flex',
    maxWidth: '25%',
    overflowY: 'auto',
  },
  content: {
    overflowY: 'auto',
  },
  dashboardBox: {},
});

const Papagraph = styled('p')`
  word-wrap: normal;
`;

function Home(): JSX.Element {
  const classes = useStyles();
  return (
    <StyledContainer columns={'repeat(1, 1fr)'} rows={'repeat(1, 1fr)'} style={{ height: 'unset' }}>
      {/* <PaperItem colSpan={4} rowSpan={4} className={classes.paper}>
        <TextBlock header={'NZ Energy Headlines'} className={classes.contentBox}>
          <Papagraph>
            <b>Lorem ipsum dolor sit amet? - </b> consectetur adipiscing elit. Maecenas lacinia semper quam, vitae vulputate felis sagittis vel. Nunc eu tellus nec velit venenatis lacinia. Maecenas pulvinar scelerisque sapien quis bibendum.
            Donec sagittis dolor ipsum, ac fermentum nulla pulvinar nec. Cras varius nec purus ut suscipit. Mauris lacus nulla, aliquam vitae massa a, eleifend suscipit lectus. Morbi non gravida nisl. Curabitur congue diam et venenatis
            suscipit. Suspendisse euismod accumsan ullamcorper. Cras lobortis nulla urna. Curabitur vulputate purus ut felis ultrices convallis. Sed leo sapien, tempor vel lacinia nec, tincidunt eu diam. Suspendisse ligula elit, semper sit
            amet nisl faucibus, placerat vulputate lacus. Morbi tempus efficitur vulputate. In viverra luctus velit at aliquam. Cras sed ornare nisi.
          </Papagraph>
          <Papagraph>
            <b>Donec convallis diam sed rutrum ornare - </b> Aenean consectetur sapien malesuada mauris porta, at egestas nulla luctus. Duis eget cursus sapien. Proin congue congue volutpat. Nunc mollis feugiat nisi, quis tempor leo
            sollicitudin sed. Donec quis dolor ac lacus pharetra blandit et id justo. Etiam ut interdum odio, laoreet auctor orci. Aenean eu luctus mauris, vel porttitor ipsum. Duis a massa at lectus tincidunt feugiat in a arcu. Nunc et
            vestibulum ex. Aliquam in ante neque. Vestibulum viverra vitae felis sit amet accumsan. Nullam fringilla aliquet lobortis.
          </Papagraph>
          <Papagraph>
            <b>Curabitur non vestibulum leo! - </b>F Mauris euismod tortor a augue vehicula, a imperdiet quam rhoncus. Vivamus iaculis odio id libero fringilla tincidunt. Nulla molestie purus nec interdum porta. Nulla ullamcorper purus ut
            augue mollis, vel pellentesque dolor facilisis. Cras ullamcorper fringilla finibus. Quisque sapien ex, ultricies ut commodo sit amet, lobortis ac neque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
            inceptos himenaeos. Vestibulum ante sapien, malesuada ut sagittis non, pharetra sed nisl. Nam vel est urna. Donec faucibus nisl et justo posuere laoreet. Integer semper semper elit, a consectetur quam egestas sit amet. Donec
            quis orci in odio tempor molestie tristique eu sem. Nunc sit amet nisi auctor, consequat odio ullamcorper, elementum est. In at feugiat ante, a egestas libero.
          </Papagraph>
          <Papagraph>
            <b>Donec convallis diam sed rutrum ornare - </b> Aenean consectetur sapien malesuada mauris porta, at egestas nulla luctus. Duis eget cursus sapien. Proin congue congue volutpat. Nunc mollis feugiat nisi, quis tempor leo
            sollicitudin sed. Donec quis dolor ac lacus pharetra blandit et id justo. Etiam ut interdum odio, laoreet auctor orci. Aenean eu luctus mauris, vel porttitor ipsum. Duis a massa at lectus tincidunt feugiat in a arcu. Nunc et
            vestibulum ex. Aliquam in ante neque. Vestibulum viverra vitae felis sit amet accumsan. Nullam fringilla aliquet lobortis.
          </Papagraph>
          <Papagraph>
            <b>Lorem ipsum dolor sit amet? - </b> consectetur adipiscing elit. Maecenas lacinia semper quam, vitae vulputate felis sagittis vel. Nunc eu tellus nec velit venenatis lacinia. Maecenas pulvinar scelerisque sapien quis bibendum.
            Donec sagittis dolor ipsum, ac fermentum nulla pulvinar nec. Cras varius nec purus ut suscipit. Mauris lacus nulla, aliquam vitae massa a, eleifend suscipit lectus. Morbi non gravida nisl. Curabitur congue diam et venenatis
            suscipit. Suspendisse euismod accumsan ullamcorper. Cras lobortis nulla urna. Curabitur vulputate purus ut felis ultrices convallis. Sed leo sapien, tempor vel lacinia nec, tincidunt eu diam. Suspendisse ligula elit, semper sit
            amet nisl faucibus, placerat vulputate lacus. Morbi tempus efficitur vulputate. In viverra luctus velit at aliquam. Cras sed ornare nisi.
          </Papagraph>
        </TextBlock>
      </PaperItem> */}
      <EnergyDashboard />
    </StyledContainer>
  );
}

export { Home };
