import { Tooltip, IconButton, TableContainer, Typography, Divider, Table, TableRow, TableCell, TableBody, makeStyles, Theme, createStyles, Button } from '@material-ui/core';
import React from 'react';
import { useSocketEmit } from 'src/modules/websocket/hooks/useSocketEmit';
import { APIRequest, APIGetResponse } from 'src/types/pantheon.types';
import clsx from 'clsx';
import moment from 'moment';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { green, yellow } from '@material-ui/core/colors';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { AuditItem } from '../common/audit.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: 3,
      marginBottom: 7,
      height: '1px',
    },
    container: {
      paddingBottom: 10,
      overflow: 'auto',
      height: '-webkit-fill-available',
    },
    noticeCell: {
      padding: theme.spacing(1),
      width: '-webkit-fill-available',
    },
    buttonCell: {
      padding: '0 !important',
      // width: 'min-content',
    },
    commonRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    evenRow: {
      backgroundColor: theme.palette.background.paper,
    },
    selectedRow: {
      display: 'flex',
      flexDirection: 'row',

      backgroundColor: '#ff0000',
    },
    oddRow: {
      backgroundColor: theme.palette.grey[300],
    },
    iconButton: {
      padding: '8px',
      borderRadius: '16px',
    },
    baseSelected: {
      backgroundColor: green[200],
    },
    otherSelected: {
      backgroundColor: yellow[200],
    },
  }),
);

//TODO tidy up params
export const SnapshotList = ({ compareSnapshot }: { compareSnapshot: (base?: string, other?: string) => void }): JSX.Element => {
  const classes = useStyles();

  const [emit] = useSocketEmit('pantheon.api');

  const [snapshots, setSnapshots] = React.useState<Record<string, Date> | undefined>(undefined);
  const [baseSnapshot, setBase] = React.useState<string | undefined>(undefined);
  const [otherSnapshot, setOther] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    emit<APIRequest, APIGetResponse<AuditItem>>(
      {
        method: 'get',
        table: 'api_snapshots',
        schema: 'gic_disclosure',
      } as APIRequest,
      data => {
        const snapshots = data?.message?.[0]?.jsonObject;
        if (!snapshots) return; // no response
        setSnapshots(snapshots);
      },
    );
  }, []);

  const setBaseSnapshot = (id: string) => () => {
    if (otherSnapshot === id) setOther(undefined);
    setBase(state => (state === id ? undefined : id));
  };

  const setComparisonSnapshot = (id: string) => () => {
    if (baseSnapshot === id) setBase(undefined);
    setOther(state => (state === id ? undefined : id));
  };

  const sortedSnapshotKeys = React.useMemo(() => (snapshots ? Object.keys(snapshots).sort((a: string, b: string) => moment(snapshots[b as keyof typeof snapshots]).diff(moment(snapshots[a as keyof typeof snapshots]), 'seconds')) : []), [
    snapshots,
  ]);

  return (
    <>
      <TableContainer className={classes.container}>
        <Typography align={'left'} variant={'h5'}>
          Outage Calendar Snapshots
        </Typography>
        <Divider className={classes.divider} />
        <Table size='small' aria-label='a dense table' padding='none' stickyHeader>
          <TableBody>
            {snapshots &&
              sortedSnapshotKeys.map((snapshotID: string, i: number) => {
                const extraStyle = snapshotID === baseSnapshot ? classes.baseSelected : snapshotID === otherSnapshot ? classes.otherSelected : '';

                return (
                  <TableRow key={snapshotID} className={clsx(extraStyle, classes.commonRow, i % 2 === 0 ? classes.evenRow : classes.oddRow)}>
                    <TableCell align='left' className={classes.noticeCell}>
                      {moment(snapshots[snapshotID as keyof typeof snapshots]).format('llll')}
                    </TableCell>
                    <TableCell align='left' className={classes.buttonCell}>
                      <Tooltip title='Latest snapshot' aria-label='base'>
                        <IconButton aria-label='base button' size={'medium'} className={classes.iconButton} onClick={setBaseSnapshot(snapshotID)}>
                          <ListAltIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align='left' className={classes.buttonCell}>
                      <Tooltip title='Other snapshot' aria-label='comparison'>
                        <IconButton aria-label='conmparison button' size={'medium'} className={classes.iconButton} onClick={setComparisonSnapshot(snapshotID)}>
                          <ListAltIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button startIcon={<CompareArrowsIcon />} variant={'contained'} color={'primary'} onClick={() => compareSnapshot(baseSnapshot, otherSnapshot)}>
        Compare snapshots
      </Button>
    </>
  );
};
