import React from 'react';
import { useSnackbar, VariantType } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { CustomSnackOptions } from 'src/types/pantheon/pantheon.types';

interface SnackSendTools {
  sendSnack: (message: string, variant: VariantType, options?: CustomSnackOptions) => void;
  snackConfirmation: (message: string, onConfirm: () => void, onCancel?: () => void) => void;
}

export const useSnackSend = (): SnackSendTools => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // standard dismiss icon
  const snackDismiss = (key: string) => (
    <IconButton aria-label='confirm' onClick={() => closeSnackbar(key)} size={'medium'}>
      <CloseIcon />
    </IconButton>
  );

  // wrapper to close snack and execute options function
  const withClose = (key: string, action?: () => void) => () => {
    closeSnackbar(key);
    action && action();
  };

  // snack with confirm/cancel
  const snackConfirmation = (message: string, onConfirm: () => void, onCancel?: () => void) =>
    enqueueSnackbar(message, {
      variant: 'info',
      persist: true,

      action: (key: string) => (
        <div>
          <IconButton aria-label='confirm' onClick={withClose(key, onConfirm)} size={'medium'}>
            <CheckIcon />
          </IconButton>
          <IconButton aria-label='close' onClick={withClose(key, onCancel)} size={'medium'}>
            <CloseIcon />
          </IconButton>
        </div>
      ),
    });

  // basic snack message
  const sendSnack = (message: string, variant: VariantType, options?: CustomSnackOptions) => {
    const action = options?.closeButton ? snackDismiss : undefined;
    enqueueSnackbar(message, { variant, action });
  };

  return { sendSnack, snackConfirmation };
};
