import React from 'react';
import { useHistory } from 'react-router-dom';

import { Paper } from '@material-ui/core';

export interface PathDefI {
  class: string;
  redirect: string;
  parent: string;
}

export function SchematicWrapper({ children, defs }: { children: JSX.Element; defs: PathDefI[] }): JSX.Element {
  const history = useHistory();

  const navigateTo = (parent: string, target: string) => (): void => history.push('/' + parent + '/' + target);

  const configureElementClass = (el: HTMLElement | null): void => {
    for (const path of defs) {
      if (el === null) return;
      const nodes: NodeListOf<Element> = el.querySelectorAll('.' + path.class);
      if (!nodes) return;
      nodes.forEach((node: Element) => {
        // bind click
        node.addEventListener('click', navigateTo(path.parent, path.redirect));
        // apply class
        node.classList.add('schematic-item');
      });
    }
  };

  React.useEffect(() => {
    // iterate classes to add listeners to
    const el = document.getElementById(children.props.id);
    el && configureElementClass(el);
  }, [children.props.id]);

  return <Paper elevation={2}>{children}</Paper>;
}
