import { Paper, withTheme, Divider, DividerProps } from '@material-ui/core';
import styled from 'styled-components';

export const ContentContainer = withTheme(styled.div`
  display: grid;
  width: -webkit-fill-available;
  padding: ${props => props.theme.spacing(2)}px;
  gap: ${props => props.theme.spacing(2)}px;
`);

export const ContentBox = withTheme(styled.div`
  text-align: left;
  /* color: ${props => props.theme.palette.text.primary}; */
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: ${props => props.theme.spacing(2)}px;
  grid-column: span 1;
  grid-row: span 1;
`);

export const PaperBox = withTheme(styled(Paper)`
  text-align: left;
  /* color: ${props => props.theme.palette.text.primary}; */
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: ${props => props.theme.spacing(2)}px;
  grid-column: span 1;
  grid-row: span 1;
`);

export const PaperColumn = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ColumnCell = withTheme(styled.div`
  padding: ${props => props.theme.spacing(2)}px;
  display: flex;
  flex-grow: 1;
`);

export const PaperGrid = styled(Paper)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-grow: 1;
`;

export const GridCell = withTheme(styled.div`
  padding: ${props => props.theme.spacing(2)}px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
`);

export const ColourDivider = styled(Divider)<DividerProps & { colour: string }>`
  margin-top: 3px;
  margin-bottom: 7px;
  height: 1px;
  &.MuiDivider-root {
    background-color: ${props => props.colour};
  }
`;
