// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace Datacore {
  interface MetadataStoreItem {
    nameProp?: string;
  }

  interface WITSNode extends MetadataStoreItem {
    nodeName: string;
    name: string;
    nodeFull: string;
    isGeneration: boolean;
    isASX: boolean;
  }

  interface OatisPoint extends MetadataStoreItem {
    ID: number;
    name: string;
    network: OatisNetwork;
    pointCode: string;
  }

  interface OatisMeter extends MetadataStoreItem {
    ID: number;
    name: string;
    network: OatisNetwork;
    meterCode: string;
    meterDirection: 'receipt' | 'delivery' | 'unknown';
    pointID: number;
  }

  interface KeyedMetadata {
    ID: number;
  }

  interface AssetJSON {
    oatisMeters?: number[];
    permitNumber?: number;
    deveopmentWells?: number;
    explorrationWells?: number;
    appraisalWells?: number;
  }

  interface Entity extends KeyedMetadata, MetadataStoreItem {
    gicID?: number;
    name: string;
    category: string;
    tags?: string[];
  }

  interface Category extends KeyedMetadata {
    name: string;
  }

  interface Asset extends KeyedMetadata {
    parentAssetID?: number;
    name: string;
    about: string;
    additionalData?: AssetJSON;
    operatorID: Entity;
    categoryID: Category;
    gicDisclosureAssetID?: number;
  }

  type GenerationFuelType = typeof GenerationFuel;
  type GenerationFuelKeys = keyof GenerationFuelType;

  type OatisNetworkType = typeof OatisNetwork;
  type OatisNetworkKeys = keyof OatisNetworkType;

  type ETPMetricType = typeof ETPMetric;
  type ETPMetricKeys = keyof ETPMetricType;
}

export enum OatisNetwork {
  MAUI,
  VGT,
}

export enum GenerationFuel {
  coal,
  gas,
  diesel,
}

export enum ETPMetric {
  traded,
  delivered,
}
