import { Tooltip, IconButton, TableContainer, Typography, Divider, Table, TableRow, TableCell, TableBody, makeStyles, Theme, createStyles, TableHead } from '@material-ui/core';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { useSocketEmit } from 'src/modules/websocket/hooks/useSocketEmit';
import { APIRequest, APIGetResponse } from 'src/types/pantheon.types';
import clsx from 'clsx';
import { AuditRecord } from '../common/audit.types';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: 3,
      marginBottom: 7,
      height: '1px',
    },
    container: {
      paddingBottom: 10,
      overflow: 'auto',
    },
    noticeCell: {
      padding: theme.spacing(1),
    },
    buttonCell: {
      width: '5em',
      textAlign: 'center',
    },
    commonRow: {},
    evenRow: {
      backgroundColor: theme.palette.background.paper,
    },
    selectedRow: {
      backgroundColor: '#ff0000',
    },
    oddRow: {
      backgroundColor: theme.palette.grey[300],
    },
    iconButton: {
      padding: '8px',
      borderRadius: '16px',
    },
  }),
);

export const AuditTrail = (): JSX.Element => {
  const classes = useStyles();

  const [emit] = useSocketEmit('pantheon.api');

  const [auditRecords, setAuditRecords] = React.useState<Record<string, AuditRecord> | undefined>(undefined);

  React.useEffect(() => {
    emit<APIRequest, APIGetResponse<{ jsonObject: Record<string, AuditRecord> }>>(
      {
        method: 'get',
        table: 'api_audit_log',
        schema: 'gic_disclosure',
        page: {
          pageNumber: 1,
          perPage: 15,
        },
      },
      data => {
        if (!data?.message) return; // no response
        const auditRecords = data.message.reduce<Record<string, AuditRecord>>((prev: Record<string, AuditRecord>, curr: { jsonObject: Record<string, AuditRecord> }) => ({ ...prev, ...curr.jsonObject }), {});
        setAuditRecords(auditRecords);
      },
    );
  }, []);

  const RecordRow = (props: { rowNumber: number; record: AuditRecord }) => {
    // const [open, setOpen] = React.useState(false);
    const { rowNumber, record } = props;
    return (
      <>
        <TableRow className={clsx(classes.commonRow, rowNumber % 2 === 0 ? classes.evenRow : classes.oddRow)}>
          <TableCell align='left' className={clsx(classes.buttonCell)}>
            {/* <Tooltip title='Expand record' aria-label='view'>
              <IconButton aria-label='view' size={'medium'} className={classes.iconButton}>
                {open ? <ArrowRightIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </Tooltip> */}
          </TableCell>

          <TableCell align='left' className={classes.noticeCell}>
            {moment(record.time).format('DD/MM/YYYY hh:mm')}
          </TableCell>
          <TableCell align='left' className={classes.noticeCell}>
            {record.schema}
          </TableCell>
          <TableCell align='left' className={classes.noticeCell}>
            {record.table}
          </TableCell>
          <TableCell align='left' className={classes.noticeCell}>
            {record.type}
          </TableCell>

          <TableCell align='left' className={clsx(classes.buttonCell)}>
            <Tooltip title='Edit notice' aria-label='view'>
              <IconButton aria-label='view' size={'medium'} className={classes.iconButton}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const TableBodyMemo = React.useCallback(
    () => (
      <TableBody>
        {auditRecords &&
          Object.keys(auditRecords).map((auditRecordID: string, i: number) => {
            const record = auditRecords[auditRecordID as keyof typeof auditRecords] as AuditRecord;

            return <RecordRow key={auditRecordID} rowNumber={i} record={record} />;
          })}
      </TableBody>
    ),
    [auditRecords],
  );

  return (
    <TableContainer className={classes.container}>
      <Typography align={'left'} variant={'h5'}>
        Outage Audit Trail
      </Typography>
      <Divider className={classes.divider} />
      <Table size='small' aria-label='a dense table' padding='none' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.noticeCell}></TableCell>

            <TableCell className={classes.noticeCell}>Time</TableCell>
            <TableCell className={classes.noticeCell}>Schema</TableCell>
            <TableCell className={classes.noticeCell}>Table</TableCell>
            <TableCell className={classes.noticeCell}>Type</TableCell>
            <TableCell className={classes.noticeCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBodyMemo />
      </Table>
    </TableContainer>
  );
};
