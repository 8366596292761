import { IconButton, Tooltip, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { NoteAdd, AccountTree, Home, DateRange } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useSocketEmit } from 'src/modules/websocket/hooks';
import { APIGetResponse, APIRequest } from 'src/types/pantheon/pantheon.types';
import { GOISMeta } from 'src/types/shared/notice.sql.types';

export default function CommonToolbar(): JSX.Element {
  const history = useHistory();
  const [emit] = useSocketEmit('pantheon.api');
  const [meta, setMeta] = React.useState<GOISMeta | null>(null);

  React.useEffect(() => {
    if (!meta)
      emit<APIRequest, APIGetResponse<{ gicStat: GOISMeta }>>(
        {
          method: 'get',
          table: 'gois_meta',
          schema: 'gic_disclosure',
        },
        data => {
          const payload = data?.message?.[0]?.gicStat;
          if (!payload) return;
          setMeta(payload);
        },
      );
  }, []);
  const goto = (page: string) => () => history.push(`/gic${page}`);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {meta &&
          Object.entries(meta).map(entry => (
            <div key={entry[0]} style={{ display: 'flex', flexFlow: 'column', flexGrow: 1, padding: '0px 16px 0px 16px', alignSelf: 'center' }}>
              <Typography color={'primary'} variant={'subtitle1'} style={{ width: 'max-content', whiteSpace: 'nowrap' }}>
                {entry[0][0].toUpperCase() +
                  entry[0]
                    .slice(1)
                    .replace(/([A-Z])/g, ' $1')
                    .trim()}
              </Typography>
              <Typography variant={'caption'} style={{ width: 'max-content', whiteSpace: 'nowrap', alignSelf: 'center' }}>
                {entry[1]}
              </Typography>
            </div>
          ))}
      </div>
      <Divider orientation='vertical' flexItem />

      <IconButton aria-label='home' size={'medium'} onClick={goto('/')}>
        <Home />
      </IconButton>
      <Tooltip title='View notices' aria-label='view'>
        <IconButton aria-label='view' size={'medium'} onClick={goto('/view')}>
          <AccountTree />
        </IconButton>
      </Tooltip>
      <Tooltip title='Outage calendar' aria-label='calendar'>
        <IconButton aria-label='calendar' size={'medium'} onClick={goto('/calendar')}>
          <DateRange />
        </IconButton>
      </Tooltip>
      <Tooltip title='Create a new notice in the system' aria-label='add'>
        <IconButton aria-label='add' size={'medium'} onClick={goto('/entry')}>
          <NoteAdd />
        </IconButton>
      </Tooltip>
    </>
  );
}
