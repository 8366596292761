import { mergeDeep } from 'src/lib/util';
import { MakeSeriesChartOptions, MakeCoreTimeSeriesSP, CoreTimeSeries } from 'src/types/module/chartModule.types';
import { withStacking, columnChart } from '..';
import { defaultRange, defaultRangeHourly } from '../common';

export const generateUnitGeneration = (title: string): Omit<MakeSeriesChartOptions<MakeCoreTimeSeriesSP>, 'seriesFilters' | 'seriesNames' | 'name'> => ({
  ...defaultRange,
  _makeInterface: CoreTimeSeries.TimeSeries,
  dataTable: 'emsVolumeData',
  xAxisColumn: 'trading_period_start',
  seriesColumns: ['reading'],
  options: mergeDeep<Highcharts.Options>({}, withStacking(columnChart), {
    title: {
      text: title,
    },
    plotOptions: {
      column: {
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
      },
    },
  }),
});

export const genericOatisDaily = (title: string): Omit<MakeSeriesChartOptions<MakeCoreTimeSeriesSP>, 'seriesFilters' | 'seriesNames' | 'name'> => ({
  ...defaultRange,
  _makeInterface: CoreTimeSeries.TimeSeries,
  dataTable: 'oatisGasDaily',
  xAxisColumn: 'date',
  seriesColumns: ['mq'],
  options: mergeDeep<Highcharts.Options>({}, withStacking(columnChart), {
    title: {
      text: title,
    },
    plotOptions: {
      column: {
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
      },
    },
  }),
});

export const genericOatisHourly = (title: string): Omit<MakeSeriesChartOptions<MakeCoreTimeSeriesSP>, 'seriesFilters' | 'seriesNames' | 'name'> => ({
  ...defaultRangeHourly,
  _makeInterface: CoreTimeSeries.TimeSeries,
  dataTable: 'oatisGasNomination',
  xAxisColumn: 'endHour',
  seriesColumns: ['actual', 'scheduled'],
  options: mergeDeep<Highcharts.Options>({}, withStacking(columnChart), {
    title: {
      text: title,
    },
    plotOptions: {
      column: {
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
      },
    },
  }),
});

export const plotLinesCommon: Highcharts.YAxisPlotLinesOptions = {
  zIndex: 3,
  width: 1,
};
