import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { WritableDraft } from '@reduxjs/toolkit/node_modules/immer/dist/internal';
import { useSelector } from 'react-redux';
import { enumToRecord } from 'src/lib/util';
import { Datacore, ETPMetric, GenerationFuel } from 'src/types/module/datacore.types';
import { RootState } from './root';

// Define a type for the slice state
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MetadataKeylist {}

export interface MetadataStore {
  status: { loaded: boolean };
  witsNodes: Record<string, Datacore.WITSNode>;
  oatisGasPoint: Record<number, Datacore.OatisPoint>;
  oatisGasMeter: Record<number, Datacore.OatisMeter>;
  entities: Datacore.Entity[];
  fuelType: Record<number, string>;
  etpMetric: Record<number, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string | number]: Record<string | number, any>;
}

export type APIPayloadAction<TResponse extends Datacore.MetadataStoreItem[]> = PayloadAction<TResponse | string>;
// Define the initial state using that type
const initialState: MetadataStore = { status: { loaded: false }, witsNodes: {}, oatisGasPoint: {}, oatisGasMeter: {}, entities: [], fuelType: enumToRecord(GenerationFuel), etpMetric: enumToRecord(ETPMetric) };

export const metadataSlice = createSlice({
  name: 'metadataSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addNodes: (state, data: APIPayloadAction<Datacore.WITSNode[]>) => {
      Array.isArray(data.payload) && (state.witsNodes = data.payload.reduce<Record<string, Datacore.WITSNode>>((prev, curr) => ({ ...prev, [curr.nodeName]: curr }), {}));
    },
    addPoints: (state, data: APIPayloadAction<Datacore.OatisPoint[]>) => {
      Array.isArray(data.payload) && (state.oatisGasPoint = data.payload.reduce<Record<number, Datacore.OatisPoint>>((prev, curr) => ({ ...prev, [curr.ID]: curr }), {}));
    },
    addMeters: (state, data: APIPayloadAction<Datacore.OatisMeter[]>) => {
      Array.isArray(data.payload) && (state.oatisGasMeter = data.payload.reduce<Record<number, Datacore.OatisMeter>>((prev, curr) => ({ ...prev, [curr.ID]: curr }), {}));
    },
    addEntities: (state, data: APIPayloadAction<Datacore.Entity[]>) => {
      Array.isArray(data.payload) && (state.entities = data.payload);
    },
    setLoaded: state => void (state.status.loaded = true),
  },
});

// Other code such as selectors can use the imported `RootState` type
const sectorEntities = {
  gas: (state: RootState): Datacore.Entity[] => state.metadata.entities.filter(entity => entity.tags?.includes('Gas')),
  oil: (state: RootState): Datacore.Entity[] => state.metadata.entities.filter(entity => entity.tags?.includes('Oil')),
  electricity: (state: RootState): Datacore.Entity[] => state.metadata.entities.filter(entity => entity.tags?.includes('Electricity')),
};

export const useSectorEntities = (sector: keyof typeof sectorEntities): Datacore.Entity[] => useSelector(sectorEntities[sector]);

export const { addNodes, addPoints, addMeters, addEntities, setLoaded } = metadataSlice.actions;
