import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import { LogIn } from 'src/modules/auth';
import { NotFound } from 'src/router';
import { MiniDrawer } from './components/Drawer/Drawer';
import { NavBar } from './components/Nav';
import { AuthorisedRoute, ContentRoutes } from './router';
import { navHeight } from './style/theme';

const useStyles = makeStyles(() => ({
  main: { height: `calc(100vh - ${navHeight})`, position: 'relative', display: 'flex' }, //height: 'calc(100vh - 64px)', width: '100vw',
  content: { overflow: 'auto', width: 'calc(100vw - 57px)', height: '100%', display: 'flex' },
}));

export default function Content(): JSX.Element {
  const classes = useStyles();

  return (
    <main>
      <NavBar />
      <section className={classes.main}>
        <MiniDrawer />
        <section className={classes.content}>
          <Switch>
            <Route path='/login' exact={true} component={LogIn} />

            <AuthorisedRoute path='/' exact={false} render={() => <ContentRoutes />} />

            <Route component={NotFound} />
          </Switch>
        </section>
      </section>
    </main>
  );
}

/*
 <LoggedInRoute
            title={'test'}
            path='/'
            exact={false}
            component={() => (
              <>
                <NavBar />
                <div className={classes.main}>
                  <Slide direction='right' in={authState.isAuthenticated} mountOnEnter unmountOnExit timeout={{ appear: 1000, enter: 1000, exit: 1000 }}>
                    <MiniDrawer />
                  </Slide>
                  <div className={classes.content}>
                    <ContentRoutes />
                  </div>
                </div>
              </>
            )}
          />
          */
