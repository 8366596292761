import React from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { InputLabel } from '@material-ui/core';

import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      padding: '2px 2px',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      marginLeft: theme.spacing(1),
    },
    iconButton: {
      padding: 10,
    },
  }),
);

interface PropsI extends InputBaseProps {
  paperClass?: string;
  wrapperClass?: string;
  iconClass?: string;
}

export default function NexusSearch({ paperClass, wrapperClass, iconClass, ...inputBaseProps }: PropsI): JSX.Element {
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', height: 'min-content', alignSelf: 'center' }} className={wrapperClass}>
      <InputLabel style={{ alignSelf: 'center' }}>Search calendar: </InputLabel>
      <Paper component='form' className={clsx(classes.inputRoot, paperClass)}>
        <InputBase className={classes.input} inputProps={{ 'aria-label': 'search', size: 40 }} {...inputBaseProps} />
        <SearchIcon className={iconClass} />
      </Paper>
    </div>
  );
}
