import React from 'react';
import { NexusTable } from 'src/components/NexusTable';
import { NexusTextContent } from 'src/components/Wrapper/NexusTextContent';
import { SkipContainer, SkipPaperContent } from 'src/style/components/styledFlex';

const assetTableHeader = ['Position', 'Asset', 'Stake'];

// todo pull data from API
const assetTableContent = [
  ['Upstream', 'Maari', '69%'],
  ['Upstream', 'Pohokura', '74%'],
  ['Upstream', 'Maui', '100%'],
];

const assetLinks: [number, string][] = [
  [0, 'gas/assets/maari'],
  [1, 'gas/assets/pohokura'],
  [2, 'gas/assets/maui'],
];

const keyPeople = [
  ['Gabriel Selischi', 'Managing Director'],
  ['Dominik Auer', 'Finance Director'],
  ['Patrick Teagle', 'Commercial & Legal Manager'],
  ['Simon Lange', 'Head of Exploration, Development & Production'],
  ['Tim Allan', 'Exploration & Appraisal Manager'],
];

const companyListing = [
  ['Exchange(s)', '', 'Vienna'],
  ['Ticker', '', 'OMV.AV'],
  ['Share price', '€/share', 41.89],
  ['Market cap', '€m', '13,709'],
  ['EV', '€m', ' 22,073 '],
  ["Issued share's", 'm', 327.3],
  ['Free float', '', '223.5m (68.3%)'],
  ['Top 20 holders', '', '215.1m (65.7%)'],
  ['Institution holders', '', '234.4m (71.6%)'],
  ['12 mth high / low', '€/share', '41.01 / 56.32'],
  ['12 mth turnover', '000 shares/day', 359.6],
];

const news = `<table id="main" width="600" align="center" cellpadding="0" cellspacing="0" style="border-top: none;
            color:#333;
            max-width: 600px!important;
            width: 100%!important;
            color:#101C2D;font-size:13px;font-style:normal;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,Helvetica,Arial,sans-serif;font-weight:normal;background-color:#ffffff;">

<tbody><tr>
<td style="border-bottom: none; margin-bottom:10px;/*padding-top: 8px; padding-bottom:4px;*/">
<table cellpadding="0" cellspacing="0" width="100%" style="padding: 0px 16px">
<tbody><tr>
<td align="left">




<table width="100%" cellpadding="0" cellspacing="0">
<tbody><tr>
<td style="height:13px; padding: 0;">
</td>
</tr>
<tr>
<td>
<a name="21090156487326" id="21090156487326" href="https://www.contify.com/url/n5Puj-NJeNhbOKZFUhwNMoWHaXRKEk2MXfGv80Brd" style="text-decoration: none;
                line-height:1.4;
                list-style: disc outside none;
                color:#0072C3;font-size:20px;font-style:normal;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,Helvetica,Arial,sans-serif;font-weight:normal;line-height:1.3;background-color:transparent" target="_blank">
Jadestone Energy PLC Announces Update on Maari Acquisition
</a>

<p style="color:#999999;
              font-size:11px;
              display:block;
              margin-top: 5px;
              margin-bottom:0;
              line-height: 1.4;
              clear:both;
              color:#8C8C8C;font-size:12px;font-style:normal;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,Helvetica,Arial,sans-serif;font-weight:normal;line-height:1.3;letter-spacing:0.32px;background-color:transparent;">
<a href="https://www.contify.com/url/n5Puj-NJeNhbOKZFUhwNMoWHaXRKEk2MXfGv80Brd" style="margin-left: 0;
                  text-decoration: none;
                  color:#8C8C8C;font-size:12px;font-style:normal;font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,Roboto,Oxygen-Sans,Ubuntu,Cantarell,&quot;Helvetica Neue&quot;,Helvetica,Arial,sans-serif;font-weight:normal;line-height:1.3;letter-spacing:0.32px;background-color:transparent;">
Yahoo
</a>
·
<span style="text-decoration: none;
                  border:none;
                  color:inherit!important;">
31 Aug 2021
</span>
</p>
</td>
</tr>
<tr>
<td style="padding: 8px 0 5px 0;">
<a href="https://www.contify.com/url/n5Puj-NJeNhbOKZFUhwNMoWHaXRKEk2MXfGv80Brd" target="_blank" style="font-size: 12px;
                              display:inline-block;
                              /* margin-top: 2px; */
                              text-decoration: none !important;
                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                              color:#0072C3;font-size:14px;line-height:1.4">
Read More →
</a>
</div>
</td>
</tr>
<tr>
<td style="padding-bottom: 8px; padding-top: 3px;">
<table width="100%" cellpadding="0" cellspacing="0">
<tbody><tr>
<td width="100%" style="font-size: 14px; color: #8C8C8C; /*font-weight: bold;*/ width: 100%;padding-top:0px;padding-bottom: 0px;line-height: 18px;">
<span>More articles like this?</span>
<span style="margin-left: 10px;">
<a href="https://www.contify.com/newsletters/story-item-feedback/2444ad9f03dab0e077cf2ab63edf25dd/2633867/like/?source=l&amp;medium=&amp;item_model=" target="_blank" style="color: #8C8C8C;text-decoration: underline;font-weight: normal;">
Yes
</a>
</span>
<span style="margin-left: 10px;">
<a href="https://www.contify.com/newsletters/story-item-feedback/2444ad9f03dab0e077cf2ab63edf25dd/2633867/dislike/?source=l&amp;medium=&amp;item_model=" target="_blank" style="color: #8C8C8C;text-decoration: underline;font-weight: normal;">
No
</a>
</span>
</td>
</tr>
</tbody></table>
</td>
</tr>
<tr>
<td style="height:8px; padding: 0;">
</td>
</tr>
</tbody></table>




</td>
</tr>
</tbody></table>
</td>
</tr>
</tbody></table>`;

function OMV(): JSX.Element {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
    script.innerHTML = ` {
  "symbol": "XETR:OMV",
  "width": "100%",
  "height": "95%",
  "locale": "en",
  "dateRange": "12M",
  "colorTheme": "light",
  "trendLineColor": "rgba(41, 98, 255, 1)",
  "underLineColor": "rgba(41, 98, 255, 0.3)",
  "underLineBottomColor": "rgba(41, 98, 255, 0)",
  "isTransparent": true,
  "autosize": true,
  "largeChartUrl": ""
}`;
    document.getElementsByClassName('tradingview-widget-container')?.item(0)?.appendChild(script);
  }, []);

  return (
    <SkipContainer isGrid w={'-webkit-fill-available'} rows={'repeat(2, 1fr)'} cols={'repeat(3, 1fr)'}>
      <SkipPaperContent ga={'auto'}>
        <NexusTextContent header={'Summary'}>
          {`OMV is an Austrian headquarter oil and gas company that first entered New Zealand in 1999 upon acquiring 30% of the Maari oil field. It graduately increased its stake in other oil fields such as Maui and Pohokura, assuming
          operatorship of the former, and eventually acquired all of Shell's upstream holdings in 2018 just prior to the New Zealand government's ban on new exploration licences. OMV's New Zealand operations make up roughly`}
        </NexusTextContent>
      </SkipPaperContent>
      <SkipPaperContent ga={'auto'}>
        <NexusTextContent header={'News'}>
          <div dangerouslySetInnerHTML={{ __html: news }} style={{ overflowY: 'auto', display: 'flex', flexGrow: 0 }} />
        </NexusTextContent>
      </SkipPaperContent>
      <SkipPaperContent ga={'auto'}>
        <NexusTextContent header={'Assets'}>
          <NexusTable headerRow={assetTableHeader} contentRows={assetTableContent} rowLinks={assetLinks} />
        </NexusTextContent>
      </SkipPaperContent>
      <SkipPaperContent ga={'auto'}>
        <NexusTextContent header={'NZ Executive Team'}>
          <NexusTable contentRows={keyPeople} />
        </NexusTextContent>
      </SkipPaperContent>
      <SkipPaperContent ga={'auto'}>
        <NexusTextContent header={'Public listing information'}>
          <NexusTable contentRows={companyListing} />
        </NexusTextContent>
      </SkipPaperContent>
      <SkipPaperContent ga={'auto'}>
        <NexusTextContent header={'Stock chart'} noWrapper>
          <div className='tradingview-widget-container'>
            <div className='tradingview-widget-container__widget'></div>
          </div>
        </NexusTextContent>
      </SkipPaperContent>
    </SkipContainer>
  );
}

export { OMV };
