import { indigo, yellow } from '@material-ui/core/colors';
import { SourceDefinition } from './types';
export const manualSources: Record<string, SourceDefinition> = {
  Pukaki: { uri: 'https://www.meridianenergy.co.nz/s3assets/graph/GraphFile/633', fields: ['masl'], table: 'lake_storage', fk: ['lakeID', 1] },
  Manapouri: { uri: 'https://www.meridianenergy.co.nz/s3assets/graph/GraphFile/638', fields: ['masl'], table: 'lake_storage', fk: ['lakeID', 2] },
  Tekapo: {
    uri: 'https://www.genesisenergy.co.nz/about/generation/rivers-lakes-and-rainfall/lake-tekapo-lake-level',
    fields: ['masl'],
    table: 'lake_storage',
    fk: ['lakeID', 3],
  },
  Hawea: {
    uri: 'https://www.orc.govt.nz/managing-our-environment/water/water-monitoring-and-alerts/upper-clutha/lake-hawea',
    fields: ['masl'],
    table: 'lake_storage',
    fk: ['lakeID', 4],
  },
  Taupo: { uri: 'https://www.mercury.co.nz/lakelevels', fields: ['masl'], table: 'lake_storage', fk: ['lakeID', 5] },
  'Brent Crude': { uri: 'https://www.theice.com/products/219/Brent-Crude-Futures/data', fields: ['spot', 'forward'], table: 'brent_crude' },
  'HBA Marker': { uri: 'http://coalspot.com', fields: ['HBA Marker Price'], table: 'coal' },
  'Huntly Coal Stockpile': { uri: 'https://www.genesisenergy.co.nz/investors/reports-and-presentations', fields: ['kt'], table: 'huntly_coal' },
  LPG: { text: 'LPG MANUAL INPUT', fields: ['propane', 'butane'], table: 'lpg', titleStyle: { color: yellow[900] } },
  Ahuroa: {
    text: 'AHUROA MANUAL INPUT',
    fields: ['Movement', 'Balance'],
    table: 'ahuroa',
    titleStyle: { color: yellow[900] },
  },
  'LME Aluminium': { uri: 'https://www.lme.com/en/Metals/Non-ferrous/LME-Aluminium#Price+graphs', fields: ['price'], table: 'aluminium_lme' },
  'Japan Aluminium': {
    uri: 'https://www.cmegroup.com/trading/metals/base/aluminum-japan-premium-platts.html',
    fields: ['price'],
    table: 'aluminium_japan',
  },
  Alumina: {
    uri: 'https://www.cmegroup.com/trading/metals/base/alumina-fob-australia-sp-global-platts_quotes_globex.html',
    fields: ['price'],
    table: 'alumina',
  },
  'MBIE importer margins': {
    uri: 'https://www.mbie.govt.nz/building-and-energy/energy-and-natural-resources/energy-statistics-and-modelling/energy-statistics/weekly-fuel-price-monitoring/',
    fields: ['petrol', 'diesel'],
    table: 'mbie_importer_margin',
  },
};

export const automatedSources: Record<string, SourceDefinition> = {
  Carbon: {
    text: 'NZ Carbon',
    fields: ['$/NZU'],
    table: 'carbon',
    titleStyle: { color: indigo[900] },
  },
  'NZ Electricity Demand': {
    text: 'Automated',
    fields: ['SI', 'NI', 'Tiwai'],
    table: 'electricity_demand',
    titleStyle: { color: indigo[900] },
  },
  'NZ Electricity Futures': {
    text: 'Automated',
    fields: ['Short-Dated OTA', 'Short-Dated BEN', 'Long-Dated OTA', 'Long-Dated BEN'],
    table: 'electricity_futures',
    titleStyle: { color: indigo[900] },
  },
  'NZ Electricity Spot Prices': {
    text: 'Automated',
    fields: ['BEN2201', 'OTA2201', 'HAY2201', 'HLY2201 GWAP'],
    table: 'electricity_spot',
    titleStyle: { color: indigo[900] },
  },
  'emsTradepoint activity': {
    text: 'Automated',
    fields: [`Traded Volume`, `Traded Value`, `Traded VWAP`, `Traded Low`, `Traded High`, `Delivered Volume`, `Delivered Value`, `Delivered VWAP`, `Delivered Low`, `Delivered High`, `FRMI`, `FRQI`],
    table: 'etp',
    titleStyle: { color: indigo[900] },
  },
  'Fonterra GDT auction': {
    text: 'Automated',
    fields: ['gdt_index', 'wmp'],
    table: 'fonterra_gdt',
    titleStyle: { color: indigo[900] },
  },
  'Gas demand': {
    text: 'Automated',
    fields: [
      `Methanex (Motunui)`,
      `Methanex (Faull Rd)`,
      `Methanex (Waitara Valley)`,
      `Balance Kapuni (BAL08201)`,
      `Balance (BAL09626)`,
      `Balance Total`,
      `Huntly PS`,
      `Te Rapa(TRC02003)`,
      `TCC(TCC00201)`,
      `Peakers(STR00521)`,
      `Junction Rd(Mangorei)`,
      `Marsden Pt(MSD01801)`,
      `Kinleith(KIN04310)`,
      `Glenbrook(GLB03401)`,
      `Kauri(KUR33601)`,
      `Lichfield(LCF20010)`,
      `Lichfield 2(LCF20011)`,
      `Morrinsville(MRV16301)`,
      `Maungaturoto(MUT19001)`,
      `Pahiatua(PHT04902)`,
      `Te Awamutu(TAC31001)`,
      `Tirau(TIR33501)`,
      `Edgecumbe(EGC30701)`,
      `Stratford Receipt (STR00513)`,
      `Stratford Delivery (STR00511)`,
    ],
    table: 'gas_demand',
    titleStyle: { color: indigo[900] },
  },
  'Gas supply': {
    text: 'Automated',
    fields: [`Ngatimaru Rd-R`, `Pohokura Gross`, `Oaonui`, `Tikorangi`, `Tikorangi-3`, `Mangahewa/McKee/Poho`, `Turangi`, `Kowhai`, `Kupe`, `Kapuni Receipt`, `Kapuni Delivery`, `Kapuni Net`],
    table: 'gas_supply',
    titleStyle: { color: indigo[900] },
  },
  'APCP Methanol': {
    text: 'Automated',
    fields: ['Methanex APCP'],
    table: 'methanol',
    titleStyle: { color: indigo[900] },
  },
  'NZ Hydro situation': {
    text: 'Automated',
    fields: ['storage_gwh', 'mean_gwh'],
    table: 'nz_hydro',
    titleStyle: { color: indigo[900] },
  },
  'NZDUSD fx': {
    text: 'Automated',
    fields: ['rate'],
    table: 'nzdusd',
    titleStyle: { color: indigo[900] },
  },
};
