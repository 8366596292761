import React, { PropsWithChildren } from 'react';
import { SkipBox } from 'src/style/components/styledFlex';

function GasFlowPage({ children, controlsComponent, contentStyle }: PropsWithChildren<{ controlsComponent?: JSX.Element; contentStyle?: React.CSSProperties }>): JSX.Element {
  return (
    <SkipBox isGrid g={2} h={'100%'} w={'100%'} rows={'1fr'} cols={'1fr 250px'}>
      <SkipBox ga={'span 1'}>{children}</SkipBox>

      {controlsComponent && (
        <SkipBox ga={'span 1'} style={contentStyle}>
          {controlsComponent}
        </SkipBox>
      )}
    </SkipBox>
  );
}

export { GasFlowPage };
