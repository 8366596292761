import React from 'react';
import { makeRangeProperty } from 'src/components/Charts/components/config/common';
import { agsNetOptionsArrayMap } from 'src/components/Charts/components/config/makeAGSNet/agsNet';
import { reservesLatest } from 'src/components/Charts/components/config/makeChartSunburstReserves';
import { gasSectorDemandOptionsArrayMap } from 'src/components/Charts/components/config/makeTimeSeries';
import { gasFieldOptionsArrayMap } from 'src/components/Charts/components/config/makeTimeSeries/gasFieldDaily';
import { NexusChart } from 'src/components/Charts/NexusChart';
import { SkipBox } from 'src/style/components/styledFlex';

function FlowContentHome(): JSX.Element {
  return (
    <SkipBox isGrid g={2} h={'100%'} w={'100%'} rows={'1fr 1fr'} cols={'1fr 1fr 1fr'}>
      <SkipBox ga={'span 1'}>
        <NexusChart settings={[reservesLatest]} />
      </SkipBox>
      <SkipBox ga={'span 1'}>
        <NexusChart settings={gasFieldOptionsArrayMap} options={{ title: { text: 'Production, year to date' } }} range={makeRangeProperty('toDate', 0, 'year')} />
      </SkipBox>
      <SkipBox ga={'span 1'}>box 3</SkipBox>
      <SkipBox ga={'span 1'}>box 4</SkipBox>
      <SkipBox ga={'span 1'}>
        <NexusChart settings={agsNetOptionsArrayMap} />
      </SkipBox>
      <SkipBox ga={'span 1'}>
        <NexusChart settings={gasSectorDemandOptionsArrayMap} options={{ title: { text: 'Demand by sector, year to date' } }} range={makeRangeProperty('toDate', 0, 'year')} />
      </SkipBox>
    </SkipBox>
  );
}

export { FlowContentHome };
