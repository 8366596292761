import moment from 'moment';
import React from 'react';
import { spotGasOptionsMap } from 'src/components/Charts/components/config/makeTimeSeries';
import { ChartRangePicker } from 'src/components/Charts/components/controls/ChartRangePicker';
import { ChartRollupRadio } from 'src/components/Charts/components/controls/ChartRollupRadio';
import { NexusChart } from 'src/components/Charts/NexusChart';
import { PageHeader } from 'src/components/PageHeader';
import { ChartControlWrapper } from 'src/lab/ChartControlWrapper';
import { SkipBox, SkipPaper } from 'src/style/components/styledFlex';

function SpotGas(): JSX.Element {
  const chartData = spotGasOptionsMap.gasOverview;

  return (
    <SkipPaper isGrid p={2} g={2} w={'100%'} h={'100%'} rows={'min-content 1fr '} cols={'1fr 250px'}>
      <SkipBox flex d={'column'} ga={' 1 / 1 / 1 / 2'} w={'100%'}>
        <PageHeader>Gas Spot Market</PageHeader>
      </SkipBox>
      <SkipBox ga={'2 / 1 / 2 / 1'}>
        <NexusChart id={'spot-carbon'} settings={[chartData]} />
      </SkipBox>
      <SkipBox ga={'2 / 2 / 2 / 3'}>
        <ChartControlWrapper title={'Chart Range'} column>
          <ChartRangePicker id={'spot-carbon'} format={'date'} initialValue={[moment(chartData.range[0], 'YYYY-MM-DD'), moment(chartData.range[1], 'YYYY-MM-DD')]} />
        </ChartControlWrapper>
        <ChartControlWrapper title={'Data Rollup'} column>
          <ChartRollupRadio id={'spot-carbon'} initialValue={'day'} />
        </ChartControlWrapper>
      </SkipBox>
    </SkipPaper>
  );
}

export { SpotGas };
