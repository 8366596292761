import React from 'react';
import { makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { Calendar as BigCalendar, momentLocalizer, Event } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import moment from 'moment';
import NexusSearch from 'src/components/NexusSearch/NexusSearch';
import { indigo } from '@material-ui/core/colors';
import { useAPI } from 'src/modules/websocket/hooks';
import { APIGetResponse } from 'src/types/pantheon/pantheon.types';
import { BlockObjectTree } from 'src/types/shared/notice.sql.types';
const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    textAlign: 'left',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    gap: `${theme.spacing(2)}px`,
    gridColumn: 'span 1',
    gridRow: 'span 1',
  },

  container: {
    display: 'flex',
    width: '-webkit-fill-available',
    padding: '16px',
    gap: '16px',
  },

  headerRow: {
    marginLeft: 'auto',
  },
  calendar: {
    padding: theme.spacing(2),
  },
  search: {
    backgroundColor: indigo[100],
    padding: theme.spacing(1),
  },
  searchInput: {
    padding: theme.spacing(0, 1, 0, 1),
  },
  searchIcon: {},
}));

export function Calendar(): JSX.Element {
  const classes = useStyles();
  const emit = useAPI();

  const [myEventsList, setEvents] = React.useState<Event[]>([]);

  React.useEffect(() => {
    emit<{ block: { id: BlockObjectTree } }>(
      {
        method: 'get',
        schema: 'gic_disclosure',
        table: 'api_all_blocks',
      },
      (response?: APIGetResponse<{ block: { id: BlockObjectTree } }>) => {
        if (!response?.message?.[0]) return;
        const blocks = response.message[0].block;
        const events: Event[] = [];

        for (const id in blocks) {
          const block = blocks[id as keyof typeof blocks];
          const reduction = block.reductionLow === block.reductionHigh ? block.reductionLow : ` ${block.reductionLow} - ${block.reductionHigh}`;
          const reudctionTimeUnit = block.startDate === block.endDate ? '' : `per day`;
          events.push({
            title: `${block.asset} - ${block.notes || 'Unknown'} - ${reduction}TJ ${reudctionTimeUnit} reduction`,
            start: moment(block.startDate).toDate(),
            end: moment(block.endDate).toDate(),
            resource: block.asset,
          });
        }

        setEvents(events);
      },
    );
  }, []);

  return (
    <div className={classes.container}>
      <Paper style={{ display: 'flex', flexDirection: 'column', padding: '16px', width: '-webkit-fill-available' }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '-webkit-fill-available' }}>
          <Typography variant={'h5'} style={{ alignSelf: 'center' }}>
            **Search focus named here**
          </Typography>
          <NexusSearch paperClass={classes.search} iconClass={classes.searchIcon} className={classes.searchInput} wrapperClass={classes.headerRow} />
        </div>
        <div style={{ height: '-webkit-fill-available' }}>
          <BigCalendar localizer={localizer} events={myEventsList} startAccessor='start' endAccessor='end' style={{ maxWidth: '100%', height: '100%', width: '100%' }} />
        </div>
      </Paper>
    </div>
  );
}
