import React from 'react';
import { pipelineOptionsMap } from 'src/components/Charts/components/config/makeTimeSeries';
import { NexusChart } from 'src/components/Charts/NexusChart';
import { PageHeader } from 'src/components/PageHeader';
import { SkipBox, SkipPaper } from 'src/style/components/styledFlex';

function Pipeline(): JSX.Element {
  return (
    <SkipPaper isGrid p={2} g={2} w={'100%'} h={'100%'} rows={'min-content 1fr 1fr'} cols={'1fr 1fr'}>
      <SkipBox flex d={'column'} ga={' 1 / 1 / 1 / 3'} w={'100%'}>
        <PageHeader>Pipeline conditions and linepack</PageHeader>
      </SkipBox>
      <SkipBox ga={'2 / 1 / 2 / 1'}>
        <NexusChart id={'linepack'} settings={[pipelineOptionsMap.linepack]} />
      </SkipBox>
      <SkipBox ga={'2 / 2 / 2 / 2'}>
        <NexusChart id={'pressure'} settings={[pipelineOptionsMap.pressure]} />
      </SkipBox>
      <SkipBox ga={'3 / 1 / 3 / 1'}>
        <NexusChart id={'position'} settings={[pipelineOptionsMap.netPosition]} />
      </SkipBox>
      <SkipBox ga={'3 / 2 / 3 / 2'}>
        <NexusChart id={'roi'} settings={[pipelineOptionsMap.roi]} />
      </SkipBox>
    </SkipPaper>
  );
}

export { Pipeline };
