import moment from 'moment';
import React from 'react';
import { generationNetbackOptionsMap } from 'src/components/Charts/components/config/makeNetback/generationNetback';
import { ChartRangePicker } from 'src/components/Charts/components/controls/ChartRangePicker';
import { ChartRollupRadio } from 'src/components/Charts/components/controls/ChartRollupRadio';
import { NexusChart } from 'src/components/Charts/NexusChart';
import { PageHeader } from 'src/components/PageHeader';
import { ChartControlWrapper } from 'src/lab/ChartControlWrapper';
import { SkipBox, SkipPaper } from 'src/style/components/styledFlex';

function SparkSpread(): JSX.Element {
  const chartData = generationNetbackOptionsMap.huntlyCC;
  const chartId = 'netback';

  return (
    <SkipPaper isGrid p={2} g={2} w={'100%'} h={'100%'} rows={'min-content 1fr '} cols={'1fr 250px'}>
      <SkipBox flex d={'column'} ga={' 1 / 1 / 1 / 2'} w={'100%'}>
        <PageHeader>Gas Spot Market Trading</PageHeader>
      </SkipBox>
      <SkipBox ga={'2 / 1 / 2 / 1'}>
        <NexusChart id={chartId} settings={[chartData]} />
      </SkipBox>
      <SkipBox ga={'2 / 2 / 2 / 3'}>
        <ChartControlWrapper title={'Chart Range'} column>
          <ChartRangePicker id={chartId} format={'date'} initialValue={[moment(chartData.range[0], 'YYYY-MM-DD'), moment(chartData.range[1], 'YYYY-MM-DD')]} />
        </ChartControlWrapper>
        <ChartControlWrapper title={'Data Rollup'} column>
          <ChartRollupRadio id={chartId} initialValue={'day'} />
        </ChartControlWrapper>
      </SkipBox>
    </SkipPaper>
  );
}

export { SparkSpread };
