import React, { createContext, PropsWithChildren, useContext, useRef, useMemo } from 'react';
import { useSP } from 'src/modules/websocket/hooks';
import { AddChartProps, MakeSeriesTypes, MakeSeriesOptions } from 'src/types/module/chartModule.types';
import { stripMakeOptions } from '../lib/interfaceDecomposers';
import { makeSeriesFetch } from '../lib/makeSeries';
import { ChartContextInterface, ChartSeriesFunctionsHOC, ChartSeriesFunctions } from 'src/types/module/chartProvider.types';
import { mergeDeep } from 'src/lib/util';

const ChartContext = createContext<ChartContextInterface>((null as unknown) as ChartContextInterface);

// eslint-disable-next-line @typescript-eslint/ban-types
export function ChartProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  const currentCharts = useRef<Record<string, AddChartProps>>({});
  const emit = useSP();

  const addChart = (props: AddChartProps, id: string) => {
    if (currentCharts.current[id]) {
      console.warn('chart ' + id + 'exists in context');
      return id;
    }

    const { chart, container, ...initialConfig } = props;
    currentCharts.current[id] = props;

    currentCharts.current[id].chart.showLoading();
    currentCharts.current[id].chart.update(props.options, false);

    // prob not needed now as there is a specific update function
    if (currentCharts.current[id].chart.nexus) {
      seriesFunction.removeAll(id)();
    }

    currentCharts.current[id].chart.update({
      chart: {
        events: {
          addSeries: function (event) {
            event.target.series.map(series => (series.nexus.id = series.index));
          },
        },
      },
    });

    currentCharts.current[id].chart.nexus = {
      id,
      initialConfig: { props: { ...props }, ...initialConfig, range: props.range },
      currentConfig: { ...initialConfig, range: props.range, request: [] },
      chart,
      container,
    };

    props.request.map(series => {
      seriesFunction.add(id)(series, true);
    });
    return id;
  };

  const fetchNewData = (id: string) => (requests: Omit<AddChartProps, 'chart' | 'container'>) => {
    const chartContext = currentCharts.current[id];
    // must take copy of object ot prevent circular logic
    const props = { ...requests };

    if (!chartContext) throw new Error(`Cannot find chart with ID ${id} to fetch new chart for.`);

    // const nexusChart = chartContext.chart.nexus;

    chartContext.chart.showLoading();

    if (props.request) {
      // nexusChart.currentConfig.request = props.request.map(request => ({ ...request }));
      seriesFunction.removeAll(id)();
      props.request.map(series => {
        seriesFunction.add(id)(series, true);
      });
    }

    if (props.options) {
      updateOptions(id)(props.options);
    }
  };

  const updateOptions = (id: string) => (options: Highcharts.Options, redraw = true) => {
    const chartContext = currentCharts.current[id];
    if (!chartContext) throw new Error(`Cannot find chart with ID ${id} to update options.`);
    const nexusChart = chartContext.chart.nexus;

    const newOptions = mergeDeep({}, nexusChart.currentConfig.options, options);
    chartContext.chart.nexus.currentConfig.options = { ...newOptions };
    nexusChart.chart.update({ ...newOptions }, redraw);
  };

  const getChart = (id?: string) =>
    Object.keys(currentCharts.current).reduce<Highcharts.Chart[]>((prev, curr) => {
      if (!id) return [...prev, currentCharts.current[curr].chart];
      if (id && prev.length === 0 && curr == id) return [currentCharts.current[curr].chart];
      return prev;
    }, []);

  // functions to manage chart series
  const seriesFunction: ChartSeriesFunctionsHOC = {
    add: (id: string) => (request: MakeSeriesOptions<MakeSeriesTypes>) => {
      const chartContext = currentCharts.current[id];
      if (!chartContext) throw new Error(`Cannot find chart with ID ${id} to add series.`);

      const { request: makeRequest } = stripMakeOptions(request);

      const { _makeInterface, ...parsedRequest } = makeRequest;

      makeSeriesFetch(
        _makeInterface,
        emit,
      )({ ...parsedRequest, range: currentCharts.current[id].chart.nexus.currentConfig.range }).then(response => {
        if (!response?.series) {
          console.warn(`Series return undefined!`);
          console.warn(parsedRequest);
          return;
        }
        const { series } = response;
        if (!currentCharts?.current[id]) return;
        currentCharts.current[id].chart.nexus.currentConfig.request.push({ ...request });
        series.map(thisSeries => {
          const newSeries = currentCharts.current[id].chart.addSeries(thisSeries, false);
          newSeries.nexus = { id: newSeries.index, request: { ...request } };
        });
        currentCharts.current[id].chart.hideLoading();
        currentCharts.current[id].chart.redraw(true);
      });
    },
    remove: (id: string) => (seriesId: number) => {
      const target = currentCharts.current[id].chart.series.find(series => series.nexus.id === seriesId);
      if (target) {
        target.remove(false);
        currentCharts.current[id].chart.nexus.currentConfig.request = currentCharts.current[id].chart.series.map(series => ({ ...series.nexus.request }));
      }
    },
    removeAll: (id: string) => () => {
      if (!currentCharts.current[id].chart.nexus) return;

      const existingSeries = currentCharts.current[id].chart.nexus.chart.series;

      for (let i = existingSeries.length - 1; i >= 0; i--) {
        const series = existingSeries[i];
        series.remove(false);
      }

      currentCharts.current[id].chart.nexus.currentConfig.request = [];
    },
    get: (id: string) => (seriesId?: number) => (seriesId ? currentCharts.current[id].chart.series.filter(series => series.nexus.id !== seriesId) : currentCharts.current[id].chart.series),
    requests: (id: string) => () => currentCharts.current[id].chart.series.map(series => series.nexus.request),
  };

  //TODO not sure if this is needed
  const removeChart = (id?: string) => {
    if (!id) return console.warn('no id specified (removechart)');
    const target = currentCharts.current[id];
    if (target) {
      // if (target.chart.destroy) {
      //   try {
      //     target.chart.destroy();
      //   } catch (e) {
      //     console.warn('could not destroy chart instance');
      //   }
      // }

      // seriesFunction.removeAll(id)();
      delete currentCharts.current[id];
    } else {
      console.warn(`could not find chart ${id}`);
    }
  };

  const contextValue = useMemo(() => [addChart, getChart, removeChart, fetchNewData, updateOptions, seriesFunction] as const, []);
  return <ChartContext.Provider value={contextValue}>{children}</ChartContext.Provider>;
}

// root level context hook
export function useChartContext(): ChartContextInterface {
  const result = useContext(ChartContext);
  if (!result) {
    throw new Error('Chart context is only available inside its provider');
  }

  return result;
}

// hook to get chart and series functions
export function useChart(id: string): [() => Highcharts.Chart, ChartSeriesFunctions, (props: Omit<AddChartProps, 'chart' | 'container'>) => void, (props: Highcharts.Options) => void] {
  const result = useContext(ChartContext);
  if (!result) {
    throw new Error('Chart context is only available inside its provider');
  }

  // destructure context
  const [, getChart, , fetchNewChart, updateChart, seriesFunction] = result;

  // destructure series functions
  const { add, remove, get, requests } = seriesFunction;

  // return chart instance and series functions with chart id already called on HOC
  return [() => getChart(id)[0], { add: add(id), remove: remove(id), get: get(id), requests: requests(id) }, fetchNewChart(id), updateChart(id)];
}

// hook to get a series from a chart
export function useChartSeries(chartId: string, seriesId: number): Highcharts.Series[] | undefined {
  const [, series] = useChart(chartId);
  return series.get(seriesId);
}
