import { TableRow, TableCell, IconButton, TextField, useTheme, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import { StyledTooltip } from 'src/components/styled';
import { useSnackSend } from 'src/hooks/useSnackSend';
import { useSocketEmit } from 'src/modules/websocket/hooks';
import { TableField, APIRequest, APIPutResponse, APIPostResponse, DataGridTypeMap } from 'src/types/pantheon/pantheon.types';
import { DataRowType, RowStateDispatch, SourceDefinition } from './types';
import { parseValue } from './util';
import clsx from 'clsx';
import { green, yellow } from '@material-ui/core/colors';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { useTableStyles } from './useTableStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commonCell: {
      padding: '11px 14px',
    },
    actionButtons: {
      textAlign: 'end',

      maxWidth: 20,
    },

    iconButtonSmall: {
      margin: 0,
      '&:hover': {
        color: green[100],
        backgroundColor: yellow[600],
      },
    },
  }),
);

export const CellInput = ({ type, value, setter }: { type: TableField; value: string; setter: Dispatch<SetStateAction<Record<string, string>>> }): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isPK = type.name === 'ID';

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = e => {
    setter(state => ({ ...state, [type.name]: e.target.value }));
  };

  return (
    <TextField
      fullWidth
      inputProps={{ className: classes.commonCell }}
      style={{ padding: 0 }}
      name={type.name}
      placeholder={isPK ? 'Auto generated' : undefined}
      variant={'outlined'}
      type={DataGridTypeMap[type.type]}
      value={value}
      onChange={handleChange}
      disabled={isPK}
    />
  );
};

export const EditableRow = ({ data, fields, index, setter, newRow, source }: { fields: TableField[]; data: DataRowType; index: number; setter: RowStateDispatch; newRow?: boolean; source: SourceDefinition }): JSX.Element => {
  const classes = { ...useStyles(), ...useTableStyles() };
  const [emit] = useSocketEmit('pantheon.api');

  const dataToState = () =>
    fields.reduce<Record<string, string>>((prev: Record<string, string>, field: TableField) => {
      return { ...prev, [field.name]: parseValue(data[field.name], field, 'YYYY-MM-DD') };
    }, {});

  const { snackConfirmation } = useSnackSend();
  const [isNew, setNew] = React.useState(newRow);
  const [cells, setCells] = React.useState<Record<string, string>>(dataToState());

  const { table, fk } = source;
  const [editing, setEditing] = React.useState(isNew);

  const toggleEditing = React.useCallback(() => setEditing(state => !state), []);
  const isOdd = index % 2 > 0;

  const handleDelete = () => {
    snackConfirmation('Confirm delete?', () => {
      const request: APIRequest = {
        method: 'delete',
        table,
        schema: 'nzed',
        id: Number.parseInt(cells.ID),
      };

      emit<APIRequest, APIPutResponse>(request, response => {
        if (response?.code === 200) setter(state => ({ ...state, rows: state.rows.filter(row => row.ID !== Number.parseInt(cells.ID)) }));
      });
    });
  };

  const handleSaveNew = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ID, ...data } = cells;

    const request: APIRequest = {
      method: 'post',
      table,
      schema: 'nzed',
      data,
      fk,
      post: {
        updateDuplicate: true,
      },
    };

    emit<APIRequest, APIPostResponse<string>>(request, response => {
      if (!response?.message?.okPacket || !response.message.okPacket.insertId) return;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setCells(state => ({ ...state, ID: response.message.okPacket!.insertId.toString() }));
      setNew(false);
      setEditing(false);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setter(state => ({ ...state, rows: [{ ID: response.message.okPacket!.insertId, ...data }, ...state.rows.slice(1)] }));
    });
  };

  const handleSave = () => {
    const { ID, ...data } = cells;

    const request: APIRequest = {
      method: 'patch',
      table,
      schema: 'nzed',
      data,
      id: Number.parseInt(ID),
    };

    emit<APIRequest, APIPutResponse<string>>(request, response => {
      if (response?.code === 200) {
        setEditing(false);
        setter(state => ({ ...state, rows: state.rows.map(row => (row.ID === Number.parseInt(ID) ? { ...cells } : row)) }));
      }
    });
  };

  const handleDiscardNew = () => {
    setter(state => ({ ...state, rows: state.rows.slice(1) }));
  };

  const handleDiscardChanges = () => {
    snackConfirmation('Confirm discard?', () => {
      setCells(dataToState());
      setEditing(false);
    });
  };

  return (
    <TableRow className={clsx(classes.allRows, { [classes.oddRow]: !editing && isOdd && !isNew, [classes.evenRow]: !editing && !isOdd && !isNew, [classes.newRow]: isNew, [classes.editingRow]: !isNew && editing })}>
      {fields.map((field: TableField, j: number) => {
        return (
          <TableCell key={`cell-${j}`} className={classes.tableCell}>
            {editing ? <CellInput type={field} value={cells[field.name]} setter={setCells} /> : <span className={classes.commonCell}>{parseValue(cells[field.name], field)}</span>}
          </TableCell>
        );
      })}
      <TableCell className={clsx(classes.headerCell, classes.tableCell, classes.actionButtons)}>
        {editing && (
          <>
            <StyledTooltip title={`Save ${cells.ID ? ' changes' : 'new entry'}`}>
              <IconButton aria-label='save' className={classes.iconButtonSmall} onClick={cells.ID ? handleSave : handleSaveNew}>
                <SaveIcon fontSize='inherit' />
              </IconButton>
            </StyledTooltip>
            <StyledTooltip title={`Discard ${cells.ID ? 'changes' : 'without saving'}`}>
              <IconButton aria-label='discard' className={classes.iconButtonSmall} onClick={cells.ID ? handleDiscardChanges : handleDiscardNew}>
                <CancelIcon fontSize='inherit' />
              </IconButton>
            </StyledTooltip>
          </>
        )}
        {cells.ID && !editing && (
          <>
            <StyledTooltip title={`Edit entry`}>
              <IconButton aria-label='edit' className={classes.iconButtonSmall} onClick={toggleEditing}>
                <EditIcon fontSize='inherit' />
              </IconButton>
            </StyledTooltip>
            <StyledTooltip title={'Delete entry'}>
              <IconButton aria-label='delete' className={classes.iconButtonSmall} onClick={handleDelete}>
                <DeleteIcon fontSize='inherit' />
              </IconButton>
            </StyledTooltip>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
