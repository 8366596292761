import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

export const useTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHeader: { backgroundColor: theme.palette.primary.light },
    evenRow: { backgroundColor: theme.palette.grey[500] },
    oddRow: { backgroundColor: theme.palette.grey[300], color: '#000 !important' },
    newRow: { backgroundColor: blue[50] },
    editingRow: { backgroundColor: '#c1b33b', color: theme.palette.getContrastText('#c1b33b') },
    allRows: {},
    headerCell: {
      padding: '8px 16px',
      height: '2rem',
      fontSize: 'larger',
      color: theme.palette.common.white,
    },
    commonCell: {
      padding: '8px 16px',
      height: '2rem',
      fontSize: 'larger',
    },
    commonHeader: {
      padding: '4px 16px',
    },
    tableCell: {
      padding: '0px 16px',
      '&:first-child': {
        paddingLeft: '16px !important',
      },
      '&:last-child': {
        paddingRight: '16px !important',
      },
    },
    headerRow: {},
  }),
);
