import { MakeCoreTimeSeriesSP, MakeSeriesChartOptions, MakeSeriesOptionsMap, SeriesRange } from 'src/types/module/chartModule.types';
import { createMakeMap, createOptionsArrayMap } from '../../lib/util';
import { genericOatisHourly } from './common';
import { mergeTimeSeries } from './util';

export const generateOatisHourly = (name: string, seriesNames: string[], seriesMeters: number[][], range?: SeriesRange): MakeSeriesChartOptions<MakeCoreTimeSeriesSP> => ({
  ...genericOatisHourly(name),
  name,
  range: range || genericOatisHourly(name).range,
  seriesNames: seriesNames,
  seriesFilters: seriesMeters.map(series => ['meterID', series]),
});

const pohokura: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Pohokura',
  seriesNames: ['Pohokura'],
  seriesFilters: [['meterID', [191, 194]]],
  ...genericOatisHourly('Pohokura'),
};

const mm: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisHourly('McKee/Mangahewa'),
  name: 'McKee/Mangahewa',
  seriesNames: ['McKee/Mangahewa'],
  seriesFilters: [['meterID', [134, 467]]],
};

const maui: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisHourly('Maui'),
  name: 'Maui',
  seriesNames: ['Maui'],
  seriesFilters: [['meterID', [132]]],
};

const kupe: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisHourly('Kupe'),
  name: 'Kupe',
  seriesNames: ['Kupe'],
  seriesFilters: [['meterID', [447]]],
};

const kowhai: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisHourly('Kowhai'),
  name: 'Kowhai',
  seriesNames: ['Kowhai'],
  seriesFilters: [['meterID', [446]]],
};

const turangi: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisHourly('Turangi'),
  name: 'Turangi',
  seriesNames: ['Turangi'],
  seriesFilters: [['meterID', [193]]],
};

const greymouth: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisHourly('Greymouth aggregate'),
  name: 'Turangi+Kowhai',
  seriesNames: ['Turangi+Kowhai'],
  seriesFilters: [['meterID', [193, 446]]],
};

const sidewinder: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisHourly('Sidewinder'),
  name: 'Sidewinder',
  seriesNames: ['Sidewinder'],
  seriesFilters: [['meterID', [459]]],
};

const cheal: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisHourly('Cheal'),
  name: 'Cheal',
  seriesNames: ['Cheal'],
  seriesFilters: [['meterID', [462]]],
};

const kaimiro: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisHourly('Rimu/Kaimiri'),
  name: 'Rimu/Kaimiri',
  seriesNames: ['Rimu/Kaimiri'],
  seriesFilters: [['meterID', [437, 461]]],
};

export const majorHourlyProduction = mergeTimeSeries('Major Production', pohokura, mm, maui, kupe, kowhai, turangi, greymouth);
export const minorHourlyProduction = mergeTimeSeries('Minor Production', cheal, sidewinder, kaimiro);
export const chealHourlyTotal = mergeTimeSeries('Matahio Total', cheal, sidewinder);

export const gasFieldHourlyOptionsMap: MakeSeriesOptionsMap<MakeCoreTimeSeriesSP> = {
  pohokura,
  mm,
  maui,
  kupe,
  kowhai,
  turangi,
  greymouth,
  sidewinder,
  cheal,
  kaimiro,
  majorHourlyProduction,
  minorHourlyProduction,
  chealHourlyTotal,
};

export const gasFieldHourlyMakeMap = createMakeMap(gasFieldHourlyOptionsMap);
export const gasFieldHourlyOptionsArrayMap = createOptionsArrayMap(gasFieldHourlyOptionsMap);
