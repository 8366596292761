import React from 'react';
import { useSectorEntities } from 'src/redux/reducer/metadata';
import { EnergyStreamRow, EnergyStreamContainer, EnergyStreamLabel, EnergyStreamItem } from 'src/style/components';

const Companies: [string, string, number, number][] = [
  ['Beach', 'beach', 1, 1],
  ['OMV', 'omv', 1, 1],
  ['First Gas', 'fg', 2, 1],
  ['Genesis', 'genesis', 1, 3],
  ['Nova', 'nova', 1, 3],
  ['Contact', 'contact', 2, 2],
  ['Vector', 'vector', 2, 2],
  ['Methanex', 'methanex', 3, 1],
  ['NZ Steel', 'nzs', 3, 1],
  ['Ballance', 'ballance', 3, 1],
];

function GasCompaniesHome(): JSX.Element {
  // const entities = useSectorEntities('gas');
  return (
    <EnergyStreamContainer style={{ flexDirection: 'column' }}>
      <EnergyStreamRow colour={'white'} style={{ gridArea: '1 / 1 / auto / span 12' }} />
      <EnergyStreamRow colour={'grey'} style={{ gridArea: '2 / 1 / auto / span 12' }} />
      <EnergyStreamRow colour={'white'} style={{ gridArea: '3 / 1 / auto / span 12' }} />
      <EnergyStreamLabel style={{ gridArea: '1 / 1 / auto / span 1', alignItems: 'center' }}>Upstream</EnergyStreamLabel>
      <EnergyStreamLabel style={{ gridArea: '2 / 1 / auto / span 1', alignItems: 'center' }}>Midstream</EnergyStreamLabel>
      <EnergyStreamLabel style={{ gridArea: '3 / 1 / auto / span 1', alignItems: 'center' }}>Downstream</EnergyStreamLabel>

      {Companies.map((item: [string, string, number, number], i: number) => (
        <div key={`${item[0]}-${i}`} style={{ gridArea: `${item[2]} / ${i + 2} / span ${item[3]} / span 1`, padding: 16, display: 'flex' }}>
          <EnergyStreamItem route={`${item[1]}`} style={{ width: '100%' }}>
            {item[0]}
          </EnergyStreamItem>
        </div>
      ))}
    </EnergyStreamContainer>
  );
}

export { GasCompaniesHome };

/*

      */
