import moment from 'moment';
import { TableField, DataGridTypeMap } from 'src/types/pantheon/pantheon.types';
import { APIDataTypeOptional } from './types';

export const parseNumber = (value: APIDataTypeOptional, decimals: number): string => {
  if (!value) return '';

  const asNumber = typeof value === 'string' ? Number.parseFloat(value) : value;

  if (typeof asNumber === 'number') {
    return decimals > 0 ? asNumber.toFixed(decimals) : asNumber.toString();
  } else return '';
};

export const parseValue = (value: APIDataTypeOptional, fieldType: TableField, dateFormat = 'DD-MM-YYYY'): string => {
  const { decimals, type } = fieldType;
  const dataType = DataGridTypeMap[type];

  if (!value) {
    switch (dataType) {
      case 'date':
        return moment().format(dateFormat);
      case 'dateTime':
        return moment().format(`${dateFormat} HH:mm:ss`);
      default:
        return '';
    }
  }

  switch (dataType) {
    case 'string':
      return typeof value === 'string' ? value : '';
    case 'number':
      return parseNumber(value, decimals);
    case 'date':
      return moment(value as Date).format(dateFormat);
    case 'dateTime':
      return moment(value as Date).format(`${dateFormat} HH:mm:ss`);
    default:
      return 'ERROR - NO TYPE DEF (havent sorted bool)';
  }
};
