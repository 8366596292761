import { mergeDeep } from '../../../../../lib/util';
import { CoreTimeSeries, MakeCoreTimeSeriesSP, MakeSeriesChartOptions, MakeSeriesOptionsMap } from 'src/types/module/chartModule.types';
import { createMakeMap, createOptionsArrayMap } from '../../lib/util';
import { makeRange } from '../common';
import { columnChart } from '../chartConfig';
import { green, red } from '@material-ui/core/colors';

const ags: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...makeRange('rolling', 3, 'month', 'date'),
  _makeInterface: CoreTimeSeries.TimeSeries,
  name: 'Ahuroa Gas Storage',
  dataTable: 'agsNetFlow',
  xAxisColumn: 'date',
  seriesNames: ['AGS flow'],
  seriesColumns: ['agsNet'],
  options: mergeDeep<Highcharts.Options>({}, columnChart, {
    title: {
      text: 'Ahuroa Gas Storage',
    },
    //! needs to know if chart has rollup applied to apply correct inject/extract capacities
    // yAxis: {
    //   max: 65000,
    //   min: -65000,

    //   plotLines: [
    //     {
    //       value: 65000,
    //       color: green['A400'],
    //       ...plotLinesCommon,
    //       label: {
    //         text: 'Injection Capacity',
    //         verticalAlign: 'top',
    //       },
    //     } as Highcharts.YAxisPlotLinesOptions,

    //     {
    //       value: -65000,
    //       color: red['A400'],
    //       label: {
    //         text: 'Extraction Capacity',
    //         verticalAlign: 'bottom',
    //       },
    //       ...plotLinesCommon,
    //     },
    //   ],
    // },
    plotOptions: {
      column: {
        pointWidth: 2,
        color: green[300],
        zones: [
          {
            value: 0,
            color: red[300],
          },
        ],
        pointPadding: 2,
        groupPadding: 2,
      },
    },
  }),
};
export const gasMiscOptionsMap: MakeSeriesOptionsMap<MakeCoreTimeSeriesSP> = { ags };

export const gasMiscMakeMap = createMakeMap(gasMiscOptionsMap);
export const gasMiscOptionsArrayMap = createOptionsArrayMap(gasMiscOptionsMap);
