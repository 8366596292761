import moment from 'moment';
import { NoticeInterface, OutageBlockInterface, OutageInterface } from '../common/notice.types';

export const makeBlankNotice = (): NoticeInterface => ({ id: 0, noticeDate: moment(), outages: [] });
export const makeBlankOutage = (description: string): OutageInterface => ({ id: 0, description, assetID: 0, operatorID: 0, ownerID: 0, blocks: [] });
export const makeBlankOutageBlock = (): OutageBlockInterface => ({ id: 0, notes: null, startDate: moment(), endDate: moment(), reductionLow: null, reductionHigh: null, tentative: false });

// validates the NoticeInterface object for submission to backend
export const doValidate = (notice: NoticeInterface): string[] => {
  const { gicNoticeID, outages } = notice;
  const errors: string[] = [];
  if (!gicNoticeID) errors.push('Notice must have a GIC notice ID');
  if (outages.length === 0) errors.push('Notice must have at least one outage');

  return [
    ...errors,
    ...outages
      .map((outage: OutageInterface, outageNumber: number) => {
        const { blocks } = outage;
        const errors: string[] = [];

        if (blocks.length === 0) errors.push(`Outage #${outageNumber + 1} - Outages must have at least one block`);
        if (outage.assetID === 0) errors.push(`Outage #${outageNumber + 1} - You must choose an outage asset`);
        if (outage.operatorID === 0) errors.push(`Outage #${outageNumber + 1} - You must choose an outage operator`);
        if (outage.ownerID === 0) errors.push(`Outage #${outageNumber + 1} - You must choose an outage owner`);
        return [
          ...errors,
          ...blocks
            .map((block: OutageBlockInterface, blockNumber: number) => {
              const { reductionLow, reductionHigh } = block;
              const errors: string[] = [];
              if (!reductionLow) errors.push(`Outage #${outageNumber + 1},  Block #${blockNumber + 1} - Outage block must have a reduction (LOW) set`);
              if (!reductionHigh) errors.push(`Outage #${outageNumber + 1},  Block #${blockNumber + 1} - Outage block must have a reduction (HIGH) set`);
              // if (startDate.diff(endDate) < 0) errors.push(`Outage #${outageNumber + 1}, Block #${blockNumber + 1} - Outage block start date must be before end date`);
              return errors;
            })
            .filter((blockErrors: string[]) => blockErrors.length > 0)
            .flat(),
        ];
      })
      .filter((blockErrors: string[]) => blockErrors.length > 0)
      .flat(),
  ];
};

// polyfill from mysql lib
export interface MysqlError extends Error {
  /**
   * Either a MySQL server error (e.g. 'ER_ACCESS_DENIED_ERROR'),
   * a node.js error (e.g. 'ECONNREFUSED') or an internal error
   * (e.g. 'PROTOCOL_CONNECTION_LOST').
   */
  code: string;

  /**
   * The error number for the error code
   */
  errno: number;

  /**
   * The sql state marker
   */
  sqlStateMarker?: string;

  /**
   * The sql state
   */
  sqlState?: string;

  /**
   * The field count
   */
  fieldCount?: number;

  /**
   * The stack trace for the error
   */
  stack?: string;

  /**
   * Boolean, indicating if this error is terminal to the connection object.
   */
  fatal: boolean;

  /**
   * SQL of failed query
   */
  sql?: string;

  /**
   * Error message from MySQL
   */
  sqlMessage?: string;
}

export const isMysqlError = (obj: unknown): obj is MysqlError => {
  const asMysqlError = obj as MysqlError;
  const keys = ['code', 'errNo'];
  return typeof obj === 'object' && keys.every((key: string) => asMysqlError[key as keyof MysqlError] !== undefined);
};
