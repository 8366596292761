import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { TreeViewer } from 'src/components/TreeBrowser';
import { TreeNodeConfig } from 'src/components/TreeBrowser/makeTree';
import { NotFound } from 'src/router';
import { SkipBox, SkipPaper } from 'src/style/components/styledFlex';
import { Downstream } from './Downstream';
import { FieldView } from './FieldView';
import { FlowContentHome } from './FlowContentHome';
import { GasFlow } from './GasFlow';
import { Points } from './Points';
import { Storage } from './Storage';

const treeDefinition: TreeNodeConfig[] = [
  {
    text: 'Upstream',
    info: 2,
    depth: 0,
    childDepth: 1,
    nodeStyle: 'primaryNode',
    childNodeStyle: 'secondaryNode',
    childNodes: [
      {
        text: 'Reserves',
        info: 3,
        depth: 1,
        childDepth: 2,
        childNodeStyle: 'tertiaryNode',
        childNodes: [
          {
            text: 'By Field',
            navigate: 'upstream/reserves/field',
          },
          {
            text: 'By Company',
            navigate: 'upstream/reserves/company',
          },
          {
            text: 'By Offshore/offshore',
            navigate: 'upstream/reserves/setting',
          },
        ],
      },
      {
        text: 'Production',
        info: 3,
        depth: 1,
        childDepth: 2,
        childNodeStyle: 'tertiaryNode',
        childNodes: [
          {
            text: 'By Field',
            navigate: 'upstream/production/field',
          },
          {
            text: 'By Company',
            navigate: 'upstream/production/company',
          },
          {
            text: 'By Setting',
            navigate: 'upstream/production/setting',
          },
        ],
      },
    ],
  },
  {
    text: 'Midstream',
    info: 8,
    depth: 0,
    childDepth: 1,
    nodeStyle: 'primaryNode',
    childNodeStyle: 'secondaryNode',
    childNodes: [
      {
        text: 'Storage',
        info: 2,
        nodeStyle: 'tertiaryNode',
        depth: 1,
        navigate: 'midstream/storage',
      },
      {
        text: 'Welded Points',
        info: 2,
        depth: 1,
        childDepth: 2,
        childNodeStyle: 'tertiaryNode',
        childNodes: [
          { text: 'Vector', navigate: 'midstream/points/vector' },
          { text: 'Maui', navigate: 'midstream/points/maui' },
          { text: 'Consolidated', navigate: 'midstream/points' },
        ],
      },
    ],
  },
  {
    text: 'Downstream',
    info: 8,
    nodeStyle: 'primaryNode',
    childNodeStyle: 'tertiaryNode',
    depth: 0,
    childDepth: 1,
    childNodes: [
      {
        text: 'By sector',
        navigate: 'downstream/sector',
      },

      {
        text: 'By sector and company',
        navigate: 'downstream/sector/company',
      },
      {
        text: 'By sector and asset',
        navigate: 'downstream/sector/asset',
      },
      {
        text: 'Methanex',
        navigate: 'downstream/methanex',
      },
    ],
  },
];

export const GasFlowRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();
  return (
    <SkipBox flex w={'100%'} h={'100%'} g={2}>
      <SkipPaper flex w={'30%'} p={2}>
        <TreeViewer config={treeDefinition} />
      </SkipPaper>

      <SkipPaper p={2} w={'-webkit-fill-available'}>
        <Switch>
          <Route path={`${path}`} exact>
            <FlowContentHome />
          </Route>
          <Route path={`${path}/upstream`} component={FieldView} />
          <Route path={`${path}/midstream/storage`} component={Storage} exact />
          <Route path={`${path}/downstream/sector`} component={Downstream} exact />
          <Route path={`${path}/downstream/sector/company`} component={Downstream} exact />
          <Route path={`${path}/downstream/sector/asset`} component={Downstream} exact />
          <Route path={`${path}/midstream/points/vector`} component={() => <Points network={'vector'} />} exact />
          <Route path={`${path}/midstream/points/maui`} component={() => <Points network={'maui'} />} exact />
          <Route path={`${path}/midstream/points`} component={Points} exact />
          <Route path={`${path}/downstream/methanex`} component={GasFlow} exact />
          <Route component={NotFound} />
        </Switch>
      </SkipPaper>
    </SkipBox>
  );
};
