import { Tooltip, IconButton, TableContainer, Typography, Divider, Table, TableRow, TableCell, TableBody, makeStyles, Theme, createStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { useSocketEmit } from 'src/modules/websocket/hooks/useSocketEmit';
import { APIRequest, APIGetResponse } from 'src/types/pantheon.types';
import { NoticeRecord } from '../common/notice.sql.types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: 3,
      marginBottom: 7,
      height: '1px',
    },
    container: {
      paddingBottom: 10,
      overflow: 'auto',
    },
    noticeCell: {
      padding: theme.spacing(1),
      width: '-webkit-fill-available',
    },
    buttonCell: {
      padding: theme.spacing(1),
      width: 'min-content',
    },
    commonRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    evenRow: {
      backgroundColor: theme.palette.background.paper,
    },
    oddRow: {
      backgroundColor: theme.palette.grey[300],
    },
    iconButton: {
      padding: '8px',
      borderRadius: '16px',
    },
  }),
);

export const NoticeList = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const [emit] = useSocketEmit('pantheon.api');

  const [notices, setNotices] = React.useState<NoticeRecord[] | undefined>(undefined);

  React.useEffect(() => {
    emit<APIRequest, APIGetResponse<NoticeRecord>>(
      {
        method: 'get',
        table: 'api_notices',
        schema: 'gic_disclosure',
      } as APIRequest,
      data => {
        const notices = data?.message;
        if (!notices) return; // null response
        setNotices(notices);
      },
    );
  }, []);

  return (
    <TableContainer className={classes.container}>
      <Typography align={'left'} variant={'h5'}>
        GIC Notices
      </Typography>
      <Divider className={classes.divider} />
      <Table size='small' aria-label='a dense table' padding='none' stickyHeader>
        <TableBody>
          {notices?.map((notice: NoticeRecord, i: number) => (
            <TableRow key={notice.gicNoticeID} className={clsx(classes.commonRow, i % 2 === 0 ? classes.evenRow : classes.oddRow)}>
              <TableCell align='left' className={classes.noticeCell}>
                {`Notice #${notice.gicNoticeID} - ${moment(notice.date).format('DD/MM/YYYY')}`}
              </TableCell>
              <TableCell align='left' className={clsx(classes.buttonCell)}>
                <Tooltip title='Edit notice' aria-label='view'>
                  <IconButton aria-label='view' size={'medium'} className={classes.iconButton} onClick={() => history.push(`entry/${notice.gicNoticeID}`)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
