import React from 'react';
import { PageHeader } from 'src/components/PageHeader';
import { SkipBox, SkipPaper } from 'src/style/components/styledFlex';
import { pipelineOptionsMap } from 'src/components/Charts/components/config/makeTimeSeries';
import { carbonOptionsMap } from 'src/components/Charts/components/config/makeTimeSeries';
import { spotGasOptionsMap } from 'src/components/Charts/components/config/makeTimeSeries';
import { generationNetbackOptionsMap } from 'src/components/Charts/components/config/makeNetback/generationNetback';

import { NexusChart } from 'src/components/Charts/NexusChart';

function GasTradingHome(): JSX.Element {
  return (
    <SkipPaper isGrid p={2} g={2} w={'100%'} h={'100%'} rows={'min-content 1fr 1fr'} cols={'1fr 1fr 1fr'}>
      <SkipBox flex d={'column'} ga={' 1 / 1 / 1 / 4'} w={'100%'}>
        <PageHeader>Gas Trading Landing page</PageHeader>
      </SkipBox>
      <SkipBox ga={'2 / 1 / 2 / 1'}>
        <NexusChart id={'linepack'} settings={[pipelineOptionsMap.linepack]} />
      </SkipBox>
      <SkipBox ga={'2 / 2 / 2 / 2'}>
        <NexusChart id={'pressure'} settings={[pipelineOptionsMap.pressure]} />
      </SkipBox>
      <SkipBox ga={'2 / 3 / 2 / 3'}>
        <NexusChart id={'spot-carbon'} settings={[carbonOptionsMap.spotCarbon]} />
      </SkipBox>
      <SkipBox ga={'3 / 1 / 3 / 1'}>
        <NexusChart id={'spot-gas-traded'} settings={[spotGasOptionsMap.traded]} />
      </SkipBox>
      <SkipBox ga={'3 / 2 / 3 / 2'}>
        <NexusChart id={'spot-gas-delivered'} settings={[spotGasOptionsMap.delivered]} />
      </SkipBox>
      <SkipBox ga={'3 / 3 / 3 / 3'}>
        <NexusChart id={'huntly-spark-spread'} settings={[generationNetbackOptionsMap.huntlyCC]} />
      </SkipBox>
    </SkipPaper>
  );
}

export { GasTradingHome };
