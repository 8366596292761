import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { NotFound } from 'src/router';
import { GasTradingHome } from './GasTradingHome';
import { PaperColumn, EnergyStreamItem, ContentBox, ColumnCell } from 'src/style/components';
import { Pipeline } from './Pipeline';
import { SparkSpread } from './SparkSpread';
import { Carbon } from './Carbon';
import { SpotGas } from './SpotGas';
import { SpotGasCarbon } from './SpotGasCarbon';
import { SpotGasBalancing } from './SpotGasBalancing';
import { SpotGasSwap } from './SpotGasSwap';
import { SpotGasVolumes } from './SpotGasVolumes';

const FlowItems: [string, string][] = [
  ['Pipeline', 'pipeline'],
  ['Spark Spreads', 'spark'],
  ['Spot trades', 'gas/delivered'],
  ['Spot trades ex. carbon', 'gas/carbon'],
  ['Spot Balancing', 'gas/balancing'],
  ['Spot Volumes', 'gas/volumes'],
  ['Spot Swaps', 'gas/swap'],
  ['Carbon', 'carbon'],
  ['Futures', 'futures'],
  ['LNG', 'lng'],
];

function TradingRoutes(): JSX.Element {
  const { url } = useRouteMatch();
  return (
    <ContentBox>
      <PaperColumn style={{ width: 'max-content' }}>
        {FlowItems.map((item, i) => (
          <ColumnCell key={`link-${i}`} style={{ flexGrow: 0 }}>
            <EnergyStreamItem route={item[1]}>{item[0]}</EnergyStreamItem>
          </ColumnCell>
        ))}
      </PaperColumn>
      <Switch>
        <Route path={`${url}`} exact>
          <GasTradingHome />
        </Route>
        <Route path={`${url}/pipeline`} exact>
          <Pipeline />
        </Route>
        <Route path={`${url}/spark`} exact>
          <SparkSpread />
        </Route>
        <Route path={`${url}/gas/carbon`} exact>
          <SpotGasCarbon />
        </Route>
        <Route path={`${url}/gas/swap`} exact>
          <SpotGasSwap />
        </Route>
        <Route path={`${url}/gas/volumes`} exact>
          <SpotGasVolumes />
        </Route>
        <Route path={`${url}/gas/balancing`} exact>
          <SpotGasBalancing />
        </Route>
        <Route path={`${url}/carbon`} exact>
          <Carbon />
        </Route>
        <Route path={`${url}/gas/delivered`} exact>
          <SpotGas />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </ContentBox>
  );
}
export { TradingRoutes };
