import { CircularProgress, Theme, Typography, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { usePanel } from './Context/DashboardContext';
import { DashboardProduct } from './types';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import clsx, { ClassValue } from 'clsx';
import '../../css/flash.css';

export const panelStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      display: 'flex',
      gridColumnEnd: 'span 1',
      gridRowEnd: 'span 1',
    },
    verticalLabel: {
      writingMode: 'vertical-lr',
      justifyContent: 'center',
      justifySelf: 'center',
      fontSize: 'large',
      fontStretch: 'expanded',
      fontWeight: 'bolder',
    },
    root: {
      display: 'grid',
      height: '100%',
      width: '100%',
      padding: theme.spacing(1),
      rowGap: `${theme.spacing(2)}px`,
      gridTemplateColumns: 'min-content 10rem repeat(3, 1fr)',
      gridTemplateRows: 'repeat(4, 1fr)',
    },
    topLabel: {
      fontWeight: 'bold',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#3b3b3a',
      color: theme.palette.common.white,
      fontSize: 14,
    },
    panelGrid: {
      display: 'grid',
    },
    gaugeContainer: {
      display: 'flex',
      padding: theme.spacing(1),
    },
  }),
);

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    ...panelStyles,
    greenFlash: {
      WebkitAnimation: 'green-fade 1s ease-in-out 0s',
      MozAnimation: 'green-fade 1s ease-in-out 0s',
      animation: 'green-fade 1s ease-in-out 0s',
    },
    whiteFlash: {
      WebkitAnimation: 'white-fade 1s ease-in-out 0s',
      MozAnimation: 'white-fade 1s ease-in-out 0s',
      animation: 'white-fade 1s ease-in-out 0s',
    },
    redFlash: {
      WebkitAnimation: 'red-fade 1s ease-in-out 0s',
      MozAnimation: 'red-fade 1s ease-in-out 0s',
      animation: 'red-fade 1s ease-in-out 0s',
    },

    title: {
      color: theme.palette.dashboard.title,
      fontWeight: 'bold',
    },
    subTitle: {
      color: '#000',
      marginTop: 'auto',
    },
    popupWindowCommon: {
      width: 100,
      height: 100,
      backgroundColor: theme.palette.dashboard.subTitle,
      position: 'relative',

      zIndex: 10000,
    },
    popupWindowHidden: {
      display: 'none',
    },
    popupWindowVisible: {
      display: 'flex',
    },
    panelSpan: { fontStretch: 'extra-condensed', fontSize: 'smaller', color: theme.palette.dashboard.label },
    panelCell: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1, 0),
      // height: '100%',
      gridColumnEnd: 'span 1',
      gridRowEnd: 'span 1',
      alignItems: 'center',
      justifyContent: 'center',
    },
    hoverPanel: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#2fabe1',
      },
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
      // flexBasis: '100%',
      width: '-webkit-fill-available',
      // height: '-webkit-fill-available',
      placeContent: 'center',
      paddingTop: theme.spacing(1),
      gap: '8px',
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '-webkit-fill-available',
      justifyContent: 'center',
      alignItems: 'center',
    },
    disabledPanel: {
      color: '#f00',
    },
  }),
);

// const PanelLoader = () => {
//   // circular progress times out after 30 seconds
//   const [timeout, setTimedout] = React.useState(false);

//   React.useEffect(() => {
//     const id = setTimeout(() => setTimedout(true), 30000);
//     return () => {
//       clearTimeout(id);
//     };
//   }, []);

//   // return circular progress until timeout
//   return !timeout ? <CircularProgress color={'primary'} size={10} /> : <SyncDisabledIcon />;
// };
export const DashPanel = ({ location, product }: { location: [number, number, number]; product: DashboardProduct }): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const [popup, setPopup] = React.useState<HTMLDivElement | false>(false);

  const panelData = usePanel(product, location);
  const key = React.useRef(Math.random());
  const memoPanel = React.useMemo(() => {
    let highlightStyle: React.CSSProperties = {
      color: 'inherit',
    };

    let flashClass: ClassValue = classes.whiteFlash;
    if (panelData.change && panelData.change > 0) {
      flashClass = classes.greenFlash;
      highlightStyle = { color: theme.palette.dashboard.instrumentUp.color };
      key.current = Math.random();
    } else if (panelData.change && panelData.change < 0) {
      flashClass = classes.redFlash;
      highlightStyle = { color: theme.palette.dashboard.instrumentDown.color };
      key.current = Math.random();
    } else {
      flashClass = classes.whiteFlash;
      highlightStyle = { color: theme.palette.dashboard.instrumentFlat.color };
      key.current = Math.random();
    }

    const panelClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      !popup ? setPopup(e.currentTarget) : setPopup(false);
    };
    return (
      <div key={key.current} className={clsx(classes.panelCell, flashClass, { [classes.hoverPanel]: typeof panelData.lastValue === 'number' })} style={panelData.panelStyle} data-instrument={panelData.code} onClick={panelClick}>
        {panelData.lastValue === undefined ? (
          panelData.code === 'NULL' ? (
            <SyncDisabledIcon htmlColor={theme.palette.dashboard.panelPlaceholder} className={classes.diabledPanel} />
          ) : (
            <CircularProgress color={'primary'} size={10} />
          )
        ) : (
          <>
            <div className={classes.flexRow}>
              <Typography className={classes.title} variant={'subtitle1'} component={'div'} style={{ lineHeight: 'unset' }}>
                <span>{panelData.meta?.prefix}</span>
                <span style={highlightStyle}>{panelData.code === 'N/A' ? 'N/A' : panelData.lastValue}</span>
              </Typography>

              <Typography className={classes.subTitle} variant={'subtitle2'} component={'div'} style={{ lineHeight: 'unset' }}>
                <span>
                  {panelData.meta?.suffix}
                  {panelData.meta?.unit}
                </span>
              </Typography>
            </div>
            <Typography variant={'body2'} component={'div'} style={{ lineHeight: 'unset' }}>
              <span style={highlightStyle}>
                {!panelData.change || panelData.change < Number.EPSILON ? '' : '+'}
                {typeof panelData.change === 'number' ? panelData.change : 'N/A'}
              </span>
            </Typography>
            <Typography component={'div'} variant='body2' className={classes.panelSpan} style={{ marginBottom: 'auto', marginTop: 4, alignItems: 'flex-start', textAlign: 'center', padding: theme.spacing(0, 1) }}>
              {panelData.meta?.description}
            </Typography>
            <Typography variant='body2' component={'div'} className={classes.panelSpan} style={{ textAlign: 'center' }}>
              {panelData.lastTime}
            </Typography>
          </>
        )}
      </div>
    );
  }, [panelData.lastValue, panelData.lastTime]);
  return (
    <>
      <div
        id={'popup-window'}
        className={clsx(classes.popupWindowCommon, {
          [classes.popupWindowHidden]: !popup,
          [classes.popupWindowVisible]: Boolean(popup),
        })}
      >
        test
      </div>
      {memoPanel}
    </>
  );
};
