import { MakeCoreTimeSeriesSP, MakeSeriesChartOptions } from 'src/types/module/chartModule.types';
import { createSeriesMaps } from '../common';
import { genericOatisHourly } from './common';
import { mergeTimeSeries } from './util';

// const defaultRange: Pick<TimeSeriesChartOptions<MakeTimeSeriesSP>, '_makeInterface' | 'range'> = {
//   _makeInterface: CoreTimeSeriesDiscriminatorsEnum.TimeSeries,
//   range: [moment().subtract(1, 'month').startOf('day').format(rangeFormat.default), moment().endOf('day').format(rangeFormat.default)],
// };

const faullRoad: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Methanex',
  seriesNames: ['Faull Road'],
  seriesFilters: [['meterID', [158]]],
  ...genericOatisHourly('Methanex'),
};
const ngatimaruRoad: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Methanex',
  seriesNames: ['Ngatimaru Road'],
  seriesFilters: [['meterID', [190]]],
  ...genericOatisHourly('Methanex'),
};
const bertrandRoad: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Methanex',
  seriesNames: ['Bertrand Road'],
  seriesFilters: [['meterID', [465]]],
  ...genericOatisHourly('Methanex'),
};

const methanexHourly = mergeTimeSeries('Methanex', faullRoad, ngatimaruRoad, bertrandRoad);

const ballance: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Ballance',
  seriesNames: ['Ballance'],
  seriesFilters: [['meterID', [440, 438, 439]]],
  ...genericOatisHourly('Ballance'),
};

const petrochemicalHourly = mergeTimeSeries('Petrochemical', methanexHourly, ballance);

const huntly: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Huntly Power Station',
  seriesNames: ['Huntly Power Station'],
  seriesFilters: [['meterID', [148]]],
  ...genericOatisHourly('Huntly Power Station'),
};

const tcc: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'TCC',
  seriesNames: ['TCC'],
  seriesFilters: [['meterID', [334]]],
  ...genericOatisHourly('Taranaki Combined Cycle'),
};

// TODO needs to use net flow for stratford 3 (has D & R)
const stratford: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Stratford Peakers',
  seriesNames: ['Stratford Peakers'],
  seriesFilters: [['meterID', [310, 458, 463, 464]]],
  ...genericOatisHourly('Stratford Peakers'),
};

const junctionRoad: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Junction Road Peakers',
  seriesNames: ['Junction Road Peakers'],
  seriesFilters: [['meterID', [484]]],
  ...genericOatisHourly('Junction Road Peakers'),
};

const generationHourly = mergeTimeSeries('Generation', huntly, tcc, stratford, junctionRoad);

const nzSteel: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'NZ Steel',
  seriesNames: ['NZ Steel'],
  seriesFilters: [['meterID', [232]]],
  ...genericOatisHourly('NZ Steel'),
};

const ojiFS: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Oji',
  seriesNames: ['Oji'],
  seriesFilters: [['meterID', [281]]],
  ...genericOatisHourly('OJI Fibre Solutions'),
};

const industrialHourly = mergeTimeSeries('Industrial', nzSteel, ojiFS);

export const [gasMajorUserHourlyOptionsMap, gasMajorUserHourlyMakeMap, gasMajorUserHourlyOptionsArrayMap] = createSeriesMaps({
  methanexHourly,
  ballance,
  huntly,
  tcc,
  stratford,
  junctionRoad,
  nzSteel,

  ojiFS,
  faullRoad,
  ngatimaruRoad,
  bertrandRoad,
});

export const [gasSectorDemandHourlyOptionsMap, gasSectorDemandHourlyMakeMap, gasSectorDemandHourlyOptionsArrayMap] = createSeriesMaps({
  petrochemicalHourly,
  generationHourly,
  industrialHourly,
});

// export const gasMajorUserMakeMap = createMakeMap(gasMajorUserOptionsMap);
// export const gasMajorUserOptionsArrayMap = createOptionsArrayMap(gasMajorUserOptionsMap);
