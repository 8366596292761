import { MakeSeriesTypes, MakeTimeSeriesTypes, CoreTimeSeries, MakeMiscSeriesTypes, MakeNetbackTimeSeriesSP, MakeCoreTimeSeriesSP } from 'src/types/module/chartModule.types';

export const isTimeSeries = (obj: MakeSeriesTypes): obj is MakeTimeSeriesTypes => {
  return typeof obj === 'object' && obj !== undefined && obj._makeInterface in CoreTimeSeries;
};

export const isMiscSeries = (obj: MakeSeriesTypes): obj is MakeMiscSeriesTypes => !isTimeSeries(obj);

export const isNetbackRequest = (obj: MakeSeriesTypes): obj is MakeNetbackTimeSeriesSP => obj._makeInterface === CoreTimeSeries.Netback;
export const isTimeSeriesRequest = (obj: MakeSeriesTypes): obj is MakeCoreTimeSeriesSP => obj._makeInterface === CoreTimeSeries.TimeSeries;
