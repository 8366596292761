/* eslint-disable @typescript-eslint/no-unused-vars */
import { toProperNoun } from '@skipnz/juno';
import moment from 'moment';
import React from 'react';
import { NexusChart } from 'src/components/Charts/NexusChart';
import { ChartControlWrapper } from 'src/lab/ChartControlWrapper';
import { useAppSelector } from 'src/redux/hooks';
import { MetadataStore } from 'src/redux/reducer/metadata';
import { ChartFilterSelect } from '../../../components/Charts/components/controls/ChartFilterSelect';
import { ChartRangePicker } from '../../../components/Charts/components/controls/ChartRangePicker';
import { ChartRollupRadio } from '../../../components/Charts/components/controls/ChartRollupRadio';
import { ChartStackingRadio } from '../../../components/Charts/components/controls/ChartStackingRadio';
import { ChartTypeRadio } from '../../../components/Charts/components/controls/ChartTypeRadio';
import { makeGasFlowRequest, makeRandomPointRequest, metadataToTransferList } from '../../../components/Charts/components/controls/lib';
import { GasFlowPage } from './GasFlowPage';
import { gasMajorUserOptionsMap, gasMajorUserMakeMap, gasMajorUserOptionsArrayMap } from '../../../components/Charts/components/config/makeTimeSeries/gasMajorUserDaily';
import { MakeSeriesChartOptions, MakeCoreTimeSeriesSP } from 'src/types/module/chartModule.types';

function GasFlow(): JSX.Element {
  const chartId = 'welded-point';
  const meterStore = useAppSelector(state => state.metadata.oatisGasMeter);

  const sourceList = metadataToTransferList('oatisGasPoint' as keyof MetadataStore, 'name', undefined).filter(item => Boolean(Object.values(meterStore).find(meter => meter.pointID === +item.id)));
  const points = makeGasFlowRequest(meterStore, [gasMajorUserOptionsMap.methanex as MakeSeriesChartOptions<MakeCoreTimeSeriesSP>]);
  return (
    <GasFlowPage
      controlsComponent={
        <>
          <ChartControlWrapper title={'Chart Type'} left>
            <ChartTypeRadio id={chartId} initialValue={'column'} />
          </ChartControlWrapper>
          <ChartControlWrapper title={'Chart Stacking'} left>
            <ChartStackingRadio id={chartId} initialValue={'normal'} />
          </ChartControlWrapper>
          <ChartControlWrapper title={'Chart Range'} column>
            <ChartRangePicker id={chartId} format={'date'} initialValue={[moment(gasMajorUserOptionsMap.methanex.range[0]), moment(gasMajorUserOptionsMap.methanex.range[1])]} />
          </ChartControlWrapper>
          <ChartControlWrapper title={'Data Rollup'} column>
            <ChartRollupRadio id={chartId} />
          </ChartControlWrapper>
          <ChartFilterSelect id={chartId} items={sourceList} initialValue={points} title={`Welded points`} />
        </>
      }
    >
      <NexusChart id={chartId} settings={[gasMajorUserOptionsMap.methanex]} />
    </GasFlowPage>
  );
}

export { GasFlow };
