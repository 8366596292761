import { mergeDeep } from 'src/lib/util';
import { GenerationFuel } from 'src/types/module/datacore.types';
import { CoreTimeSeries, MakeNetbackTimeSeriesSP, MakeSeriesChartOptions, MakeSeriesOptionsMap } from 'src/types/module/chartModule.types';
import { lineChart } from '..';
import { createMakeMap, createOptionsArrayMap } from '../../lib/util';
import { defaultRange } from '../common';

const generationNetbackBase: Pick<MakeSeriesChartOptions<MakeNetbackTimeSeriesSP>, '_makeInterface' | 'range'> & Required<Pick<MakeSeriesChartOptions<MakeNetbackTimeSeriesSP>, 'options'>> = {
  _makeInterface: CoreTimeSeries.Netback,
  ...defaultRange,
  options: {
    plotOptions: {
      series: {
        label: {
          enabled: false,
        },
      },
    },
  },
};

// const netbackChart: Partial<TimeSeriesOptions<MakeNetbackSeriesSP>> = {
//   options: mergeDeep<Highcharts.Options>({}, lineChart, {
//     legend: { enabled: false },
//     chart: {
//       panning: {
//         enabled: true,
//         type: 'xy',
//       },
//       panKey: 'ctrl',
//       zoomType: 'x',
//     },
//     plotOptions: {
//       line: {
//         showCheckbox: true,
//       },
//     },
//   }),
// };

const huntlyCC: MakeSeriesChartOptions<MakeNetbackTimeSeriesSP> = {
  ...generationNetbackBase,
  fuelType: GenerationFuel.gas,
  referenceNode: 'HLY',
  heatRate: 7.4,
  name: 'Huntly - e3p',
  options: mergeDeep<Highcharts.Options>({}, lineChart, generationNetbackBase.options, {
    title: {
      text: 'Huntly - e3p',
    },
  }),
};

const huntlyUnitSix: MakeSeriesChartOptions<MakeNetbackTimeSeriesSP> = {
  ...generationNetbackBase,
  fuelType: GenerationFuel.gas,
  referenceNode: 'HLY',
  heatRate: 10.7,
  name: 'Huntly - Unit 6',
  options: mergeDeep<Highcharts.Options>({}, lineChart, generationNetbackBase.options, {
    title: {
      text: 'Huntly - Unit 6',
    },
  }),
};

const huntlyRankineGas: MakeSeriesChartOptions<MakeNetbackTimeSeriesSP> = {
  ...generationNetbackBase,
  fuelType: GenerationFuel.gas,
  referenceNode: 'HLY',
  heatRate: 10.9,
  name: 'Huntly - Rankines',
  options: mergeDeep<Highcharts.Options>({}, lineChart, generationNetbackBase.options, {
    title: {
      text: 'Huntly - Units 1 -4 (gas)',
    },
  }),
};

const huntlyRankineCoal: MakeSeriesChartOptions<MakeNetbackTimeSeriesSP> = {
  ...generationNetbackBase,
  fuelType: GenerationFuel.coal,
  referenceNode: 'HLY',
  heatRate: 10.9,
  name: 'Huntly - Rankines',
  options: mergeDeep<Highcharts.Options>({}, lineChart, generationNetbackBase.options, {
    title: {
      text: 'Huntly - Units 1 -4 (coal)',
    },
  }),
};

const taranakiCC: MakeSeriesChartOptions<MakeNetbackTimeSeriesSP> = {
  ...generationNetbackBase,
  fuelType: GenerationFuel.gas,
  referenceNode: 'SFD',
  heatRate: 7.4,
  name: 'TCC',
  options: mergeDeep<Highcharts.Options>({}, lineChart, generationNetbackBase.options, {
    title: {
      text: 'Stratford - TCC',
    },
  }),
};

const stratfordPeakers: MakeSeriesChartOptions<MakeNetbackTimeSeriesSP> = {
  ...generationNetbackBase,
  fuelType: GenerationFuel.gas,
  referenceNode: 'SFD',
  heatRate: 9.5,
  name: 'Stratford Peakers',
  options: mergeDeep<Highcharts.Options>({}, lineChart, generationNetbackBase.options, {
    title: {
      text: 'Stratford - Peakers',
    },
  }),
};

const whirinakiPeaker: MakeSeriesChartOptions<MakeNetbackTimeSeriesSP> = {
  ...generationNetbackBase,
  fuelType: GenerationFuel.diesel,
  referenceNode: 'WHI',
  heatRate: 10.9,
  name: 'Whirinaki',
  options: mergeDeep<Highcharts.Options>({}, lineChart, generationNetbackBase.options, {
    title: {
      text: 'Whirinaki Peaker',
    },
  }),
};

const breamBayPeaker: MakeSeriesChartOptions<MakeNetbackTimeSeriesSP> = {
  ...generationNetbackBase,
  fuelType: GenerationFuel.diesel,
  referenceNode: 'BRB',
  heatRate: 10.9,
  name: 'Bream Bay',

  options: mergeDeep<Highcharts.Options>({}, lineChart, generationNetbackBase.options, {
    title: {
      text: 'Bream Bay Peaker',
    },
  }),
};

const mckee: MakeSeriesChartOptions<MakeNetbackTimeSeriesSP> = {
  ...generationNetbackBase,
  fuelType: GenerationFuel.gas,
  referenceNode: 'MKE',
  heatRate: 10,
  name: 'McKee Peaker',

  options: mergeDeep<Highcharts.Options>({}, lineChart, generationNetbackBase.options, {
    title: {
      text: 'McKee Peaker',
    },
  }),
};

const junctionRoad: MakeSeriesChartOptions<MakeNetbackTimeSeriesSP> = {
  ...generationNetbackBase,
  fuelType: GenerationFuel.gas,
  referenceNode: 'JRD',
  heatRate: 10,
  name: 'Junction Road',
  options: mergeDeep<Highcharts.Options>({}, lineChart, generationNetbackBase.options, {
    title: {
      text: 'Junction Road Peaker',
    },
  }),
};

export const generationNetbackOptionsMap: MakeSeriesOptionsMap<MakeNetbackTimeSeriesSP> = {
  huntlyCC,
  huntlyRankineCoal,
  huntlyRankineGas,
  huntlyUnitSix,
  taranakiCC,
  stratfordPeakers,
  mckee,
  junctionRoad,
  breamBayPeaker,
  whirinakiPeaker,
};

export const generationNetbackMakeMap = createMakeMap(generationNetbackOptionsMap);
export const generationNetbackOptionsArrayMap = createOptionsArrayMap(generationNetbackOptionsMap);
