import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { NotFound } from 'src/router';
import { GasCompaniesHome } from './GasCompaniesHome';
import { OMV } from './OMV';

export const CompaniesRoutes = (): JSX.Element => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${url}`} exact>
        <GasCompaniesHome />
      </Route>

      <Route path={`${url}/omv`} exact>
        <OMV />
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
};
