import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { NotFound } from 'src/router';
import { GasAssetsHome } from './GasAssetsHome';
import { Pohokura } from './Pohokura';

export const AssetRoutes = (): JSX.Element => {
  const { url } = useRouteMatch();
  console.log(url);
  return (
    <Switch>
      <Route path={`${url}`} exact>
        <GasAssetsHome />
      </Route>

      <Route path={`${url}/pohokura`} exact>
        <Pohokura />
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
};
