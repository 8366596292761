import { mergeDeep } from 'src/lib/util';
import { MakeFieldTimeSeries, MakeSeriesChartOptions, MiscSeries } from 'src/types/module/chartModule.types';
import { columnChart } from '..';
import { makeRange } from '../common';

export const fieldDefault: Omit<MakeSeriesChartOptions<MakeFieldTimeSeries>, 'groupingColumn' | 'dataType' | 'metric'> = {
  ...makeRange('previous', 10, 'year', 'year'),
  _makeInterface: MiscSeries.Field,
  name: '',
  xType: 'unix_ms',

  options: mergeDeep<Highcharts.Options>({}, columnChart, {
    xAxis: {
      categories: false,
      type: 'datetime',
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
      column: {
        pointPadding: 0.05,
        groupPadding: 0,
      },
    },
  }),
};
