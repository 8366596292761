import React from 'react';
import { Moment } from 'moment';
import moment from 'moment';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useChart } from 'src/components/Charts/components/Context';
import { rangeFormat, RangeFormat, rangeFormatPicker, rangeFormatViews } from 'src/components/Charts/components/config/common';

export type SelectionDateRange = [Moment, Moment];

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(0.5),
      marginTop: theme.spacing(2),
      width: '-webkit-fill-available',
      textAlign: 'center',
    },
  }),
);

export function ChartRangePicker({ id, initialValue, format = 'default' }: { id: string; initialValue?: SelectionDateRange; format?: RangeFormat }): JSX.Element {
  const [start, setStartDate] = React.useState<Moment>(initialValue?.[0] || moment());
  const [end, setEndDate] = React.useState<Moment>(initialValue?.[1] || moment());
  const [chart, , fetchNewData] = useChart(id);

  const classes = useStyles();

  const getRange = (which: 'start' | 'end', date: Moment) => {
    return which === 'start' ? ([date.format(rangeFormat[format]), end.format(rangeFormat[format])] as [string, string]) : ([start.format(rangeFormat[format]), date.format(rangeFormat[format])] as [string, string]);
  };

  const handleChange = (which: 'start' | 'end') => (date: MaterialUiPickersDate) => {
    if (!date) return;
    const range = getRange(which, date);
    const chartInstance = chart();
    chartInstance.nexus.currentConfig.range = [...range];
    const request = chartInstance.nexus.currentConfig.request.map(request => ({ ...request, range: [...range] as [string, string] }));
    fetchNewData({ range, options: chartInstance.nexus.currentConfig.options, request });
    // chartInstance.redraw();
    which === 'start' ? setStartDate(date) : setEndDate(date);
  };

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'en'}>
      <DatePicker className={classes.formControl} autoOk inputVariant={'outlined'} variant={'inline'} format={rangeFormatPicker[format]} views={rangeFormatViews[format]} label='Start' value={start} onChange={handleChange('start')} />
      <DatePicker className={classes.formControl} autoOk inputVariant={'outlined'} variant={'inline'} format={rangeFormatPicker[format]} views={rangeFormatViews[format]} label='End' value={end} onChange={handleChange('end')} />
    </MuiPickersUtilsProvider>
  );
}
