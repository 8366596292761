import React from 'react';
import { EnergyStreamRow, EnergyStreamContainer, EnergyStreamLabel, EnergyStreamItem } from 'src/style/components';

const Assets: [string, string, number, number][][] = [
  [
    ['Pohokura', 'pohokura', 1, 1],
    ['Maui', 'maui', 1, 1],
    ['McKee & Mangahewa', 'mm', 1, 1],
    ['Kupe', 'kupe', 1, 1],
    ['Kapuni', 'kapuni', 1, 1],
    ['Turangi', 'turangi', 1, 1],
  ],
  [
    ['MAUI Pipeline', 'maui', 2, 1],
    ['Ahuroa', 'ags', 2, 1],
  ],
  [
    ['TCC', 'tcc', 3, 1],
    ['Junction Road', 'jrd', 3, 1],
    ['Huntly', 'huntly', 3, 1],
  ],
];

function GasAssetsHome(): JSX.Element {
  return (
    <EnergyStreamContainer style={{ flexDirection: 'column' }}>
      <EnergyStreamRow colour={'white'} style={{ gridArea: '1 / 1 / auto / span 12' }} />
      <EnergyStreamRow colour={'grey'} style={{ gridArea: '2 / 1 / auto / span 12' }} />
      <EnergyStreamRow colour={'white'} style={{ gridArea: '3 / 1 / auto / span 12' }} />
      <EnergyStreamLabel style={{ gridArea: '1 / 1 / auto / span 1', alignItems: 'center' }}>Upstream</EnergyStreamLabel>
      <EnergyStreamLabel style={{ gridArea: '2 / 1 / auto / span 1', alignItems: 'center' }}>Midstream</EnergyStreamLabel>
      <EnergyStreamLabel style={{ gridArea: '3 / 1 / auto / span 1', alignItems: 'center' }}>Downstream</EnergyStreamLabel>
      {Assets.map(assetSet =>
        assetSet.map((item: [string, string, number, number], i: number) => (
          <div key={`${item[0]}-${i}`} style={{ gridArea: `${item[2]} / ${i + 2} / span ${item[3]} / span 1`, padding: 16, display: 'flex' }}>
            <EnergyStreamItem route={`${item[1]}`} style={{ width: '100%' }}>
              {item[0]}
            </EnergyStreamItem>
          </div>
        )),
      )}
    </EnergyStreamContainer>
  );
}

export { GasAssetsHome };
