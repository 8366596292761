import { blue, green, red } from '@material-ui/core/colors';
import { mergeDeep } from 'src/lib/util';
import { CoreTimeSeries, MakeCoreTimeSeriesSP, MakeSeriesChartOptions } from 'src/types/module/chartModule.types';
import { lineChart, plotLinesCommon } from '..';
import { createSeriesMaps, makeRangeProperty } from '../common';

const roiPoints = {
  oaonui: 132,
  frankleyRoad: 133,
  tikorangi: 134,
  pokuru: 143,
  pirongia: 144,
  rotowaro: 146,
  huntlyPowerStation: 148,
  faullRoad: 158,
  ngatimaruRdD: 190,
  ngatimaruRdR: 191,
  turangi: 193,
  tikorangi2: 194,
  mokau: 195,
  kowhai: 446,
  bertrandRoad: 465,
  tikorangi3D: 466,
  tikorangi3R: 467,
  mangorei: 484,
};

export const roiGeneric: Pick<MakeSeriesChartOptions<MakeCoreTimeSeriesSP>, 'range' | '_makeInterface' | 'options'> = {
  range: makeRangeProperty('rolling', 7, 'days'),
  _makeInterface: CoreTimeSeries.TimeSeries,
  options: mergeDeep<Highcharts.Options>({}, lineChart, {
    plotOptions: {
      column: {
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    yAxis: {
      crosshair: {
        color: blue[300],
      },
    },
    xAxis: {
      crosshair: {
        color: blue[300],
      },
    },
  }),
};

const makeROI = (name: string, meter: number): MakeSeriesChartOptions<MakeCoreTimeSeriesSP> => ({
  ...roiGeneric,
  dataTable: 'bgixImbalance',
  xAxisColumn: 'd',
  seriesNames: [name],
  seriesColumns: ['roi'],
  seriesFilters: [['meterID', [meter]]],
  options: mergeDeep<Highcharts.Options>({}, roiGeneric.options || {}, {
    title: { text: `Running Operational Imbalance - ${name}` },
    legend: { enabled: false },
    plotOptions: {
      series: {
        label: {
          enabled: false,
        },
      },
    },
  }),

  name,
});

const roi: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...roiGeneric,
  dataTable: 'mauiROI',
  xAxisColumn: 'endHour',
  seriesNames: ['Running Operational Imbalance'],
  seriesColumns: ['roi'],
  options: mergeDeep<Highcharts.Options>({}, roiGeneric.options || {}, {
    title: { text: 'MAUI pipeline ROI' },
    legend: { enabled: false },
    plotOptions: {
      series: {
        label: {
          enabled: false,
        },
      },
    },
  }),

  name: 'ROI',
};

export const [roiOptionsMap, roiMakeMap, roiOptionsArrayMap] = createSeriesMaps({
  roi,
  ...Object.entries(roiPoints).reduce<Record<string, MakeSeriesChartOptions<MakeCoreTimeSeriesSP>>>((prev, [name, meter]) => ({ ...prev, [name]: makeROI(name, meter) }), {}),
});
