import { JSONSchema7 } from 'json-schema';

export const makeChartNetbackSeries: JSONSchema7 = {
  $schema: 'http://json-schema.org/draft-07/schema',
  type: 'object',
  title: 'Generation Netback Series',
  required: ['name', 'fuelType', 'referenceNode', 'range', 'heatRate'],

  properties: {
    name: {
      title: 'Generation unit name',
      type: 'string',
      maxLength: 15,
    },
    fuelType: {
      type: 'number',
      title: 'Fuel Type',
      source: 'redux',
      sourceLink: 'fuelType',
    },
    referenceNode: {
      type: 'string',
      title: 'Electricity node',
      source: 'redux',
      sourceLabel: 'name',
      sourceLink: 'witsNodes',
    },
    heatRate: {
      type: 'number',
      title: 'Heat rate',
      minimum: 0,
    },
    range: {
      title: 'Data range for query',
      type: 'array',
      editorExcluded: true,
      additionalItems: false,
      minLength: 2,
      maxLength: 2,
      items: [
        {
          type: 'string',
          title: 'Range start',
          format: 'YYYY-MM-DD HH:mm:ss',
          customType: 'datetime',
        },
        {
          type: 'string',
          title: 'Range End',
          format: 'YYYY-MM-DD HH:mm:ss',
          customType: 'datetime',
        },
      ],
    },
  },
};
