import { RequestOptions } from 'src/modules/types';
import { MysqlError } from 'mysql';
import moment from 'moment';
// http request

export async function newRequest(url: string, options?: RequestOptions): Promise<Response> {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('credentials', 'include');

  if (options?.extraHeaders !== undefined) options?.extraHeaders.map(header => requestHeaders.set(header[0], header[1]));

  let requestOptions: Record<string, unknown> = {
    method: options?.method || 'GET',
    headers: requestHeaders,
  };

  if (options?.body !== undefined && options?.method !== 'GET') requestOptions = { ...requestOptions, body: JSON.stringify(options.body) };

  const response = await fetch(url, requestOptions);

  return response;
}

export const isMysqlError = (obj: unknown): obj is MysqlError => {
  const asMysqlError = obj as MysqlError;
  const keys = ['code', 'errNo'];
  return typeof obj === 'object' && keys.every((key: string) => asMysqlError[key as keyof MysqlError] !== undefined);
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export function isObject(item: unknown): item is object {
  return typeof item === 'object' && !Array.isArray(item);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const enumToRecord = (source: object): Record<number, string> => {
  return Object.keys(source).reduce<Record<number, string>>((prev, curr) => (!isNaN(Number(curr)) ? { ...prev, [curr]: source[curr as keyof typeof source] } : prev), {});
};

// TODO doesn't merge arrays within the object
/**
 * Deep merge two objects.
 * @typeParam TObject
 * @param target
 * @param ...sources
 */
export function mergeDeep<TObject>(target: TObject, ...sources: TObject[]): TObject {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const getRandomElement = <T = unknown[]>(arr: T[]): T => arr[Math.floor(Math.random() * arr.length)];

// date helper functions
export const makeShortDate = (sqlDate: string): string => moment(sqlDate, 'YYYY-MM-DD').format('D-MMM');

export const makeDateRange = (start: string, end: string): string => {
  const startMoment = moment(start, 'YYYY-MM-DD');
  const endMoment = moment(end, 'YYYY-MM-DD');

  // if not current year
  if (startMoment.year() !== moment().year()) {
    // if different months
    if (startMoment.month() !== endMoment.month()) return `${makeShortDate(start)} - ${endMoment.format('D-MMM, YYYY')}`;

    // more than 1 day
    if (startMoment.date() !== endMoment.date()) return `${startMoment.date()} - ${endMoment.format('D-MMM, YYYY')}`;

    // same month, single day, different year
    return `${startMoment.format('D-MMM, YYYY')}`;
  }

  // this year, but different months
  if (startMoment.month() !== endMoment.month()) return `${makeShortDate(start)} - ${makeShortDate(end)}`;

  // this year, same month, more than 1 day
  if (startMoment.date() !== endMoment.date()) return `${startMoment.date()} - ${makeShortDate(end)}`;

  // this year, single day
  return makeShortDate(start);
};
