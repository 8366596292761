import { APIGetResponse, APIRequest } from 'src/types/pantheon/pantheon.types';
import { useSocketEmit } from './useSocketEmit';

type APIEmitter = <TResponse extends unknown>(query: APIRequest, callback?: ((response: APIGetResponse<TResponse> | undefined) => void) | undefined) => void;

export const useAPI = (): APIEmitter => {
  const [emit] = useSocketEmit('pantheon.api');

  const apiEmit = <TResponse extends unknown>(query: APIRequest, callback?: (response: APIGetResponse<TResponse> | undefined) => void) => emit<APIRequest, APIGetResponse<TResponse>>(query, callback);

  return apiEmit;
};
