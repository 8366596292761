import { mergeDeep } from 'src/lib/util';
import { MakeFieldTimeSeries, MakeSeriesChartOptions } from 'src/types/module/chartModule.types';
import { columnChart } from '..';
import { createSeriesMaps } from '../common';
import { fieldDefault } from './common';
const reservesCommon: Pick<MakeSeriesChartOptions<MakeFieldTimeSeries>, 'reserveType' | 'dataType' | 'metric'> = {
  reserveType: 4,
  dataType: 'reserves',
  metric: 3,
};

const reservesBySetting: MakeSeriesChartOptions<MakeFieldTimeSeries> = {
  ...fieldDefault,
  ...reservesCommon,
  groupingColumn: 'fieldType',
  options: mergeDeep<Highcharts.Options>({}, columnChart, fieldDefault.options as Highcharts.Options, {
    title: {
      text: 'Field reserves, by setting',
    },
  }),
};

const reservesByCompany: MakeSeriesChartOptions<MakeFieldTimeSeries> = {
  ...fieldDefault,
  ...reservesCommon,
  groupingColumn: 'ownerName',
  options: mergeDeep<Highcharts.Options>({}, columnChart, fieldDefault.options as Highcharts.Options, {
    title: {
      text: 'Field reserves, by company',
    },
  }),
};

const reservesByField: MakeSeriesChartOptions<MakeFieldTimeSeries> = {
  ...fieldDefault,
  ...reservesCommon,
  groupingColumn: 'field',
  options: mergeDeep<Highcharts.Options>({}, columnChart, fieldDefault.options as Highcharts.Options, {
    title: {
      text: 'Field reserves, by field',
    },
  }),
};

export const [reservesOptionsMap, reservesMakeMap, reservesOptionsArrayMap] = createSeriesMaps({
  setting: reservesBySetting,
  company: reservesByCompany,
  field: reservesByField,
});
