import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import React, { PropsWithChildren } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { NotFound } from 'src/router';
import { StyledGasSectionButton } from 'src/style/components';
import { Overview } from '.';
import { AssetRoutes } from './assets';
import { CompaniesRoutes } from './companies';
import { GasFlowRoutes } from './flow/FlowRoutes';
import { TradingRoutes } from './trading/TradingRoutes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      gap: `${theme.spacing(2)}px`,
      gridColumn: 'span 1',
      gridRow: 'span 1',
    },

    container: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '3rem 1fr',
      width: '-webkit-fill-available',
      padding: '16px',
      gap: '16px',
    },

    headerRow: {
      padding: theme.spacing(1, 2, 0, 1),
      justifyContent: 'space-around',
      gap: '16px',
      display: 'flex',
      gridColumn: 'span 1',
      gridRow: 'span 1',
    },
  }),
);

const GasSectionButton = ({ route, children }: PropsWithChildren<{ route: string }>) => {
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  const history = useHistory();

  const splitLocation = pathname.split('/').filter(item => item.length > 0);
  const isPage = splitLocation?.[1] === route;
  const handleClick = () => history.push(`${path}/${route}`);

  return (
    <StyledGasSectionButton selected={isPage} onClick={handleClick}>
      {children}
    </StyledGasSectionButton>
  );
};

export const GasRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.headerRow}>
        <GasSectionButton route={'overview'}>Overview</GasSectionButton>
        <GasSectionButton route={'flow'}>Gas flow data</GasSectionButton>
        <GasSectionButton route={'companies'}>Companies</GasSectionButton>
        <GasSectionButton route={'assets'}>Assets</GasSectionButton>
        <GasSectionButton route={'trading'}>Gas Prices and trading</GasSectionButton>
      </div>

      <div className={classes.content}>
        <Switch>
          <Route path={`${path}/overview`} exact>
            <Overview />
          </Route>

          <Route path={`${path}/assets`}>
            <AssetRoutes />
          </Route>

          <Route path={`${path}/flow`}>
            <GasFlowRoutes />
          </Route>
          <Route path={`${path}/spot`}>
            <GasFlowRoutes />
          </Route>
          <Route path={`${path}/companies`}>
            <CompaniesRoutes />
          </Route>

          <Route path={`${path}/trading`}>
            <TradingRoutes />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};
