import { Controller } from 'react-hook-form';
import React from 'react';
import { withStyles, TextField, TextFieldProps } from '@material-ui/core';
import { formStyles, WithFormStyles } from './common';

export interface StyledTextFieldProps extends WithFormStyles {
  name: string;
}

export const StyledTextfield = withStyles(formStyles)((props: StyledTextFieldProps & TextFieldProps) => {
  const { classes, name, control, ControllerProps, InputLabelProps, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      {...ControllerProps}
      render={({ field }) => (
        <TextField
          variant={'outlined'}
          size={'small'}
          InputLabelProps={{
            shrink: true,
            ...InputLabelProps,
          }}
          {...field}
          className={classes.root}
          {...rest}
        />
      )}
    />
  );
});
