import React from 'react';
import { useParams } from 'react-router-dom';
import { gasFieldOptionsMap, pipelineOptionsMap, gasMajorUserOptionsMap, roiOptionsMap, gasMiscOptionsMap, electricityOptionsMap } from 'src/components/Charts/components/config/makeTimeSeries';
import { gasFieldHourlyOptionsMap } from 'src/components/Charts/components/config/makeTimeSeries/gasFieldHourly';
import { gasMajorUserHourlyOptionsMap } from 'src/components/Charts/components/config/makeTimeSeries/gasMajorUserHourly';
import { NexusChart } from 'src/components/Charts/NexusChart';
import { PageHeader } from 'src/components/PageHeader';

import { StyledContainer } from 'src/components/styled';
import { SkipBox, SkipPaper } from 'src/style/components/styledFlex';
import { ChartProps, MakeSeriesTypes } from 'src/types/module/chartModule.types';

type DashboardChart = Pick<Required<ChartProps<MakeSeriesTypes>>, 'id'> & Omit<ChartProps<MakeSeriesTypes>, 'id'>;
type DashboardComponent = {
  title: string;
  chart: DashboardChart[];
};

const dashboards: Record<string, Record<string, DashboardComponent>> = {
  gasPipeline: {
    pipeline: {
      title: 'Pipeline conditions',
      chart: [
        { id: 'pressure', settings: [pipelineOptionsMap.pressure] },
        { id: 'linepack', settings: [pipelineOptionsMap.linepack] },
        { id: 'position', settings: [pipelineOptionsMap.netPosition] },
        { id: 'roi', settings: [pipelineOptionsMap.roi] },
      ],
    },
    storage: {
      title: 'Ahruoa Gas Storage',
      chart: [{ id: 'ags', settings: [gasMiscOptionsMap.ags] }],
    },
    roi: {
      title: 'ROI',
      chart: Object.entries(roiOptionsMap).map(([id, settings]) => ({ id, settings: [settings] })),
    },
  },
  gasDaily: {
    production: {
      title: 'Major gas production',
      chart: [
        { id: 'mm', settings: [gasFieldOptionsMap.mm] },
        { id: 'kupe', settings: [gasFieldOptionsMap.kupe] },
        { id: 'maui', settings: [gasFieldOptionsMap.maui] },
        { id: 'pohokura', settings: [gasFieldOptionsMap.pohokura] },
        { id: 'turangi', settings: [gasFieldOptionsMap.turangi] },
        { id: 'kowhai', settings: [gasFieldOptionsMap.kowhai] },
      ],
    },
    minorProduction: {
      title: 'Other gas production',
      chart: [
        { id: 'sidewinder', settings: [gasFieldOptionsMap.sidewinder] },
        { id: 'cheal', settings: [gasFieldOptionsMap.cheal] },
        { id: 'matahio', settings: [gasFieldOptionsMap.chealDailyTotal] },
        { id: 'kaimiro', settings: [gasFieldOptionsMap.kaimiro] },
      ],
    },
    storage: {
      title: 'Ahruoa Gas Storage',
      chart: [{ id: 'ags', settings: [gasMiscOptionsMap.ags] }],
    },
    generation: {
      title: 'Gas generation',
      chart: [
        { id: 'huntly', settings: [gasMajorUserOptionsMap.huntly] },
        { id: 'tcc', settings: [gasMajorUserOptionsMap.tcc] },
        { id: 'stratford', settings: [gasMajorUserOptionsMap.stratford] },
        { id: 'jr', settings: [gasMajorUserOptionsMap.junctionRoad] },
      ],
    },
    pipeline: {
      title: 'Pipeline conditions',
      chart: [
        { id: 'pressure', settings: [pipelineOptionsMap.pressure] },
        { id: 'linepack', settings: [pipelineOptionsMap.linepack] },
        { id: 'position', settings: [pipelineOptionsMap.netPosition] },
        { id: 'roi', settings: [pipelineOptionsMap.roi] },
      ],
    },
    methanex: {
      title: 'Methanex consumption',
      chart: [
        { id: 'split', settings: [gasMajorUserOptionsMap.faullRoad, gasMajorUserOptionsMap.ngatimaruRoad, gasMajorUserOptionsMap.bertrandRoad] },
        { id: 'all', settings: [gasMajorUserOptionsMap.methanex] },
      ],
    },
    majors: {
      title: 'Major gas users',
      chart: [
        { id: 'all', settings: [gasMajorUserOptionsMap.ballance, gasMajorUserOptionsMap.methanex, gasMajorUserOptionsMap.nzSteel, gasMajorUserOptionsMap.refiningNZ, gasMajorUserOptionsMap.ojiFS] },
        { id: 'production', settings: [gasFieldOptionsMap.majorProduction] },
        { id: 'ballance', settings: [gasMajorUserOptionsMap.ballance] },
        { id: 'methanex', settings: [gasMajorUserOptionsMap.methanex] },
        { id: 'nzs', settings: [gasMajorUserOptionsMap.nzSteel] },
        { id: 'oji', settings: [gasMajorUserOptionsMap.ojiFS] },
      ],
    },
    roi: {
      title: 'ROI',
      chart: Object.entries(roiOptionsMap).map(([id, settings]) => ({ id, settings: [settings] })),
    },
  },
  gasHourly: {
    production: {
      title: 'Major gas production',
      chart: [
        { id: 'mm', settings: [gasFieldHourlyOptionsMap.mm] },
        { id: 'kupe', settings: [gasFieldHourlyOptionsMap.kupe] },
        { id: 'maui', settings: [gasFieldHourlyOptionsMap.maui] },
        { id: 'pohokura', settings: [gasFieldHourlyOptionsMap.pohokura] },
        { id: 'turangi', settings: [gasFieldHourlyOptionsMap.turangi] },
        { id: 'kowhai', settings: [gasFieldHourlyOptionsMap.kowhai] },
      ],
    },
    minorProduction: {
      title: 'Other gas production',
      chart: [
        { id: 'sidewinder', settings: [gasFieldHourlyOptionsMap.sidewinder] },
        { id: 'cheal', settings: [gasFieldHourlyOptionsMap.cheal] },
        { id: 'matahio', settings: [gasFieldOptionsMap.chealHourlyTotal] },
        { id: 'kaimiro', settings: [gasFieldHourlyOptionsMap.kaimiro] },
      ],
    },

    generation: {
      title: 'Gas generation',
      chart: [
        { id: 'huntly', settings: [gasMajorUserHourlyOptionsMap.huntly] },
        { id: 'tcc', settings: [gasMajorUserHourlyOptionsMap.tcc] },
        { id: 'stratford', settings: [gasMajorUserHourlyOptionsMap.stratford] },
        { id: 'jr', settings: [gasMajorUserHourlyOptionsMap.junctionRoad] },
      ],
    },

    methanex: {
      title: 'Methanex consumption',
      chart: [
        { id: 'split', settings: [gasMajorUserHourlyOptionsMap.faullRoad, gasMajorUserHourlyOptionsMap.ngatimaruRoad, gasMajorUserHourlyOptionsMap.bertrandRoad] },
        { id: 'all', settings: [gasMajorUserHourlyOptionsMap.methanex] },
      ],
    },
    majors: {
      title: 'Major gas users',
      chart: [
        { id: 'all', settings: [gasMajorUserHourlyOptionsMap.ballance, gasMajorUserHourlyOptionsMap.methanex, gasMajorUserHourlyOptionsMap.nzSteel, gasMajorUserHourlyOptionsMap.ojiFS] },
        { id: 'production', settings: [gasFieldHourlyOptionsMap.majorProduction] },
        { id: 'ballance', settings: [gasMajorUserHourlyOptionsMap.ballance] },
        { id: 'methanex', settings: [gasMajorUserHourlyOptionsMap.methanex] },
        { id: 'nzs', settings: [gasMajorUserHourlyOptionsMap.nzSteel] },
        { id: 'oji', settings: [gasMajorUserHourlyOptionsMap.ojiFS] },
      ],
    },
  },
  electricity: {
    genesis: {
      title: 'Genesis Thermal',
      chart: [
        { id: 'rankine1', settings: [electricityOptionsMap.rankineOne] },
        { id: 'rankine2', settings: [electricityOptionsMap.rankineTwo] },
        { id: 'rankine3', settings: [electricityOptionsMap.rankineThree] },
        { id: 'e3p', settings: [electricityOptionsMap.e3p] },
      ],
    },
    contact: {
      title: 'Contact Thermal',
      chart: [
        { id: 'stratford1', settings: [electricityOptionsMap.stratfordOne] },
        { id: 'stratford2', settings: [electricityOptionsMap.stratfordTwo] },
        { id: 'tcc', settings: [electricityOptionsMap.TCC] },
      ],
    },
    overview: {
      title: 'All Thermal',
      chart: [
        { id: 'genesis', settings: [electricityOptionsMap.genesisEnergy] },
        { id: 'contact', settings: [electricityOptionsMap.contactEnergy] },
        { id: 'thermal', settings: [electricityOptionsMap.thermal] },
      ],
    },
    prices: {
      title: 'Electricity Demand',
      chart: [{ id: 'mm', settings: [electricityOptionsMap.cheal] }],
    },
  },
};

function Dashboard(): JSX.Element {
  const { section, dash } = useParams<{ section: string; dash: string }>();

  const dashboard = dashboards[section]?.[dash];

  if (!dashboard)
    return (
      <StyledContainer columns={'1fr'} rows={'1fr'}>
        Could not find dashboard for {section} {dash}
      </StyledContainer>
    );

  const { title, chart } = dashboard;
  return (
    <SkipPaper w={'100%'} p={2} style={{ display: 'flex', flexDirection: 'column' }}>
      <SkipBox flex w={'100%'}>
        <PageHeader>{title}</PageHeader>
      </SkipBox>
      <SkipBox isGrid w={'100%'} h={'100%'} g={2} style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill,minmax(25%, 1fr))', gridTemplateRows: 'repeat(auto-fill,minmax(48%, 1fr))', overflow: 'auto' }}>
        {chart.map(component => (
          <div key={component.id}>
            <NexusChart {...component} />
          </div>
        ))}
      </SkipBox>
    </SkipPaper>
  );
}

export { Dashboard };
// flex: 0 0 33.3333%;
