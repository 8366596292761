import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Calendar as BigCalendar, momentLocalizer, Event } from 'react-big-calendar';
import { Section } from 'src/components/Wrapper';
import { GICPage } from '../common/PageWrapper';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import moment from 'moment';
import { useAPI } from 'src/modules/websocket/hooks';
import { BlockObjectTree } from 'src/types/notice.sql.types';
import { APIGetResponse } from 'src/types/pantheon.types';
const localizer = momentLocalizer(moment);

const useStyles = makeStyles(() =>
  createStyles({
    section: {
      width: '-webkit-fill-available',
      height: '-webkit-fill-available',
    },
  }),
);

export function Calendar(): JSX.Element {
  const classes = useStyles();
  const emit = useAPI();

  const [myEventsList, setEvents] = React.useState<Event[]>([]);

  React.useEffect(() => {
    emit<{ block: { id: BlockObjectTree } }>(
      {
        method: 'get',
        schema: 'gic_disclosure',
        table: 'api_all_blocks',
      },
      (response?: APIGetResponse<{ block: { id: BlockObjectTree } }>) => {
        if (!response?.message?.[0]) return;
        const blocks = response.message[0].block;
        const events: Event[] = [];

        for (const id in blocks) {
          const block = blocks[id as keyof typeof blocks];
          const reduction = block.reductionLow === block.reductionHigh ? block.reductionLow : ` ${block.reductionLow} - ${block.reductionHigh}`;
          const reudctionTimeUnit = block.startDate === block.endDate ? '' : `per day`;
          events.push({
            title: `${block.asset} - ${block.notes || 'Unknown'} - ${reduction}TJ ${reudctionTimeUnit} reduction`,
            start: moment(block.startDate).toDate(),
            end: moment(block.endDate).toDate(),
            resource: block.asset,
          });
        }

        setEvents(events);
      },
    );
  }, []);

  return (
    <GICPage rows={'1fr '} columns={'1fr '} columnCount={1}>
      <Section childClassName={classes.section}>
        <BigCalendar localizer={localizer} events={myEventsList} startAccessor='start' endAccessor='end' style={{ maxWidth: '100%', height: '100%', width: '100%' }} />
      </Section>
    </GICPage>
  );
}
