import React from 'react';
import { StyledTreeItem } from './TreeBase';

import DescriptionIcon from '@material-ui/icons/Description';

export type TreeNodeStyle = { color: string; bgColor: string; labelStyle?: string; depth?: number };

export type NodeTreeDefinition = {
  treeDefinition: TreeNode[];
  nodeCount: number;
};

export interface TreeNode {
  ID: number;
  text: string;
  info?: number | string;
  nodeStyle?: TreeNodeStyle;

  childNodeStyle?: TreeNodeStyle;
  childNodes?: TreeNode[];
  navigate?: string;
}

export interface TreeNodeConfig {
  text: string;
  info?: number | string;
  nodeStyle?: TreeNodeStyleName;
  depth?: number;
  childNodeStyle?: TreeNodeStyleName;
  childDepth?: number;
  childNodes?: TreeNodeConfig[];
  navigate?: string;
}

export type TreeNodeStyleName = 'primaryNode' | 'secondaryNode' | 'tertiaryNode' | 'quarternaryNode';

export type TreeNodeStyleMap = { [key in TreeNodeStyleName]: TreeNodeStyle };

const makeTreeNode = (node: TreeNode, passedNodeStyle?: TreeNodeStyle): JSX.Element => (
  <StyledTreeItem
    depth={node.nodeStyle?.depth || passedNodeStyle?.depth || 0}
    nodeId={node.ID.toString()}
    key={node.ID}
    labelText={node.text}
    labelIcon={DescriptionIcon}
    labelInfo={node.info?.toString()}
    color={node.nodeStyle?.color || passedNodeStyle?.color || '#1a73e8'}
    bgColor={node.nodeStyle?.bgColor || passedNodeStyle?.bgColor || '#1a73e8'}
    labelStyle={node.nodeStyle?.labelStyle || passedNodeStyle?.labelStyle}
    navigate={node.navigate}
  >
    {node.childNodes && makeTreeNodes(node.childNodes, node.childNodeStyle)}
  </StyledTreeItem>
);

export const makeTreeNodes = (nodes: TreeNode[], passedNodeStyle?: TreeNodeStyle): JSX.Element[] => nodes.map(node => makeTreeNode(node, node.nodeStyle || passedNodeStyle));
