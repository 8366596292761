import React, { PropsWithChildren } from 'react';

import { ContainerProps, PaperItemProps } from 'src/components/styled/Grid';
import { Header } from 'src/components/Header';
import { StyledContainer } from '../styled';

interface PageProps extends PropsWithChildren<Omit<Partial<ContainerProps>, 'rows' & 'columns'>> {
  title: string;
  paperProps?: Partial<PaperItemProps>;
  rows?: string;
  columns?: string;
  columnCount?: number;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function Page({ children, title, rows, columns, columnCount, ...props }: PageProps): JSX.Element {
  return (
    <StyledContainer columns={columns || 'repeat(4, 1fr)'} rows={`2.5rem ${rows || 'repeat(4, 1fr)'}`} {...props}>
      <Header text={title} max columnCount={columnCount || 4} />
      {children}
    </StyledContainer>
  );
}

export { Page };
export type { PageProps };
