import { createReducer } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { WritableDraft } from '@reduxjs/toolkit/node_modules/immer/dist/internal';
import { wsConnect, wsConnected, wsDisconnected } from '../middleware/actions';

// Define a type for the slice state
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SocketStore {
  connected: boolean;
}

// Define the initial state using that type
const initialState: SocketStore = {
  connected: false,
};

export const socketReducer = createReducer(initialState, builder => {
  builder
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .addCase(wsConnect, () => {})
    .addCase(wsConnected, state => {
      state.connected = true;
    })
    .addCase(wsDisconnected, state => {
      state.connected = false;
    });
});
