/* eslint-disable no-constant-condition */
import React from 'react';

import { Item, PaperItem } from 'src/components/styled/Grid';
import { makeStyles, createStyles, Theme, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { Header } from 'src/components/Header';
import { AccordionRoot } from 'src/components/Accordion';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';

import { useSP } from 'src/modules/websocket/hooks';
import NewWindow from 'react-new-window';
import { automatedSources, manualSources } from './config';
import { SourceDefinition } from './types';

import { useSnackSend } from 'src/hooks/useSnackSend';
import { StatefulButton } from 'src/components/StatefulButton';
import { DarkListItem } from './DarkListItem';
import { DownloadCSVButton } from 'src/components/DownloadCSVButton';
import { DataSection } from './DataSection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '-webkit-fill-available',
      textAlign: 'left',
      color: theme.palette.text.primary,
      whiteSpace: 'nowrap',
    },
    accordionRoot: {},
    iconButton: {
      margin: theme.spacing(1),
      '&:hover': {
        color: green[100],
        backgroundColor: theme.palette.grey[600],
      },
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
    },
    controlComponent: {
      display: 'flex',
      width: '50%',
      flexDirection: 'column',
    },
    buttonContainer: {
      padding: 0,
      display: 'flex',
      width: '50%',
      flexDirection: 'column',
      gap: `${theme.spacing(2)}px`,
      justifyContent: 'space-evenly',
    },
    buttonDiv: {
      display: 'flex',
      padding: `0px ${theme.spacing(2)}px`,
    },
  }),
);

function NZEDInterface(): JSX.Element {
  const classes = useStyles();

  const [windowUrl, setUrl] = React.useState<string | null>(null);
  const [refreshData, setRefresh] = React.useState(false);
  const [autoSnapshot, setAutoSnapshot] = React.useState(true);

  const autoSnapshotToggle = () => setAutoSnapshot(!autoSnapshot);

  const doRefresh = () => setRefresh(state => !state);
  const emit = useSP();
  const { sendSnack } = useSnackSend();

  const OpenerCallback = React.useCallback(() => (windowUrl ? <NewWindow url={windowUrl} features={{ width: 500, height: 500, left: 0 }} /> : <></>), [windowUrl]);

  const openUrl = (url: string) => () => {
    if (windowUrl !== url) setUrl(url);
  };

  const doUpdate = (setSuccess: (message: string) => void, setFailure: (message: string) => void): void => {
    emit<[{ message: string }][]>(
      {
        method: 'execute',
        procedure: {
          sp: 'nzedMorningRun',
          database: 'nzed',
        },
      },
      response => {
        if (response?.code === 200 && Array.isArray(response.message)) {
          response.message.map(packet => packet[0] && sendSnack(packet[0].message, 'info'));
          setSuccess('NZED Update complete');
          doRefresh();
        } else {
          setFailure(response?.message.toString() || 'There was an unknown error');
        }
      },
    );
  };

  const accordionItems = (sources: Record<string, SourceDefinition>) =>
    Object.entries(sources).map((urlSource: [string, SourceDefinition], i: number) => {
      const [name, source] = urlSource;

      return <DataSection key={i} i={i} name={name} source={source} opener={openUrl} />;
    });

  // a function  component for user to input  number of  days, default 10.
  const DaysInput = (props: { onDaysChange: (days: number) => void }) => {
    const [days, setDays] = React.useState(10);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newDays = Number(event.target.value) || 0;
      setDays(newDays);
      props.onDaysChange(newDays);
    };
    return (
      <div className={classes.buttonDiv}>
        <TextField id='standard-basic' label='Days' value={days} onChange={handleChange} />
      </div>
    );
  };

  const manualSourceList = React.useMemo(() => accordionItems(manualSources).map((el: JSX.Element) => el), [manualSources, refreshData]);
  const automatedSourceList = React.useMemo(() => accordionItems(automatedSources).map((el: JSX.Element) => el), [automatedSources, refreshData]);

  // function passed to DaysInput that sets the  chosen days length to a variable that can be accessed by the `to` propr of DownloadCSVButton
  const [days, setDays] = React.useState(10);
  const handleDaysChange = (days: number) => setDays(days);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '16px', gap: '16px' }}>
      <Header text={'NZED Interface'} max />
      <Item colSpan={4} rowSpan={1} className={clsx(classes.paper, classes.controls)}>
        <div className={classes.controlComponent}>
          <ul>
            <li>Hit the update database button and wait for it to finish</li>
            <li>Go through the manually entered data sets below and update as needed</li>
            <li>Hit the download CSV button to pull the data file</li>
          </ul>
        </div>
        <div className={classes.buttonContainer}>
          <div className={classes.buttonDiv}>
            <StatefulButton label={'Update NZED database'} onClick={doUpdate} timeout={120000} />
          </div>
          <div className={classes.buttonDiv}>
            <DownloadCSVButton to={'/reports/nzed'}>Download NZED CSV</DownloadCSVButton>
          </div>

          <DaysInput onDaysChange={handleDaysChange} />
          <div className={classes.buttonDiv}>
            <DownloadCSVButton to={`/reports/nzed?seriesLength={days}`}>Download NZED CSV (for chosen number of days)</DownloadCSVButton>
          </div>
          <div className={classes.buttonDiv}>
            <DownloadCSVButton to={'/reports/asxEOD'}>Download ASX EOD CSV</DownloadCSVButton>
          </div>

          <div className={classes.buttonDiv}>
            <DownloadCSVButton to={`/reports/gasdaily?snapGic=${autoSnapshot.toString()}`}>Download Gas Daily CSV</DownloadCSVButton>
          </div>
        </div>
      </Item>
      <Header text={'Settings'} max />
      <Item colSpan={4} rowSpan={1} className={clsx(classes.paper, classes.controls)}>
        <div className={classes.buttonContainer}>
          <div className={classes.buttonDiv}>
            <FormControlLabel control={<Checkbox checked={autoSnapshot} onChange={autoSnapshotToggle} name='checkedB' color='primary' />} label='Automatically create GIC outages snapshot when downloading Gas Daily CSV' />
          </div>
        </div>
      </Item>
      <PaperItem colSpan={4} rowSpan={4} className={classes.paper}>
        <Header padding={'16px'} text={'Manual Datasets'} max />
        <AccordionRoot className={classes.accordionRoot}>{manualSourceList}</AccordionRoot>
      </PaperItem>
      <PaperItem colSpan={4} rowSpan={4} className={classes.paper}>
        <Header padding={'16px'} text={'Automated Datasets'} max />
        <AccordionRoot className={classes.accordionRoot}>{automatedSourceList}</AccordionRoot>
      </PaperItem>

      <OpenerCallback />
    </div>
  );
}

export { NZEDInterface };
