import React, { PropsWithChildren } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Accordion, { AccordionProps } from '@material-ui/core/Accordion';
import AccordionDetails, { AccordionDetailsProps } from '@material-ui/core/AccordionDetails';
import AccordionSummary, { AccordionSummaryProps } from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useAccordion } from './AccordionContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }),
);

export type AccordionChildProps = Omit<AccordionProps, 'children' | 'expanded' | 'onChange'> & {
  id: string;
  heading: JSX.Element | string;
  subHeading?: string;
  detailProps?: Partial<AccordionDetailsProps>;
  summaryProps?: Partial<AccordionSummaryProps>;
};

function AccordionChild(props: PropsWithChildren<AccordionChildProps>): JSX.Element {
  const { heading, subHeading, children, detailProps, summaryProps, id, ...accordionProps } = props;

  const classes = useStyles();
  const [handleChange, expanded] = useAccordion();

  const computedName = `accordion-panel-${id}`;

  return (
    <Accordion expanded={expanded === computedName} onChange={handleChange(computedName)} {...accordionProps}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${computedName}-content`} id={`${computedName}-header`} {...summaryProps}>
        {heading}
        {subHeading && <Typography className={classes.secondaryHeading}>{subHeading}</Typography>}
      </AccordionSummary>
      <AccordionDetails id={`${computedName}-content`} {...detailProps}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export { AccordionChild };
