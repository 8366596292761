import { green, red } from '@material-ui/core/colors';
import { mergeDeep } from 'src/lib/util';
import { CoreTimeSeries, MakeAGSNetTimeSeriesSP, MakeSeriesChartOptions, MakeSeriesOptionsMap } from 'src/types/module/chartModule.types';
import { columnChart, plotLinesCommon } from '..';
import { createMakeMap, createOptionsArrayMap } from '../../lib/util';
import { makeRange } from '../common';

const agsNetFlow: MakeSeriesChartOptions<MakeAGSNetTimeSeriesSP> = {
  ...makeRange('rolling', 3, 'month', 'date'),
  _makeInterface: CoreTimeSeries.AGS,
  name: 'Ahuroa Gas Storage',
  options: mergeDeep<Highcharts.Options>({}, columnChart, {
    title: {
      text: 'Ahuroa Gas Storage',
    },
    //! needs to know if chart has rollup applied to apply correct inject/extract capacities
    // yAxis: {
    //   max: 65000,
    //   min: -65000,

    //   plotLines: [
    //     {
    //       value: 65000,
    //       color: green['A400'],
    //       ...plotLinesCommon,
    //       label: {
    //         text: 'Injection Capacity',
    //         verticalAlign: 'top',
    //       },
    //     } as Highcharts.YAxisPlotLinesOptions,

    //     {
    //       value: -65000,
    //       color: red['A400'],
    //       label: {
    //         text: 'Extraction Capacity',
    //         verticalAlign: 'bottom',
    //       },
    //       ...plotLinesCommon,
    //     },
    //   ],
    // },
    plotOptions: {
      column: {
        pointWidth: 2,
        color: green[300],
        zones: [
          {
            value: 0,
            color: red[300],
          },
        ],
        pointPadding: 2,
        groupPadding: 2,
      },
    },
  }),
};

export const agsNetOptionsMap: MakeSeriesOptionsMap<MakeAGSNetTimeSeriesSP> = {
  agsNetFlow,
};

export const agsNetMakeMap = createMakeMap(agsNetOptionsMap);
export const agsNetOptionsArrayMap = createOptionsArrayMap(agsNetOptionsMap);
