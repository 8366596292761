import React from 'react';
import { SkipPaper, SkipBox } from 'src/style/components/styledFlex';
import Variwide from 'highcharts/modules/variwide';
import Xrange from 'highcharts/modules/xrange';
import Highcharts, { SeriesVariwideOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

Variwide(Highcharts);
Xrange(Highcharts);

const lakes = ['Pukaki', 'Tekapo', 'Taupo', 'Hawea', 'Te Anau', 'Manapouri'] as const;

// function to round to 2 decimals
const round = (num: number) => Math.round(num * 100) / 100;

// function to calculate % of total
// const percentage = (num: number, total: number) => round((num / total) * 100);

type LakeKeys = typeof lakes[number];
type LakeData = Record<LakeKeys, number>;
type ChartData = Array<[number | string, number, number]>;
function Dev(): JSX.Element {
  const lakeMax: LakeData = {
    Pukaki: 50,
    Tekapo: 5,
    Taupo: 30,
    Hawea: 80,
    'Te Anau': 90,
    Manapouri: 100,
  };

  const lakeCurrentLevel: LakeData = {
    Pukaki: round(Math.random() * lakeMax.Pukaki),
    Tekapo: round(Math.random() * lakeMax.Tekapo),
    Taupo: round(Math.random() * lakeMax.Taupo),
    Hawea: round(Math.random() * lakeMax.Hawea),
    'Te Anau': round(Math.random() * lakeMax['Te Anau']),
    Manapouri: round(Math.random() * lakeMax.Manapouri),
  };

  const lakeLongTermAverage: LakeData = {
    Pukaki: round(Math.random() * lakeMax.Pukaki),
    Tekapo: round(Math.random() * lakeMax.Tekapo),
    Taupo: round(Math.random() * lakeMax.Taupo),
    Hawea: round(Math.random() * lakeMax.Hawea),
    'Te Anau': round(Math.random() * lakeMax['Te Anau']),
    Manapouri: round(Math.random() * lakeMax.Manapouri),
  };

  const lakeLongTermAverageAsData: ChartData = Object.entries(lakeLongTermAverage)
    .map(([lake, level]) => [lake, level, lakeMax[lake as LakeKeys]] as [number | string, number, number])
    .sort((a, b) => (a[0] > b[0] ? 1 : -1));

  const lakeLevelRemainingAsData: ChartData = Object.entries(lakeCurrentLevel)
    .map(([lake, currentLevel]) => {
      const maxLevel = lakeMax[lake as LakeKeys];
      const remaining = maxLevel - currentLevel;
      return [lake, remaining, maxLevel] as [number | string, number, number];
    })
    .sort((a, b) => (a[0] > b[0] ? 1 : -1));
  const data: ChartData = Object.entries(lakeCurrentLevel)
    .map(([lake, currentLevel]) => [lake, currentLevel, lakeMax[lake as LakeKeys]] as [number | string, number, number])
    .sort((a, b) => (a[0] > b[0] ? 1 : -1));
  const chartOptions: Highcharts.Options = {
    title: {
      text: 'Current lake storage - marimekko concept',
    },

    subtitle: {
      text: 'Source: someWhere',
    },

    xAxis: {
      type: 'category',
    },

    caption: {
      text: 'Column widths are proportional to lakes share of total',
    },

    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },

    yAxis: [
      {
        id: '0',
      },
      {
        id: '1',
        linkedTo: 0,
        opposite: true,
      },
    ],
    series: [
      {
        type: 'variwide',

        name: 'Remaining capacity',
        data: lakeLevelRemainingAsData,
        dataLabels: {
          enabled: true,
          inside: true,
          format: '{point.y:.0f}',
        },
        yAxis: '0',
        zIndex: 2,

        colors: ['#002060'],
        stacking: 'percent',
        colorByPoint: true,
        tooltip: {
          pointFormat: 'Remaining capacity: <b>{point.y}</b><br>' + 'Max level: <b>{point.z}</b><br>',
        },
      } as SeriesVariwideOptions,
      {
        type: 'variwide',

        name: 'Current Level',
        data,
        dataLabels: {
          enabled: true,
          inside: true,
          format: '{point.y:.0f}',
        },
        yAxis: '0',
        zIndex: 2,
        stacking: 'percent',
        colors: ['#0070c0'],

        tooltip: {
          pointFormat: 'Currentl level: <b>{point.y}</b><br>' + 'Max level: <b>{point.z}</b><br>',
        },
        colorByPoint: true,
      } as SeriesVariwideOptions,
      {
        type: 'variwide',
        name: 'Long term average',
        data: lakeLongTermAverageAsData,

        dataLabels: {
          enabled: true,
          inside: true,
          format: '------', //LT AVERGE {point.y:.0f}
          backgroundColor: '#002060',
          style: {
            color: '#ff0000',
            textOutline: 'none',
          },
        },
        enableMouseTracking: false,
        color: '#ff0000',
        opacity: 1,
        grouping: false,
        yAxis: '0',
        zIndex: 1,

        tooltip: undefined,
      } as Highcharts.SeriesVariwideOptions,
    ],
  };

  return (
    <SkipBox isGrid g={2} rows={'100%'} cols={'100%'} w={'100%'} h={'100%'} style={{ gridAutoFlow: 'dense' }}>
      <SkipPaper flex d={'column'} p={2} style={{ gridColumn: 'span 1' }}>
        <HighchartsReact containerProps={{ style: { display: 'flex', borderRadius: 4, height: '100%', width: '100%' } }} highcharts={Highcharts} constructorType={'chart'} options={chartOptions} />
      </SkipPaper>
    </SkipBox>
  );
}

export { Dev };
