import React from 'react';
import { StyledContainer } from 'src/components/styled/StyledContainer';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { PaperItem } from 'src/components/styled/Grid';
import CommonToolbar from '../common/toolbarIcons';
import { NoticeList } from './NoticeList';
import { OutageTable } from './OutageTable';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gap: '4px',
      padding: 16,
      overflow: 'auto',
    },
    table: {
      flexDirection: 'column',
    },
    header: {
      textAlign: 'center',
      alignSelf: 'center',
      marginRight: 'auto',
      paddingLeft: 16,
    },
    iconBox: {
      display: 'flex',
      flexDirection: 'row',
      height: 'min-content',
      alignSelf: 'center',
      gap: '16px',
      paddingLeft: 16,
      paddingRight: 16,
    },
    toolBar: {
      overflow: 'hidden',
    },
  }),
);

export function View(): JSX.Element {
  const classes = useStyles();
  return (
    <StyledContainer columns={'repeat(3, 1fr)'} rows={'5em repeat(2, 1fr)'}>
      <PaperItem flex colSpan={3} rowSpan={1} className={clsx(classes.root, classes.toolBar)}>
        <Typography variant={'h5'} className={classes.header}>
          Aotearoa Energy Gas Outage Information System (GOIS)
        </Typography>

        <div className={classes.iconBox}>
          <CommonToolbar />
        </div>
      </PaperItem>

      <PaperItem flex colSpan={1} rowSpan={2} className={classes.root}>
        <NoticeList />
      </PaperItem>
      <PaperItem flex colSpan={2} rowSpan={2} className={clsx(classes.root, classes.table)}>
        <OutageTable />
      </PaperItem>
    </StyledContainer>
  );
}
