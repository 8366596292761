export const currencyLabelFormatter = (ctx: Highcharts.AxisLabelsFormatterContextObject): string => `$${ctx.value}`;

export const dateTimeLabelFormats: Highcharts.AxisDateTimeLabelFormatsOptions = {
  millisecond: '%H:%M:%S.%L',
  second: '%H:%M:%S',
  minute: '%H:%M',
  hour: '%H:%M',
  day: '%d/%m/%y',
  week: '%d/%m/%y',
  month: '%m/%y',
  year: '%Y',
};
