/* eslint-disable @typescript-eslint/no-unused-vars */

import { Dispatch } from 'react';
import { AnyAction, Middleware } from 'redux';
import { Socket } from 'src/types/pantheon/specific.types';
import { RootState } from '../reducer/root';
import { AppDispatch, StoreType } from '../store';
import * as actions from './actions';

// eslint-disable-next-line @typescript-eslint/ban-types
export const socketMiddleware = (): Middleware<{}, RootState> => {
  let socket: Socket | null = null;

  const onOpen = (store: StoreType) => () => {
    store.dispatch(actions.wsConnected('event.target.url'));
  };

  const onClose = (store: StoreType) => () => {
    store.dispatch(actions.wsDisconnected());
  };

  const onMessage = (store: StoreType) => () => {
    // const payload = JSON.parse(event.data);
    // console.log('receiving server message');
    // switch (payload.type) {
    //   case 'update_game_players':
    //     store.dispatch(updateGame(payload.game, payload.current_player));
    //     break;
    //   default:
    //     break;
    // }
  };

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case 'WS_CONNECT':
        // if (socket !== null) {
        //   socket.close();
        // }
        // connect to the remote host
        // socket = new WebSocket(action.host);

        // // websocket handlers
        // socket.onmessage = onMessage(store);
        // socket.onclose = onClose(store);
        // socket.onopen = onOpen(store);

        break;
      case 'WS_DISCONNECT':
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
