import React from 'react';

import { StyledContainer } from 'src/components/styled';

function DashboardHome(): JSX.Element {
  return (
    <StyledContainer columns={'1fr'} rows={'1fr'}>
      Dashboard Home
    </StyledContainer>
  );
}

export { DashboardHome };
