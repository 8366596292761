import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Home } from 'src/pages';
import { gic } from 'src/pages/modules';
import { NZEDInterface } from 'src/pages/nzed';
import { AuthorisedRoute, NotFound } from '.';
import { GasRoutes } from 'src/pages/gas/GasRoutes';
import { Calendar } from 'src/pages/calendar';
import { News } from 'src/pages/news';
import { Dev } from 'src/pages/Dev';
import { DashboardRoutes } from 'src/pages/dashboards/DashboardRoutes';

export const ContentRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <AuthorisedRoute path={`${path}nzed`} exact axx={100}>
        <NZEDInterface />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${path}gas`}>
        <GasRoutes />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${path}calendar`} exact>
        <Calendar />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${path}news`} exact>
        <News />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${path}dev`} exact>
        <Dev />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${path}dashboards`}>
        <DashboardRoutes />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${path}gic`} axx={100}>
        <Route path={`${path}gic`} exact={true} component={gic.Home} title='GIC Outage Home' />
        <Route path={`${path}gic/view`} exact={true} component={gic.View} title='GIC Outage viewer' />
        <Route path={`${path}gic/calendar`} exact={true} component={gic.Calendar} title='Outage Calendar' />
        <Route path={`${path}gic/entry`} exact component={gic.Entry} title='GIC Outage entry' />
        <Route path={`${path}gic/entry/:noticeId`} component={gic.Entry} title='GIC Outage entry' />
      </AuthorisedRoute>

      <AuthorisedRoute path={`${path}`} exact>
        <Home />
      </AuthorisedRoute>

      <Route component={NotFound} />
    </Switch>
  );
};
