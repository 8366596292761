import { Paper, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { SourceDefinition } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionToolbar: {
      display: 'flex',
      flexDirection: 'row',
      gap: `${theme.spacing(2)}px`,
    },
    toolbarMessages: {
      padding: theme.spacing(1),
      marginRight: 'auto',
      flexGrow: 1,
      alignSelf: 'center',
      height: '-webkit-fill-available',
    },
    button: {},
  }),
);

export const SectionToolbar = ({ name, source, openUrl, addRow }: { name: string; source: SourceDefinition; openUrl: (url: string) => () => void; addRow: () => void }): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.sectionToolbar}>
      <Paper className={classes.toolbarMessages} elevation={2}>
        <span>No action has been taken.</span>
      </Paper>
      <Button className={classes.button} variant={'contained'} color={'primary'} onClick={addRow}>
        Add new entry
      </Button>
      {source.uri ? (
        <Button className={classes.button} variant={'contained'} color={'primary'} onClick={openUrl(source.uri)}>
          Open {name} data source
        </Button>
      ) : (
        <span>Manual data collection</span>
      )}
    </div>
  );
};
