import React from 'react';
import { NoticeForm } from './NoticeForm';
import { StyledContainer } from 'src/components/styled/StyledContainer';
import { NoticeInterface, CancelItem, NoticeSubmit } from '../common/notice.types';
import { Button, IconButton, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@material-ui/core';
import { PaperItem } from 'src/components/styled/Grid';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSocket } from 'src/modules/websocket/hooks/useSocket';
import { useSnackbar } from 'notistack';
import CommonToolbar from '../common/toolbarIcons';
import { doValidate, makeBlankNotice, isMysqlError } from './util';
import { noticeEntryStyles } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import { RPCRequest, RPCResponse } from 'src/types/pantheon/rpc.types';
import { useParams } from 'react-router-dom';

export function Entry(): JSX.Element {
  const classes = noticeEntryStyles();

  // const location = useLocation();
  // const locationParts = location.pathname.split('/');
  // const noticeID = locationParts[locationParts.indexOf('entry') + 1];
  const { noticeId } = useParams<{ noticeId: string | undefined }>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const noticeRef = React.useRef<NoticeInterface>(makeBlankNotice());
  const cancelList = React.useRef<CancelItem[]>([]);

  const [currentState, clearTicket] = React.useState(false);
  const [response, request] = useSocket<RPCResponse<string>, RPCRequest<NoticeSubmit>>('pantheon.rpc');

  const showData = () => console.log(noticeRef);
  const showCancels = () => console.log(cancelList);

  const clearData = () => {
    noticeRef.current = makeBlankNotice();
    cancelList.current = [];
    clearTicket(!currentState);
  };

  React.useEffect(() => {
    !noticeId && noticeRef.current.id > 0 && clearData();
  }, [noticeId]);

  const saveData = () => {
    if (!checkValidation()) return;
    request({ method: 'post', procedure: 'submitGIC', parameters: { noticeObject: noticeRef.current, cancelList: [] } });
  };

  const updateData = () => {
    request({ method: 'patch', procedure: 'submitGIC', parameters: { noticeObject: noticeRef.current, cancelList: cancelList.current } });
  };

  // TODO split out into standard handler?
  // processes response and enqueues popup
  React.useEffect(() => {
    // parse server response
    const parseResponse = (message: unknown) => {
      if (typeof message === 'string') return message;
      if (isMysqlError(message)) {
        if (message.sqlMessage) return message.sqlMessage;
        return `Database error - ${message.code}`;
      }

      return JSON.stringify(message);
    };

    if (response) {
      const message = parseResponse(response.message);
      enqueueSnackbar(message, {
        variant: response.code === 200 ? 'success' : 'error',
        persist: false,
        action: (key: string) => (
          <IconButton aria-label='confirm' onClick={() => closeSnackbar(key)} size={'medium'}>
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  }, [response]);

  const memoNotice = React.useMemo(() => <NoticeForm noticeRef={noticeRef} clear={currentState} noticeID={noticeId ? Number.parseInt(noticeId) : undefined} cancelList={cancelList} />, [currentState]);

  //validator for noticce data
  const [dialogOpen, setDialogOpen] = React.useState<JSX.Element[]>([]);
  const closeDialog = () => setDialogOpen([]);
  const openDialog = (content: JSX.Element[]) => setDialogOpen(content);

  const checkValidation = () => {
    const errors = doValidate(noticeRef.current);
    if (errors.length === 0) return true;

    const dialogElements = errors.map((text: string, errId: number) => {
      return (
        <li key={errId} className={classes.validationErrorItem}>
          {text}
        </li>
      );
    });

    openDialog(dialogElements);
    return false;
  };

  return (
    <StyledContainer columns={'repeat(4, 1fr)'} rows={'5em repeat(2, 1fr)'}>
      <Dialog open={Boolean(dialogOpen.length)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Notice validation errors</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <ul className={classes.validationErrorList}>{dialogOpen.map((el: JSX.Element) => el)}</ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <PaperItem flex colSpan={4} rowSpan={1} className={classes.entryRoot}>
        <Typography variant={'h5'} className={classes.header}>
          Aotearoa Energy Gas Outage Information System (GOIS)
        </Typography>

        <div className={classes.iconBox}>
          <Divider orientation='vertical' flexItem />

          <Tooltip title='Output current notice in console' aria-label='show data'>
            <IconButton aria-label='show data' size={'medium'} onClick={showData}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Show cancelled items with reasons' aria-label='delete notice'>
            <IconButton aria-label='delete notice' size={'medium'} onClick={showCancels}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          {!noticeId && (
            <>
              <Tooltip title='Clear current inputs' aria-label='clear data'>
                <IconButton aria-label='clear data' size={'medium'} onClick={clearData}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Save notice' aria-label='save'>
                <IconButton aria-label='save' size={'medium'} onClick={saveData}>
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            </>
          )}

          {noticeId && (
            <Tooltip title='Update notice' aria-label='update'>
              <IconButton aria-label='update' size={'medium'} onClick={updateData}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
          )}
          <CommonToolbar />
        </div>
      </PaperItem>
      {memoNotice}
    </StyledContainer>
  );
}
