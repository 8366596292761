import { Modal, Backdrop, createStyles, makeStyles, Theme, Grid, Box, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { PaperItem } from 'src/components/styled/Grid';
import { EpicModalProps } from './ModalProvider';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { UberIconButton } from 'src/components/UberIconButton';

export const useModalContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '95vh',
      maxWidth: '95vw',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #fff',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[2],
      padding: 0,
    },
    headerBar: {
      width: '-webkit-fill-available',
      borderWidth: `0px 0px 1px 0px`,
      borderStyle: `solid`,
      borderColor: theme.palette.divider,
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
      boxShadow: theme.shadows[1],
    },
    headerText: {
      marginLeft: 12,
      marginRight: 'auto',
      color: theme.palette.getContrastText(theme.palette.primary.main),
      fontWeight: 'normal',
      letterSpacing: '1px',
    },
  }),
);

export type EpicModalContainerProps = EpicModalProps & {
  closeModal: () => void;
  open: boolean;
  title?: string;
};

const ModalContainer = ({ closeModal, title, open, children, paperItemProps, boxProps, ...modalProps }: EpicModalContainerProps): JSX.Element => {
  const classes = useModalContainerStyles();
  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      open={open}
      {...modalProps}
    >
      <div>
        <PaperItem {...paperItemProps} className={clsx(classes.paper, paperItemProps?.className)}>
          <Grid container alignItems='center' className={classes.headerBar}>
            <Typography component={'div'} variant={'h6'} className={classes.headerText}>
              {title}
            </Typography>
            <Divider orientation='vertical' flexItem />
            <UberIconButton onClick={closeModal} tooltip={'Close window'} Icon={CloseIcon} />
          </Grid>
          <Box p={2} {...boxProps}>
            {children}
          </Box>
        </PaperItem>
      </div>
    </Modal>
  );
};

export { ModalContainer };
