import { Controller } from 'react-hook-form';
import React from 'react';
import { withStyles, FormControl, FormControlProps, InputLabel, Select, MenuItem } from '@material-ui/core';
import { WithFormStyles, formStyles } from './common';

export interface StyledSelectProps extends WithFormStyles {
  name: string;
  options?: [number | string, string][]; // tuple list
  optionPlaceHolder?: string; // optional placeholder option
  label?: string;
}

export const StyledSelect = withStyles(formStyles)((props: StyledSelectProps & FormControlProps) => {
  const { classes, name, control, options, ControllerProps, optionPlaceHolder, label, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      {...ControllerProps}
      render={({ field }) => (
        <FormControl size={'small'} variant='outlined' className={classes.root} {...rest}>
          {label && <InputLabel id={`${name}-label`}>{label}</InputLabel>}
          <Select labelId={`${name}-label`} label={label} {...field}>
            {optionPlaceHolder && (
              <MenuItem value={0}>
                <em>{optionPlaceHolder}</em>
              </MenuItem>
            )}
            {options?.map(option => (
              <MenuItem key={`${name}-${option[0]}`} value={option[0]}>
                {option[1]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
});
