import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { panelStyles } from './DashPanel';
import { Gauge } from '../Charts/Gauge';
import { DashboardRowProps } from './types';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { labelColour: string }>((theme: Theme) =>
  createStyles({
    labelClass: {
      padding: '0px 8px',
      width: '-webkit-fill-available',
      alignItems: 'center',
      backgroundColor: ({ labelColour }) => labelColour,
      border: theme.palette.dashboard.border,
      borderRight: 0,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderCollapse: 'collapse',
    },
    gaugeClass: {
      border: theme.palette.dashboard.border,
      borderRight: 0,
      borderLeft: 0,
      borderCollapse: 'collapse',
    },
  }),
);

export const DashRow = (props: PropsWithChildren<DashboardRowProps>): JSX.Element => {
  const { label, labelColour, gaugeHook, children, row } = props;
  const classes = panelStyles();
  const { gaugeClass, labelClass, rowClass } = useStyles({ labelColour });

  return (
    <>
      <div className={clsx(classes.gridItem, classes.verticalLabel, labelClass, rowClass)}>{label}</div>
      <div className={clsx(classes.gaugeContainer, gaugeClass)} style={{ backgroundColor: labelColour, gridArea: `${row} / 2` }}>
        <Gauge label={label} gaugeHook={gaugeHook} backgroundColor={labelColour} />
      </div>
      {children}
    </>
  );
};

// div style={{ display: 'flex', flexDirection: 'row', gridArea: `${row + 1} / 1 / ${row + 1} / 6` }}>
// div>
