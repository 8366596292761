import { combineReducers } from '@reduxjs/toolkit';
import { authSlice } from './auth';
// import { chartControlsSlice } from './chartControls.tsx.old';
import { metadataSlice } from './metadata';
import { socketReducer } from './socket';

export const rootReducer = combineReducers({
  metadata: metadataSlice.reducer,
  auth: authSlice.reducer,
  socket: socketReducer,
  // chart: chartControlsSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
