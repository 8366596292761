import { MakeCoreTimeSeriesSP, MakeSeriesChartOptions, MakeSeriesOptionsMap } from 'src/types/module/chartModule.types';
import { createMakeMap, createOptionsArrayMap } from '../../lib/util';
import { generateUnitGeneration } from './common';
import { mergeTimeSeries } from './util';

const rankineOne: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...generateUnitGeneration('Rankine One'),
  name: 'Rankine 1',
  seriesNames: ['Rankine 1'],
  seriesFilters: [['node_id', ['HLY2201 HLY1']]],
};
const rankineTwo: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...generateUnitGeneration('Rankine 2'),
  name: 'Rankine 2',
  seriesNames: ['Rankine 2'],
  seriesFilters: [['node_id', ['HLY2201 HLY2']]],
};
const rankineThree: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...generateUnitGeneration('Rankine 3'),
  name: 'Rankine 3',
  seriesNames: ['Rankine 3'],
  seriesFilters: [['node_id', ['HLY2201 HLY3']]],
};
const e3p: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...generateUnitGeneration('e3p'),
  name: 'e3p',
  seriesNames: ['e3p'],
  seriesFilters: [['node_id', ['HLY2201 HLY5']]],
};

const stratfordOne: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...generateUnitGeneration('Stratford 1'),
  name: 'Stratford 1',
  seriesNames: ['Peaker 1'],
  seriesFilters: [['node_id', ['SFD2201 SFD21']]],
};
const stratfordTwo: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...generateUnitGeneration('Stratford 2'),
  name: 'Stratford 2',
  seriesNames: ['Peaker 2'],
  seriesFilters: [['node_id', ['SFD2201 SFD22']]],
};
const TCC: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...generateUnitGeneration('TCC'),
  name: 'TCC',
  seriesNames: ['TCC'],
  seriesFilters: [['node_id', ['SFD2201 SPL0']]],
};

export const thermalGeneration = mergeTimeSeries('Thermal', rankineOne, rankineTwo, rankineThree, e3p, stratfordOne, stratfordTwo, TCC);
export const genesisEnergy = mergeTimeSeries('Genesis Energy', rankineOne, rankineTwo, rankineThree, e3p);
export const contactEnergy = mergeTimeSeries('Contact Energy', TCC, stratfordOne, stratfordTwo);

export const electricityOptionsMap: MakeSeriesOptionsMap<MakeCoreTimeSeriesSP> = {
  rankineOne,
  rankineTwo,
  rankineThree,
  e3p,
  stratfordOne,
  stratfordTwo,
  TCC,
  contactEnergy,
  genesisEnergy,
  thermalGeneration,
};

export const electricityMakeMap = createMakeMap(electricityOptionsMap);
export const electricityOptionsArrayMap = createOptionsArrayMap(electricityOptionsMap);
