import { Paper, withTheme, PaperProps } from '@material-ui/core';
import React from 'react';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { SkipBoxProps, SkipPaperProps } from './styling.types';
import { omitForwardedProps } from './util';

export const SkipBox = withTheme(styled('div').withConfig(
  omitForwardedProps<HTMLAttributes<HTMLDivElement>>(['flex', 'isGrid', 'ga', 'w', 'h', 'theme', 'p', 'g']),
)<SkipBoxProps>`
  ${props =>
    props.flex &&
    `
    display: flex;
     ${props.d && `flex-direction: ${props.d};`}
  `}
  ${props =>
    props.isGrid &&
    `
    display: grid;
    ${props.cols && `grid-template-columns: ${props.cols};`}
    ${props.rows && `grid-template-rows: ${props.rows};`}
  `}
  ${({ ga }) => ga && `grid-area: ${ga};`};
  ${({ w }) => w && ` width: ${w};`}
  ${({ h }) => h && ` height: ${h};`}
  ${({ theme, p }) => p && `padding: ${theme.spacing(p)}px;`}
  ${({ theme, g }) => g && `gap: ${theme.spacing(g)}px;`}
`);

export const SkipPaper = withTheme(styled(Paper).withConfig(
  omitForwardedProps<PaperProps>(['flex', 'isGrid', 'ga', 'w', 'h', 'theme', 'p', 'g']),
)<SkipPaperProps>`
  ${props =>
    props.flex &&
    `
    display: flex;
     ${props.d && `flex-direction: ${props.d};`}
  `}
  ${props =>
    props.isGrid &&
    `
    display: grid;
    ${props.cols && `grid-template-columns: ${props.cols};`}
    ${props.rows && `grid-template-rows: ${props.rows};`}
  `}
  ${({ ga }) => ga && `grid-area: ${ga};`};
  ${({ w }) => w && ` width: ${w};`}
  ${({ h }) => h && ` height: ${h};`}
  ${({ theme, p }) => p && `padding: ${theme.spacing(p)}px;`}
  ${({ theme, g }) => g && `gap: ${theme.spacing(g)}px;`}
`);

// re-exported to fix typing on withTheme + styled

export const SkipContent = (props: SkipBoxProps): JSX.Element => <SkipBox p={2} {...props} />;
export const SkipPaperContent = (props: SkipPaperProps): JSX.Element => <SkipPaper p={2} {...props} />;

export const SkipContainer = (props: SkipBoxProps): JSX.Element => <SkipBox g={2} {...props} />;
export const SkipPaperContainer = (props: SkipPaperProps): JSX.Element => <SkipPaper g={2} {...props} />;
