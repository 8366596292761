import React, { PropsWithChildren } from 'react';

import clsx from 'clsx';
import { panelStyles } from './DashPanel';

// eslint-disable-next-line @typescript-eslint/ban-types
export const Dashboard = ({ children, className }: PropsWithChildren<{ className?: string }>): JSX.Element => {
  const classes = panelStyles();
  return <div className={clsx(classes.root, className)}>{children}</div>;
};
