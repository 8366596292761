/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createAction } from '@reduxjs/toolkit';

// modules/websocket.js
export type SocketActionType = 'WS_CONNECT' | 'WS_CONNECTING' | 'WS_CONNECTED' | 'WS_DISCONNECT' | 'WS_DISCONNECTED';
export type SocketAction = { type: SocketActionType };

export const wsConnect = createAction<string | undefined>('WS_CONNECT');
export const wsConnecting = createAction<string | undefined>('WS_CONNECTING');
export const wsConnected = createAction<string | undefined>('WS_CONNECTED');
export const wsDisconnect = createAction<string | undefined>('WS_DISCONNECT');
export const wsDisconnected = createAction<string | undefined>('WS_DISCONNECTED');
