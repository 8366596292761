import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

import CommonToolbar from '../common/toolbarIcons';
import { Page, Section } from 'src/components/Wrapper';
import { PropsWithChildren } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    entryRoot: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gap: '4px',
      padding: 16,
    },
    toolbar: {
      padding: 4,
    },
    iconBox: {
      display: 'flex',
      flexDirection: 'row',
      height: 'min-content',
      alignSelf: 'center',
      gap: '16px',
      paddingLeft: 16,
      paddingRight: 16,
      marginLeft: 'auto',
    },
  }),
);

export function GICPage({ rows, columns, columnCount, children, toolbarIcons }: PropsWithChildren<{ rows: string; columns: string; columnCount: number; toolbarIcons?: JSX.Element }>): JSX.Element {
  const classes = useStyles();

  return (
    <Page rows={`5rem ${rows}`} columns={columns} columnCount={columnCount} title={'Aotearoa Energy Gas Outage Information System (GOIS)'}>
      <Section colSpan={columnCount} rowSpan={1} className={classes.toolbar} childClassName={classes.iconBox}>
        {toolbarIcons}
        <CommonToolbar />
      </Section>
      {children}
    </Page>
  );
}
