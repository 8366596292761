import { configureStore } from '@reduxjs/toolkit';
import { socketMiddleware } from './middleware/socket';
import { rootReducer } from './reducer/root';

const middleware = [socketMiddleware()];

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(...middleware),
});

export type StoreType = typeof store;
export type AppDispatch = typeof store.dispatch;
export type DispatchReducer<TPayload extends unknown> = (payload: TPayload[] | string) => { payload: TPayload[] | string; type: string; meta?: string };
