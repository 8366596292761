import { createStyles, Theme, StyleRules, WithStyles } from '@material-ui/core';
import { Control, ControllerProps } from 'react-hook-form';

export const formStyles = ({ spacing }: Theme): StyleRules<'root'> =>
  createStyles({
    root: {
      margin: `${spacing(1)}px ${spacing(2)}px ${spacing(1)}px ${spacing(2)}px`,
      minWidth: 200,
      width: '50%',
      textAlign: 'left',
    },
  });

export interface WithFormStyles extends WithStyles<typeof formStyles> {
  control: Control;
  ControllerProps?: Partial<ControllerProps>;
}
