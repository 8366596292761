import React, { createContext, useContext } from 'react';
import { io, Socket } from 'socket.io-client';
import parser from 'socket.io-msgpack-parser';
import { DecodedJWT } from '../auth/types';
import { useSnackSend } from 'src/hooks/useSnackSend';
import { APIGetResponse, APIRequest, PantheonNotification } from 'src/types/pantheon/pantheon.types';
import { useDispatch } from 'react-redux';
import { useSocketEmit } from './hooks';
import { addNodes, addPoints, addMeters, addEntities, setLoaded } from 'src/redux/reducer/metadata';
import { auth } from 'src/redux/reducer/auth';
import { DispatchReducer } from 'src/redux/store';
import { Datacore } from 'src/types/module/datacore.types';
import { wsConnected, wsDisconnected } from 'src/redux/middleware/actions';
import { useAppSelector } from 'src/redux/hooks';

export interface WebSocketContextInterface {
  socket: Socket;
}

const WebSocketContext = createContext<WebSocketContextInterface>({
  socket: (null as unknown) as Socket,
});

const WebSocketProvider = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const dispatch = useDispatch();
  const hasLoaded = useAppSelector(state => state.metadata.status.loaded);

  const socket = React.useRef(
    io({
      parser,
      forceNew: false,
      autoConnect: false,
      reconnection: true,
      rejectUnauthorized: false,
      withCredentials: true,
      query: {
        token: localStorage.getItem('token') || '',
      },
    }),
  );

  const [emit] = useSocketEmit('pantheon.api', undefined, socket.current);

  const { sendSnack } = useSnackSend();

  const getMetaData = async () => {
    // wrapper for emitter since we can't use the useAPI hook in the websocket context itself.
    const fetchData = async <TResponse extends unknown>(request: Omit<APIRequest, 'method' | 'schema'>, dispatchReducer: DispatchReducer<TResponse>) => {
      const response = await new Promise((resolve: (values?: APIGetResponse<TResponse>) => void) => {
        emit<APIRequest, APIGetResponse<TResponse>>({ method: 'get', schema: 'datacore', ...request }, response => resolve(response));
      });
      response?.message ? dispatch(dispatchReducer(response.message)) : sendSnack(`Error loading metadata for ${request.table}`, 'error');
    };

    await Promise.all([
      fetchData<Datacore.Entity>(
        {
          table: 'entities',
          fields: [`ID`, `gicID`, `name`, `category`, `tags`],
        },
        addEntities,
      ),

      fetchData<Datacore.WITSNode>(
        {
          table: 'witsNodes',
          fk: ['isCollecting', 1],
          fields: ['nodeName', 'name', 'nodeFull', 'isGeneration', 'isASX'],
        },
        addNodes,
      ),

      fetchData<Datacore.OatisPoint>({ table: 'oatisGasPoint', fk: ['isDisabled', 0], fields: ['ID', 'name', 'network', 'pointCode'] }, addPoints),

      fetchData<Datacore.OatisMeter>({ table: 'oatisGasMeter', fields: [`ID`, `name`, `meterCode`, `meterDirection`, `pointID`] }, addMeters), //fk: ['isDisabled', 0],
    ]);

    dispatch(setLoaded());
  };

  React.useEffect(() => {
    socket.current.on('pantheon.notify', (data: PantheonNotification) => {
      sendSnack(data.message, data.variant, data.options);
    });

    socket.current.on('connect_error', (err: Error) => {
      console.error('connect error');
      console.error(err);
    });

    socket.current.on('connect', () => {
      dispatch(wsConnected());
      socket.current.emit('pantheon.user');
    });

    socket.current.on('disconnect', (reason: Socket.DisconnectReason) => {
      console.warn('disconnect');
      console.warn(reason);
      dispatch(wsDisconnected());
    });

    socket.current.on('pantheon.user', (data: DecodedJWT | false) => {
      if (data) {
        dispatch(auth(data));

        if (!hasLoaded) getMetaData();
      } else {
        socket.current.disconnect();
      }
    });

    return () => {
      socket.current.off('lastmon.core');
      socket.current.off('pantheon.user');
      socket.current.off('connect');
      socket.current.off('connect_error');
      socket.current.off('disconnect');
      socket.current.off('pantheon.notify');
    };
  }, []);

  // initial context state
  const contextState: WebSocketContextInterface = {
    socket: socket.current,
  };

  return <WebSocketContext.Provider value={contextState}>{children}</WebSocketContext.Provider>;
};

const useSocketInstance = (): WebSocketContextInterface => {
  const { socket } = useContext(WebSocketContext);
  return { socket };
};

export { WebSocketProvider, useSocketInstance };
