import { makeStyles, Theme } from '@material-ui/core';

export const useWrapperStyles = makeStyles((theme: Theme) => ({
  container: {
    gridGap: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    background: 'rgb(154, 195, 191)',
    border: 0,
    borderRadius: 0,
  },
  paper: {
    padding: theme.spacing(1.5),
    width: '-webkit-fill-available',
    textAlign: 'left',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
  },
  divider: {
    marginTop: 3,
    marginBottom: 7,
    height: '1px',
    width: '-webkit-fill-available',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
