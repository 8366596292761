import { blueGrey, grey, orange } from '@material-ui/core/colors';
import { createTheme, darken, lighten } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';

interface ICustomPalette {
  border: string;
  sectionButton: {
    selected: string;
    unselected: string;
  };
  streamButton: {
    selected: string;
    unselected: string;
  };
  myOrange: string;
  myBlueDarker: string;
  dashboard: {
    border: string;
    oils: string;
    gas: string;
    lpg: string;
    electricity: string;
    instrumentUp: {
      color: string;
      flash?: string;
    };
    instrumentDown: {
      color: string;
      flash?: string;
    };
    instrumentFlat: {
      color: string;
      flash?: string;
    };
    title: string;
    subTitle: string;
    label: string;
    panelPlaceholder: string;
  };
  navBar: string;
  commonAxisOptions: Highcharts.XAxisOptions | (Highcharts.XAxisOptions[] & Highcharts.YAxisOptions) | Highcharts.YAxisOptions[];

  chartBackground: string;
  chartBase: string;
  chartLegendStyle: Highcharts.CSSObject;
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette extends ICustomPalette {} // eslint-disable-line
  interface PaletteOptions extends ICustomPalette {} // eslint-disable-line
}

export const navHeight = '94px';
const defaultBackground = '#0089a4';
const defaultBorder = '#f0ffff';

const theme = createTheme({
  palette: createPalette({
    border: defaultBorder,
    sectionButton: {
      selected: 'rgb(0,173,239)',
      unselected: grey[200],
    },
    streamButton: {
      selected: blueGrey[200],
      unselected: grey[200],
    },

    myOrange: blueGrey[200],
    myBlueDarker: lighten(grey[200], 0.4),
    background: {
      paper: 'rgb(0, 109, 131)', //darken(defaultBackground, 0.2),
      default: darken(defaultBackground, 0.7),
    },

    primary: {
      main: 'rgb(79,129,189)',
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgb(4,123,54)',
      contrastText: '#fff',
    },
    text: {
      primary: '#faf4f4',
      secondary: '#faf4f4',
      disabled: '#faf4f4',
      hint: '#faf4f4',
    },
    dashboard: {
      border: `1px ${defaultBorder} solid`,
      oils: darken(defaultBackground, 0.2),
      gas: darken(defaultBackground, 0.2),
      lpg: darken(defaultBackground, 0.2),
      electricity: darken(defaultBackground, 0.2),
      instrumentUp: {
        color: '#b0f1c0',
      },
      instrumentDown: {
        color: '#f1b0b6',
      },
      instrumentFlat: {
        color: '#faf4f4',
      },
      title: '#000',
      subTitle: '#000',
      label: '#faf4f4',
      panelPlaceholder: '#dbc0c0',
    },
    navBar: '#7295a3',
    commonAxisOptions: {
      labels: {
        style: {
          fontFamily: 'Arial',
          fontSize: '12px',
          color: '#d2d7ff',
        },
        align: 'center',
      },
    },
    chartBackground: grey[700],
    chartBase: '#ff0',
    chartLegendStyle: {
      color: '#d2d7ff',
    },
  }),

  typography: {
    //   fontSize: '1.2rem',
    //   '@media (min-width:600px)': {
    //     fontSize: '1.5rem',
    //   },
    //   [theme.breakpoints.up('md')]: {
    //     fontSize: '2.4rem',
    //   },
    fontFamily: 'Arial',
    fontSize: 12,
    h5: {
      fontFamily: 'Arial Narrow',
    },
    h6: {
      fontFamily: 'Arial Narrow',
    },
  },
  shape: {
    borderRadius: 12,
  },

  overrides: {
    MuiAppBar: {
      root: {
        border: '0px',
      },
      colorPrimary: {
        backgroundColor: '#0f485e',
      },
    },
    MuiDrawer: {
      paper: {
        borderLeft: 0,
        borderTop: 0,
        borderBottom: 0,
        backgroundColor: lighten('#0f485e', 0.4),
      },
    },
    MuiPaper: {
      root: {
        border: `1px ${defaultBorder} solid`,
      },
    },
    MuiTableContainer: {
      root: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: defaultBorder,
      },
    },
    MuiTableRow: {
      head: {
        backgroundColor: '#00adef !important',
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#fff',
      },
    },
  },
});

export { theme };
