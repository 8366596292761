import React, { PropsWithChildren } from 'react';
import { Typography } from '@material-ui/core';
import { SkipBox } from 'src/style/components/styledFlex';

export function ChartControlWrapper({ title, children, left, column }: PropsWithChildren<{ title: string; left?: boolean; column?: boolean }>): JSX.Element {
  return (
    <SkipBox flex d={'column'} p={1} g={0}>
      <Typography variant={'h6'} style={{ color: '#000', fontWeight: 'bolder' }}>
        {title}
      </Typography>
      <SkipBox flex d={column ? 'column' : 'row'} style={{ placeContent: left ? 'flex-start' : 'space-evenly', padding: left ? '0px 16px' : 0 }}>
        {children}
      </SkipBox>
    </SkipBox>
  );
}
