import { MakeCoreTimeSeriesSP, MakeSeriesChartOptions } from 'src/types/module/chartModule.types';
import { createSeriesMaps } from '../common';
import { genericOatisDaily } from './common';
import { mergeTimeSeries } from './util';

// const defaultRange: Pick<TimeSeriesChartOptions<MakeTimeSeriesSP>, '_makeInterface' | 'range'> = {
//   _makeInterface: CoreTimeSeriesDiscriminatorsEnum.TimeSeries,
//   range: [moment().subtract(1, 'month').startOf('day').format(rangeFormat.default), moment().endOf('day').format(rangeFormat.default)],
// };

const faullRoad: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Methanex',
  seriesNames: ['Faull Road'],
  seriesFilters: [['meterID', [8]]],
  ...genericOatisDaily('Methanex'),
};
const ngatimaruRoad: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Methanex',
  seriesNames: ['Ngatimaru Road'],
  seriesFilters: [['meterID', [9]]],
  ...genericOatisDaily('Methanex'),
};
const bertrandRoad: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Methanex',
  seriesNames: ['Bertrand Road'],
  seriesFilters: [['meterID', [19]]],
  ...genericOatisDaily('Methanex'),
};

const methanex = mergeTimeSeries('Methanex', faullRoad, ngatimaruRoad, bertrandRoad);

const ballance: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Ballance',
  seriesNames: ['Ballance'],
  seriesFilters: [['meterID', [561, 727, 560, 809, 810, 811, 812]]],
  ...genericOatisDaily('Ballance'),
};

const petrochemical = mergeTimeSeries('Petrochemical', methanex, ballance);

const huntly: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Huntly Power Station',
  seriesNames: ['Huntly Power Station'],
  seriesFilters: [['meterID', [7]]],
  ...genericOatisDaily('Huntly Power Station'),
};

const tcc: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'TCC',
  seriesNames: ['TCC'],
  seriesFilters: [['meterID', [501, 502]]],
  ...genericOatisDaily('Taranaki Combined Cycle'),
};

// TODO needs to use net flow for stratford 3 (has D & R)
const stratford: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Stratford Peakers',
  seriesNames: ['Stratford Peakers'],
  seriesFilters: [['meterID', [469, 749, 750, 764, 765, 766, 767]]],
  ...genericOatisDaily('Stratford Peakers'),
};

const junctionRoad: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Junction Road Peakers',
  seriesNames: ['Junction Road Peakers'],
  seriesFilters: [['meterID', [24]]],
  ...genericOatisDaily('Junction Road Peakers'),
};

const generation = mergeTimeSeries('Generation', huntly, tcc, stratford, junctionRoad);

const nzSteel: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'NZ Steel',
  seriesNames: ['NZ Steel'],
  seriesFilters: [['meterID', [405, 760]]],
  ...genericOatisDaily('NZ Steel'),
};

const refiningNZ: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Refining NZ',
  seriesNames: ['Refining NZ'],
  seriesFilters: [['meterID', [414, 624, 757]]],
  ...genericOatisDaily('Refining NZ'),
};

const ojiFS: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Oji',
  seriesNames: ['Oji'],
  seriesFilters: [['meterID', [409, 410]]],
  ...genericOatisDaily('OJI Fibre Solutions'),
};

const industrial = mergeTimeSeries('Industrial', nzSteel, refiningNZ, ojiFS);

export const [gasMajorUserOptionsMap, gasMajorUserMakeMap, gasMajorUserOptionsArrayMap] = createSeriesMaps({
  methanex,
  ballance,
  huntly,
  tcc,
  stratford,
  junctionRoad,
  nzSteel,
  refiningNZ,
  ojiFS,
  faullRoad,
  ngatimaruRoad,
  bertrandRoad,
});

export const [gasSectorDemandOptionsMap, gasSectorDemandMakeMap, gasSectorDemandOptionsArrayMap] = createSeriesMaps({
  petrochemical,
  generation,
  industrial,
});

// export const gasMajorUserMakeMap = createMakeMap(gasMajorUserOptionsMap);
// export const gasMajorUserOptionsArrayMap = createOptionsArrayMap(gasMajorUserOptionsMap);
