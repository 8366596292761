import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SeriesChartType } from 'src/types/module/chartModule.types';
import { useChart } from 'src/components/Charts/components/Context';

export function ChartTypeRadio({ id, initialValue }: { id: string; initialValue: SeriesChartType }): JSX.Element {
  const [value, setValue] = React.useState<SeriesChartType>(initialValue);
  const [chart] = useChart(id);

  const options: [SeriesChartType, string][] = [
    ['column', 'Column'],
    ['line', 'Line'],
    ['area', 'Area'],
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = (event.target as HTMLInputElement).value as SeriesChartType;

    const chartInstance = chart();
    // get chart instance
    if (!chartInstance) throw new Error(`Could not retreive chart instance with id ${id}`);

    // change chart series type
    const allSeries = chartInstance.series;
    allSeries.map(series => series.update({ type }, false));
    const currentConfigSeries = chartInstance.nexus.currentConfig.options.series;

    // update currentConfig
    // todo prob needs to be refactored out to be used across all chartcontrol components (gotta be consistent with the way the currentConfig is kept up to date)
    if (currentConfigSeries) currentConfigSeries.map(series => (series.type = type));
    else if (chartInstance.userOptions.series) chartInstance.nexus.currentConfig.options.series = [...chartInstance.userOptions.series];
    else chartInstance.nexus.currentConfig.options.series = chartInstance.series.map(() => ({ type }));
    chartInstance.redraw();
    setValue(type);
  };

  const optionControls = React.useMemo(() => options.map(option => <FormControlLabel key={option[0]} value={option[0]} control={<Radio />} label={option[1]} />), [value]);

  return (
    <RadioGroup aria-label='chartType' name='chartType' value={value} onChange={handleChange}>
      {optionControls}
    </RadioGroup>
  );
}
