import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { theme } from 'src/style/theme';
import FullScreen from 'highcharts/modules/full-screen';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import Sunburst from 'highcharts/modules/sunburst';
import SeriesLabel from 'highcharts/modules/series-label';

// apply modules
HighchartsMore(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
FullScreen(Highcharts);
Sunburst(Highcharts);
SeriesLabel(Highcharts);

import * as muiColors from '@material-ui/core/colors';
import { darken, lighten } from '@material-ui/core';

const fontStyle: Highcharts.CSSObject = {
  fontFamily: 'Arial Narrow',
  fontSize: '11pt',
};

const grey = muiColors.grey;

const makeColourSeries = (colour: Record<string | number, string>, start: number, step: number, count: number) => {
  const colours: any[] = [];
  for (let i = 0; i < count; i++) {
    colours.push(colour[start - i * step]);
  }
  return colours;
};

const makeDarkerColors = (color: string, step: number, count: number) => {
  return [...Array(count).keys()]
    .map(i => {
      console.log(darken(color, step * (i + 1)));
      return darken(color, step * (i + 1));
    })
    .concat([color]);
};
// const colors = [muiColors.orange['A700'], muiColors.orange['A400'], muiColors.orange['A200'], ...makeColourSeries(muiColors.orange, 900, 100, 6)];

export const globalOptions: Highcharts.Options = {
  // colors,
  time: {
    useUTC: false,
    timezone: 'Pacific/Auckland',
  },
  navigation: {
    buttonOptions: {
      enabled: true,
    },
    menuStyle: {
      background: grey[200],
      color: theme.palette.getContrastText(grey[200]),
    },
  },
  colors: [theme.palette.chartBase, ...makeDarkerColors(theme.palette.chartBase, 0.2, 5)],
  chart: {
    backgroundColor: theme.palette.chartBackground,
    borderColor: theme.palette.border,
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius,
    style: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize.toString(),
      color: theme.palette.getContrastText(theme.palette.chartBackground),
    },
    panning: {
      enabled: true,
      type: 'x',
    },
  },
  loading: {
    hideDuration: 300,
    showDuration: 300,
    style: {
      backgroundColor: theme.palette.chartBackground,
    },
  },

  credits: {
    enabled: false,
  },

  xAxis: {
    crosshair: false,
    ...theme.palette.commonAxisOptions,
  },
  yAxis: {
    gridLineColor: lighten(theme.palette.getContrastText(theme.palette.chartBackground), 0.2),
    gridLineWidth: 1,
    crosshair: false,
    ...theme.palette.commonAxisOptions,
  },

  plotOptions: {
    line: {
      lineWidth: 1,
    },
    column: {
      pointPadding: 1,
    },
    series: {
      turboThreshold: 10000,
      connectNulls: false,
      animation: false,
      marker: {
        enabled: false,
      },
    },
  },
  title: {
    align: 'left',

    style: {
      color: theme.palette.getContrastText(theme.palette.chartBackground),
      fontFamily: theme.typography.h5.fontFamily,
      fontSize: (theme.typography.h5.fontSize || '14pt').toString(),
    },
  },

  legend: {
    enabled: true,
    itemStyle: theme.palette.chartLegendStyle,
  },
};

Highcharts.setOptions(globalOptions);
