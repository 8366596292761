import React from 'react';
const html = `<table
  align="center"
  width="100%"
  cellpadding="0"
  cellspacing="0"
  bgcolor="f3f3f3"
  style="max-width: 600px !important; width: 100% !important; margin-top: 16px; color: #000000; font-size: 15px; background: #dcdcdc; font-style: normal; font-family: Arial; font-weight: normal"
>
  <tbody>
    <tr>
      <td>
        <table
          width="600"
          align="center"
          cellpadding="0"
          cellspacing="0"
          bgcolor="#ececec"
          style="
            font-size: 13px;
            line-height: 1.6;
            font-weight: bold;
            color: #686868;
            text-decoration: none;
            border-bottom: none;
            max-width: 600px !important;
            width: 100% !important;
            color: #ffffff;
            font-size: 15px;
            font-style: normal;
            font-family: Arial;
            font-weight: bold;
            line-height: 1.2;
            letter-spacing: 0.16px;
            background-color: #f45e10;

            height: 48px;
          "
        >
          <tbody>
            <tr>
              <td style="padding: 10px 16px 10px; width: 450px">
                <a name="sp-NZ%20Issuances" id="sp-NZ%20Issuances" href=""> </a>
                <span name="section-2,603,890" style="letter-spacing: 1.2px"> NZ Issuances </span>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          id="main"
          width="600"
          align="center"
          cellpadding="0"
          cellspacing="0"
          style="
            border-top: none;
            color: #333;
            max-width: 600px !important;
            width: 100% !important;
            color: #101c2d;
            font-size: 13px;
            font-style: normal;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-weight: normal;
            background-color: #ffffff;
          "
        >
          <tbody>
            <tr>
              <td style="border-bottom: none; margin-bottom: 10px; /*padding-top: 8px;
                padding-bottom:4px;*/">
                <table cellpadding="0" cellspacing="0" width="100%" style="padding: 0px 16px">
                  <tbody>
                    <tr>
                      <td align="left"></td>
                    </tr>
                    <tr>
                      <td>
                        <p
                          style="
                            background: transparent;
                            /* padding-top: 15px; */
                            margin-top: 16px;
                            text-decoration: none;
                            background: transparent;
                            font-size: 12px;
                            letter-spacing: 0;
                            color: #2875a7;
                            font-size: 16px;
                            font-style: normal;
                            font-family: Arial;
                            font-weight: bold;
                            line-height: 18px;
                            background-color: transparent;
                          "
                          name="section-2,603,978"
                        >
                          NZ Mainstream Media
                        </p>
                        <div style="width: 48px; height: 2px; margin-top: 12px; background-color: #f45e10"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156008519"
                                          id="21083156008519"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0haEk2MXfGldn3YE"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Move to more renewables hard but possible: Genesis CEO
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0haEk2MXfGldn3YE"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Businessdesk
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 27 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The electricity system is going through a difficult transition to renewable generation and care will be needed in the treatment of power generated from fossil fuels, says Genesis Energy chief
                                          executive Marc England.Genesis yesterday posted
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0haEk2MXfGldn3YE"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090357534099"
                                          id="21090357534099"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04qEk2MXfGvo3eXQ"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Ara Ake partners with Contact Energy, Meridian Energy, Genesis Energy and international start-up, Wegaw
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04qEk2MXfGvo3eXQ"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Voxy (co.nz)
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 3 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          South Island hydro generators Contact Energy, Meridian Energy and Genesis Energy have agreed to be involved in a collaborative project, with the aim of improving their water management and
                                          forecasting in their South Island snow-fed catchments.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04qEk2MXfGvo3eXQ"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090257066388"
                                          id="21090257066388"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV042Ek2MXfGvm3uHX"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          NZ carbon price hits record $59/tonne after strong Emissions Trading Scheme auction
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV042Ek2MXfGvm3uHX"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzherald
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 2 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          New Zealand carbon prices hit a record $59 a tonne this week after a stronger-than-expected quarterly auction. The price hit a high of $53.85 at the auction on strong demand, and kicked higher in
                                          the secondary market once the auction closed.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV042Ek2MXfGvm3uHX"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090257019051"
                                          id="21090257019051"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04yEk2MXfGvm3hjo"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Major investment accelerates growth of ChargeNet
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04yEk2MXfGvm3hjo"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Scoop
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 2 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          ChargeNet New Zealands leading electric vehicle (EV) charging network, ChargeNet has signed a deal with global energy trading company Mercuria, which has acquired a 20% stake in the kiwi company.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04yEk2MXfGvm3hjo"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090156514072"
                                          id="21090156514072"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04-Ek2MXfGv80LNb"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Maritime Union flags concerns over coastal tanker threat
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04-Ek2MXfGv80LNb"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Scoop
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 1 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The Maritime Union says New Zealand’s energy security is under threat as New Zealand flagged coastal tankers face an uncertain future. Maritime Union of New Zealand National Secretary Craig Harrison
                                          says the proposed closure of the Marsden Point oil refinery leaves big questions as to how fuel will be delivered in future to New Zealand ports.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04-Ek2MXfGv80LNb"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090156314850"
                                          id="21090156314850"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV046Ek2MXfGv837ih"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Refinery Key To Break Monopoly On Fuel Retailing
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV046Ek2MXfGv837ih"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Scoop
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 1 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Refinery Key To Break Monopoly On Fuel RetailingThe potential closure of the Marsden Point oil refinery provides a unique opportunity to break the stranglehold the oil companies have on fuel
                                          retailing.Government should purchase it and
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV046Ek2MXfGv837ih"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156185459"
                                          id="21083156185459"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04GEk2MXfGldkDkw"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Vince Hawksworth: Transition to renewable needs careful management
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04GEk2MXfGldkDkw"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzherald
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 31 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Wholesale electricity prices seem to have almost matched the Covid pandemic for column inches recently. It's true that low lake inflows and constrained gas supply have meant elevated prices.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04GEk2MXfGldkDkw"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156103570"
                                          id="21083156103570"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04CEk2MXfGldnvnR"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          KiwiRail defends decision not to get lowest-emission ferries for Wellington
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04CEk2MXfGldnvnR"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Stuff New Zealand
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 31 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          KiwiRail is defending its decision not to choose the lowest-emission option when buying its two new Interislander ferries. KiwiRail signed a $551 million contract with Korean shipbuilder Hyundai
                                          Mipo Dockyard in June to build two new mega-ferries, expected to be delivered in 2025/26.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04CEk2MXfGldnvnR"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156101607"
                                          id="21083156101607"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04OEk2MXfGldnoGk"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Climate conscious investors challenged to scrutinise Ampol offer for Z Energy
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04OEk2MXfGldnoGk"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Newsroom
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 25 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          They are NZ and Australia's two biggest fuel suppliers but, despite their work around biofuels and fast chargers, neither Z Energy nor Ampol has committed to cutting customer emissions. The flares
                                          from the oil refineries they own at Marsden Point and Lytton are just their most visible emissions.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04OEk2MXfGldnoGk"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156099772"
                                          id="21083156099772"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04KEk2MXfGldnor_"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Refinery Petition signatures top 10,000
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04KEk2MXfGldnor_"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Scoop
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 26 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The petition calling on the government to take action to keep the Marsden Point Oil Refinery operating has topped ten thousand signatures. The petition calls on the government to declare the
                                          refinery a strategic asset and keep it operating so it has the capability to refine the fuels needed to keep the essential services operating in a crisis.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04KEk2MXfGldnor_"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156079399"
                                          id="21083156079399"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04WEk2MXfGldnltk"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Meridian Energy accused of making billions in extra profit
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04WEk2MXfGldnltk"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Rnz (co.nz)
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 24 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          A report by the Major Electricity Users' Group (MEUG) showed Meridian produced $3.5 billion in excess of the cost of capital - a claim questioned by the power company. The Economic Profitability
                                          Analysis, carried out by Ireland Wallace and Associates, also showed $1.96 billion (56 percent) of the excess profit was over the past five years.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04WEk2MXfGldnltk"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156077915"
                                          id="21083156077915"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04SEk2MXfGldnmUY"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Genesis profit down more than quarter
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04SEk2MXfGldnmUY"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Rnz (co.nz)
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 26 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The energy generator and retailer said the result followed a challenging year with persistently low hydro inflows and declining gas supply. The company's revenue was up nearly a quarter, but it paid
                                          $33 million more for carbon fuels, and also paid out $53m, following an unfavourable arbitration decision over a gas liability.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04SEk2MXfGldnmUY"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156077157"
                                          id="21083156077157"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04eEk2MXfGldnmIm"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Power profits up for Big Five
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04eEk2MXfGldnmIm"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Rnz (co.nz)
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 27 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The big five power companies have posted positive results for the year, while smaller outfits are turning customers away and struggling to survive. Earlier this week a 15-strong group of New
                                          Zealand's major industries released a report analysing the trend towards 'super profits' at Meridian since 2002, fanning the flame for sector reform. Kathryn speaks with Executive director of the
                                          Major Energy Users Group, John Harbord.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04eEk2MXfGldnmIm"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156007217"
                                          id="21083156007217"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04aEk2MXfGldn3Fy"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Government gives Emissions Trading Scheme a longer rein in 2022
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04aEk2MXfGldn3Fy"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Stuff New Zealand
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 23 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Bills for petrol, diesel and natural gas could all get a little more painful next year – after the Government decided to give our galloping carbon price a slightly longer rein. To encourage
                                          individuals and businesses to stop burning fossil fuels, everyone must pay a price for each tonne of carbon dioxide they emit.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV04aEk2MXfGldn3Fy"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083155994847"
                                          id="21083155994847"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07mEk2MXfGldnyCc"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Meridian to commission NZ’s biggest battery to avert more black-outs
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07mEk2MXfGldnyCc"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Newsroom
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 26 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The renewable power gentailer will seek bids from Tesla and others to build a 100MW battery in the lower North Island, 50 times bigger than New Zealand has seen before When network operator Vector
                                          installed its first Tesla batteries at a substation in the Auckland suburb of Glen Innes, the company faced push-back from the community, other power companies, and regulators.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07mEk2MXfGldnyCc"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083155992689"
                                          id="21083155992689"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07iEk2MXfGldnygy"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Contact Energy to supply ‘flexible’ renewable electricity
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07iEk2MXfGldnygy"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Scoop
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 31 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Contact Energy (CEN) and its 100%-owned energy solutions business Simply Energy will supply renewable electricity for a datacentre being developed near the Clyde Dam by UK-based digital
                                          infrastructure company Lake Parime. The agreement signed with Lake Parime (lakeparime.com) will see Contact supply 10MW of renewable electricity to operate the planned low-emissions data centre.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07iEk2MXfGldnygy"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055807310"
                                          id="21083055807310"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07uEk2MXfGlblGMN"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Genesis Energy charts cleaner future, after coal use more than doubles to 1.5m tonnes
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07uEk2MXfGlblGMN"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzherald
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 26 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Genesis Energy is forecasting a sharp drop in how much coal it will burn to produce electricity in the coming years, after low hydro lakes and gas shortages saw it burn around 1.5 million tonnes in
                                          the year to June 30.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07uEk2MXfGlblGMN"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055777245"
                                          id="21083055777245"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07qEk2MXfGlbld2e"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Coal mines remained open through lockdown
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07qEk2MXfGlbld2e"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Rnz (co.nz)
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 30 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          A spokesperson from the Ministry of Business, Innovation and Employment (MBIE) said all 17 of New Zealand's coal mines that were operating prior to the alert level 4 announcement have been able to
                                          continue operating as key utilities producing solid fuel.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07qEk2MXfGlbld2e"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055721165"
                                          id="21083055721165"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV072Ek2MXfGlblTKO"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Meridian says Government needs to be more accepting of some gas generation
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV072Ek2MXfGlblTKO"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Stuff New Zealand
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 25 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Meridian Energy chief executive Neal Barclay has cast doubt on the Government’s “aspirational target” of achieving 100 per cent renewable generation by 2030, after reporting a jump in the company’s
                                          net profit. Barclay also continued to take aim at big power users in the wake of the Major Electricity User Group (MEUG) releasing a study on Tuesday that argued Meridian had made $3.5 billion in
                                          excess profits over the past 20 years.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV072Ek2MXfGlblTKO"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055714328"
                                          id="21083055714328"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07yEk2MXfGlbkshb"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Amid calls for electricity market reform, industry group says Meridian is generating 'super profits'
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07yEk2MXfGlbkshb"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzherald
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 24 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          A body representing a group of New Zealand's major industries has released a report pointing to what it claims is a trend of escalating super profits at Meridian Energy, the electricity generation
                                          giant half owned by the Government.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07yEk2MXfGlbkshb"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055627537"
                                          id="21083055627537"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07-Ek2MXfGlbk6VS"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Work on Harapaki Wind Farm, north of Napier, set to spin to full speed in spring
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07-Ek2MXfGlbk6VS"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzherald
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 30 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Progress on New Zealand's second-largest wind farm, north of Napier, is set to intensify in spring if Covid-19 alert restrictions allow it. Meridian Energy announced plans to begin construction on
                                          the $395 million Harapaki Wind Farm project earlier this year, with work starting in late June.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07-Ek2MXfGlbk6VS"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21082955447576"
                                          id="21082955447576"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV076Ek2MXfGlRiMVb"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Rod Oram: Z Energy needs to refuel its ambition
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV076Ek2MXfGlRiMVb"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Newsroom
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 29 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Z Energy promised so much but has delivered so little in playing a part in the clean energy transformation NZ needs, writes Rod Oram This is the global climate crisis, as described in the UN’s
                                          latest report: This is the response by Z Energy, our largest seller of fossil fuels.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV076Ek2MXfGlRiMVb"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055597901"
                                          id="21083055597901"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07GEk2MXfGlbkxEO"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Ampol should commit to funding low-carbon energy infrastructure, if it's to be allowed Z Energy
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07GEk2MXfGlbkxEO"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Stuff New Zealand
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 29 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Australian fuel retailer Ampol wants to buy Z Energy, with its chain of petrol stations serving the public. To get it, it will almost certainly have to sell its Gull petrol stations. OPINION:
                                          Australian listed fuel retailer Ampol has announced its intention to spend close to $2 billion buying Z Energy, New Zealand’s listed petrol retailer.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07GEk2MXfGlbkxEO"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055604856"
                                          id="21083055604856"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07CEk2MXfGlbk3w7"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          IPO an option for Ampol to shed Gull in its bid for Z Energy, but would KiwiSaver funds want another fossil fuel stock?
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07CEk2MXfGlbk3w7"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Stuff New Zealand
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 29 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Where Gull opens a petrol station, fuel prices drop for motorists at other nearby fuel stops. It's called ‘The Gull Effect’. An IPO on the NZX sharemarket is among the options should Ampol be forced
                                          to sell petrol retailer Gull in order to get permission to take over Z Energy.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07CEk2MXfGlbk3w7"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055698820"
                                          id="21083055698820"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07OEk2MXfGlbkovH"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Marsden Pt Refinery moves step closer to being import only terminal
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07OEk2MXfGlbkovH"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzherald
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 30 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Marsden Pt Oil Refinery has moved a step closer to being an import only terminal after Refining NZ got the green light from the third, and final, customer.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07OEk2MXfGlbkovH"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055667819"
                                          id="21083055667819"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07KEk2MXfGlbkgIo"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Two new board members appointed to Orion
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07KEk2MXfGlbkgIo"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Voxy (co.nz)
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 30 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Christchurch City Council Holdings Limited and Selwyn District Council have appointed two new board members to local electricity distribution company, Orion New Zealand Limited. New director Jen
                                          Crawford is a professional director with a background in resource management and infrastructure projects.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV07KEk2MXfGlbkgIo"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table cellpadding="0" cellspacing="5" width="100%">
                  <tbody>
                    <tr>
                      <td style="border-bottom: 1px solid #efefef"></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td style="border-bottom: none; margin-bottom: 10px; /*padding-top: 8px;
                padding-bottom:4px;*/">
                <table cellpadding="0" cellspacing="0" width="100%" style="padding: 0px 16px">
                  <tbody>
                    <tr>
                      <td align="left"></td>
                    </tr>
                    <tr>
                      <td>
                        <p
                          style="
                            background: transparent;
                            /* padding-top: 15px; */
                            margin-top: 16px;
                            text-decoration: none;
                            background: transparent;
                            font-size: 12px;
                            letter-spacing: 0;
                            color: #2875a7;
                            font-size: 16px;
                            font-style: normal;
                            font-family: Arial;
                            font-weight: bold;
                            line-height: 18px;
                            background-color: transparent;
                          "
                          name="section-2,603,980"
                        >
                          NZ Specialist Media
                        </p>
                        <div style="width: 48px; height: 2px; margin-top: 12px; background-color: #f45e10"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090257028742"
                                          id="21090257028742"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06iEk2MXfGvm3n7F"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Gas explorers warn electricity supply at risk
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06iEk2MXfGvm3n7F"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nbr (co.nz)
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 2 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The country’s biggest energy explorers are warning of electricity shortages if the government goes ahead with legislation governing the decommissioning of oil and gas fields. Appearing before a
                                          Parliamentary select
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06iEk2MXfGvm3n7F"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table cellpadding="0" cellspacing="5" width="100%">
                  <tbody>
                    <tr>
                      <td style="border-bottom: 1px solid #efefef"></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td style="border-bottom: none; margin-bottom: 10px; /*padding-top: 8px;
                padding-bottom:4px;*/">
                <table cellpadding="0" cellspacing="0" width="100%" style="padding: 0px 16px">
                  <tbody>
                    <tr>
                      <td align="left"></td>
                    </tr>
                    <tr>
                      <td>
                        <p
                          style="
                            background: transparent;
                            /* padding-top: 15px; */
                            margin-top: 16px;
                            text-decoration: none;
                            background: transparent;
                            font-size: 12px;
                            letter-spacing: 0;
                            color: #2875a7;
                            font-size: 16px;
                            font-style: normal;
                            font-family: Arial;
                            font-weight: bold;
                            line-height: 18px;
                            background-color: transparent;
                          "
                          name="section-2,603,987"
                        >
                          NZ Government
                        </p>
                        <div style="width: 48px; height: 2px; margin-top: 12px; background-color: #f45e10"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156069026"
                                          id="21083156069026"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gmEk2MXfGldngLh"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Commission announces enhanced information disclosure requirements for Aurora Energy
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gmEk2MXfGldngLh"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Comcom
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 31 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Associate Commissioner John Crawford said that when the Commission agreed in March to a CPP allowing Aurora to recover up to $563.4 million from its consumers over five years to repair, upgrade,
                                          maintain and operate its network, it was clear that enhanced ID requirements would be an important part of the package.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gmEk2MXfGldngLh"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090357603137"
                                          id="21090357603137"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06-Ek2MXfGvo3NMC"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Investigation of an alleged breach by Transpower New Zealand Limited as the grid owner
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06-Ek2MXfGvo3NMC"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Ea (govt.nz)
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 3 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The Authority has commenced an investigation into an alleged breach of the Code by Transpower New Zealand Limited as the grid owner. On 17 February 2021, the grid owner notified the Authority that
                                          it had breached clause 4 of Technical Code A, Schedule 8.3.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06-Ek2MXfGvo3NMC"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156066405"
                                          id="21083156066405"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV066Ek2MXfGldnggm"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Updating regulatory settings for distribution networks
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV066Ek2MXfGldnggm"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Ea (govt.nz)
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 31 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The Authority is looking at potential issues on distribution networks and a range of potential regulatory options that may be needed to the regulation of distribution networks to support New
                                          Zealand’s transition to a low-emissions economy and ensure long-term benefits to consumers.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV066Ek2MXfGldnggm"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156065840"
                                          id="21083156065840"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06GEk2MXfGldnhZz"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Section 90 exemptions from Part 3 of the Act
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06GEk2MXfGldnhZz"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Ea (govt.nz)
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 31 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Under Part 3 of the Act, the Authority has the ability to grant exemptions from complying with the legislation which prohibits the involvement in both an electricity lines business and an
                                          electricity supply business.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06GEk2MXfGldnhZz"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156064969"
                                          id="21083156064969"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06CEk2MXfGldnhKK"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Internal transfer pricing and segmented profitability reporting decision
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06CEk2MXfGldnhKK"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Ea (govt.nz)
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 31 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The Authority is mandating the annual disclosure of mass market internal transfer prices by integrated generator retailers, the methodology used to derive them and the disclosure of retail gross
                                          margin reports by retailers. This decision addresses the Electricity Price Review recommendation for the Authority to enhance reporting on retail and generation profitability.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06CEk2MXfGldnhKK"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156063099"
                                          id="21083156063099"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06OEk2MXfGldnhs4"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Update on wholesale market competition review
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06OEk2MXfGldnhs4"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Ea (govt.nz)
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 31 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The Electricity Authority is currently working through its review into competition in the wholesale market for the period 2018 to early 2021, including analysis of spot and forward prices. The
                                          Authority notes MEUG’s Economic Profit Analysis of Meridian Energy’s last 20 financial years released last week and their comment that their analysis would assist the Authority with its wholesale
                                          market review.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06OEk2MXfGldnhs4"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156062568"
                                          id="21083156062568"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06KEk2MXfGldnhkr"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Electricity Authority Review of 9 August 2021 event under the Electricity Industry Act 2010
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06KEk2MXfGldnhkr"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Ea (govt.nz)
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 31 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The Authority is focused on the performance of the electricity market and ensuring security of supply is maintained during the transition to the Government’s target of 100 percent renewables, and
                                          for the future. The Authority has initiated a future security and resilience project.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06KEk2MXfGldnhkr"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055716476"
                                          id="21083055716476"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06WEk2MXfGlbksA_"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Impact of Covid-19 highlighted in Energy in New Zealand 2021
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06WEk2MXfGlbksA_"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Mbie
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 25 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The impact on the energy sector of restrictions to curb the spread of Covid-19 in New Zealand are evident in the latest annual energy report from the Ministry of Business, Innovation and Employment.
                                          Energy in New Zealand 2021, released today, is MBIE’s annual round-up of the energy sector, highlighting key trends in energy supply, transformation, demand and price for the 2020 calendar year.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV06WEk2MXfGlbksA_"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table cellpadding="0" cellspacing="5" width="100%">
                  <tbody>
                    <tr>
                      <td style="border-bottom: 1px solid #efefef"></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td style="border-bottom: none; margin-bottom: 10px; /*padding-top: 8px;
                padding-bottom:4px;*/">
                <table cellpadding="0" cellspacing="0" width="100%" style="padding: 0px 16px">
                  <tbody>
                    <tr>
                      <td align="left"></td>
                    </tr>
                    <tr>
                      <td>
                        <p
                          style="
                            background: transparent;
                            /* padding-top: 15px; */
                            margin-top: 16px;
                            text-decoration: none;
                            background: transparent;
                            font-size: 12px;
                            letter-spacing: 0;
                            color: #2875a7;
                            font-size: 16px;
                            font-style: normal;
                            font-family: Arial;
                            font-weight: bold;
                            line-height: 18px;
                            background-color: transparent;
                          "
                          name="section-2,603,988"
                        >
                          NZ Company &amp; Agency Announcements
                        </p>
                        <div style="width: 48px; height: 2px; margin-top: 12px; background-color: #f45e10"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156017153"
                                          id="21083156017153"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV09mEk2MXfGldn0hC"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Appointment of Non-Executive Director to Meridian Energy Board
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV09mEk2MXfGldn0hC"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Meridian Energy
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 25 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Further to Meridian’s announcement on 19 July 2021, the Electricity Authority has granted an exemption under the Electricity Industry Act 2010 in relation to the appointment of Tania Simpson as a
                                          non-executive director to the Meridian Board.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV09mEk2MXfGldn0hC"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156016563"
                                          id="21083156016563"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV09iEk2MXfGldn1Xw"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Dividend Reinvestment Plan Announced
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV09iEk2MXfGldn1Xw"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Meridian Energy
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 25 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Meridian is pleased to advise that the Dividend Reinvestment Plan Offer Document, Participation Notice and instructions for participating in the Dividend Reinvestment Plan have been sent to
                                          shareholders today. The Dividend Reinvestment Plan will apply with respect to the FY21 final ordinary dividend.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV09iEk2MXfGldn1Xw"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055712563"
                                          id="21083055712563"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV09uEk2MXfGlbktFw"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          New Zealand Oil &amp; Gas Annual Result For Year To 30 June 2021
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV09uEk2MXfGlbktFw"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzog
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 25 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          New Zealand Oil &amp; Gas announced a net loss in line with expectations following steady production performance during the past year, increased exploration expenses associated with drilling
                                          offshore Western Australia, and foreign exchange impacts. Revenue and NPAT are estimated to substantially increase in the current financial year as a result of the acquisition of a share of three
                                          producing assets in the Amadeus Basin in Australia's Northern Territory, and new production from Indonesia.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV09uEk2MXfGlbktFw"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055711459"
                                          id="21083055711459"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV09qEk2MXfGlbktyg"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Hamon cooling tower selected for the Tauhara geothermal power project in New Zealand
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV09qEk2MXfGlbktyg"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Hamon
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 24 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The Hamon Group has been selected by Fuji Electric Co., Ltd. to take part in the Tauhara Geothermal Power Project with an FRP induced draft cooling tower. Tauhara, located near Lake Taupo, in the
                                          center of New Zealand’s North Island, about 280 kilometers south of Auckland, will help to meet New Zealand’s increased electricity needs over the next decades.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV09qEk2MXfGlbktyg"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table cellpadding="0" cellspacing="5" width="100%">
                  <tbody>
                    <tr>
                      <td style="border-bottom: 1px solid #efefef"></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td style="border-bottom: none; margin-bottom: 10px; /*padding-top: 8px; padding-bottom:4px;*/">
                <table cellpadding="0" cellspacing="0" width="100%" style="padding: 0px 16px">
                  <tbody>
                    <tr>
                      <td align="left"></td>
                    </tr>
                    <tr>
                      <td>
                        <p
                          style="
                            background: transparent;
                            /* padding-top: 15px; */
                            margin-top: 16px;
                            text-decoration: none;
                            background: transparent;
                            font-size: 12px;
                            letter-spacing: 0;
                            color: #2875a7;
                            font-size: 16px;
                            font-style: normal;
                            font-family: Arial;
                            font-weight: bold;
                            line-height: 18px;
                            background-color: transparent;
                          "
                          name="section-2,603,990"
                        >
                          NZX (Stock Exchange)Company Announcements
                        </p>
                        <div style="width: 48px; height: 2px; margin-top: 12px; background-color: #f45e10"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090357596269"
                                          id="21090357596269"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CqEk2MXfGvo3Ogu"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Vector Limited: D&amp;O INITIAL DISLCOSURE - ANNE URLWIN
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CqEk2MXfGvo3Ogu"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzx
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 3 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Vector announced its D&amp;O INITIAL DISLCOSURE - ANNE URLWIN.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CqEk2MXfGvo3Ogu"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090357594204"
                                          id="21090357594204"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0C2Ek2MXfGvo3PAf"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Contact Energy: D&amp;O Ongoing Disclosures
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0C2Ek2MXfGvo3PAf"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzx
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 3 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Contact Energy announced its D&amp;O Ongoing Disclosures.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0C2Ek2MXfGvo3PAf"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090257066371"
                                          id="21090257066371"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CyEk2MXfGvm3uHA"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Contact Energy: DRP strike price and updated ASX Appendix 3A.1
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CyEk2MXfGvm3uHA"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzx
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 2 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The price at which shares will be allotted under Contact’s Dividend Reinvestment Plan (DRP) for the 2021 full year dividend is NZ$8.1127. The shares will be issued on 15 September 2021. The price
                                          has been determined, in accordance with the DRP rules, as the volume weighted average sale price for a share calculated on all sales of shares which took place through the NZX main board market on
                                          the five trading days commencing on 26 August 2021.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CyEk2MXfGvm3uHA"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090257066365"
                                          id="21090257066365"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0C-Ek2MXfGvm3uE-"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Genesis Energy D&amp;O Ongoing Disclosure Notice
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0C-Ek2MXfGvm3uE-"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzx
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 2 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Genesis Energy D&amp;O Ongoing Disclosure Notice.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0C-Ek2MXfGvm3uE-"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090257066363"
                                          id="21090257066363"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0C6Ek2MXfGvm3uE4"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Genesis Energy: Capital Change Notice
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0C6Ek2MXfGvm3uE4"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzx
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 2 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Genesis Energy Limited provides the attached capital change notice pursuant to NZX listing rule 3.13.1. This notice relates to the issue of performance share rights in respect of 504,608 ordinary
                                          shares to senior executives. The performance share rights are being issued pursuant to the Genesis Performance Share Rights Plan.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0C6Ek2MXfGvm3uE4"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156117118"
                                          id="21083156117118"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CGEk2MXfGldns49"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Vector: NOTICE OF MEETING 2021
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CGEk2MXfGldns49"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzx
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 31 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Notice of Meeting and Proxy Form for Vector's 2021 Annual Meeting.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CGEk2MXfGldns49"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156112468"
                                          id="21083156112468"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CCEk2MXfGldntwX"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Chief Financial Officer Departure Date
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CCEk2MXfGldntwX"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzx
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 27 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Genesis Energy confirms that Chief Financial Officer, Chris Jewell’s, final day at the company is 29 October 2021. An internal and external search for a new CFO is currently underway.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CCEk2MXfGldntwX"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156110881"
                                          id="21083156110881"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0COEk2MXfGldnuZi"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          2021 ASM and closing date for director nominations
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0COEk2MXfGldnuZi"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzx
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 31 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Genesis Energy Limited (GNE) announces that the Company’s Annual Shareholder Meeting (ASM) will be held on Friday, 29 October 2021, commencing at 10.00am. Genesis continues to closely monitor the
                                          COVID-19 situation in New Zealand. If the Board determines a physical meeting is inappropriate in the circumstances, Genesis may elect to hold the ASM as a virtual only meeting.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0COEk2MXfGldnuZi"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156021170"
                                          id="21083156021170"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CKEk2MXfGldn0fx"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Director changes at Refining NZ
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CKEk2MXfGldn0fx"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzx
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 27 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Refining NZ announces the appointment by the Board of John Bourke as a Director of the Company, effective 10 September 2021. John is currently Operations Manager at ExxonMobil’s Altona Refinery in
                                          Victoria, Australia. John has over 15 years’ experience in Oil and Gas, across two upstream sites and three refineries.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CKEk2MXfGldn0fx"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083156012656"
                                          id="21083156012656"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CWEk2MXfGldn2Yz"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          2021 Annual Shareholder Meeting Director Nominations
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CWEk2MXfGldn2Yz"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Nzx
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 30 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Contact Energy Limited advises that its Annual Shareholder Meeting will be held on Wednesday, 10 November 2021. Further details about the meeting will be included in the Notice of Meeting to be sent
                                          to shareholders in October 2021.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0CWEk2MXfGldn2Yz"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table width="600" align="center" cellpadding="0" cellspacing="0" height="15" style="height: 15px">
          <tbody>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>

        <table
          width="600"
          align="center"
          cellpadding="0"
          cellspacing="0"
          bgcolor="#ececec"
          style="
            font-size: 13px;
            line-height: 1.6;
            font-weight: bold;
            color: #686868;
            text-decoration: none;
            border-bottom: none;
            max-width: 600px !important;
            width: 100% !important;
            color: #ffffff;
            font-size: 15px;
            font-style: normal;
            font-family: Arial;
            font-weight: bold;
            line-height: 1.2;
            letter-spacing: 0.16px;
            background-color: #f45e10;

            height: 48px;
          "
        >
          <tbody>
            <tr>
              <td style="padding: 10px 16px 10px; width: 450px">
                <a name="sp-Offshore%20Issuances" id="sp-Offshore%20Issuances" href=""> </a>
                <span name="section-2,603,991" style="letter-spacing: 1.2px"> Offshore Issuances </span>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          id="main"
          width="600"
          align="center"
          cellpadding="0"
          cellspacing="0"
          style="
            border-top: none;
            color: #333;
            max-width: 600px !important;
            width: 100% !important;
            color: #101c2d;
            font-size: 13px;
            font-style: normal;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-weight: normal;
            background-color: #ffffff;
          "
        >
          <tbody>
            <tr>
              <td style="border-bottom: none; margin-bottom: 10px; /*padding-top: 8px; padding-bottom:4px;*/">
                <table cellpadding="0" cellspacing="0" width="100%" style="padding: 0px 16px">
                  <tbody>
                    <tr>
                      <td align="left"></td>
                    </tr>
                    <tr>
                      <td>
                        <p
                          style="
                            background: transparent;
                            /* padding-top: 15px; */
                            margin-top: 16px;
                            text-decoration: none;
                            background: transparent;
                            font-size: 12px;
                            letter-spacing: 0;
                            color: #2875a7;
                            font-size: 16px;
                            font-style: normal;
                            font-family: Arial;
                            font-weight: bold;
                            line-height: 18px;
                            background-color: transparent;
                          "
                          name="section-2,603,992"
                        >
                          Media
                        </p>
                        <div style="width: 48px; height: 2px; margin-top: 12px; background-color: #f45e10"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090357369955"
                                          id="21090357369955"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0giEk2MXfGvo22Qg"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Column: Integrated electricity systems must be more resilient: Kemp
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0giEk2MXfGvo22Qg"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Reuters
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 2 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Prolonged blackouts in Louisiana following Hurricane Ida are a reminder the power grid needs to become more resilient as well as reliable if even more services such as electric vehicles are going to
                                          depend on it in future.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0giEk2MXfGvo22Qg"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090257258600"
                                          id="21090257258600"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0guEk2MXfGvm0_Ar"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          English solar developers prep for New Zealand
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0guEk2MXfGvm0_Ar"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            PV Magazine
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 2 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          The charms of New Zealand, it appears, have become attractive to solar developers as well as end-of-the-world-fleeing Silicon Valley billionaires. Three English solar developers today announced the
                                          formation of a joint venture to develop a 350 MW utility scale solar pipeline in New Zealand, having been attracted by the clear environmental commitments made by Jacinda Ardern‘s government; a lack
                                          of the grid congestion which is hobbling PV in neighboring Australia; and high levels of solar irradiation – compared to England, anyway.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0guEk2MXfGvm0_Ar"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090257118222"
                                          id="21090257118222"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gqEk2MXfGvm0ZxN"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Welcome to the Age of Wireless Electricity
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gqEk2MXfGvm0ZxN"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Interesting Engineering
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 2 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Wireless electricity is a 100-year-old dream that just might turn into reality in the coming years. The advent of wireless charging, electric vehicles, 5G, and the need for greater sustainability
                                          have led to a push for the development of fully operational wireless transmission technology in different parts of the world.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gqEk2MXfGvm0ZxN"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090257116793"
                                          id="21090257116793"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0g2Ek2MXfGvm0aY6"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Energy traders see big bucks in the carbon emission market
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0g2Ek2MXfGvm0aY6"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Texas News Today
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 1 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          A major energy trading company that has long focused on deep and volatile markets such as oil and natural gas, is now trading carbon as governments around the world drive to expand the market for
                                          carbon emissions trading.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0g2Ek2MXfGvm0aY6"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090257111177"
                                          id="21090257111177"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gyEk2MXfGvm0bDK"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Hive Energy team targets 350MW PV in New Zealand
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gyEk2MXfGvm0bDK"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            reNEWS
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 2 Sep 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Hive Energy, Ethical Power and Solar South West have launched a new joint venture called HES Aotearoa to develop up to 350MW of solar assets in New Zealand. The three UK-based companies said they
                                          have complementary skills, creating a JV that is fully integrated along the whole value chain of solar development – from site selection to project design, construction and operation and
                                          maintenance.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gyEk2MXfGvm0bDK"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090156487326"
                                          id="21090156487326"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0g-Ek2MXfGv80Brd"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Jadestone Energy PLC Announces Update on Maari Acquisition
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0g-Ek2MXfGv80Brd"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Yahoo
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 31 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Jadestone Energy plc, an independent oil and gas production company focused on the Asia Pacific region, provides an update on the planned completion of the acquisition of the 69% operated interest
                                          in the Maari asset, shallow water offshore New Zealand.Further to the Company's announcement on 30 June 2021, both Jadestone and OMV New Zealand Limited continue to work towards completion of the
                                          transaction, including an extension to the current long stop date of 31 August 2021.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0g-Ek2MXfGv80Brd"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21090156472736"
                                          id="21090156472736"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0g6Ek2MXfGv80dHj"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Geothermal in a low carbon future for New Zealand
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0g6Ek2MXfGv80dHj"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Thinkgeoenergy
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 31 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          In a recently held workshop by the New Zealand Geothermal Association (NZGA), the role of the geothermal industry in reducing national and global carbon emissions was highlighted. The annual winter
                                          seminar was held on July 29 and had almost 150 registered participants consisting of both newcomers and seasoned experts of the geothermal industry.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0g6Ek2MXfGv80dHj"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083155996357"
                                          id="21083155996357"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gGEk2MXfGldnyaG"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Meridian confirms possible exit from “highly politicised” Australian energy market
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gGEk2MXfGldnyaG"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Reneweconomy
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 25 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Meridian Energy has confirmed it is still seriously considering an exit from the Australian market, where it says heavy-handed political and regulatory interventions and the lack of a price on
                                          carbon emissions have nobbled renewable energy investment.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gGEk2MXfGldnyaG"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055710349"
                                          id="21083055710349"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gCEk2MXfGlbktjO"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          New Zealand Energy Corp Announces 2021 Quarter 2 Results
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gCEk2MXfGlbktjO"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            QuoteMedia
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 25 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          New Zealand Energy Corp. announced it has filed with Canadian regulatory authorities its Q2 2021 consolidated financial results and management discussion and analysis report, which documents are
                                          available on the Company's website at www.newzealandenergy.com and on SEDAR at www.sedar.com.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gCEk2MXfGlbktjO"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21083055708500"
                                          id="21083055708500"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gOEk2MXfGlbkuEX"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Meridian Energy Earnings Hit by Low New Zealand Rainfall
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gOEk2MXfGlbkuEX"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Morningstar
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 25 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Meridian Energy Ltd. said its full-year earnings fell as drought in New Zealand reduced power generation from its hydro lakes. The company on Wednesday said net profit for the financial year ended
                                          June 30 fell 27% from a year earlier to 232 million New Zealand dollars ($161 million) on an underlying basis.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gOEk2MXfGlbkuEX"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table cellpadding="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style="border-bottom: 1px solid #efefef"></td>
                            </tr>
                          </tbody>
                        </table>

                        <table cellpadding="0" cellspacing="3" width="100%" style="padding-bottom: 0px; padding-top: 0px; padding-left: 0px; padding-right: 0px">
                          <tbody>
                            <tr>
                              <td align="left">
                                <table width="100%" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td style="height: 13px; padding: 0"></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          name="21082855276550"
                                          id="21082855276550"
                                          href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gKEk2MXfGkvgLhF"
                                          style="
                                            text-decoration: none;
                                            line-height: 1.4;
                                            list-style: disc outside none;
                                            color: #252526;
                                            font-size: 17px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: bold;
                                            line-height: 1.3;
                                            background-color: transparent;
                                          "
                                          target="_blank"
                                        >
                                          Ara Ake and Singapore-based EcoLabs Centre of Innovation for Energy partner to facilitate the deployment of energy innovation demonstration ‘testbeds’ in Aotearoa
                                        </a>

                                        <p
                                          style="
                                            color: #999999;
                                            font-size: 11px;
                                            display: block;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            line-height: 1.4;
                                            clear: both;
                                            color: #f45e10;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.3;
                                            letter-spacing: 0.32px;
                                            background-color: transparent;
                                          "
                                        >
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gKEk2MXfGkvgLhF"
                                            style="
                                              margin-left: 0;
                                              text-decoration: none;
                                              color: #f45e10;
                                              font-size: 12px;
                                              font-style: normal;
                                              font-family: Arial;
                                              font-weight: normal;
                                              line-height: 1.3;
                                              letter-spacing: 0.32px;
                                              background-color: transparent;
                                            "
                                          >
                                            Livenews
                                          </a>
                                          ·
                                          <span style="text-decoration: none; border: none; color: inherit !important"> 27 Aug 2021 </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding: 8px 0 5px 0">
                                        <div
                                          style="
                                            font-size: 14px;
                                            line-height: 1.4;
                                            margin: 0;
                                            text-decoration: none !important;
                                            text-align: justify;
                                            word-break: break-word;
                                            color: #171717;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-family: Arial;
                                            font-weight: normal;
                                            line-height: 1.4;
                                            background-color: transparent;
                                          "
                                        >
                                          Ara Ake is pleased to announce a partnership with EcoLabs Centre of Innovation for Energy at Nanyang Technological University (Ecolabs) to facilitate the establishment of ‘real world’ technology
                                          demonstration sites, also known as ‘testbeds’, for validating innovative low emissions technology at scale in New Zealand.
                                          <a
                                            href="https://www.contify.com/url/n5Puj-NINqxbOKZFUhkZMoWHV0gKEk2MXfGkvgLhF"
                                            target="_blank"
                                            style="
                                              font-size: 12px;
                                              display: inline-block;
                                              /* margin-top: 2px; */
                                              text-decoration: none !important;
                                              font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
                                              color: #0072c3;
                                              font-size: 14px;
                                              line-height: 1.4;
                                            "
                                          >
                                            Read More →
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="height: 8px; padding: 0"></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table width="600" align="center" cellpadding="0" cellspacing="0" height="15" style="height: 15px">
          <tbody>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>

        <table
          width="600"
          align="center"
          cellpadding="0"
          cellspacing="0"
          bgcolor="#ececec"
          style="
            font-size: 13px;
            line-height: 1.6;
            font-weight: bold;
            color: #686868;
            text-decoration: none;
            border-bottom: none;
            max-width: 600px !important;
            width: 100% !important;
            margin-bottom: 16px;
            color: #b0b0b0;
            font-size: 12px;
            font-style: normal;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-weight: normal;
            background-color: #ffffff;
          "
        >
          <tbody>
            <tr>
              <td style="padding: 40px 16px 16px; background: #ffffff; width: 450px; border-top: 2px solid #8a3ffc">
                <div style="display: flex">
                  <div>
                    <p
                      style="
                        font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
                        color: #525252;
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 400;
                        letter-spacing: 0.32px;
                        margin: 0 0 4px;
                      "
                    >
                      Curated By
                    </p>
                    <h4
                      style="
                        margin: 0 0 16px;
                        color: #161616;
                        font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
                        font-weight: normal;
                        font-size: 20px;
                        letter-spacing: 0;
                        line-height: 26px;
                        color: #161616;
                        font-size: 20px;
                        font-style: normal;
                        font-family: Arial;
                        font-weight: normal;
                        line-height: 26px;
                        background-color: transparent;
                      "
                    >
                      Sagar Bhuyan
                    </h4>
                  </div>
                </div>
                <p
                  style="
                    font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
                    color: #525252;
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 0.16px;
                    line-height: 18px;
                    margin: 0 0 8px;
                    color: #525252;
                    font-size: 14px;
                    font-style: normal;
                    font-family: Arial;
                    font-weight: normal;
                    line-height: 18px;
                    background-color: transparent;
                  "
                >
                  Enerlytica
                </p>
                <p style="margin: 0; color: #525252">
                  <a
                    href="mailto:block.analyst_data.email"
                    style="
                      font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
                      color: #0072c3;
                      font-size: 14px;
                      font-weight: normal;
                      letter-spacing: 0.16px;
                      line-height: 18px;
                      color: #0072c3;
                      font-size: 14px;
                      font-style: normal;
                      font-family: Arial;
                      font-weight: normal;
                      line-height: 18px;
                      background-color: transparent;
                    "
                  >
                    sagar.bhuyan@contify.com
                  </a>
                  <a
                    href="tel:block.analyst_data.phone"
                    style="
                      font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
                      color: #0072c3;
                      font-size: 14px;
                      font-weight: normal;
                      letter-spacing: 0.16px;
                      line-height: 18px;
                      color: #0072c3;
                      font-size: 14px;
                      font-style: normal;
                      font-family: Arial;
                      font-weight: normal;
                      line-height: 18px;
                      background-color: transparent;
                    "
                  >
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          id="bottom-message"
          cellpadding="16"
          cellspacing="0"
          width="600"
          align="center"
          style="
            color: #b0b0b0;
            /* margin-top: 23px; */
            margin-bottom: 20px;
            font-size: 12px;
            max-width: 600px !important;
            width: 100% !important;
            color: #b0b0b0;
            font-size: 12px;
            font-style: normal;
            font-family: Arial;
            font-weight: normal;
            background-color: transparent;
          "
        >
          <tbody>
            <tr style="/*background: #ffffff;*/">
              <td height="10" style="color: #999999; font-size: 11px; line-height: 20px; margin-bottom: 10px; padding-bottom: 4px; padding-top: 5px">
                <span style="margin: 0">
                  <img src="http://112233.contify.com/images/favicon.png" alt="Contify Logo" width="20" style="vertical-align: top" />
                </span>
                <span style="margin-top: 10px; vertical-align: bottom; margin-bottom: 8px">
                  <a href="http://www.contify.com/" title="Contify" style="color: #0072c3; font-weight: normal; margin-left: 4px; text-decoration: none; font-size: 16px"> Powered by Contify </a>
                </span>
                <p style="color: #565656; font-size: 14px; letter-spacing: 0.16px; line-height: 1.3; margin-top: 8px; margin-bottom: 16px">
                  Contify is an AI-enabled Market and Competitive Intelligence platform to track unique and strategic information on your competitors, customers, industry segments, and topics of interest.
                </p>
                <div style="height: 2px; width: 48px; background-color: #0072c3"></div>
                <p style="color: #8c8c8c; font-size: 12px; letter-spacing: 0.32px; line-height: 1.4">
                  This email was intended for <a href="mailto:" style="color: #0072c3; text-decoration: none"></a>. You are receiving this email because you are a contact at Athena Information Solutions Private Limited, the parent company
                  of Contify. We do not sell your information to any third parties and make use of cookies on our website to deliver a personalized experience. To learn more, read our
                  <a style="color: #0072c3; text-decoration: none" href="https://www.contify.com/privacy-policy">privacy policy</a>.
                </p>
              </td>
            </tr>
            <tr style="/*background: #fff;*/">
              <td style="padding: 0 16px">
                <p style="font-size: 12px; color: #8c8c8c; margin: 0">
                  To unsubscribe and stop receiving these emails,
                  <a href="https://www.contify.com/subscriber/unsubscribe/?token=c712d4496903a83ee1de46da7c00e399&amp;source=&amp;newsletterId=&amp;newsletterName=" style="text-decoration: none; font-size: 12px; color: #0072c3">
                    click here </a
                  >.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
`;
export const News = () => {
  const Content = React.createElement('div', { style: { width: '100%' }, dangerouslySetInnerHTML: { __html: html } });

  return Content;
};
