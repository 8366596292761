import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import { theme } from '../style/theme';

const styles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateRows: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
    padding: theme.spacing(3),
    background: 'rgb(154, 195, 191)',
    border: 0,
    borderRadius: 0,
    height: '-webkit-fill-available',
    width: '-webkit-fill-available',
  },
  item: {
    display: 'flex',
    gridColumnStart: '2',
    gridColumnEnd: 'span 10',
    gridRowStart: '5',
    gridRowEnd: 'span 2',
  },
  paper: {
    padding: theme.spacing(3),
    width: '-webkit-fill-available',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
});

export function Unauthorised(): JSX.Element {
  const classes = styles();

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <Paper className={classes.paper}>
          <Typography variant={'h5'}>401 : You do not have sufficient permissions to view this page, or there is a problem with your credentials. If this is an error, log out and try again, or contact the system admin.</Typography>
        </Paper>
      </div>
    </div>
  );
}
