import { Control } from 'react-hook-form';
import React from 'react';
import { JSONSchema7Definition } from 'json-schema';
import { SkipBox } from 'src/style/components/styledFlex';
import { StyledSelect, StyledTextfield } from 'src/components/StyledForm';
import { useAppSelector } from 'src/redux/hooks';
import { MetadataStore } from 'src/redux/reducer/metadata';
import { ValueOf } from 'src/types/utility.types';

const makeOptions = (sourceData: ValueOf<MetadataStore> | false, sourceProperty?: string, sourceLabel?: string): [number | string, string][] => {
  if (typeof sourceData === 'boolean') throw new Error('Cannot make select options, source data provided was ' + sourceData);
  return Object.entries(sourceData).map(entry => {
    const key = !sourceProperty ? entry[0] : entry[1][sourceProperty];
    const value = !sourceLabel ? entry[1] : entry[1][sourceLabel];
    if (typeof key !== 'string' && typeof key !== 'number') throw new Error('Select option keys must be either a number or string. Returned ' + typeof key + ' for field ' + sourceLabel);
    if (typeof value !== 'string') throw new Error('Select option labels must be a string. Returned ' + typeof value);
    return [key, value];
  });
};

export const PropertyInput = ({ control, defaultValue, property, definition }: { control: Control; defaultValue?: unknown; property: string; definition: JSONSchema7Definition }): JSX.Element => {
  if (typeof definition === 'boolean') return <div>Boolean passed to input generator</div>;
  const { type, title, source, sourceLink, sourceProperty, sourceLabel } = definition;
  const sourceData = useAppSelector(state => Boolean(source) && sourceLink !== undefined && state.metadata[sourceLink]);
  const options = React.useMemo(() => sourceLink && makeOptions(sourceData, sourceProperty, sourceLabel), [sourceData]);

  //TODO refactor -- not optimal at all
  const InputComponent = React.useCallback(() => {
    // if property has a source, use that to determine input component type
    switch (source) {
      case undefined:
        break;
      case 'enum':
        return <StyledSelect name={property} label={title} control={control} ControllerProps={{ defaultValue }} options={options} />;
      case 'redux': // remove enum/redux disambiguation?
        return <StyledSelect name={property} label={title} control={control} ControllerProps={{ defaultValue }} options={options} />;
    }

    // otherwise use the property type
    switch (type) {
      case 'string':
        return <StyledTextfield name={property} id={property} label={title} variant={'outlined'} control={control} type={'text'} ControllerProps={{ defaultValue }} />;
      case 'number': // number with decimals?
        return <StyledTextfield name={property} id={property} label={title} variant={'outlined'} control={control} type={'decimal'} ControllerProps={{ defaultValue }} />;
      case 'boolean': // checkbox? switch?
        return <>boolean</>;
      case 'integer': // same as number?
        return <StyledTextfield name={property} id={property} label={title} variant={'outlined'} control={control} type={'number'} ControllerProps={{ defaultValue }} />;
      case 'array': // multiple components? multiselect?
        return <>array</>;
      case 'object': // recursive?
        return <>object</>;
      case undefined:
        return <>Undefined data type</>;
      case null:
        return <>Null placeholder</>;
      default:
        return <>Unkown input type</>;
    }
  }, []);

  return (
    <SkipBox flex>
      <InputComponent />
    </SkipBox>
  );
};
