//TODO only merges the first seriesFilter, but does merge a primitive with array filter values

import moment from 'moment';
import { MakeSeriesChartOptions, MakeCoreTimeSeriesSP } from 'src/types/module/chartModule.types';
import { rangeFormat } from '../common';

export const mergeTimeSeries = (name: string, ...series: MakeSeriesChartOptions<MakeCoreTimeSeriesSP>[]): MakeSeriesChartOptions<MakeCoreTimeSeriesSP> => {
  return series.reduce<MakeSeriesChartOptions<MakeCoreTimeSeriesSP>>(
    (prev, curr, i) => {
      if (i > 0) {
        const { dataTable, xAxisColumn, seriesColumns, seriesFilters, tableStatement, range } = curr;
        if (!prev.seriesFilters || !seriesFilters) throw new Error('Merged time series must have a series filter set for each series');
        if (prev.dataTable !== dataTable || prev.xAxisColumn !== xAxisColumn || JSON.stringify(prev.seriesColumns) !== JSON.stringify(seriesColumns) || prev.tableStatement !== tableStatement)
          throw new Error('Time series can only be merged when their table, xAxisColumn, series column, and table statement flag are the same');

        const [currStart, currEnd] = range.map(date => moment(date, rangeFormat.default));
        const [prevStart, prevEnd] = prev.range.map(date => moment(date, rangeFormat.default));
        const newStart = (currStart.diff(prevStart) < 0 ? currStart : prevStart).format(rangeFormat.default);
        const newEnd = (currEnd.diff(prevEnd) > 0 ? currEnd : prevEnd).format(rangeFormat.default);

        const newFilters = Array.isArray(seriesFilters[0][1]) ? [...seriesFilters[0][1]] : [seriesFilters[0][1]];
        const prevFilters = Array.isArray(prev.seriesFilters[0][1]) ? [...prev.seriesFilters[0][1]] : [prev.seriesFilters[0][1]];
        return { ...prev, seriesFilters: [[prev.seriesFilters[0][0], [...prevFilters, ...newFilters]]], range: [newStart, newEnd] };
      }

      return prev;
    },
    { ...series[0], seriesNames: [name], name },
  );
};
