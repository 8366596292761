import React from 'react';
import { FlowTable } from 'src/components/FlowTable';
import { SkipPaper, SkipBox } from 'src/style/components/styledFlex';
import { NexusChart } from 'src/components/Charts/NexusChart';
import { gasFieldOptionsMap } from 'src/components/Charts/components/config/makeTimeSeries';
import { seasonalChartOptionsMap } from 'src/components/Charts/components/config/makeSeasonal';
import { OutageTable } from 'src/components/OutageTable/OutageTable';
import { NexusTable } from 'src/components/NexusTable';
import { NexusTextContent } from 'src/components/Wrapper/NexusTextContent';
import { NavLink } from 'react-router-dom';

const reservesContent = [
  ['1P Reserves', '1,183 PJ'],
  ['2P Reserves', '1,413 PJ'],
  ['3P Reserves', '1,586 PJ'],
  ['2C Reserves', '217 PJ'],
];

const giipContent = [
  ['1P GIIP', '1,783 PJ'],
  ['2P GIIP', '2,062 PJ'],
  ['1P GIIP', '2,283 PJ'],
];

function Pohokura(): JSX.Element {
  return (
    <SkipBox isGrid g={2} rows={'auto'} cols={'repeat(3, 1fr)'} w={'100%'} h={'100%'} style={{ gridAutoFlow: 'dense' }}>
      <SkipPaper flex d={'column'} p={2} style={{ gridColumn: 'span 1' }}>
        <NexusTextContent header={'About'}>
          The Pohokura field is located 4km offshore of north Taranaki. Discovered in 2000 by Fletcher Challenge it was developed from 2004-2006 with 6 offshore and 3 onshore wells with a production station located onshore adjacent to the{' '}
          <NavLink to={'/gas/assets/motonui'}>Motonui</NavLink> methanol plants operated by <NavLink to={'/gas/companies/methanex'}>Methanex</NavLink>. The field is owned by <NavLink to={'/gas/companies/omv'}>OMV</NavLink> (74% and
          operator) and <NavLink to={'/gas/companies/todd'}>Todd Energy</NavLink> (26%).
        </NexusTextContent>
      </SkipPaper>
      <SkipPaper p={2} style={{ gridColumn: 'span 2' }}>
        <OutageTable asset={3} title={`Planned Outages`} />
      </SkipPaper>
      <SkipPaper flex p={2} d={'column'} style={{ gridColumn: 'span 1' }}>
        <NexusTextContent header={'Reserves'}>
          <NexusTable contentRows={reservesContent} columnWidth={'50%'} />
          <NexusTable contentRows={giipContent} columnWidth={'50%'} />
        </NexusTextContent>
      </SkipPaper>
      <SkipPaper p={2} style={{ gridColumn: 'span 2' }}>
        <FlowTable meters={[10, 12]} />
      </SkipPaper>

      <SkipPaper flex d={'row'} p={2} style={{ minWidth: '100%', minHeight: 500, gridColumn: 'span 3' }}>
        <NexusChart settings={[gasFieldOptionsMap.pohokura]} />

        <NexusChart settings={[seasonalChartOptionsMap.pohokura]} />
      </SkipPaper>
    </SkipBox>
  );
}

export { Pohokura };
