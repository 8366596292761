import { MakeCoreTimeSeriesSP, MakeSeriesChartOptions, MakeSeriesOptionsMap, SeriesRange } from 'src/types/module/chartModule.types';
import { createMakeMap, createOptionsArrayMap } from '../../lib/util';
import { genericOatisDaily } from './common';
import { mergeTimeSeries } from './util';

export const generateOatisDaily = (name: string, seriesNames: string[], seriesMeters: number[][], range?: SeriesRange): MakeSeriesChartOptions<MakeCoreTimeSeriesSP> => ({
  ...genericOatisDaily(name),
  name,
  range: range || genericOatisDaily(name).range,
  seriesNames: seriesNames,
  seriesFilters: seriesMeters.map(series => ['meterID', series]),
});

const pohokura: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  name: 'Pohokura',
  seriesNames: ['Pohokura'],
  seriesFilters: [['meterID', [10, 12]]],
  ...genericOatisDaily('Pohokura'),
};

const mm: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisDaily('McKee/Mangahewa'),
  name: 'McKee/Mangahewa',
  seriesNames: ['McKee/Mangahewa'],
  seriesFilters: [['meterID', [2, 21]]],
};

const maui: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisDaily('Maui'),
  name: 'Maui',
  seriesNames: ['Maui'],
  seriesFilters: [['meterID', [1]]],
};

const kupe: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisDaily('Kupe'),
  name: 'Kupe',
  seriesNames: ['Kupe'],
  seriesFilters: [['meterID', [740, 741]]],
};

const kowhai: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisDaily('Kowhai'),
  name: 'Kowhai',
  seriesNames: ['Kowhai'],
  seriesFilters: [['meterID', [14]]],
};

const turangi: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisDaily('Turangi'),
  name: 'Turangi',
  seriesNames: ['Turangi'],
  seriesFilters: [['meterID', [11]]],
};

const greymouth: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisDaily('Greymouth aggregate'),
  name: 'Turangi+Kowhai',
  seriesNames: ['Turangi+Kowhai'],
  seriesFilters: [['meterID', [11, 14]]],
};

const sidewinder: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisDaily('Sidewinder'),
  name: 'Sidewinder',
  seriesNames: ['Sidewinder'],
  seriesFilters: [['meterID', [755, 756]]],
};

const cheal: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...genericOatisDaily('Cheal'),
  name: 'Cheal',
  seriesNames: ['Cheal'],
  seriesFilters: [['meterID', [762, 763]]],
};

export const chealDailyTotal = mergeTimeSeries('Matahio Total', cheal, sidewinder);

export const majorProduction = mergeTimeSeries('Major Production', pohokura, mm, maui, kupe, kowhai, turangi, greymouth, sidewinder, cheal);

export const gasFieldOptionsMap: MakeSeriesOptionsMap<MakeCoreTimeSeriesSP> = {
  pohokura,
  mm,
  maui,
  kupe,
  kowhai,
  turangi,
  greymouth,
  sidewinder,
  cheal,
  majorProduction,
  chealDailyTotal,
};

export const gasFieldMakeMap = createMakeMap(gasFieldOptionsMap);
export const gasFieldOptionsArrayMap = createOptionsArrayMap(gasFieldOptionsMap);
