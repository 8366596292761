/* eslint-disable @typescript-eslint/no-unused-vars */
import { toProperNoun } from '@skipnz/juno';
import moment from 'moment';
import React from 'react';
import { makeRangeProperty } from 'src/components/Charts/components/config/common';
import { gasSectorDemandOptionsArrayMap } from 'src/components/Charts/components/config/makeTimeSeries';
import { NexusChart } from 'src/components/Charts/NexusChart';
import { ChartControlWrapper } from 'src/lab/ChartControlWrapper';
import { ChartRangePicker } from '../../../components/Charts/components/controls/ChartRangePicker';
import { ChartStackingRadio } from '../../../components/Charts/components/controls/ChartStackingRadio';
import { ChartTypeRadio } from '../../../components/Charts/components/controls/ChartTypeRadio';
import { GasFlowPage } from './GasFlowPage';

function Downstream(): JSX.Element {
  const range = React.useMemo(() => makeRangeProperty('toDate', 0, 'year'), []);
  return (
    <GasFlowPage
      controlsComponent={
        <>
          <ChartControlWrapper title={'Chart Type'} left>
            <ChartTypeRadio id={'demand-by-sector'} initialValue={'column'} />
          </ChartControlWrapper>
          <ChartControlWrapper title={'Chart Stacking'} left>
            <ChartStackingRadio id={'demand-by-sector'} initialValue={'normal'} />
          </ChartControlWrapper>
          <ChartControlWrapper title={'Chart Range'} column>
            <ChartRangePicker id={'demand-by-sector'} format={'date'} initialValue={[moment(range[0]), moment(range[1])]} />
          </ChartControlWrapper>
          {/* <ChartControlWrapper title={'Source data'} column> */}
          {/* <ChartSourceRadio id={'demand-by-sector'} format={'date'} initialValue={[moment(range[0]), moment(range[1])]} /> */}
          {/* </ChartControlWrapper> */}
        </>
      }
    >
      <NexusChart id={'demand-by-sector'} settings={gasSectorDemandOptionsArrayMap} options={{ title: { text: 'Demand by sector, year to date' } }} range={range} />
    </GasFlowPage>
  );
}

export { Downstream };
