/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { TreeView } from './TreeBase';

import { TableContainer, Typography, Divider, createStyles, makeStyles } from '@material-ui/core';
import { makeTreeNodes, TreeNode, TreeNodeConfig } from './makeTree';
import { makeTreeConfig } from './makeTreeConfig';

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400,
    },
  }),
);

const componentStyles = makeStyles(() =>
  createStyles({
    divider: {
      marginTop: 3,
      marginBottom: 7,
      height: '1px',
    },
    primaryNode: {
      paddingLeft: 0,
      fontWeight: 'bolder',
    },
    secondaryNode: {
      paddingLeft: 0,
      fontStyle: 'italic',

      // paddingLeft: theme.spacing(1),
    },
    tertiaryNode: {
      paddingLeft: 0,

      // paddingLeft: theme.spacing(2),
    },
    quarternaryNode: {
      paddingLeft: 0,

      // paddingLeft: theme.spacing(3),
    },
    defaultLabelStyle: {},
  }),
);

const makeExpandArray = (count: number): number[] => {
  const arr: number[] = [];
  for (let i = 0; i < count; i++) {
    arr.push(i);
  }
  return arr;
};

const TreeViewer = ({ config }: { config: TreeNodeConfig[] }): JSX.Element => {
  const treeStyles = useStyles();
  const classes = componentStyles();

  const treeConfig = React.useRef(makeTreeConfig(config, classes));

  const [expanded, setExpanded] = React.useState<string[]>(makeExpandArray(treeConfig.current.nodeCount).map(val => val.toString()));
  const [selected, setSelected] = React.useState<string[]>([]);

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  const treeElements = React.useMemo(() => makeTreeNodes(treeConfig.current.treeDefinition), []);
  return (
    <TableContainer style={{ paddingBottom: '0.5rem' }}>
      <Typography variant={'h6'} style={{ textAlign: 'left' }}>
        Datasets
      </Typography>
      <Divider className={classes.divider} />
      <TreeView selected={selected} className={treeStyles.root} expanded={expanded} onNodeSelect={handleSelect}>
        {treeElements}
      </TreeView>
    </TableContainer>
  );
};

// defaultCollapseIcon={<ArrowDropDownIcon />} defaultExpandIcon={<ArrowRightIcon />} onNodeToggle={handleToggle}
export { TreeViewer };
