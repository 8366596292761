import { blue, green, red } from '@material-ui/core/colors';
import { mergeDeep } from 'src/lib/util';
import { CoreTimeSeries, MakeCoreTimeSeriesSP, MakeSeriesChartOptions } from 'src/types/module/chartModule.types';
import { lineChart, plotLinesCommon } from '..';
import { createSeriesMaps, makeRangeProperty } from '../common';

export const pipelineGeneric: Pick<MakeSeriesChartOptions<MakeCoreTimeSeriesSP>, 'range' | '_makeInterface' | 'options'> = {
  range: makeRangeProperty('rolling', 7, 'days'),
  _makeInterface: CoreTimeSeries.TimeSeries,
  options: mergeDeep<Highcharts.Options>({}, lineChart, {
    plotOptions: {
      column: {
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    yAxis: {
      crosshair: {
        color: blue[300],
      },
    },
    xAxis: {
      crosshair: {
        color: blue[300],
      },
    },
  }),
};

const linepack: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...pipelineGeneric,
  name: 'Linepack',
  dataTable: 'mauiPipeline',
  xAxisColumn: 'endHour',
  seriesColumns: ['targetLinepack', 'linepack'],
  seriesNames: ['Target Linepack', 'Linepack'],
  options: mergeDeep<Highcharts.Options>({}, pipelineGeneric.options || {}, {
    title: {
      text: 'Pipeline Linepack',
    },
  }),
};

const pressure: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...pipelineGeneric,
  name: 'Pressure',
  dataTable: 'mauiPressure',
  xAxisColumn: 'endHour',
  seriesColumns: ['bertrandRoad', 'rotowaro'],
  seriesNames: ['Bertrand Road', 'Rotowaro'],
  options: mergeDeep<Highcharts.Options>({}, pipelineGeneric.options || {}, {
    title: {
      text: 'Pipeline Pressure',
    },
    plotOptions: {
      series: {
        label: {
          enabled: false,
        },
      },
    },
    yAxis: {
      min: 40,

      plotLines: [
        {
          value: 48,
          color: red['A400'],
          ...plotLinesCommon,
          label: {
            text: 'High Pressure limit',
            verticalAlign: 'top',
          },
        } as Highcharts.YAxisPlotLinesOptions,

        {
          value: 42,
          color: red['A400'],
          label: {
            text: 'Low Pressure limit',
            verticalAlign: 'bottom',
          },
          ...plotLinesCommon,
        },
      ],
    },
  }),
};

const roi: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...pipelineGeneric,
  dataTable: 'mauiROI',
  xAxisColumn: 'endHour',
  seriesNames: ['Running Operational Imbalance'],
  seriesColumns: ['roi'],
  options: mergeDeep<Highcharts.Options>({}, pipelineGeneric.options || {}, {
    title: { text: 'Running Operational Imbalance' },
    legend: { enabled: false },
    plotOptions: {
      series: {
        label: {
          enabled: false,
        },
      },
    },
  }),

  name: 'ROI',
};

const netPosition: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  ...pipelineGeneric,
  name: 'Net Position',
  dataTable: 'mauiNetPosition',
  xAxisColumn: 'endHour',
  seriesColumns: ['value'],
  seriesNames: ['Net Pipeline Position'],
  options: mergeDeep<Highcharts.Options>({}, pipelineGeneric.options || {}, {
    title: {
      text: 'Net Pipeline Position',
    },
    legend: { enabled: false },
    plotOptions: {
      series: {
        label: {
          enabled: false,
        },
      },
    },
    yAxis: {
      plotLines: [
        {
          value: 10000,
          color: red['A400'],
          ...plotLinesCommon,
        },
        {
          value: 0,
          color: green['A200'],
          ...plotLinesCommon,
        },
        {
          value: -10000,
          color: red['A400'],
          ...plotLinesCommon,
        },
      ],
    },
  }),
};
export const [pipelineOptionsMap, pipelineMakeMap, pipelineOptionsArrayMap] = createSeriesMaps({
  linepack,
  pressure,
  netPosition,
  roi,
});
