import { StoredProcedureRequest, StoredProcedureResponse } from 'src/types/pantheon/pantheon.types';
import { SocketEmitOptions, useSocketEmit } from './useSocketEmit';

export type SPEmitter = <TResponse extends Array<unknown>, TParameters extends unknown = string[]>(query: StoredProcedureRequest<TParameters>, callback?: (response: StoredProcedureResponse<TResponse> | undefined) => void) => void;

export const useSP = (options: SocketEmitOptions = { noSuccess: true, noError: false }): SPEmitter => {
  const [emit] = useSocketEmit('pantheon.sp', options);
  const spEmit = <TResponse extends Array<unknown>, TParameters extends unknown = string[]>(query: StoredProcedureRequest<TParameters>, callback?: (response: StoredProcedureResponse<TResponse> | undefined) => void) =>
    emit<StoredProcedureRequest<TParameters>, StoredProcedureResponse<TResponse>>(query, callback);
  return spEmit;
};
