import { MakeSeriesTypes, MakeSeriesChartOptions } from 'src/types/module/chartModule.types';
import { makeSeriesName } from './makeSeries';
import { stripMakeDiscriminator, stripMakeOptions } from './interfaceDecomposers';

export function not<T extends unknown>(a: T[], b: T[]): T[] {
  return a.filter(value => b.indexOf(value) === -1);
}

export function intersection<T extends unknown>(a: T[], b: T[]): T[] {
  return a.filter(value => b.indexOf(value) !== -1);
}

// used by object editor to get either the request settings from a series, or alternatively return the defaults.
//! THROWS
export const getValuesOrDefaults = (name: string, series: Highcharts.Series | undefined, defaults: MakeSeriesTypes[]): Omit<MakeSeriesTypes, '_makeInterface'> | null => {
  // undefined means it's a series not yet applied to the chart.
  const seriesSettings = series?.nexus.request;
  const strippedDefault = defaults.find(series => makeSeriesName(series) === name);

  if (seriesSettings) {
    const { request } = stripMakeDiscriminator(seriesSettings);
    return request;
  }

  if (strippedDefault) {
    const { request } = stripMakeDiscriminator(strippedDefault);
    return request;
  }
  return null;
};

export const createMakeMap = (items: Record<string, MakeSeriesChartOptions<MakeSeriesTypes>>): MakeSeriesTypes[] => Object.values(items).map(series => stripMakeOptions(series).request);
export const createOptionsArrayMap = (items: Record<string, MakeSeriesChartOptions<MakeSeriesTypes>>): MakeSeriesChartOptions<MakeSeriesTypes>[] => Object.values(items);
