import React from 'react';

import { makeStyles, Theme, createStyles, lighten } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { SkipBox, SkipPaper } from 'src/style/components/styledFlex';
import { IconButton, ListItemSecondaryAction, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { intersection, not } from '../lib/util';
import { ActionButtonGroup } from 'src/components/ActionButtonGroup';
import { ObjectEditor } from '../ObjectEditor';
import * as objectSchema from '../makeSchema';
import * as objectSource from 'src/components/Charts/components/config/makeNetback';
import { SeriesItem } from '.';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
    },
    paper: {
      width: 200,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
      height: 'max-content',
      '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.8),
      },
    },

    editBackdropRoot: {},
  }),
);

export function SeriesSelectionList({
  currentSeries,
  availableSeries,
  onApply,
  onDiscard,
  chartId,
}: {
  currentSeries: SeriesItem[];
  availableSeries: SeriesItem[];
  onApply: (selectedSeries: SeriesItem[], unselectedSeries: SeriesItem[]) => void;
  onDiscard: () => void;
  chartId: string;
}): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const [checked, setChecked] = React.useState<SeriesItem[]>([]);
  const [left, setLeft] = React.useState<SeriesItem[]>(currentSeries);
  const [right, setRight] = React.useState<SeriesItem[]>(availableSeries);
  const [editModalOpen, setEditModal] = React.useState<SeriesItem>();

  const leftChecked = intersection<SeriesItem>(checked, left);
  const rightChecked = intersection<SeriesItem>(checked, right);

  const handleToggle = (value: SeriesItem) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not<SeriesItem>(left, leftChecked));
    setChecked(not<SeriesItem>(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not<SeriesItem>(right, rightChecked));
    setChecked(not<SeriesItem>(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const toggleSeriesEdit = (series?: SeriesItem) => () => setEditModal(series);

  const makeList = (name: string, items: SeriesItem[], edit?: boolean) => (
    <List dense component='div' role='list'>
      <Typography
        variant={'h6'}
        style={{
          padding: theme.spacing(1, 2.5),
        }}
      >
        {name}
      </Typography>
      {items.map((value: SeriesItem) => {
        const labelId = `transfer-list-item-${value.name}-label`;
        return (
          <ListItem key={value.id} role='listitem' button onClick={handleToggle(value)}>
            <ListItemIcon>
              <Checkbox checked={checked.indexOf(value) !== -1} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />
            </ListItemIcon>
            <ListItemText id={labelId} style={{ paddingRight: 16 }} primary={value.name} />
            {edit && (
              <ListItemSecondaryAction>
                <IconButton edge='end' aria-label='comments' onClick={toggleSeriesEdit(value)}>
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      })}
      <ListItem />
    </List>
  );

  const rootRef = React.useRef<HTMLDivElement>(null);

  return (
    <SkipBox ref={rootRef} id={'series-manager'} isGrid h={'100%'} rows={'1fr min-content'} cols={'1fr auto 1fr'} p={3} className={classes.root}>
      <ObjectEditor chartId={chartId} setOpenState={toggleSeriesEdit} open={editModalOpen} objectSchema={objectSchema.makeChartNetbackSeries} objectMap={objectSource.generationNetbackMakeMap} edit />
      <SkipPaper flex ga={'1 / 1'} h={'100%'}>
        {makeList('Current Series', left, true)}
      </SkipPaper>
      <SkipBox h={'100%'} flex ga={'1 / 2'}>
        <SkipBox flex p={2} g={1} style={{ flexDirection: 'column', flexShrink: 1, alignSelf: 'center' }}>
          <Button variant='outlined' size='small' color={'primary'} className={classes.button} onClick={handleAllRight} disabled={left.length === 0} aria-label='move all right'>
            ≫
          </Button>
          <Button variant='outlined' size='small' color={'primary'} className={classes.button} onClick={handleCheckedRight} disabled={leftChecked.length === 0} aria-label='move selected right'>
            &gt;
          </Button>
          <Button variant='outlined' size='small' color={'primary'} className={classes.button} onClick={handleCheckedLeft} disabled={rightChecked.length === 0} aria-label='move selected left'>
            &lt;
          </Button>
          <Button variant='outlined' size='small' color={'primary'} className={classes.button} onClick={handleAllLeft} disabled={right.length === 0} aria-label='move all left'>
            ≪
          </Button>
        </SkipBox>
      </SkipBox>
      <SkipPaper h={'100%'} flex ga={'1 / 3'}>
        {makeList('Available Series', right)}
      </SkipPaper>
      <ActionButtonGroup ga={'2 / span 3'} onApply={() => onApply(left, right)} onDiscard={onDiscard} />
    </SkipBox>
  );
}
