import React from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';

import MoreIcon from '@material-ui/icons/MoreVert';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { revokeAuthentication } from 'src/modules/auth/authenticate';
import { StyledTooltip } from '../styled/StyledTooltip';
import { NexusLogo } from 'src/image';
import { Grid } from '@material-ui/core';
import { NavLinkBar } from './NavLinkBar';
import { useAppSelector } from 'src/redux/hooks';
import { useSocketInstance } from 'src/modules/websocket/context';
import { CoreMessage, CoreAction } from 'src/modules/message';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoDiv: {
      display: 'flex',
      width: '160px',
      placeContent: 'flex-start',
      paddingLeft: theme.spacing(2),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '2rem',
        display: 'block',
      },
    },
    sectionNav: {
      display: 'flex', //none
    },
    username: {
      alignSelf: 'center',
    },

    sectionDesktop: {
      display: 'none',
      marginLeft: 'auto',
      gap: `${theme.spacing(2)}px`,
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'none',
      marginLeft: 'auto',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

const StyledIconButton = withStyles({
  root: {
    width: '2.5rem',
    height: '2.5rem',
    '&:hover': {
      transition: '0.5s ease',
      color: 'rgb(154, 195, 191)',
      backgroundColor: '#484855',
    },
  },
})(IconButton);

function NavBar(): JSX.Element {
  const classes = useStyles();
  const { socket } = useSocketInstance();
  // const { getState } = React.useContext(AuthContext);
  // const isAuthenticated = getState().isAuthenticated;
  // const history = useHistory();

  const { isAuthenticated, user } = useAppSelector(state => state.auth);

  const logout = (): void => {
    revokeAuthentication(() => socket.disconnect());
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [navMenuAnchorEl, setNavMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [renderLogOut] = React.useState<JSX.Element | null>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isNavMenuOpen = Boolean(navMenuAnchorEl);

  const lastmonConnect = () => {
    socket.emit('lastmon.core', new CoreMessage(CoreAction.CONNECT));
  };

  const lastmonDisconnect = () => {
    socket.emit('lastmon.core', new CoreMessage(CoreAction.DISCONNECT));
    socket.off('lastmon.core');
  };

  const handleNavMenuClose = (handlerFunction?: () => void) => (): void => {
    setNavMenuAnchorEl(null);
    handlerFunction && handlerFunction();
  };

  const handleMobileMenuClose = (handlerFunction?: () => void) => (): void => {
    setMobileMoreAnchorEl(null);
    handlerFunction && handlerFunction();
  };

  const handleMenuClose = (handlerFunction?: () => void) => (): void => {
    setAnchorEl(null);
    handleMobileMenuClose(handlerFunction);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>): void => setMobileMoreAnchorEl(event.currentTarget);
  const handleNavMenuOpen = (event: React.MouseEvent<HTMLElement>): void => setNavMenuAnchorEl(event.currentTarget);

  const menuId = 'navbar-menu';
  const renderMobileMenuItems = (
    <Menu anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} id={menuId} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={isMenuOpen} onClose={handleMenuClose()}>
      <MenuItem onClick={handleMenuClose(logout)}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'navbar-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose()}
    >
      <MenuItem onClick={handleMobileMenuClose(logout)}>
        <IconButton aria-label='mail' color='inherit'>
          <ExitToApp />
        </IconButton>
        <p>Log out</p>
      </MenuItem>
      )
    </Menu>
  );

  const navMenuId = 'navbar-nav-menu';

  return (
    <>
      <AppBar component={'header'} position='sticky'>
        <Toolbar>
          <Grid className={classes.logoDiv}>
            <NexusLogo style={{ width: '100px' }} id={'nexus-logo'} />
          </Grid>

          {isAuthenticated && (
            <>
              <div className={classes.sectionDesktop}>
                <Typography className={classes.username} component={'div'} variant='subtitle2' noWrap>
                  {user && user.name + (user.companyName ? ' -- ' + user.companyName : '')}
                </Typography>
                <StyledTooltip title={'Nexus settings'}>
                  <StyledIconButton aria-label='nav menu' aria-controls={navMenuId} aria-haspopup='true' color='inherit' onClick={handleNavMenuOpen}>
                    <MenuIcon />
                  </StyledIconButton>
                </StyledTooltip>

                <StyledTooltip title={'Log out of portal'}>
                  <StyledIconButton aria-label='logout' color='inherit' onClick={logout}>
                    <ExitToApp />
                  </StyledIconButton>
                </StyledTooltip>
              </div>
              <div className={classes.sectionMobile}>
                <StyledIconButton aria-label='show more' aria-controls={mobileMenuId} aria-haspopup='true' onClick={handleMobileMenuOpen} color='inherit'>
                  <MoreIcon />
                </StyledIconButton>
              </div>
            </>
          )}
        </Toolbar>
        <NavLinkBar />
      </AppBar>
      {isAuthenticated && renderMobileMenu}
      {isAuthenticated && renderMobileMenuItems}
      {isAuthenticated && renderLogOut}
      {isAuthenticated && (
        <Menu anchorEl={navMenuAnchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} id={navMenuId} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNavMenuOpen} onClose={handleNavMenuClose()}>
          <MenuItem onClick={lastmonConnect}>
            <StyledIconButton aria-label='mail' color='inherit'>
              <LinkIcon />
            </StyledIconButton>
            <p>Connect to Lastmon</p>
          </MenuItem>
          <MenuItem onClick={lastmonDisconnect}>
            <StyledIconButton aria-label='mail' color='inherit'>
              <LinkOffIcon />
            </StyledIconButton>
            <p>Disconnect from Lastmon</p>
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

const MemoNavBar = React.memo(NavBar);

export { MemoNavBar as NavBar };

// <div className={classes.sectionDesktop}>
//               <StyledTooltip title={'Disconnect from socket'}>
//                 <StyledIconButton aria-label='logout' color='inherit' onClick={() => socket && socket.disconnect()}>
//                   <ExitToApp />
//                 </StyledIconButton>
//               </StyledTooltip>
//             </div>
//             <div className={classes.sectionDesktop}>
//               <StyledTooltip title={'Open socket'}>
//                 <StyledIconButton aria-label='logout' color='inherit' onClick={() => socket && socket.open()}>
//                   <ExitToApp />
//                 </StyledIconButton>
//               </StyledTooltip>
//             </div>
