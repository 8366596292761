import { OkPacket } from 'mysql';
import { VariantType } from 'notistack';
import { SocketRequest, SocketResponse } from './socket.types';

//! --------------------------- FRONTEND
export type CustomSnackOptions = {
  closeButton?: boolean;
};
export type PantheonNotification = {
  message: string;
  variant: VariantType;
  options?: CustomSnackOptions;
};

//! --------------------------- PANTHEON SP
export type StoredProcedure<TParameters = string[] | Record<string, unknown>> = { sp: string; params?: TParameters; database: string };

export interface StoredProcedureRequest<TParameters = string[] | Record<string, unknown>> extends SocketRequest {
  procedure: StoredProcedure<TParameters>;
}

export interface StoredProcedureResponse<TMessage extends Array<unknown>> extends SocketResponse<TMessage> {
  sp: string;
}

//! --------------------------- PANTHEON API

export type OrderByType = 'ASC' | 'DESC';

export type APIPagination = {
  pageNumber: number;
  perPage: number;
};

export type APILast = {
  count: number;
  orderFields: string[];
  order?: OrderByType;
};
export type APIOrderBy = [string, OrderByType][];

type APIDateRangeProperties = {
  min?: string;
  max?: string;
};

type APIDateRange = (Pick<Required<APIDateRangeProperties>, 'min'> & Pick<APIDateRangeProperties, 'max'>) | (Pick<Required<APIDateRangeProperties>, 'max'> & Pick<APIDateRangeProperties, 'min'>);

export type APIDate = APIDateRange & {
  field: string;
  sign?: '>' | '<' | '<=' | '>=' | '=';
};

export type APIKeyValue = number | string;
export type APIForeignKey = [string, APIKeyValue | APIKeyValue[]] | undefined;
export type APIPrimaryKey = number | string | number[] | string[] | undefined;
export type APIDataType = string | number | boolean | Date;

export interface APIPostOptions {
  updateDuplicate?: boolean; // when inserting a record, if duplicate key exists update the record with new values
}

//TODO re-factor to make it narrower for each use (PUT/PATCH/GET)
export interface APIRequest extends SocketRequest {
  schema: string;
  table: string;
  page?: APIPagination; // pagination options
  //todo deprecate and use a single property for all filterign (WHERE)
  id?: APIPrimaryKey; // specific filter for one or more ID's
  date?: APIDate;
  //TODO rename to criteria.
  fk?: APIForeignKey; // fk to filter by
  data?: Record<string, APIDataType>; // data to insert/update
  fields?: string[]; // fields to return
  last?: APILast; // if set query will get rows from back of table, ordered by PK
  orderBy?: APIOrderBy; // orders query. array of tuples supplied
  post?: APIPostOptions; // options specific to POST requests
  info?: {
    fieldInfo?: boolean; // return field definitions
    rowCount?: boolean; // return number of rows total
  };
}

export type TableField = { name: string; length: number; type: MySQLAllTypes; decimals: number };

export type APIGetInfo = { tableFields?: TableField[] };
export type APIGetResponse<TRow> = SocketResponse<TRow[] | null> & APIGetInfo;

export type APIPutInfo<TMessage> = { message: TMessage; refreshLast?: boolean; okPacket?: OkPacket };
export type APIPutResponse<T extends unknown = string> = SocketResponse<APIPutInfo<T>>;

export type APIPostInfo<TMessage> = { message: TMessage; refreshLast?: boolean; okPacket?: OkPacket };
export type APIPostResponse<TMessage extends unknown = string> = SocketResponse<APIPostInfo<TMessage>>;

// DEPRECATED
export type APIResponse_NEW<T> = T[] | null;

//! --------------------------- PCP DRAWER

export interface DrawerItem {
  icon: string;
  text: string;
  path: string;
}

//! --------------------------- MYSQL DATA TYPING
export type DrawerDefinition = Record<string, DrawerItem[]>;

export enum MySQLTypes {
  DECIMAL = 0x00, // aka DECIMAL (http://dev.mysql.com/doc/refman/5.0/en/precision-math-decimal-changes.html)
  TINY = 0x01, // aka TINYINT, 1 byte
  SHORT = 0x02, // aka SMALLINT, 2 bytes
  LONG = 0x03, // aka INT, 4 bytes
  FLOAT = 0x04, // aka FLOAT, 4-8 bytes
  DOUBLE = 0x05, // aka DOUBLE, 8 bytes
  NULL = 0x06, // NULL (used for prepared statements, I think)
  TIMESTAMP = 0x07, // aka TIMESTAMP
  LONGLONG = 0x08, // aka BIGINT, 8 bytes
  INT24 = 0x09, // aka MEDIUMINT, 3 bytes
  DATE = 0x0a, // aka DATE
  TIME = 0x0b, // aka TIME
  DATETIME = 0x0c, // aka DATETIME
  YEAR = 0x0d, // aka YEAR, 1 byte (don't ask)
  NEWDATE = 0x0e, // aka ?
  VARCHAR = 0x0f, // aka VARCHAR (?)
  BIT = 0x10, // aka BIT, 1-8 byte
  TIMESTAMP2 = 0x11, // aka TIMESTAMP with fractional seconds
  DATETIME2 = 0x12, // aka DATETIME with fractional seconds
  TIME2 = 0x13, // aka TIME with fractional seconds
  JSON = 0xf5, // aka JSON
  NEWDECIMAL = 0xf6, // aka DECIMAL
  ENUM = 0xf7, // aka ENUM
  SET = 0xf8, // aka SET
  TINY_BLOB = 0xf9, // aka TINYBLOB, TINYTEXT
  MEDIUM_BLOB = 0xfa, // aka MEDIUMBLOB, MEDIUMTEXT
  LONG_BLOB = 0xfb, // aka LONGBLOG, LONGTEXT
  BLOB = 0xfc, // aka BLOB, TEXT
  VAR_STRING = 0xfd, // aka VARCHAR, VARBINARY
  STRING = 0xfe, // aka CHAR, BINARY
  GEOMETRY = 0xff, // aka GEOMETRY
}

export enum MySQLCustomTypes {
  BOOLEAN,
}

export enum DataGridTypeMap {
  DECIMAL = 'number', // aka DECIMAL (http://dev.mysql.com/doc/refman/5.0/en/precision-math-decimal-changes.html)
  TINY = 'number', // aka TINYINT, 1 byte
  SHORT = 'number', // aka SMALLINT, 2 bytes
  LONG = 'number', // aka INT, 4 bytes
  FLOAT = 'number', // aka FLOAT, 4-8 bytes
  DOUBLE = 'number', // aka DOUBLE, 8 bytes
  NULL = 'string', // NULL (used for prepared statements, I think)
  TIMESTAMP = 'number', // aka TIMESTAMP
  LONGLONG = 'number', // aka BIGINT, 8 bytes
  INT24 = 'number', // aka MEDIUMINT, 3 bytes
  DATE = 'date', // aka DATE
  TIME = 'dateTime', // aka TIME
  DATETIME = 'dateTime', // aka DATETIME
  YEAR = 'number', // aka YEAR, 1 byte (don't ask)
  NEWDATE = 'date', // aka ?
  JSON = 'string',
  VARCHAR = 'number', // aka VARCHAR (?)
  BIT = 'boolean', // aka BIT, 1-8 byte
  TIMESTAMP2 = 'number', // aka TIMESTAMP with fractional seconds
  DATETIME2 = 'dateTime', // aka DATETIME with fractional seconds
  TIME2 = 'dateTime', // aka TIME with fractional seconds
  NEWDECIMAL = 'number', // aka DECIMAL
  ENUM = 'string', // aka ENUM
  SET = 'string', // aka SET
  TINY_BLOB = 'string', // aka TINYBLOB, TINYTEXT
  MEDIUM_BLOB = 'string', // aka MEDIUMBLOB, MEDIUMTEXT
  LONG_BLOB = 'string', // aka LONGBLOG, LONGTEXT
  BLOB = 'string', // aka BLOB, TEXT
  VAR_STRING = 'string', // aka VARCHAR, VARBINARY
  STRING = 'string', // aka CHAR, BINARY
  GEOMETRY = 'string', // aka GEOMETRY
  BOOLEAN = 'boolean',
}

export type MySQLAllTypes = keyof typeof MySQLCustomTypes | keyof typeof MySQLTypes;

export interface MySQLFieldInfo {
  catalog: string;
  db: string;
  table: string;
  orgTable: string;
  name: string;
  orgName: string;
  charsetNr: number;
  length: number;
  flags: number;
  decimals: number;
  default?: string;
  zeroFill: boolean;
  protocol41: boolean;
  type: string;
}
