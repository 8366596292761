import React from 'react';
import { Typography, Divider, FormControl, InputLabel, OutlinedInput, InputAdornment, TextField, FormControlLabel, Switch } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { NoticeInterface, OutageBlockInterface } from '../common/notice.types';
import { PaperItem } from 'src/components/styled/Grid';
import { makeBlankOutageBlock } from './util';
import { noticeEntryStyles } from './styles';

const OutageBlock = function OutageBlock({ id, outageID, noticeRef, setter }: { id: number; outageID: number; noticeRef: React.MutableRefObject<NoticeInterface>; setter: (id: number, notes: string) => void }): JSX.Element {
  const classes = noticeEntryStyles();

  const [blockState, setState] = React.useState<OutageBlockInterface>(makeBlankOutageBlock());

  const checkLabel = () => {
    if (blockState.notes?.length === 0 && blockState.startDate) setter(id, blockState.startDate.format('DD/MM/YY'));
  };

  React.useEffect(() => {
    if (noticeRef.current.outages[outageID]) setState({ ...noticeRef.current.outages[outageID].blocks[id] });
    else setState({ ...noticeRef.current.outages[outageID].blocks[id] });
  }, [id, outageID]);

  const handleDateChange = (property: keyof Pick<OutageBlockInterface, 'startDate' | 'endDate'>) => (date: MaterialUiPickersDate): void => {
    date && setState(state => ({ ...state, [property]: date }));
    checkLabel();
    if (property === 'startDate' && date?.isValid() && blockState.endDate.isBefore(date)) setState(state => ({ ...state, endDate: date }));
  };
  const handleTentativeChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setState(state => ({ ...state, tentative: checked }));
  };

  const handleFieldChange = (property: keyof Pick<OutageBlockInterface, 'reductionLow' | 'reductionHigh' | 'notes'>) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (property === 'notes') {
      if (value.length > 0) setter(id, value);
      else checkLabel();
    }
    setState(state => ({ ...state, [property]: value }));
  };

  React.useEffect(() => void (noticeRef.current.outages[outageID].blocks[id] = { ...blockState }), [blockState]);
  return (
    <PaperItem flex colSpan={1} rowSpan={2} className={classes.noticeRoot}>
      <Typography variant={'h5'}>Outage Block #{blockState.id}</Typography>
      <Divider className={classes.divider} />
      <div style={{ alignSelf: 'center' }}>
        <FormControlLabel control={<Switch checked={Boolean(blockState.tentative)} onChange={handleTentativeChange} name='tenatativeOutage' color='primary' />} label='Tentative Outage' />
      </div>
      <Divider className={classes.divider} />
      <TextField id={`block-${blockState.id}-notes`} label='Notes' value={blockState.notes || ''} onChange={handleFieldChange('notes')} variant='outlined' className={classes.formControl} />
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'en'}>
        <FormControl variant='outlined' className={classes.formControl}>
          <KeyboardDatePicker
            format='DD-MM-YYYY'
            variant='inline'
            inputVariant='outlined'
            id='start-date'
            label='Start Date'
            value={blockState.startDate}
            onChange={handleDateChange('startDate')}
            autoOk
            KeyboardButtonProps={{
              'aria-label': 'change start date',
            }}
          />
        </FormControl>
        <FormControl variant='outlined' className={classes.formControl}>
          <KeyboardDatePicker
            format='DD-MM-YYYY'
            variant='inline'
            inputVariant='outlined'
            id='end-date'
            label='End Date'
            value={blockState.endDate}
            onChange={handleDateChange('endDate')}
            autoOk
            KeyboardButtonProps={{
              'aria-label': 'change start date',
            }}
          />
        </FormControl>

        <FormControl className={classes.formControl} variant='outlined'>
          <InputLabel htmlFor='reduction-low'>Volume Reduction (low)</InputLabel>
          <OutlinedInput
            id='reduction-low'
            type={'number'}
            label={'Volume Reduction (low)'}
            value={blockState.reductionLow || ''}
            onChange={handleFieldChange('reductionLow')}
            onBlur={() => blockState.reductionHigh === null && setState(state => ({ ...state, reductionHigh: state.reductionLow }))}
            endAdornment={<InputAdornment position='end'>TJ</InputAdornment>}
          />
        </FormControl>
        <FormControl className={classes.formControl} variant='outlined'>
          <InputLabel htmlFor='reduction-high'>Volume Reduction (high)</InputLabel>
          <OutlinedInput
            id='reduction-high'
            type={'number'}
            label={'Volume Reduction (high)'}
            value={blockState.reductionHigh || ''}
            onChange={handleFieldChange('reductionHigh')}
            endAdornment={<InputAdornment position='end'>TJ</InputAdornment>}
          />
        </FormControl>
      </MuiPickersUtilsProvider>
    </PaperItem>
  );
};

export { OutageBlock };
