import React, { PropsWithChildren } from 'react';

type AccordionContextType = [(panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => void, string | false, () => void];

export const AccordionContext = React.createContext<AccordionContextType>((null as unknown) as AccordionContextType);

// eslint-disable-next-line @typescript-eslint/ban-types
export function AccordionProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const collapse = () => setExpanded(false);

  const contextValue = [handleChange, expanded, collapse] as AccordionContextType;

  return <AccordionContext.Provider value={contextValue}>{children}</AccordionContext.Provider>;
}

export function useAccordion(): AccordionContextType {
  const result = React.useContext(AccordionContext);
  if (!result) {
    throw new Error('Accordion context is only available inside its provider');
  }
  return result;
}
