import { useRef, useMemo } from 'react';
import { useSocketInstance } from 'src/modules/websocket/context';
import { makeID } from '@skipnz/juno';
import { SocketRequest, SocketResponse } from 'src/types/pantheon/socket.types';
import { useSnackSend } from 'src/hooks/useSnackSend';
import { makeSocketID, parseResponse } from '../util';
import { Socket } from 'src/types/pantheon/specific.types';

export interface SocketEmitOptions {
  noError?: boolean;
  noSuccess?: boolean;
}

export const useSocketEmit = (
  channel: string,
  options?: SocketEmitOptions,
  socketInstance?: Socket,
): [<TRequest extends SocketRequest, TResponse extends SocketResponse<unknown>>(query: TRequest, callback?: (response: TResponse | undefined) => void) => void] => {
  const { socket: hookSocket } = useSocketInstance();

  // use socket specified in HOC, otherwise use hook socket instance
  const socket = useMemo(() => socketInstance || hookSocket, []);

  const { sendSnack } = useSnackSend();

  const sockID = useRef<string>(makeSocketID());

  const { noError, noSuccess } = options || {};

  const emit = <TRequest extends SocketRequest, TResponse extends SocketResponse<unknown>>(query: TRequest, callback?: (response: TResponse | undefined) => void) => {
    // make new unique ID
    sockID.current = makeID(20);

    // emit message
    socket.emit(channel, { ...query, queryID: sockID.current });

    // response
    // setImmediate(() =>
    socket.once(sockID.current, (response: TResponse | undefined) => {
      // auto-handler of all request type errors using snackbar (default on)
      if (!noError && response && response.code !== 200) {
        sendSnack(parseResponse(response.message), 'error');
      }

      // auto-handler of non-get success using snackbar (default on)
      if (!noSuccess && response && response.code === 200 && query.method !== 'get') {
        sendSnack(parseResponse(response.message), 'success');
      }

      callback && callback(response);
    });
    // );

    return sockID.current;
  };

  return [emit];
};
