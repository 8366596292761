import React, { createContext, PropsWithChildren, useContext } from 'react';
import { useSocketInstance } from 'src/modules/websocket/context';
import { InstrumentPacket, makeInstrument } from '..';
import { ProductRow, PanelData, DashboardProduct, nullPanel } from '../types';

type DashboardContext = [ProductRow, ProductRow, ProductRow, ProductRow];

const DashboardContext = createContext<DashboardContext>((null as unknown) as DashboardContext);

const sources = ['oils', 'gas', 'lpg', 'elec'];
const streams = ['upstream', 'midstream', 'downstream'];

const getCodes = (rows: ProductRow[]) => {
  const codes = new Set<string>();

  for (let i = 0; i < rows.length; i++) {
    const column = rows[i];
    for (const key in column) {
      for (let j = 0; j < column[key].length; j++) {
        const section = column[key][j];
        for (let k = 0; k < section.length; k++) {
          const instrument = section[k];
          codes.add(instrument.code);
        }
      }
    }
  }

  return Array.from(codes);
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function DashboardProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  const instrumentReducer = (state: Record<string, PanelData>, action: { target: string; payload: Partial<PanelData> }) => {
    if (state[action.target]) {
      return (state = { ...state, [action.target]: { ...state[action.target], ...action.payload } });
    }
    return state;
  };

  const [instruments, dispatch] = React.useReducer(instrumentReducer, {
    NZDUSD: makeInstrument('NZDUSD'),
    BRENT_SPOT: makeInstrument('BRENT_SPOT'),
    BRENT_12M: makeInstrument('BRENT_12M'),
    NZDUSD_12M: makeInstrument('NZDUSD_12M'),
    BRENT_12M_NZD: makeInstrument('BRENT_12M_NZD'),
    RPETROL_IMPORTS: makeInstrument('RPETROL_IMPORTS'),
    LPG_IMPORTS: makeInstrument('LPG_IMPORTS'),
    FUELOIL_IMPORTS: makeInstrument('FUELOIL_IMPORTS'),
    PPETROL_IMPORTS: makeInstrument('PPETROL_IMPORTS'),
    DIESEL_IMPORTS: makeInstrument('DIESEL_IMPORTS'),
    JETFUEL_IMPORTS: makeInstrument('JETFUEL_IMPORTS'),
    GASPY_91_INDEX: makeInstrument('GASPY_91_INDEX'),
    GASPY_95_INDEX: makeInstrument('GASPY_95_INDEX'),
    GASPY_DIESEL_INDEX: makeInstrument('GASPY_DIESEL_INDEX'),
    Z_CALTEX_PETROL_WEEKLY: makeInstrument('Z_CALTEX_PETROL_WEEKLY'),
    Z_CALTEX_DIESEL_WEEKLY: makeInstrument('Z_CALTEX_DIESEL_WEEKLY'),
    Z_CALTEX_JETFUEL_WEEKLY: makeInstrument('Z_CALTEX_JETFUEL_WEEKLY'),
    GE_POHO: makeInstrument('GE_POHO'),
    FG_LASTCALL: makeInstrument('FG_LASTCALL'),
    FG_LASTPUT: makeInstrument('FG_LASTPUT'),
    GE_MM: makeInstrument('GE_MM'),
    GE_MAUI: makeInstrument('GE_MAUI'),
    GE_KUPE: makeInstrument('GE_KUPE'),
    GE_TURANGI: makeInstrument('GE_TURANGI'),
    GE_KOWHAI: makeInstrument('GE_KOWHAI'),
    SPOT_GAS_LAST: makeInstrument('SPOT_GAS_LAST'),
    SPOT_GAS_VWAP: makeInstrument('SPOT_GAS_VWAP'),
    SPOT_GAS_NB: makeInstrument('SPOT_GAS_NB'),
    GI_AGS: makeInstrument('GI_AGS'),
    LP_MAUI: makeInstrument('LP_MAUI'),
    PP_BR_MAUI: makeInstrument('PP_BR_MAUI'),
    GI_MOTONUI: makeInstrument('GI_MOTONUI'),
    GI_WAITARA: makeInstrument('GI_WAITARA'),
    GI_BALLANCE: makeInstrument('GI_BALLANCE'),
    GI_HUNTLY: makeInstrument('GI_HUNTLY'),
    GI_STRATFORD: makeInstrument('GI_STRATFORD'),
    GI_INDUSTRIALS: makeInstrument('GI_INDUSTRIALS'),
    OTA_RTP: makeInstrument('OTA_RTP'),
    BEN_RTP: makeInstrument('BEN_RTP'),
    OTA_FINAL: makeInstrument('OTA_FINAL'),
    BEN_FINAL: makeInstrument('BEN_FINAL'),
    GEN_TCC: makeInstrument('GEN_TCC'),
    GEN_SFD1: makeInstrument('GEN_SFD1'),
    GEN_SFD2: makeInstrument('GEN_SFD2'),
    GEN_HLY1: makeInstrument('GEN_HLY1'),
    GEN_HLY2: makeInstrument('GEN_HLY2'),
    GEN_HLY4: makeInstrument('GEN_HLY4'),
    GEN_HLY5: makeInstrument('GEN_HLY5'),
    GEN_HLY6: makeInstrument('GEN_HLY6'),
    GEN_MKE: makeInstrument('GEN_MKE'),
    GEN_JRD: makeInstrument('GEN_JRD'),
    GEN_GAS: makeInstrument('GEN_GAS'),
    GEN_HYD: makeInstrument('GEN_HYD'),
    GEN_GEO: makeInstrument('GEN_GEO'),
    GEN_WIN: makeInstrument('GEN_WIN'),
    GEN_COG: makeInstrument('GEN_COG'),
  });

  const dashboard: DashboardContext = React.useMemo(() => {
    return [
      {
        upstream: [
          [nullPanel, nullPanel, nullPanel],
          [nullPanel, nullPanel, nullPanel],
        ],
        midstream: [
          [nullPanel, nullPanel, nullPanel],
          [nullPanel, nullPanel, nullPanel],
        ],
        downstream: [
          [nullPanel, nullPanel, nullPanel],
          [nullPanel, nullPanel, nullPanel],
        ],
      },
      {
        upstream: [
          [instruments.GE_POHO, instruments.GE_MM, instruments.GE_MAUI],
          [instruments.GE_KUPE, instruments.GE_TURANGI, instruments.GE_KOWHAI],
        ],
        midstream: [
          [instruments.FG_LASTCALL, instruments.FG_LASTPUT, nullPanel],
          [nullPanel, instruments.LP_MAUI, instruments.PP_BR_MAUI],
        ],
        downstream: [
          [instruments.GI_MOTONUI, instruments.GI_WAITARA, instruments.GI_BALLANCE],
          [instruments.GI_HUNTLY, nullPanel, nullPanel],
        ],
      },
      {
        upstream: [
          [instruments.GEN_GAS, instruments.GEN_HYD, instruments.GEN_WIN],
          [instruments.GEN_COG, nullPanel, nullPanel],
        ],
        midstream: [
          [nullPanel, nullPanel, nullPanel],
          [nullPanel, nullPanel, nullPanel],
        ],
        downstream: [
          [nullPanel, nullPanel, nullPanel],
          [nullPanel, nullPanel, nullPanel],
        ],
      },
      {
        upstream: [
          [instruments.GEN_TCC, instruments.GEN_SFD1, instruments.GEN_SFD2],
          [instruments.GEN_MKE, instruments.GEN_JRD, nullPanel],
        ],

        midstream: [
          [instruments.GEN_HLY1, instruments.GEN_HLY2, instruments.GEN_HLY4],
          [instruments.GEN_HLY5, instruments.GEN_HLY6, nullPanel],
        ],
        downstream: [
          [instruments.OTA_RTP, instruments.BEN_RTP, nullPanel],
          [instruments.OTA_FINAL, instruments.BEN_FINAL, nullPanel],
        ],
      },
    ];
  }, [instruments]);

  const [contextValue, setContext] = React.useState<DashboardContext>(dashboard);

  const socket = useSocketInstance();

  React.useEffect(() => {
    setContext(dashboard);
  }, [instruments]);

  React.useEffect(() => {
    const lastMonConnect = () => {
      socket.socket.emit('lastmon.client', { action: 'connect' });
    };

    lastMonConnect();
    socket.socket.on('disconnect', lastMonConnect);

    socket.socket.on('lastmon.connected', () => {
      socket.socket.emit('lastmon.request', { action: 'get', payload: { codes: getCodes(dashboard) } });
    });

    socket.socket.on('lastmon.data', (data: { action: 'update'; payload: InstrumentPacket }) => {
      const { action, payload } = data;
      if (!action || !payload) throw new Error('lastmon data packet invalid');
      if (action === 'update') {
        if (instruments[payload.code]) {
          setContext(state => {
            const mutate: DashboardContext = [...state];
            dispatch({ target: payload.code, payload: { ...payload, hydrated: true } });
            return mutate;
          });
        }
      }
    });
    return () => {
      socket.socket.emit('lastmon.client', { action: 'disconnect' });
      socket.socket.off('disconnect', lastMonConnect);
      socket.socket.off('lastmon.data');
      socket.socket.off('lastmon.connected');
    };
  }, []);

  return <DashboardContext.Provider value={contextValue}>{children}</DashboardContext.Provider>;
}

// root level context hook
export function useDashboardContext(): DashboardContext {
  const result = useContext(DashboardContext);
  if (!result) {
    throw new Error('Dashboard context is only available inside its provider');
  }

  return result;
}

export const usePanel = (source: DashboardProduct, location: [number, number, number]): PanelData => {
  const result = useContext(DashboardContext);
  const sourceIndex = sources.indexOf(source);
  const stream = streams[location[0]];
  if (!result) {
    throw new Error('Dashboard context is only available inside its provider');
  }

  const memoPanel = React.useMemo(() => {
    return result[sourceIndex][stream][location[1]][location[2]];
  }, [result[sourceIndex][stream][location[1]][location[2]].lastValue, result[sourceIndex][stream][location[1]][location[2]].lastTime]);

  return memoPanel;
};
