import React, { PropsWithChildren } from 'react';
import { Typography } from '@material-ui/core';
import { StyledDivider } from '../styled/StyledDivider';

export const NexusTextContent = ({ header, children, noWrapper = false }: PropsWithChildren<{ header: string; noWrapper?: boolean }>): JSX.Element => {
  return (
    <>
      <Typography variant={'h5'}>{header}</Typography>
      <StyledDivider />
      {!noWrapper ? <Typography variant={'body1'}>{children}</Typography> : children}
    </>
  );
};
