import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import ListAltIcon from '@material-ui/icons/ListAlt';
import AnnouncementIcon from '@material-ui/icons/Announcement';

import HomeIcon from '@material-ui/icons/Home';
import ConsoleIcon from '@material-ui/icons/DeveloperMode';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { theme } from 'src/style/theme';
import { DrawerItem, APIRequest, APIGetResponse } from 'src/types/pantheon/pantheon.types';
import { useSocket } from 'src/modules/websocket/hooks/useSocket';
import { NavLink } from 'react-router-dom';
import { SvgIconTypeMap, ClickAwayListener } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { StyledTooltip } from '../styled/StyledTooltip';
// import { AuthContext } from 'src/modules/auth/context';
import { useAppSelector } from 'src/redux/hooks';

const drawerWidth = 240;

// eslint-disable-next-line @typescript-eslint/ban-types
const iconObject: Record<string, OverridableComponent<SvgIconTypeMap<{}, 'svg'>>> = {
  ListAltIcon,
  AnnouncementIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    // transition: 'width 1s ease-out;',
    transition: 'width 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',

    width: drawerWidth,
    overflow: 'hidden',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    transition: 'width 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',

    width: drawerWidth,
  },
  drawerClose: {
    transition: 'width 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',

    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    position: 'unset',
  },
}));

const MiniDrawer = React.forwardRef((props, ref) => {
  const classes = useStyles(theme);

  const { isAuthenticated, user } = useAppSelector(state => state.auth);

  const [open, setOpen] = React.useState(false);
  const setClosed = () => open && setOpen(false);

  const [drawerItems, setItems] = React.useState<DrawerItem[]>([]);

  const [response, send] = useSocket<APIGetResponse<{ ID: number; sidebar: DrawerItem[] }>, APIRequest>('pantheon.api');
  React.useEffect(() => {
    if (response?.message && response.message.length > 0 && response.code === 200) setItems(response.message[0].sidebar);
  }, [response]);

  React.useEffect(() => {
    if (user && drawerItems.length === 0)
      send({
        method: 'get',
        schema: 'nexus',
        table: `group_drawer_objects`,
        fk: ['ID', user.axxGroup || 0],
      });
  }, [isAuthenticated]);

  if (!isAuthenticated && drawerItems.length > 0) setItems([]);

  const toggleDrawer = () => setOpen(state => !state);

  return (
    <ClickAwayListener onClickAway={setClosed}>
      <Drawer
        ref={ref}
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <List>
          <ListItem button onClick={toggleDrawer}>
            <ListItemIcon>{theme.direction === 'rtl' ? open ? <ChevronRightIcon /> : <ChevronLeftIcon /> : open ? <ChevronLeftIcon /> : <ChevronRightIcon />}</ListItemIcon>
          </ListItem>
        </List>
        <Divider />
        <List>
          <StyledTooltip title={'Navigate to homepage'}>
            <ListItem button component={NavLink} to={'/'}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={'Homepage'} />
            </ListItem>
          </StyledTooltip>
          <ListItem button component={NavLink} to={'/dev'}>
            <ListItemIcon>
              <ConsoleIcon />
            </ListItemIcon>
            <ListItemText primary={'Dev page'} />
          </ListItem>
        </List>
        <Divider />
        <List>
          {drawerItems.map((item, i) => {
            const IconComponent = iconObject[`${item.icon}Icon`];
            return IconComponent ? (
              <StyledTooltip key={i} title={item.text}>
                <ListItem button component={NavLink} to={item.path}>
                  <ListItemIcon>
                    <IconComponent />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </StyledTooltip>
            ) : (
              <React.Fragment key={i}></React.Fragment>
            );
          })}
        </List>
      </Drawer>
    </ClickAwayListener>
  );
});

export { MiniDrawer };
