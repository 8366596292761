import React from 'react';
import { StyledContainer } from 'src/components/styled/StyledContainer';
import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { PaperItem } from 'src/components/styled/Grid';
import { CameraAlt } from '@material-ui/icons';
import { useSocket } from 'src/modules/websocket/hooks/useSocket';
import { RPCRequest, RPCResponse, StoredProcedureRequest } from 'src/types/pantheon.types';
import CommonToolbar from '../common/toolbarIcons';

import { useSnackbar } from 'notistack';
import { SnapshotList } from './SnapshotList';
import { AuditTrail } from './AuditTrail';
import { ComparisonResponse, SnapshotComparePayload } from 'src/types/notice.sql.types';
import { OutageTable } from '../view/OutageTable';
import { SocketResponse } from 'src/types/pantheon/socket.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gap: '4px',
      padding: 16,
    },
    header: {
      textAlign: 'center',
      alignSelf: 'center',
      marginRight: 'auto',
      paddingLeft: 16,
      width: '',
    },
    iconBox: {
      display: 'flex',
      flexDirection: 'row',
      height: 'min-content',
      alignSelf: 'center',
      gap: '16px',
      paddingLeft: 16,
      paddingRight: 16,
    },
    divider: {
      marginTop: 3,
      marginBottom: 7,
      height: '1px',
      width: '-webkit-fill-available',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
      textAlign: 'left',
    },
    button: {
      marginTop: 'auto',
    },
    snapshotRoot: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '4px',
      padding: 16,
    },
  }),
);

export function Home(): JSX.Element {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [response, send] = useSocket<SocketResponse<string>, StoredProcedureRequest>('pantheon.sp');
  const [rpcResponse, rpcSend] = useSocket<RPCResponse<ComparisonResponse | string>, RPCRequest<SnapshotComparePayload>>('pantheon.rpc');

  const [content, setContent] = React.useState<string>('trail');
  const [compareResponse, setCompareResponse] = React.useState<ComparisonResponse | undefined>(undefined);

  React.useEffect(() => {
    if (response) {
      enqueueSnackbar(response.message, {
        variant: response.code === 200 ? 'success' : 'error',
        persist: true,
        action: (key: string) => (
          <Button aria-label='confirm' variant={'contained'} onClick={() => closeSnackbar(key)} color={'primary'}>
            DISMISS
          </Button>
        ),
      });
    }
  }, [response]);

  const takeSnapshot = () => {
    send({
      method: 'execute',
      procedure: {
        sp: 'take_snapshot',
        database: 'gic_disclosure',
      },
    });
  };

  const compareSnapshot = (baseSnapshotID?: string, compareToSnapshotID?: string) => {
    //! DEV DOES LAST TWO SNAPSHOTS
    rpcSend({
      method: 'get',
      procedure: 'compareSnapshot',
      parameters: {
        baseSnapshotID,
        compareToSnapshotID,
      },
    });
  };

  React.useEffect(() => {
    if (rpcResponse) {
      if (typeof rpcResponse.message === 'string') {
        console.error(rpcResponse);
      } else if (rpcResponse.code === 200) {
        setCompareResponse(rpcResponse.message);
        setContent('calendar');
      }
    }
  }, [rpcResponse]);

  const Comparison = React.useCallback(() => <OutageTable blockObjects={compareResponse?.calendar} comparison={compareResponse?.comparison} />, [rpcResponse, compareResponse]);

  return (
    <StyledContainer columns={'repeat(4, 1fr)'} rows={'5em 5em repeat(2, 1fr)'}>
      <PaperItem flex colSpan={4} rowSpan={1} className={classes.root}>
        <Typography variant={'h5'} className={classes.header}>
          Aotearoa Energy Gas Outage Information System (GOIS)
        </Typography>

        <div className={classes.iconBox}>{<CommonToolbar />}</div>
      </PaperItem>

      <PaperItem flex colSpan={4} rowSpan={1} className={classes.root}>
        <Button startIcon={<CameraAlt />} variant={'contained'} color={'primary'} onClick={takeSnapshot}>
          Take snapshot of outage calendar
        </Button>
      </PaperItem>

      <PaperItem flex colSpan={3} rowSpan={2} className={classes.snapshotRoot}>
        {content === 'trail' ? <AuditTrail /> : content === 'calendar' ? <Comparison /> : <></>}
      </PaperItem>
      <PaperItem flex colSpan={1} rowSpan={2} className={classes.snapshotRoot}>
        <SnapshotList compareSnapshot={compareSnapshot} />
      </PaperItem>
    </StyledContainer>
  );
}
