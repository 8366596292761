import Highcharts from 'highcharts';
import { ETPMetric, GenerationFuel } from './datacore.types';

//! --------------------------- PANTHEON SP - ALIASES

// alias for type of values that can be supplied as a series filter
export type SeriesFilterValue = string | number;

// alias for SeriesFilter, which is used in multiple makeChart interfaces.
export type SeriesFilter = [string, SeriesFilterValue | SeriesFilterValue[]][];

export type SeriesRange = [string, string];

// values that can be returned from makeSP's that instruct the client to sort chart data
export type DataSortType = 'year' | 'month' | 'date' | 'unix' | 'unix_ms' | 'alpha';

export type SeriesRollupFunction = 'sum' | 'avg';

// values that can be returned from makeSP's that instruct the client which item with a series to use as a baselane to sort the series by
export type SeriesSortBaseline = 'latest' | 'oldest';

// values that can be returned from makeSP's that instruct the client to sort chart series
export type SeriesSortType = 'asc' | 'desc';

export type SeriesRollupType = 'none' | 'hour' | 'day' | 'month' | 'quarter' | 'year';
//! --------------------------- PANTHEON SP - CHART CONTROL OPTIONS
// allowed chart type for customisable charts
export type SeriesChartType = 'line' | 'column' | 'area' | 'pie';

// alias for chart stacking options
export type SeriesStackingType = 'none' | 'normal' | 'percent';

export type SeriesAccumulatorType = 'normal' | 'cumulative';

//! --------------------------- PANTHEON SP - CHARTS
// time range types for makeRange helper function
export type MakeRangeType = 'calendar' | 'rolling' | 'current' | 'toDate' | 'previous';

// return type from the SeriesFetchFunction, which fetches chart data from the server
export type MakeChartResponse = Omit<Partial<Highcharts.Chart>, 'series'> & { series: Highcharts.SeriesOptionsType[]; dataSort?: DataSortType; seriesSort?: [SeriesSortBaseline, SeriesSortType]; error?: string };

// function type return from series fetch function maker
export type SeriesFetchFunction<TRequest extends MakeSeriesTypes> = (request: Omit<TRequest, '_makeInterface'>, callback: (chart?: MakeChartResponse) => void) => void;

// alias for ref of the Highcharts wrapper
export interface HighchartChartRef {
  chart: Highcharts.Chart;
  container: React.RefObject<HTMLDivElement>;
}

// properties interface for nexus react  chart components
export interface ChartProps<TRequest extends MakeSeriesTypes> {
  options?: Highcharts.Options;
  range?: SeriesRange;
  settings: MakeSeriesChartOptions<TRequest>[];
  callback?: Highcharts.ChartCallbackFunction;
  id?: string;
}

// chart data interface that is passed to context when adding a chart
export interface AddChartProps extends HighchartChartRef {
  range: SeriesRange;
  options: Highcharts.Options;
  request: MakeSeriesChartOptions<MakeSeriesTypes>[];
}

//! --------------------------- OPTIONS

// additional chart & series properties that are not part of the MySQL side of the MakeChart interface
export type MakeSeriesExtraOptions = {
  seriesOptions?: Highcharts.SeriesOptionsType;
  options?: Highcharts.Options;
};

// alias for properties in time series options that are should not be sent to the server when requesting data
export type MakeSeriesNonMakeOptions = MakeSeriesDiscriminators & MakeSeriesExtraOptions;

// overall options for a timeseries chart
export type MakeSeriesChartOptions<TRequest extends MakeSeriesTypes> = TRequest & MakeSeriesExtraOptions;

// options for a single timeseries
export type MakeSeriesOptions<TRequest extends MakeSeriesTypes> = TRequest & Pick<MakeSeriesExtraOptions, 'seriesOptions'>;

export type MakeSeriesOptionsMap<TMake extends MakeSeriesTypes> = Record<string, MakeSeriesChartOptions<TMake>>;

//! --------------------------- MAKESERIES

// alias for interface that should be sent to server
export type MakeSeriesRequest = Omit<MakeSeriesTypes, '_makeInterface'>;

export enum CoreTimeSeries {
  TimeSeries = 'makeChartTimeSeries',
  Netback = 'makeChartNetbackSeries',
  Seasonal = 'makeChartSeasonal',
  AGS = 'makeChartNetAGS',
  ETP = 'makeChartETPSeries',
}

export enum MiscSeries {
  Sunburst = 'makeChartSunburstReserves',
  Field = 'makeChartField',
}

// discriminator names for MakeSeries interfaces
export type MakeSeriesDiscriminators = CoreTimeSeries | MiscSeries;

// foundation interface for all MakeChart's
export interface MakeChartBase {
  _makeInterface: MakeSeriesDiscriminators;
  name: string; // TODO kinda useless?
  range: SeriesRange; //TODO refactoring to discriminate between range specified time series, and other charts. functionality locked into requiring the range property unless i re-factor it
}

// base interface for all core time series
export interface MakeTimeSeriesBase extends MakeChartBase {
  _makeInterface: CoreTimeSeries;
  rollup?: SeriesRollupType;
  rollupFunction?: SeriesRollupFunction;
  // range: SeriesRange;  //TODO  needs to be moved to here here once refactor of MakeChartBase is done
}

// base interface for other charts
export interface MiscSeriesBase extends MakeChartBase {
  _makeInterface: MiscSeries;
}

// interface for creating a reserves sunburst chart
export interface MakeSunburstReservesSP extends MiscSeriesBase {
  _makeInterface: MiscSeries.Sunburst;
  reserveYear?: number;
  reserveType: number;
  rebaseUnit?: number;
}

// interface for creating a reserves sunburst chart
export interface MakeFieldTimeSeries extends MiscSeriesBase {
  _makeInterface: MiscSeries.Field;
  reserveYear?: number;
  reserveType?: number;
  rebaseUnit?: number;
  metric: number;
  xType?: 'year' | 'date' | 'unix' | 'unix_ms';
  dataType: 'reserves' | 'production'; // seriesColumns?
  groupingColumn: string;
  accumulateBy?: string;
  fieldID?: number[]; // seriesFilter
}

// geneic timeseries options
export interface MakeCoreTimeSeriesSP extends MakeTimeSeriesBase {
  _makeInterface: CoreTimeSeries.TimeSeries;
  dataTable: string;
  xAxisColumn: string;
  seriesNames: string[];
  seriesColumns: string[];
  seriesFilters?: SeriesFilter;
  tableStatement?: boolean;
}

// etp chart options
export interface MakeETPTimeSeriesSP extends MakeTimeSeriesBase {
  _makeInterface: CoreTimeSeries.ETP;
  metric: ETPMetric;
}

// netback chart options
export interface MakeNetbackTimeSeriesSP extends MakeTimeSeriesBase {
  _makeInterface: CoreTimeSeries.Netback;
  fuelType: GenerationFuel;
  referenceNode: string;
  heatRate: number;
}

// netback chart options
export interface MakeSeasonalTimeSeriesSP extends MakeTimeSeriesBase {
  _makeInterface: CoreTimeSeries.Seasonal;
  dataTable: string;
  dateColumn: string;
  dataColumn: string;
  seriesFilters?: SeriesFilter;
}

// netback chart options
export interface MakeAGSNetTimeSeriesSP extends MakeTimeSeriesBase {
  _makeInterface: CoreTimeSeries.AGS;
}

//TODO maybe move MakeSeriesSP's into namespace for dynamic inclusion?
// alias for union of all MakeSeries interfaces
export type MakeMiscSeriesTypes = MakeSunburstReservesSP | MakeFieldTimeSeries;
export type MakeTimeSeriesTypes = MakeCoreTimeSeriesSP | MakeNetbackTimeSeriesSP | MakeSeasonalTimeSeriesSP | MakeAGSNetTimeSeriesSP | MakeETPTimeSeriesSP;
export type MakeSeriesTypes = MakeTimeSeriesTypes | MakeMiscSeriesTypes;

// type for variables of makeChart interfaces that can be edited in the chart object editor
export type MakeChartEditorVariables = Omit<MakeSeriesTypes, 'range' | '_makeInterface'>;
