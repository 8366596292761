import { ButtonProps, Button } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { useRouteMatch, useHistory } from 'react-router';

export const LinkButton = ({ route, children, ...props }: PropsWithChildren<{ route: string } & ButtonProps>): JSX.Element => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const handleClick = () => history.push(`${url}/${route}`);

  return (
    <Button {...props} variant={'contained'} onClick={handleClick} fullWidth>
      {children}
    </Button>
  );
};
