import React, { PropsWithChildren } from 'react';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      flexBasis: '33.33%',
      flexShrink: 0,
      alignSelf: 'center',
      paddingLeft: theme.spacing(2),
    },
    iconButton: {
      margin: theme.spacing(1),
      color: '#000',
      '&:hover': {
        color: green[100],
        backgroundColor: theme.palette.grey[600],
      },
    },
  }),
);
// eslint-disable-next-line @typescript-eslint/ban-types
export const AccordionTitle = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const classes = useStyles();

  // eslint-disable-next-line no-constant-condition
  const Icon = true ? CheckCircleIcon : RadioButtonUncheckedIcon;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    // !approved && collapse();
    // toggleApproval();
  };

  return (
    <>
      <IconButton aria-label='delete' className={classes.iconButton} onClick={handleClick}>
        <Icon fontSize='inherit' />
      </IconButton>
      <Typography className={classes.heading}>{children}</Typography>
    </>
  );
};
