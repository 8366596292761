import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { TreeViewer } from 'src/components/TreeBrowser';
import { TreeNodeConfig } from 'src/components/TreeBrowser/makeTree';
import { NotFound } from 'src/router';
import { SkipBox, SkipPaper } from 'src/style/components/styledFlex';
import { Dashboard } from './Dashboard';
import { DashboardHome } from './DashboardHome';

const treeDefinition: TreeNodeConfig[] = [
  {
    text: 'Home',
    info: 2,
    depth: 0,
    childDepth: 1,
    nodeStyle: 'primaryNode',
    navigate: '/dashboards',
  },

  {
    text: 'Gas Pipeline',
    info: 2,
    depth: 0,
    childDepth: 1,
    nodeStyle: 'primaryNode',
    childNodeStyle: 'secondaryNode',
    childNodes: [
      {
        text: 'Pipeline',
        navigate: 'gasPipeline/pipeline',
      },
      {
        text: 'Storage',
        navigate: 'gasPipeline/storage',
      },
      {
        text: 'ROI',
        navigate: 'gasPipeline/roi',
      },
    ],
  },
  {
    text: 'Gas Daily Flow',
    info: 2,
    depth: 0,
    childDepth: 1,
    nodeStyle: 'primaryNode',
    childNodeStyle: 'secondaryNode',
    childNodes: [
      {
        text: 'Major Production',
        navigate: 'gasDaily/production',
      },
      {
        text: 'Other Production',
        navigate: 'gasDaily/minorProduction',
      },
      {
        text: 'Generation',
        navigate: 'gasDaily/generation',
      },
      {
        text: 'Methanex',
        navigate: 'gasDaily/methanex',
      },
      {
        text: 'Major Users',
        navigate: 'gasDaily/majors',
      },
    ],
  },
  {
    text: 'Gas Hourly Flow',
    info: 2,
    depth: 0,
    childDepth: 1,
    nodeStyle: 'primaryNode',
    childNodeStyle: 'secondaryNode',
    childNodes: [
      {
        text: 'Major Production',
        navigate: 'gasHourly/production',
      },
      {
        text: 'Minor Production',
        navigate: 'gasHourly/minorProduction',
      },
      {
        text: 'Generation',
        navigate: 'gasHourly/generation',
      },
      {
        text: 'Methanex',
        navigate: 'gasHourly/methanex',
      },
      {
        text: 'Major Users',
        navigate: 'gasHourly/majors',
      },
    ],
  },
  {
    text: 'Electricity Generation',
    info: 2,
    depth: 0,
    childDepth: 1,
    nodeStyle: 'primaryNode',
    childNodeStyle: 'secondaryNode',
    childNodes: [
      {
        text: 'Genesis',
        navigate: 'electricity/genesis',
      },
      {
        text: 'Contact',
        navigate: 'electricity/contact',
      },
      {
        text: 'Overview',
        navigate: 'electricity/overview',
      },
      {
        text: 'Prices',
        navigate: 'electricity/prices',
      },
    ],
  },
];

export const DashboardRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <SkipBox flex w={'100%'} h={'100%'} g={2} p={2}>
      <SkipPaper flex w={'20%'} p={2}>
        <TreeViewer config={treeDefinition} />
      </SkipPaper>

      <Switch>
        <Route path={`${path}`} exact>
          <DashboardHome />
        </Route>
        <Route path={`${path}/:section/:dash`} exact>
          <Dashboard />
        </Route>

        <Route component={NotFound} />
      </Switch>
    </SkipBox>
  );
};
