import { Modal, Grid, Typography, Divider, useTheme } from '@material-ui/core';
import React from 'react';
import { ActionButtonGroup } from 'src/components/ActionButtonGroup';
import { useModalContainerStyles } from 'src/components/Modal/ModalContainer';
import { UberIconButton } from 'src/components/UberIconButton';
import { SkipPaper, SkipBox } from 'src/style/components/styledFlex';
import CloseIcon from '@material-ui/icons/Close';
import { JSONSchema7 } from 'json-schema';

import { Moment } from 'moment';
import { PropertyInput } from './ObjectInput';
import { useForm } from 'react-hook-form';
import { useChartSeries } from '../Context';
import { getValuesOrDefaults } from '../lib/util';
import { MakeSeriesTypes } from 'src/types/module/chartModule.types';
import { TransferListItem } from '../controls/NexusTransferList';

export const ObjectEditor = ({
  open,
  setOpenState,
  objectMap,
  objectSchema,
  edit,
  chartId,
}: {
  open?: TransferListItem;
  setOpenState: (series?: TransferListItem) => () => void;
  objectMap: MakeSeriesTypes[];
  objectSchema: JSONSchema7;
  edit?: boolean;
  chartId: string;
}): JSX.Element => {
  const modalClasses = useModalContainerStyles();
  const theme = useTheme();

  // get series from hook -- -1 short circuit will return null
  const targetSeries = useChartSeries(chartId, open?.id || 0);

  // gets either current series variables or defaults
  const settings = getValuesOrDefaults(open?.name || '', targetSeries?.[0], objectMap);

  // variable list from schema
  const objectProperties = objectSchema.properties;

  // catch no props
  if (!objectProperties) return <div>Could not find object schema</div>;

  // setup form hook (unregisters to allow resetting of detaults when switching between series)
  const { control } = useForm<Record<string, string | number | Moment>>({
    defaultValues: {},
    shouldUnregister: true,
  });

  // apply latest variables to defaults
  control.defaultValuesRef.current = { ...settings };

  return (
    <Modal open={Boolean(open)} disablePortal style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', placeContent: 'center' }}>
      <SkipPaper style={{ minWidth: '40vw', minHeight: '25vh', maxWidth: '80vw', maxHeight: '80vh' }} className={modalClasses.paper}>
        <Grid container alignItems='center' className={modalClasses.headerBar}>
          <Typography component={'div'} variant={'h6'} className={modalClasses.headerText}>
            {edit && open ? `Edit Series - ${open.name}` : objectSchema.title}
          </Typography>
          <Divider orientation='vertical' flexItem />
          <UberIconButton onClick={setOpenState()} tooltip={'Close window'} Icon={CloseIcon} />
        </Grid>
        <SkipBox flex d={'column'} h={'100%'} style={{ margin: 'auto' }} p={3}>
          <Typography
            variant={'h6'}
            component={'div'}
            style={{
              padding: theme.spacing(1, 2.5),
              width: '-webkit-fill-available',
            }}
          >
            Editable properties
          </Typography>
          {Object.entries(objectProperties)
            .filter(property => typeof property[1] !== 'boolean' && !property[1].editorExcluded)
            .map(
              property =>
                typeof property[1] !== 'boolean' && (
                  <SkipBox flex d={'row'} key={property[0]}>
                    <PropertyInput property={property[0]} definition={property[1]} control={control} />
                  </SkipBox>
                ),
            )}
        </SkipBox>
        <ActionButtonGroup ga={'span 1 / 1 / auto / 3'} onApply={() => console.log('apply')} onDiscard={setOpenState()} />
      </SkipPaper>
    </Modal>
  );
};
