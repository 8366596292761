import { mergeDeep } from 'src/lib/util';
import { CoreTimeSeries, MakeCoreTimeSeriesSP, MakeSeriesChartOptions } from 'src/types/module/chartModule.types';
import { lineChart, columnChart } from '..';
import { createSeriesMaps, makeRangeProperty } from '../common';

const makeSpotGasTimeSeries = (dataTable: string): MakeSeriesChartOptions<MakeCoreTimeSeriesSP> => ({
  range: makeRangeProperty('rolling', 1, 'year', 'date'),
  name: 'Spot Gas',
  dataTable,
  xAxisColumn: 'date',
  seriesColumns: ['VWAP'],
  seriesNames: ['VWAP'],
  _makeInterface: CoreTimeSeries.TimeSeries,
  options: mergeDeep<Highcharts.Options>({}, lineChart, {
    title: {
      text: 'New Zealand Spot Gas',
    },
  }),
});

const gasVolumes: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  range: makeRangeProperty('rolling', 1, 'year', 'date'),
  name: 'Spot Gas Volumes',
  dataTable: 'etpVolumes',
  xAxisColumn: 'date',
  seriesColumns: ['tradedVolume', 'deliveredVolume'],
  seriesNames: ['Traded Volume', 'Delivered Volume'],
  _makeInterface: CoreTimeSeries.TimeSeries,
  options: mergeDeep<Highcharts.Options>({}, columnChart, {
    title: {
      text: 'Spot Gas Volumes',
    },
  }),
};
const gasOverview: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  range: makeRangeProperty('rolling', 1, 'year', 'date'),
  name: 'Spot Gas',
  dataTable: 'etpGasOverview',
  xAxisColumn: 'date',
  seriesColumns: ['Traded VWAP', 'Delivered VWAP', 'Balancing VWAP'],
  seriesNames: ['Traded VWAP', 'Delivered VWAP', 'Balancing VWAP'],
  _makeInterface: CoreTimeSeries.TimeSeries,
  options: mergeDeep<Highcharts.Options>({}, lineChart, {
    title: {
      text: 'New Zealand Spot Gas',
    },
  }),
};

const etpSpotCarbonComponent: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  range: makeRangeProperty('rolling', 1, 'year', 'date'),
  name: 'Spot Gas - Carbon Component',
  dataTable: 'etpDeliveredCarbon',
  xAxisColumn: 'date',
  seriesColumns: ['Delivered VWAP', 'Delivered VWAP (ex. carbon)'],
  seriesNames: ['Delivered VWAP', 'Delivered VWAP (ex. carbon)'],
  _makeInterface: CoreTimeSeries.TimeSeries,
  options: mergeDeep<Highcharts.Options>({}, lineChart, {
    title: {
      text: 'Spot Gas - Carbon Component',
    },
  }),
};

const etpSwap: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  range: makeRangeProperty('rolling', 1, 'year', 'date'),
  name: 'Spot Gas - Swap Analysis',
  dataTable: 'etpSwapTrades',
  xAxisColumn: 'date',
  seriesColumns: ['swapVolume', 'netValue', 'marginPerGJ', 'cumulativeValue', 'annualisedRR'],
  seriesNames: ['Swap Volume', 'Net Swap Value', 'Margin Per GJ', 'Cumulative Value', 'Annualised Return (R/R basis)'],
  _makeInterface: CoreTimeSeries.TimeSeries,
  options: mergeDeep<Highcharts.Options>({}, lineChart, {
    title: {
      text: 'Spot Gas - Swap Analysis',
    },
  }),
};

const etpBalancingGap: MakeSeriesChartOptions<MakeCoreTimeSeriesSP> = {
  range: makeRangeProperty('rolling', 1, 'year', 'date'),
  name: 'Spot Gas - Balancing Gap',
  dataTable: 'etpBalancingGap',
  xAxisColumn: 'date',
  seriesColumns: ['Balancing VWAP', 'Delivered VWAP (ex. balancing)', 'Balancing Gap'],
  seriesNames: ['Balancing VWAP', 'Delivered VWAP (ex. balancing)', 'Balancing Gap'],
  _makeInterface: CoreTimeSeries.TimeSeries,
  options: mergeDeep<Highcharts.Options>({}, lineChart, {
    title: {
      text: 'Spot Gas - Balancing Gap',
    },
  }),
};
export const [spotGasOptionsMap, spotGasMakeMap, spotGasOptionsArrayMap] = createSeriesMaps({
  traded: makeSpotGasTimeSeries('etpTraded'),
  delivered: makeSpotGasTimeSeries('etpDelivered'),
  gasOverview,
  etpBalancingGap,
  etpSpotCarbonComponent,
  etpSwap,
  gasVolumes,
});
