import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SeriesAccumulatorType } from 'src/types/module/chartModule.types';
import { useChart } from 'src/components/Charts/components/Context';
import { FieldCategory, FieldFocus } from '../../../../pages/gas/flow/FieldView';

// map of column names for the accumulator to pass to the SQL routine, depending on which focus we have
const accumulateMap = { field: 'field', company: 'ownerName', setting: 'fieldType' };

export function ChartAccumulatorRadio({ id, initialValue, category, focus }: { id: string; initialValue: SeriesAccumulatorType; category: FieldCategory; focus: FieldFocus }): JSX.Element {
  const [value, setValue] = React.useState<SeriesAccumulatorType>(initialValue);
  const [chart, series, fetchNewData] = useChart(id);

  const options: [SeriesAccumulatorType, string][] = [
    ['normal', 'None'],
    ['cumulative', 'Cumulative'],
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const accumulator = (event.target as HTMLInputElement).value as SeriesAccumulatorType;
    const settings = chart();

    if (accumulator === 'cumulative') {
      // clone current chart settings, adding an acculator parameter for the SQL routine
      const chartSeries = series.get();
      const chartRequest = settings.nexus.currentConfig.request[0];
      if (!chartRequest) {
        throw new Error(`Could not find request (accumulator)`);
      }
      // generate list of categories from series names
      //todo get SQL routine to do this
      const categories = chartSeries?.map(series => series.name).sort();

      if (!categories) {
        alert('Could not parse categories for accumulation');
        return;
      }

      // compose new request
      const request = [
        {
          ...chartRequest,
          accumulateBy: accumulateMap[focus],
          range: chartRequest.range,
        },
      ];

      const options = {
        chart: {
          type: settings.options?.chart?.type,
        },
        legend: { enabled: false },
        title: {
          text: `Cumulative ${focus} ${category}, ${chartRequest.range[0]} - ${chartRequest.range[1]}`,
        },
        xAxis: { type: 'category', categories },
        plotOptions: {
          series: { stacking: undefined },
        },
      } as Highcharts.Options;

      fetchNewData({ request, options, range: chartRequest.range });
    }
    if (accumulator === 'normal') {
      const intialConfig = settings.nexus.initialConfig;
      const initialRequest = intialConfig.request[0];
      const currentConfig = settings.nexus.currentConfig;
      const currentRequest = currentConfig.request[0];
      if (!initialRequest) {
        throw new Error(`Could not find request (accumulator)`);
      }

      // just update state with original chart settings
      fetchNewData({ request: [{ ...initialRequest, range: currentRequest.range }], options: { ...currentConfig.options, xAxis: { categories: false, type: 'datetime' }, title: { ...intialConfig.options.title } } });
    }

    setValue(accumulator);
  };

  const optionControls = React.useMemo(() => options.map(option => <FormControlLabel key={option[0]} value={option[0]} control={<Radio />} label={option[1]} />), [value]);

  return (
    <RadioGroup aria-label='chartType' name='chartType' value={value} onChange={handleChange}>
      {optionControls}
    </RadioGroup>
  );
}
