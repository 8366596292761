import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const noticeEntryStyles = makeStyles((theme: Theme) =>
  createStyles({
    entryRoot: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gap: '4px',
      padding: 16,
    },
    noticeRoot: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '4px',
      padding: 16,
    },
    header: {
      textAlign: 'center',
      alignSelf: 'center',
      marginRight: 'auto',
      paddingLeft: 16,
      width: '',
    },
    iconBox: {
      display: 'flex',
      flexDirection: 'row',
      height: 'min-content',
      alignSelf: 'center',
      gap: '16px',
      paddingLeft: 16,
      paddingRight: 16,
    },
    divider: {
      marginTop: 3,
      marginBottom: 7,
      height: '1px',
      width: '-webkit-fill-available',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
      textAlign: 'left',
    },
    button: {
      marginTop: 'auto',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    validationErrorList: {
      listStyleType: 'circle',
      paddingInlineStart: 10,
      paddingInlineEnd: 10,
    },
    validationErrorItem: {
      paddingLeft: 10,
    },
    list: {
      backgroundColor: theme.palette.grey[100],
      border: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[300],
      marginTop: 8,
    },
  }),
);
