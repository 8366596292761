import { TransferList } from './NexusTransferList';
import { MetadataStore } from 'src/redux/reducer/metadata';
import { useAppSelector } from 'src/redux/hooks';
import { generateOatisDaily } from 'src/components/Charts/components/config/makeTimeSeries';
import { MakeSeriesChartOptions, MakeCoreTimeSeriesSP, SeriesFilterValue } from 'src/types/module/chartModule.types';
import { Datacore } from 'src/types/module/datacore.types';

// eslint-disable-next-line @typescript-eslint/ban-types
export const metadataToTransferList = <P extends keyof MetadataStore, K extends keyof MetadataStore[P]>(storeObject: P, nameProp: MetadataStore[P][K], filter?: Record<string, string | number>): TransferList => {
  const store = useAppSelector(state => state.metadata[storeObject]);
  if (!store) throw new Error(`Could not find metadata store object ${storeObject}, or it is undefined`);
  return Object.keys(store)
    .reduce<TransferList>((prev, curr) => {
      const id = curr;
      const name = store[curr][nameProp];
      const filterMatch = filter
        ? Object.keys(filter).reduce<boolean>((prev, curr) => {
            if (store[id][curr] === filter[curr]) return false;
            return prev;
          }, true)
        : false;
      if (!id || !name) {
        console.warn(`Could not find either id (${id}) or name (${name}) when generating transfer list from ${storeObject}. name prop ${nameProp}`);
        return prev;
      }
      return filterMatch ? prev : [...prev, { id, name }];
    }, [])
    .sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const makeRandomPointRequest = (points: TransferList, meterStore: Record<number, Datacore.OatisMeter>) => (count: number): [MakeSeriesChartOptions<MakeCoreTimeSeriesSP>[], TransferList] => {
  const mutatingList = [...points];
  const sliced: TransferList = [];
  const output: MakeSeriesChartOptions<MakeCoreTimeSeriesSP>[] = [];
  for (let i = 0; i < count; i++) {
    const point = mutatingList.splice(Math.floor(Math.random() * mutatingList.length), 1)[0];
    sliced.push(point);
    const meters = oatisPointToMeters(point.id, meterStore);
    output.push(generateOatisDaily(point.name, [point.name], [meters]));
  }
  return [output, sliced];
};

export const makeGasFlowRequest = (meterStore: Record<number, Datacore.OatisMeter>, config: MakeSeriesChartOptions<MakeCoreTimeSeriesSP>[]): TransferList => {
  const points = oatisMetersToPoints(Array.from(config.map(series => series.seriesFilters?.find(filter => filter[0] === 'meterID')?.[1]))[0], meterStore);
  const sliced: TransferList = [];
  const output: MakeSeriesChartOptions<MakeCoreTimeSeriesSP>[] = [];

  return points;
};

export const oatisPointToMeters = (point: string | number, meterStore: Record<number, Datacore.OatisMeter>): number[] => {
  const meters = Object.values(meterStore)
    .filter(meter => meter.pointID === +point)
    .map(meter => meter.ID);
  return meters;
};

export const oatisMetersToPoints = (meters: SeriesFilterValue | SeriesFilterValue[] | undefined, meterStore: Record<number, Datacore.OatisMeter>): TransferList => {
  if (!meters) return [];

  const points = Object.values(meterStore).reduce<TransferList>((prev, meter) => {
    if (Array.isArray(meters) ? meters.includes(+meter.ID) : +meters === meter.ID) {
      return [...prev, { id: +meter.pointID, name: meter.name }];
    }
    return prev;
  }, []);

  return points;
};
