import { MakeSeriesChartOptions, MakeSunburstReservesSP, MiscSeries } from 'src/types/module/chartModule.types';
import { createSeriesMaps } from '../common';

export const reservesLatest: MakeSeriesChartOptions<MakeSunburstReservesSP> = {
  _makeInterface: MiscSeries.Sunburst,
  range: ['xx', 'xx'],
  name: '2021 Gas+LPG+Oils 2P reserves',
  reserveType: 4,
  options: {
    title: {
      text: '2021 Gas+LPG+Oils 2P reserves',
    },
  },
};

export const [reservesOptionsMap, reservesMakeMap, reservesOptionsArrayMap] = createSeriesMaps({
  reservesLatest,
});
