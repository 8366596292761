import { dateTimeLabelFormats } from './textFormat';

export const plotLinesCommon: Highcharts.YAxisPlotLinesOptions = {
  zIndex: 3,
  width: 1,
};

export const timeSeries: Highcharts.XAxisOptions = {
  type: 'datetime',
  dateTimeLabelFormats,
};

export const categorySeries: Highcharts.XAxisOptions = {
  type: 'category',
};

export const defaultAxes: Pick<Highcharts.Options, 'xAxis' | 'yAxis'> = {
  xAxis: {
    ...timeSeries,
  },
  yAxis: {
    title: {
      text: undefined,
    },
  },
};

export const lineChart: Highcharts.Options = {
  chart: {
    type: 'line',
    panning: {
      enabled: true,
      type: 'xy',
    },
    panKey: 'ctrl',
    zoomType: 'x',
    events: {},
  },

  ...defaultAxes,
};

export const columnChart: Highcharts.Options = {
  chart: {
    type: 'column',
  },
  ...defaultAxes,
};

export const areaChart: Highcharts.Options = {
  chart: {
    type: 'area',
  },

  ...defaultAxes,
};

export const withStacking = (options: Highcharts.Options): Highcharts.Options =>
  options.chart?.type
    ? {
        ...options,
        plotOptions: {
          ...options.plotOptions,
          series: {
            ...options.plotOptions?.series,
            stacking: 'normal',
          },
        },
      }
    : options;

export const withCategories = (options: Highcharts.Options): Highcharts.Options =>
  options.chart?.type
    ? {
        ...options,
        xAxis: {
          ...categorySeries,
        },
      }
    : options;
