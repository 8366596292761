import { Typography } from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';
import React from 'react';
import { ColourDivider } from 'src/style/components';

// eslint-disable-next-line @typescript-eslint/ban-types
function PageHeader({ children }: PropsWithChildren<{}>): JSX.Element {
  return (
    <>
      <Typography variant={'h5'} style={{ fontWeight: 'bold', letterSpacing: '.1rem' }}>
        {children}
      </Typography>
      <ColourDivider flexItem orientation={'horizontal'} colour={indigo[500]} variant={'fullWidth'} />
    </>
  );
}

export { PageHeader };
