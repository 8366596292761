import { mergeDeep } from 'src/lib/util';
import { CoreTimeSeries, MakeCoreTimeSeriesSP, MakeSeriesChartOptions } from 'src/types/module/chartModule.types';
import { lineChart } from '..';
import { createSeriesMaps, defaultRange } from '../common';

const makeCarbonTimeSeries = (contract: string): MakeSeriesChartOptions<MakeCoreTimeSeriesSP> => ({
  ...defaultRange,
  name: 'NZ Carbon',
  seriesNames: ['Spot'],
  seriesFilters: [['contract', [contract]]],
  _makeInterface: CoreTimeSeries.TimeSeries,
  dataTable: 'commTradeCarbon',
  xAxisColumn: 'date',
  seriesColumns: ['last'],
  rollupFunction: 'avg',
  options: mergeDeep<Highcharts.Options>({}, lineChart, {
    title: {
      text: 'NZ Carbon',
    },
  }),
});

export const [carbonOptionsMap, carbonMakeMap, carbonOptionsArrayMap] = createSeriesMaps({
  spotCarbon: makeCarbonTimeSeries('Spot'),
  carbon2022: makeCarbonTimeSeries('2022-04-01'),
  carbon2023: makeCarbonTimeSeries('2023-04-01'),
  carbon2024: makeCarbonTimeSeries('2024-04-01'),
});
